//mejoras Noviembre 2023. Solo se cambimel didMount y se pusierin 2 variables de estado: campo1 y campo2
import React, {useState,useEffect} from 'react'
import { Card,CardTitle,CardSubtitle,CardHeader,CardBody,CardFooter,Input, Label, Button, } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css' //Esta linea esta OK. Con que lo traiga solamente EL LOGIN.JS es suficiente
import {AppContextProvider} from './ContextBase'
import MenuPrincipalCorona from './MenuPrincipalCorona'
import buscarPhpPath,{sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet} from './lib_basica'

// =========================================================
// ***** variables del context (fuera de la funcion )
// =========================================================
//dentro de estatus. exitoContext [true/false]: me sirve para saber si se pudo leer todo bien de la base de datos. mensajeError: bueno cualquier mensaje de error
let estatus={}  //para saber si hubo exito al conectarse y se haya conseguido exitosamente al usuario (y su clave sea correcta)
let usuario={}   //aqui guardo una copia de la tabla usuario (pero sin la clave para que no se vea. ni siquera enrciptada)
let usuarios={} //todos los usuarios, pocas columnas. Mas que todo para las pantallas negras y similares
let estable={}   //datos de UN establecimiento. Solo del usuario logeado
let establecimientos={} //lista de todos los establecimientos. Util para saber el formato y poder imprimir un documento de otro establecimiento
let almacen={}   //datos de UN almacen. Solo del usuario logeado
let almacenes={} //lista de los nombres/alias de TODOS los almacenes
let bancos={}
let vendedores={}
let categorias={}
let correoElectronico={}
let marcas={}
let razonSocial={}
let sistema={}
let iva={}
let unidades={}

//domingo
let pulsadoF5=null //para saber si esta entrando por primera vez o es F5  (valores: true, false)
let miU=null
let miC=null
//para poder cargar la data, numeroDeIntentos=3, milisegundosDelay=4000
let numeroDeIntentos=3
let milisegundosDelay=4000
//es el json que recibo desde PHP
let pepeJson=null 
//variable de la version del front
let frontVersion="1.32" // siempre 2 decimales en modo texto 
let frontFecha="20 ene 2.024" //ejemplo: 20 ene 2.024

const LoginCorona=()=>{
  const [nombreComponenteParaVer,setNombreComponenteParaVer]=useState('vacio') //Nombre del componente que deseo ver. Arranca obviamente con: vacio, luego: login, luego:menu principal
  const [campo1,set_campo1]=useState('')  
  const [campo2,set_campo2]=useState('')  

const prepararParametrosSistemaTexto=()=>{
  if ( (pepeJson.Data!=null && pepeJson.Data.TablaSistemaTexto!=null)==false ) return

  pepeJson.Data.TablaSistemaTexto.forEach ( (item)=>{
    //el campo CodSistema paso a tipo entero para luego usar comodamente === (y asi no me lance el Warning)
    item.CodSistema=parseInt(item.CodSistema)

    //aqui voy a sacar de la tabla SistemaTexto, los valores realmente necesarios
    //************************************ SISTEMA DE 100 a 499 ****************************/
    if (item.CodSistema===100) sistema.division100="****** SISTEMA ******"
    if (item.CodSistema===110) sistema.simboloDeLaMoneda=item.Valor    
    if (item.CodSistema===120) sistema.nombreDeLaMoneda=item.Valor
    if (item.CodSistema===130) sistema.mallaDeInventarioVerPreciosSinIva=item.Valor //puede ser Si,No
    if (item.CodSistema===140) sistema.mallaDeInventarioVerPreciosConIva=item.Valor //puede ser Si,No
    if (item.CodSistema===150) sistema.mostrarProductosInactivosEnElInventario=item.Valor //Si, No
    if (item.CodSistema===160) sistema.diasDeAnticipacionParaMarcarFechaVenc=item.Valor //Entero
    if (item.CodSistema===170) sistema.montoMaximoParaConsumidorFinal=item.Valor
    if (item.CodSistema===180) sistema.precioComun=item.Valor
    if (item.CodSistema===190) sistema.identificacionDeClientePredeterminado=item.Valor
    if (item.CodSistema===200) sistema.cantidadPredeterminadaParaVender=item.Valor
    if (item.CodSistema===210) sistema.ventanaParaPedirCantidadEnVentas=item.Valor //Si.No
    if (item.CodSistema===220) sistema.mallaDeVentasVerPreciosSinIva=item.Valor //puede ser Si,no
    if (item.CodSistema===230) sistema.mallaDeVentasVerPreciosConIva=item.Valor //puede ser Si,no
    if (item.CodSistema===240) sistema.mallaDeVentasVerDescuentoPorcentaje=item.Valor //puede ser Si,no
    if (item.CodSistema===250) sistema.mallaDeVentasVerDescuentoMoneda=item.Valor //puede ser Si,no
    if (item.CodSistema===260) sistema.alertarAlTratarDeHacerFA_NE_PL_SinStock=item.Valor //puede ser Si,no
    if (item.CodSistema===270) sistema.alertarAlTratarDeHacerProforma_SinStock=item.Valor //puede ser Si,no    
    if (item.CodSistema===460) sistema.atsComprasMontoMinimoParaIndicarFormaDePago=item.Valor
    if (item.CodSistema===470) sistema.atsComprasValidar=item.Valor //Si,No
    if (item.CodSistema===480) sistema.atsComprasTipoDeCompraPredefinidaFisicaElectronica=item.Valor //Fisica,Electronica
    if (item.CodSistema===490) sistema.atsComprasCodigoDeSustentoPredefinido=item.Valor //Generalmente 06
    //************************************ CLIENTES NUEVOS DE 500 a 699 ****************************/
    if (item.CodSistema===500) sistema.division500="****** CLIENTES NUEVOS ******"
    if (item.CodSistema===510) sistema.tipoDePrecioParaClientesNuevos=item.Valor  //[Común, Precio 1, Precio 2, Precio 3, Precio 4]
    if (item.CodSistema===520) sistema.formaDePagoParaClientesNuevos=item.Valor  //[Solo contado, Contado y Crédito]. Pero en la tabla de clientes el campo TipoVenta=0,1
    if (item.CodSistema===530) sistema.direccionPorDefectoParaClientesNuevos=item.Valor  //texto
    if (item.CodSistema===540) sistema.ciudadPorDefectoParaClientesNuevos=item.Valor  //texto
    if (item.CodSistema===550) sistema.paraClientesNuevosElCorreoEsObligatorio=item.Valor  //Si,No
        
    if (item.CodSistema===560) sistema.paraClientesNuevosLaDireccionEsObligatoria=item.Valor  //Si,No
    if (item.CodSistema===570) sistema.paraClientesNuevosLaCiudadEsObligatoria=item.Valor  //Si,No
    if (item.CodSistema===580) sistema.paraClientesNuevosElTelefonoFijoEsObligatorio=item.Valor  //Si,No    
    if (item.CodSistema===590) sistema.paraClientesNuevosElTelefonoCelularEsObligatorio=item.Valor  //Si,No    
    if (item.CodSistema===600) sistema.paraClientesNuevosElTelefonoWhatsAppEsObligatorio=item.Valor  //Si,No    
    //************************************ PRODUCTOS NUEVOS DE 700 a 899 ****************************/
    if (item.CodSistema===700) sistema.division700="****** PRODUCTOS NUEVOS ******"
    if (item.CodSistema===710) sistema.tipoDeProductoPredeterminado=item.Valor  //Bien,Servicio
    if (item.CodSistema===720) sistema.gravaIvaEnVentas=item.Valor //Si,No    
    if (item.CodSistema===730) sistema.gravaIvaEnCompras=item.Valor //Si,No (esta es mi referencia)    
    if (item.CodSistema===740) sistema.descontableDeInventario=item.Valor //Si,No      
    if (item.CodSistema===750) sistema.pagaComision=item.Valor //Si,No  
    if (item.CodSistema===760) sistema.productoParaCompra=item.Valor //Si,No      
    if (item.CodSistema===770) sistema.productoParaVenta=item.Valor //Si,No      
    if (item.CodSistema===780) sistema.venderPorDebajoDelCostoPonderado=item.Valor //Si,No      
    if (item.CodSistema===790) sistema.venderSinStock=item.Valor //Si,No          
    //************************************ VENTAS NORMALES DE 900 a 1099 ****************************/
    if (item.CodSistema===900) sistema.division900="****** VENTAS NORMALES ******" 
    if (item.CodSistema===910) sistema.ventasCantidadMaximaDeLineas=item.Valor  //por ahora no lo voy a usar
    if (item.CodSistema===920) sistema.ventasObservacionesObligatorias=item.Valor  //Si. No
    if (item.CodSistema===930) sistema.ventasPedidoObligatorio=item.Valor  //Si,No
    if (item.CodSistema===940) sistema.ventasTextoComplementarioObligatorio=item.Valor  //Si,No
    //************************************ PROFORMAS DE 1100 a 1299 ****************************/
    if (item.CodSistema===1100) sistema.division1100="****** PROFORMAS ******" 
    if (item.CodSistema===1110) sistema.proformasCantidadMaximaDeLineas=item.Valor  //por ahora no lo voy a usar
    if (item.CodSistema===1120) sistema.proformasImprimirCondiciones=item.Valor  //Si. No
    if (item.CodSistema===1130) sistema.proformasValidezPredeterminada=item.Valor  //en dias
    if (item.CodSistema===1140) sistema.proformasTiempoDeEntregaPredeterminado=item.Valor  //en dias
    if (item.CodSistema===1150) sistema.proformasGarantia=item.Valor  //texto
    if (item.CodSistema===1160) sistema.proformasFormaDePago=item.Valor  //CONTADO, CREDITO
    if (item.CodSistema===1170) sistema.proformasPlazoDelCredito=item.Valor  //en dias
    //************************************ NOTAS DE ENTREGA DE 1300 a 1499 ****************************/
    if (item.CodSistema===1300) sistema.division1300="****** NOTAS DE ENTREGA ******" 
    if (item.CodSistema===1310) sistema.notasEntregaCantidadMaximaDeLineas=item.Valor  //por ahora no lo voy a usar
    //************************************ PLAN ACUMULATIVO DE 1500 a 1699 ****************************/
    if (item.CodSistema===1500) sistema.division1500="****** PLAN ACUMULATIVO ******" 
    if (item.CodSistema===1510) sistema.planesCantidadMaximaDeLineas=item.Valor  //por ahora no lo voy a usar
    if (item.CodSistema===1520) sistema.planesDiasPredeterminadoParaPagar=item.Valor  //en dias
    //************************************ COLORES DE 1700 a 1999 ****************************/
    if (item.CodSistema===1700) sistema.division1700="****** COLORES ******" 
    if (item.CodSistema===1710) sistema.coloresCuadroDeTexto=item.Valor   
    if (item.CodSistema===1720) sistema.coloresInventarioEntre1yMinimo=item.Valor   
    if (item.CodSistema===1730) sistema.coloresInventario0yNegativo=item.Valor   
    if (item.CodSistema===1760) sistema.coloresFacturasVencidas=item.Valor   
    if (item.CodSistema===1770) sistema.coloresProformaFavorita=item.Valor   
    if (item.CodSistema===1780) sistema.coloresFilaSeleccionadaPrincipal=item.Valor   
    if (item.CodSistema===1790) sistema.coloresFilaSeleccionadaSecundaria=item.Valor   
    if (item.CodSistema===1800) sistema.coloresItemInactivo=item.Valor   
    if (item.CodSistema===1810) sistema.coloresSriNoAutorizado=item.Valor   
    if (item.CodSistema===1820) sistema.coloresSriNoCorreo=item.Valor   
    if (item.CodSistema===1830) sistema.coloresSriPerfecto=item.Valor   
    //************************** PAGINADO PARA LAS TABELAS DE 2000 a 2299 ****************************/
    if (item.CodSistema===2000) sistema.division2000="****** PAGINADO ******" 
    if (item.CodSistema===2010) sistema.paginadoParaClientes=item.Valor //numerico
    if (item.CodSistema===2015) sistema.paginadoParaBuscadorClientes=item.Valor //numerico
    if (item.CodSistema===2020) sistema.paginadoParaInventario=item.Valor
    if (item.CodSistema===2030) sistema.paginadoParaBuscadorInventario=item.Valor
    if (item.CodSistema===2040) sistema.paginadoParaFacturas=item.Valor
    if (item.CodSistema===2050) sistema.paginadoParaCxC=item.Valor
    if (item.CodSistema===2060) sistema.paginadoParaNotasEntrega=item.Valor
    if (item.CodSistema===2070) sistema.paginadoParaPlanes=item.Valor
    if (item.CodSistema===2080) sistema.paginadoParaProformas=item.Valor
    if (item.CodSistema===2090) sistema.paginadoParaProveedores=item.Valor
    if (item.CodSistema===2095) sistema.paginadoParaBuscadorProveedores=item.Valor
    if (item.CodSistema===2100) sistema.paginadoParaCompras=item.Valor
    if (item.CodSistema===2110) sistema.paginadoParaCxP=item.Valor
    if (item.CodSistema===2120) sistema.paginadoParaOtros=item.Valor
    //************************** DELAY PARA PHP, SWAL, SRI DE 2300 a 2599 ****************************/
    if (item.CodSistema===2300) sistema.division2300="****** DELAY ******" 
    if (item.CodSistema===2310) sistema.numeroDeReintentosPhp=item.Valor
    if (item.CodSistema===2320) sistema.milisegundosParaNuevoReintentoPhp=item.Valor
    if (item.CodSistema===2330) sistema.milisegundosDeDuracionEnNotificacionesSwal=item.Valor
    if (item.CodSistema===2340) sistema.numeroDeReintentosSri=item.Valor
    if (item.CodSistema===2350) sistema.milisegundosParaNuevoReintentoSri=item.Valor
    if (item.CodSistema===2360) sistema.milisegundosEntreRecepcionYautorizacionSri=item.Valor
  })
}

const leerTablas=async()=>{
  let intentos=1
  let seguir=true //seguir en bucle ?
  pepeJson=null; //es lo que recibo desde PHP
  mostrarSwalEspera()
  while (seguir){ 
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','context_leerTablas')
    data.append('login',miU)
    data.append('clave',miC)
    data.append('f5',(pulsadoF5) ? '1':'0' )

    try{
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      pepeJson=await response.json()  //NOTA: al no haber internet, entonces no se puede convertir a JSON y se va automaticmente al CATCH y me muestra la pantalla NEGRA. AL darle salir entonces el sistema completo se cierra y deberia ingresar nuevamete con USUARIO y CONTRASENA      

      //alert (JSON.stringify(pepeJson))
      //analizo la cabecera del JSON de la respuesta. 
      let hayError=false
      let miE=null       
      if (!pepeJson.AgrModConExito){
        //al no hacer todo correctamente, se lanza un mensaje
        hayError=true
        miE="no se pudo ejecutar la solicitud " + ( (pepeJson.MensajeErrorBUC==null) ? "" : pepeJson.MensajeErrorBUC ) + ( (pepeJson.MensajeErrorAgrMod==null) ? "" : pepeJson.MensajeErrorAgrMod )     //no cambiar este mensaje para que coincida con otras librerias:  
      }           
      if (hayError){
        throw miE      
      } 

      //rumbo normal, ya hubo una respuesta *********
      apagarSwal()
      seguir=false   
    } //del try
    catch (e) {    
      intentos++
      if (intentos<=numeroDeIntentos){      
          await sleepPepe(milisegundosDelay)
      }
      else{        
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1        
        }
        else{
          //ha pulsado salir en el swal, no hago nada (elimino un posible usuario)
          sessionStorage.removeItem("usuario")
          seguir=false
        }
      }//del else    
    }  //del catch    
  }// del while
}

const gestionarIngresar=async()=>{
  await leerTablas()
  //al no haber respuesta, me retorno
  if (pepeJson == null) return

  let huboError=false //me sirve para saber si debo pasar del componente vacio al componente del login (aplica solo cuando estoy refrescabdo con F5)
  //determino si el u existe
  if (pepeJson.UsuarioEncontrado1===false){
    mostrarSwalBotonAceptar("error","ATENCION","Usuario o contraseña INCORRECTA")
    huboError=true    
  }
  //determino si la c es correcta. Solo cuando sea primera vez (es decir cuando entra con www....)
  if (huboError===false && pulsadoF5===false){
    //let estatusClave=bcrypt.compareSync(miC, pepeJson.Data.TablaUsuario[0].ClaveUsu)
  if (pepeJson.UsuarioEncontrado2===false){
      mostrarSwalBotonAceptar("error","ATENCION","usuario o Contraseña INCORRECTA")
      huboError=true    
    }  
  }

  //determino si el usuario esta activo
  if (huboError===false && pepeJson.Data.TablaUsuario[0].ActivoUsu==="0"){
    mostrarSwalBotonAceptar("error","ATENCION",`El Usuario ${miU} se encuentra Inactivo`)
    huboError=true    
  }

  if (huboError && nombreComponenteParaVer==="vacio")
    setNombreComponenteParaVer('login')
  
  if (huboError) return

  //preparo el estatus  
  estatus.exitoContext=pepeJson.AgrModConExito
  estatus.mensajeError=pepeJson.MensajeErrorBUC + " " + pepeJson.MensajeErrorAgrMod
  estatus.usuarioEncontrado1=pepeJson.UsuarioEncontrado1 //usuario existe ?
  estatus.usuarioEncontrado2=pepeJson.UsuarioEncontrado2 //la clave es correcta ?
  //preparo las tablas necesarias
  usuario=(pepeJson.Data!=null && pepeJson.Data.TablaUsuario!=null ) ? pepeJson.Data.TablaUsuario[0] : {}  //es preferible con [0]
  usuarios=(pepeJson.Data!=null && pepeJson.Data.TablaUsuarios!=null ) ? pepeJson.Data.TablaUsuarios : {}  //todos los usuarios, pocas columnas.

  estable=(pepeJson.Data!=null && pepeJson.Data.TablaEstablecimiento!=null ) ? pepeJson.Data.TablaEstablecimiento[0] : {}  
  establecimientos=(pepeJson.Data!=null && pepeJson.Data.TablaEstablecimientos!=null ) ? pepeJson.Data.TablaEstablecimientos : {} 
  
  almacen=(pepeJson.Data!=null && pepeJson.Data.TablaAlmacen!=null ) ? pepeJson.Data.TablaAlmacen[0] : {}
  almacenes=(pepeJson.Data!=null && pepeJson.Data.TablaAlmacenes!=null ) ? pepeJson.Data.TablaAlmacenes : {}   
  bancos=(pepeJson.Data!=null && pepeJson.Data.TablaBancos!=null ) ? pepeJson.Data.TablaBancos : {} 
  vendedores=(pepeJson.Data!=null && pepeJson.Data.TablaVendedores!=null ) ? pepeJson.Data.TablaVendedores : {}
  categorias=(pepeJson.Data!=null && pepeJson.Data.TablaCategorias!=null ) ? pepeJson.Data.TablaCategorias : {} 
  correoElectronico=(pepeJson.Data!=null && pepeJson.Data.TablaCorreoElectronico!=null ) ? pepeJson.Data.TablaCorreoElectronico[0] : {} 
  marcas=(pepeJson.Data!=null && pepeJson.Data.TablaMarcas!=null ) ? pepeJson.Data.TablaMarcas : {}
  razonSocial=(pepeJson.Data!=null && pepeJson.Data.TablaRazonSocial!=null ) ? pepeJson.Data.TablaRazonSocial[0] :{} 
  iva=(pepeJson.Data!=null && pepeJson.Data.TablaTiposDeIvaSri!=null ) ? pepeJson.Data.TablaTiposDeIvaSri[0] : {} 
  unidades=(pepeJson.Data!=null && pepeJson.Data.TablaUnidades!=null ) ? pepeJson.Data.TablaUnidades : {}

  //la tabla SistemaTexto, tiene muchas filas. Voy a extraer solo las necesarias
  prepararParametrosSistemaTexto()

  //guardo en disco (sessionstorage)
  sessionStorage.setItem("usuario",miU)

  //mando a mostrar el MENU PRINCIPAL
    //rosa
  //limpio las casillas de Usuario y Contra
  set_campo1('')
  set_campo2('')
  setNombreComponenteParaVer('menu')
}

const formularioVacio=()=>{   
  return (  
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  </div>  
  )
}

const formularioLogin=()=>{   
  return (  
  <div id="divcontainer" name="divcontainer" className="classContainerLogin">    
  <div id="divLogin" name="divLogin" className="classLogin" >    
  
  <Card>
    <CardHeader style={{backgroundColor:'rebeccapurple'}}>
      <CardTitle tag="h2" style={{fontWeight: 'bold',color:'white'}}>
        P A L A D I N O
      </CardTitle>
      <CardSubtitle tag="h6" style={{textAlign:'right',color:'pink'}}>
        corona {frontVersion} 
        <br/>
        {frontFecha}
      </CardSubtitle>    
    </CardHeader>
  
    {/* div para poner el Usuario, contrasena */}    
    <CardBody style={{backgroundColor:'purple',paddingBottom:'20px'}}>
      <Label for="txtU" style={{color:'pink'}}>Usuario</Label>
      <Input name="txtU" id="txtU" style={{marginTop:'5px', width:'100%',height:'40px', fontSize:'18px' }} autoFocus value={campo1}
        onChange={(e)=>set_campo1(e.target.value)}
      />            
      <Label for="txtC" style={{marginTop:"15px",color:'pink'}}>Contraseña</Label>
      <Input type="password" name="txtC" id="txtC" style={{marginTop:'5px',width:'100%',height:'40px', fontSize:'18px' }} value={campo2}
        onChange={(e)=>set_campo2(e.target.value)}
      />            
    
      {/* Boton Aceptar style={{marginRight:'10%'}} */}
      <Button style={{width:'100%',marginTop:'30px',background:'dimgray'}} size="lg" id="btnIngresar" name="btnIngresar" solid="true" 
          onClick={async()=>{          
            if (validarCasillasLlenas()){
               //reviso que haya internet
                if (await hayInternet()===false) return
                await gestionarIngresar() 
            }
            }}>Iniciar Sesión
      </Button>
    </CardBody>
  
    <CardFooter style={{backgroundColor:'rebeccapurple',textAlign:'center'}}>
    <a href="http://www.paladino.digital" target="_blank" class="btn btn-link" style={{color:"goldenrod",}}>www.paladino.digital</a>             
    </CardFooter>
  
  </Card>
  </div> {/* del divLogin */}
  </div> // divContainer
  )
  }

  const validarCasillasLlenas=()=>{
    //Valido que las dos casillas tengan info
    miU=document.getElementById("txtU").value.trim()
    miC=document.getElementById("txtC").value.trim()
   
    if (miU.length==0){
       mostrarSwalBotonAceptar("warning","ATENCION","Debe indicar el Usuario")
       return false
     }
    if (miC.length==0){
       mostrarSwalBotonAceptar("warning","ATENCION","Debe indicar la Contraseña")
       return false
     }
   return true
   }

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  console.log('entrando a login')
  let usuarioDisco=sessionStorage.getItem("usuario")

  if (usuarioDisco !=null){ 
    pulsadoF5=true //viene del menu principal
    miU=sessionStorage.getItem("usuario")
    //elimino y empiezo de nuevo
    sessionStorage.removeItem("usuario")
    gestionarIngresar()  
  }
  else{
    pulsadoF5=false //arrancó escribiendo www.....
    setNombreComponenteParaVer('login')
    //cuando estoy en MIPC, pongo de una vez: admin, admin para ganar tiempo
    if (document.location.hostname==="localhost" || document.location.hostname==="pruebas.paladino.digital"){
      set_campo1('admin')
      set_campo2('admin')
    }
  }
  },[] 
)   

const mostrarMenuPrincipal=()=>{  
  return(
    <AppContextProvider 
      estatus={estatus} //para saber si hubo exito al conectarse
      usuario={usuario}
      usuarios={usuarios}
      estable={estable}
      establecimientos={establecimientos}
      almacen={almacen}
      almacenes={almacenes}
      bancos={bancos}
      vendedores={vendedores}
      categorias={categorias}
      correoElectronico={correoElectronico}
      marcas={marcas}
      licencia={razonSocial}
      sistema={sistema}
      iva={iva}
      unidades={unidades}
      >
      <MenuPrincipalCorona
        regresarAlLogin={()=>{
          setNombreComponenteParaVer('login')
        }}      
      />      
    </AppContextProvider>
  )
}

if (nombreComponenteParaVer==='vacio') return formularioVacio()
if (nombreComponenteParaVer==='login') return formularioLogin()
if (nombreComponenteParaVer==='menu') return mostrarMenuPrincipal()

} //del const LoginCorona

export default LoginCorona
