//Me permite ver los detalles (panel) de documentos no sri (Nota Entrega,Proforma,Plan). Por ejemplo: fecha con hora de elaboracion, cliente, correo,
//NOTA: las proformas nunca guardan la hora de emision. En este caso se usa el campo FechaEmisionFormat
import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//Variables
let valueDC=null //Aqui hago una copia de los valores del context
 
const MostrarPanelDocNoSri=(props)=> {
  //variables de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')

const mostrarDatos=()=>{    
  if (props.tipoDoc.includes("NOTA DE ENTREGA")) 
    document.getElementById("txtDocumentoNumero").value=props.row.Nota  
  if (props.tipoDoc.includes("PROFORMA")) 
    document.getElementById("txtDocumentoNumero").value=props.row.Proforma

  //las proformas no guardan la hora, siempre guarda 0:00, por eso uso mejor el campo: FechaEmisionFormat
  if (props.tipoDoc.includes("PROFORMA"))     
    document.getElementById("txtFecha").value=props.row.FechaEmisionFormat
  else
  document.getElementById("txtFecha").value=props.row.FechaEmision

  document.getElementById("txtCliente").value=props.row.RazonSocial
  document.getElementById("txtCorreo").value=props.row.EmailCliente
  document.getElementById("txtEnviadoPorCorreo").value=props.row.EnviadoAlReceptor
   
  //********************* pare el bloque 2 */
  document.getElementById("txtUsuCrea").value=props.row.OpCrea
  document.getElementById("txtVendedor").value=props.row.NombreVen
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  mostrarDatos()

  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    // por ahora nada
  }  
  },[]
)

const ComponentePrincipalBody=()=>{       
return ( 
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    

{/* Titulo + Boton Cerrar*/}
<Row style={{margin:'0',}}>
  <Col xs="10" >
    <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"PANEL DE " + props.tipoDoc}</Label>
  </Col>
  <Col xs="2" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ props.cerrarModal()  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
  </Col>
</Row> 

{/* Empiezo a mostrar fontSize:'16px' */}
<div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink', }}>
  <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0', marginBottom:"1%"}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Numero</Label>
    </Col>
    <Col xs="6">
      <Input disabled name="txtDocumentoNumero" id="txtDocumentoNumero" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>
  <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0', marginBottom:"1%"}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Emisión</Label>
    </Col>
    <Col xs="6">
      <Input disabled name="txtFecha" id="txtFecha" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>
    </Col>    
  </Row>
  <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0', marginBottom:"1%"}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Cliente</Label>
    </Col>
    <Col xs="8">
      <Input disabled name="txtCliente" id="txtCliente" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>
  <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0', marginBottom:"1%"}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Correo</Label>
    </Col>
    <Col xs="8">
    <Input disabled name="txtCorreo" id="txtCorreo" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>  
  <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0', marginBottom:"1%"}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Enviado</Label>
    </Col>
    <Col xs="2">
    <Input disabled name="txtEnviadoPorCorreo" id="txtEnviadoPorCorreo" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>    
  </div> {/* (divBloque1) */}

  {/* OTRO BLOQUECITO, para mostrar informacion USUCREA, VENDEDOR,.. */}

  <div id="divBloque2" name="divBloque2" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'gray', color:'pink', }}>
  <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0', marginBottom:"1%"}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Creada por</Label>
    </Col>
    <Col xs="5">
      <Input disabled name="txtUsuCrea" id="txtUsuCrea" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'dimgray' }}/>            
    </Col>    
  </Row>
  <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0', marginBottom:"1%"}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Vendedor</Label>
    </Col>
    <Col xs="5">
      <Input disabled name="txtVendedor" id="txtVendedor" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'dimgray'  }}/>
    </Col>    
  </Row>
  </div> {/* (divBloque2) */}

</div> // div externo (master)
) //del return interno
} //del value

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //del  MostrarPanelDocNoSri

export default MostrarPanelDocNoSri
