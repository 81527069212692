//esta tabela, me mestra las CxP de Facturas/Notas de venta/Liquidaciones 
import React, {useState,useEffect} from 'react'
import {Row,Col,Jumbotron,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, {devolverFechaHoyTextoYYYYMMDD,mostrarSwalUnSegundo,sleepPepe} from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,registrarPagoDeCxPconSwal,hayInternet,generarCompraPDFconSwal} from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDonate, faWindowClose, faPiggyBank, faSun, faBolt, faCalendarAlt ,faRunning,faVoteYea,faGlasses,faStickyNote,faCopy,faBan,faMoneyCheckAlt,faDoorOpen,faReceipt,faBroom,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed } from '@fortawesome/free-solid-svg-icons' 
import RecibosEgresoDeUnDocumento from './RecibosEgresoDeUnDocumento'
import VerPdfOk from './VerPdfOk'
import PedirFormaDePago from './PedirFormaDePago'
import MostrarPanelDeLaCompra from './MostrarPanelDeLaCompra'
//Context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let intentos=1 //me sirve para hacer 3 intentos en cada solicitud/peticion. El tope maximo viene en el context
let permanecerEnForm=null //al tratar de refrescar y no poder, entonces posiblemente me tengo que devuelver al MENU PRINCIPAL
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho={
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE FACTURAS'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
		},
	}, 
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender
      fontSize:'18px',   //ok
      fontWeight: 'bold'
    },
  },    
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  }, 
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
    },
  },
}

export default function TabelaCxP(props){
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
  const [modalPanelFacturaState,set_modalPanelFacturaState]=useState(false)    
  const [modalRecibosCajaState,set_modalRecibosCajaState]=useState(false)  
  const [modalFormaDePagoState,set_modalFormaDePagoState]=useState(false)  

const filaCondicional=[
  //fila NO seleccionada. Las facturas vencidas las pone de fondo gris (o sebgun prefefrencias del sisgtema)
  {
    when: row => (rowRegistroState==null || row.ComprobanteConIDr != rowRegistroState.ComprobanteConIDr ),
    style: row => ({ 
      backgroundColor:(devolverFechaHoyTextoYYYYMMDD() >= row.FechaVence)  ? valueDC.sistema.coloresFacturasVencidas : 'white',
      color: 'dimgray', 
    }),    
    },
    //fila seleccionada    
    { 
      //al tocar una fila se debe poner azul (aunque este vencida).
      when: (row) => (rowRegistroState!=null && row.ComprobanteConIDr == rowRegistroState.ComprobanteConIDr ),      
      style: row=> ({    
        backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
        color: 'white', 
      }),
    },
]

const miEstructura=[  
  {  
    name:'COMPROBANTE', //lo que el usario ve. 17 caracteres
    selector:row=>row.Comprobante, //Tal como viene en el Json desde las Base de datos
    sortable:true,
    left:true,
    grow:1.5, //es una medida relativa (1 es mi referencia)
    compact:true, //sets cell padding to 0
    omit:false, //en algunos casos, se debe ocultar algunas columnas
  },
  {  
    name:'COMPROBANTECONIDR',  
    selector:row=>row.ComprobanteConIDr, 
    sortable:true,
    left:true,
    grow:1.5,  
    compact:true,  
    omit:true,  
  },
  {
    name:'RAZON SOCIAL',
    selector:row=>row.RazonSocial, 
    sortable:true,
    left:true,
    compact:true,
    grow:1.5,
  },      
  {
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1.3,  

    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton Anular y Enviar */}
    <Button id="btnAbonar" name="btnAbonar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      onClick={(e)=>{
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_modalFormaDePagoState(true)
      }}>
      <FontAwesomeIcon size='lg' color="white" icon={faDonate}/></Button>

    <Button id="btnRecibos" name="btnRecibos" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      onClick={(e)=>{    
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_modalRecibosCajaState(true)
      }}>
      <FontAwesomeIcon size='lg' color="white" icon={faMoneyCheckAlt} /></Button>          
    {/** dropdown para el boton MAS... */}
    <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.ComprobanteConIDr } direction="right"  
      toggle={()=>{      
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.ComprobanteConIDr : 0)        
      }}>    
        <DropdownToggle color="info">
          <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
        </DropdownToggle>
      <DropdownMenu style={{backgroundColor:'pink',}} >            

      <DropdownItem onClick={ () => gestionarVerCompraPdf() }>
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faGlasses}  /> Ver compra</DropdownItem>        

      <DropdownItem onClick={ () => set_modalPanelFacturaState(true)}>
      <FontAwesomeIcon style={{color:'dimGray'}} icon={faVoteYea}  /> Ver panel</DropdownItem>        

      <DropdownItem onClick={ () => alert(JSON.stringify(rowRegistroClon,null,2) ) }> <FontAwesomeIcon style={{color:'dimGray'}} icon={faVoteYea}  /> VJDLFS</DropdownItem>        
      <DropdownItem divider />
      <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        

      </DropdownMenu>
      </Dropdown>
    </div>,
  },
  {
    name:'FECHA',
    selector:row=>row.FechaComprobanteFormat, 
    sortable:true,
    left:true,
    compact:true,
    grow:0.7,   
  },
  {
    name:'F.VENCE',
    selector:row=>row.FechaVenceFormat, 
    sortable:true,
    left:true,
    compact:true,
    grow:0.7,   
  },
  {
    name:'IDENTIFIC.',
    selector:row=>row.IdPrincipal, 
    sortable:true,
    left:true,
    compact:true,
    grow:0.9,  
  },
  {
    name:'TOTAL',
    selector:row=>row.TotalCompra, 
    sortable:true,
    right:true,
    compact:true,
    grow:0.7, 
  },  
  {
    name:'ABONOS',
    selector:row=>row.AbonosAnteriores, 
    sortable:true,
    right:true,
    compact:true,
    grow:0.7, 
  }, 
  {
    name:'SALDO',
    selector:row=>row.Saldo, 
    sortable:true,
    right:true,
    compact:true,
    grow:0.7, 
  },  
  {
    name:'CREA',
    selector:row=>row.OpCrea, 
    sortable:true,
    center:true,
    compact:true,
    grow:0.25,
    hide:'sm', //se oculta en pantallas pequenas por debajo de 600px de ancho
    //hide:'md',
  }, 
]

const refrescarData=async()=>{ 
intentos=1
let seguir=true  
mostrarSwalEspera()
while (seguir){
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','cxp_listaParaTabela')
  data.append('estabLogin',valueDC.usuario.EstabUsu)
  data.append('orderBy','FechaVence')
  data.append('forma','Asc') 
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeJson = await response.json()  
    //analizo la cabecera del JSON de la respuesta
    let hayError=false
    let miE=null
    if (!pepeJson.ConexionBUC){
      hayError=true
      miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
    } 
    if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
      hayError=true
      miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
    }     
    if (hayError){
      throw miE      
    } 
    //rumbo normal *********    
    set_registrosFullState(pepeJson.Data)
    apagarSwal()
    seguir=false
  } //del try
  catch (e) {   
    intentos++
    if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
      await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
    }
    else{  
      //llamo al gestor para que me aparezca la pantalla de REINTENTAR
      apagarSwal()
      let miRespSwal = await gestionarCatch(e)
      //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
      if (miRespSwal.isDenied) {
        mostrarSwalEspera()
        intentos=1
      }
      else{
        //ha pulsado salir en el swal
        seguir=false
        if (!permanecerEnForm){
          props.activarMenu()
          desmontar()
        }
      }
    }//del else
  }//del catch
} //del while
}
  
const hayFilaSeleccionada=()=>{
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Factura")          
    miDevolver=false
  }
  return miDevolver
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //=== blanqueo estas variables 
  rowRegistroClon=null 
  permanecerEnForm=false
  refrescarData()
  },[]
)

const desmontar=()=>{
  set_registrosFullState([])
  //hay que blanquear las variables que no son parte del estado. Obligatorio, ya que al volver a entrar nuevamente al formulario, entonces queda el valor de la sesion anterior  
  //Solo las mas sensibles
  rowRegistroClon=null 
}

const filtrarElementos=()=>{
  if (rowRegistroState != null) {
    set_rowRegistroState(null)                 
    rowRegistroClon=null
  }
  if (idMasState !=0) set_idMasState(0)
  let cadenaLoca=document.getElementById("inputBusqueda").value.trim().toLocaleLowerCase()  
  let filtradosTmp=registrosFullState.map(item=>{
    if (  
      item.Comprobante.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.TotalCompra.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.OpCrea.toString().toLocaleLowerCase().includes(cadenaLoca)
      ) 
        item.VisibleSN='S'
      else
        item.VisibleSN='N'

        return item        
    })
  set_registrosFullState(filtradosTmp)
} 

const copiarVariablesDesdeContext=(value)=>{
  valueDC=value
}

const gestionarVerCompraPdf=async()=>{
  if (await hayInternet()==false) return 
  //debo generar el PDF y luego lo muestro  
  let pdfCreadoCorrectamenteSN=await generarCompraPDFconSwal(rowRegistroClon.IDr,rowRegistroClon.Tipo.substring(0,1),rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumFac,valueDC)
  if (pdfCreadoCorrectamenteSN=="S")
    set_nombreComponenteParaVerState('verPdf')
  else
    mostrarSwalBotonAceptar("error","ATENCION","No se pudo crear el PDF correctamente")
}

const actualizarTabelaDespuesDeAgregarRecibo=(miAbono,miSaldo)=>{
  //esta funcion, actualiza los campos [AbonosAnteriores] y [Saldo]
  //o bien, elimina la compra si ya se cancelo toda la deuda
  //miAbono,miSaldo ya vienen float (pero en el JSON se requiere tipo texto)

  let copiaFull=null
  let copiaFiltrada=null
  //=============== cuando sea eliminar de la tabela
  if (miSaldo<=0){
    copiaFull=registrosFullState.filter(item=>(item.ComprobanteConIDr!=rowRegistroClon.ComprobanteConIDr) )
    //elimino del estado la referencia a la factura
    set_rowRegistroState(null)
    set_idMasState(0)
    //refresco la tabela con los cambios
    set_registrosFullState(copiaFull)
    rowRegistroClon=null
  }
  //=============== Cuando sea actualizar los campos [AbonosAnteriores] y [Saldo]
  if (miSaldo>0){
    copiaFull=registrosFullState.map(item=>{ 
      if (item.ComprobanteConIDr==rowRegistroClon.ComprobanteConIDr) {
        let a=parseFloat(item.AbonosAnteriores)
        a=a+miAbono
        let s=parseFloat(item.Saldo)
        s=s-miAbono
        item.AbonosAnteriores=a.toFixed(2)
        item.Saldo=s.toFixed(2)
      }
      return item
    })
    set_registrosFullState(copiaFull)
  }  
}

const actualizarTabelaDespuesDeEliminarRecibo=(sumaDeAbonos)=>{
  //esta funcion, actualiza los columnas [AbonosAnteriores] y [Saldo]
  //el valor sumaDeAbonos, viene como float
    
  let copiaFull=null
  copiaFull=registrosFullState.map(item=>{ 
    if (item.ComprobanteConIDr==rowRegistroClon.ComprobanteConIDr) {
      let tf=parseFloat(item.TotalCompra)
      let s= tf - sumaDeAbonos
      item.AbonosAnteriores=sumaDeAbonos.toFixed(2)
      item.Saldo=s.toFixed(2)
    }
    return item
    })

    //refresco la tabela con los cambios
    set_registrosFullState(copiaFull)
}

const Componente_ListaRegistros=()=>{
return (  
<AppContextConsumer>
{value =>{  
return(  
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'0', paddingBottom:'10px', }} > 

  {/* Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick={()=>{          
          props.activarMenu()
          desmontar()
        }} 
      >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 

  {copiarVariablesDesdeContext(value)}

  <div id="divSubMaster" name="divSubMaster">
    {/* En algunas ocasiones se requiere el divSubMaster para poner la barra de menu */}

    {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
        <Input
        className = "textoBusquedaClientes" type="text" placeholder="Texto de búsqueda" name="inputBusqueda" id="inputBusqueda" 
        onChange={()=>{
          filtrarElementos()
        }}/>
        <Button id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
          onClick={()=>{          
            document.getElementById("inputBusqueda").value='' 
            set_rowRegistroState(null)
            rowRegistroClon=null
            set_idMasState(0)
            filtrarElementos()
          }}> 
          <FontAwesomeIcon color="paleGreen" icon={faBroom} />
        </Button>
    </div> { /* divBuscador1 */ }

  </div> { /* div SubMaster */ }

    { /* inicio del DIV para la tabela */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title='Cuentas por Pagar'
    columns={miEstructura}
    data={registrosFullState.filter(item=>item.VisibleSN=='S')}
    conditionalRowStyles={filaCondicional}  
    keyField ='Factura' /* Se puede poner 'id' (valor por omision), 'Factura' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */   
    onRowClicked={(row) => { 
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_idMasState(0)      
    }}
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""  //Nada por aquí
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //ahora se usa dentro de estilacho
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //onRowClicked={miOnRowClicked()} //callback to access the row, even on row click (no pude hacerle funcionar)
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras
    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    paginationPerPage={ valueDC.sistema.paginadoParaFacturas } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //pepe. no poner fixedHeader
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }
 
    {/* ******* MODAL PARA VER UNA LISTA DE LOS RECIBOS DE CAJA ********/}
    {/********** es posible que se elimimen recibos en el modal  */}
    <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalRecibosCajaState } >
      <RecibosEgresoDeUnDocumento
        cerrarModal={(todoOKsn,sumaDeAbonos)=> {
          //todoOKsn, es una variable para saber si se cargo exitosamente la lista de recibos
          //sumaDeAbonos: Sumatoria de los abonos
          //actualizo las columnas de Abonos y Saldo (alomejor se eliminaron recibos)
          if (todoOKsn=="s"){
            actualizarTabelaDespuesDeEliminarRecibo(sumaDeAbonos) //se eliminen o no los recibos, siempre hay que actualizar la tabela
          }
          set_modalRecibosCajaState(false)
        }}
        IDr={rowRegistroClon==null ? '' : rowRegistroClon.IDr}
        proveedorNombre={rowRegistroClon==null ? '' : rowRegistroClon.RazonSocial}
        doc17DG={rowRegistroClon==null ? '' : rowRegistroClon.Comprobante} 
        estabDG={rowRegistroClon==null ? '' : rowRegistroClon.Estab}
        puntoDG={rowRegistroClon==null ? '' : rowRegistroClon.Punto}
        numDG={rowRegistroClon==null ? '' : rowRegistroClon.NumFac}
        fechaDG={rowRegistroClon==null ? '' : rowRegistroClon.FechaIngresoFormat}
        montoDG={rowRegistroClon==null ? '' : rowRegistroClon.TotalCompra}
        //desde este formulario, solo pueden haber documentos del tipo FACTURA,NOTA DE VENTA,LIQUIDACION
        prefijoDG={rowRegistroClon==null ? '' : rowRegistroClon.TipoDocFNL}
        condicion={'CREDITO'}
      />
    </Modal>
      
  {/************ MODAL PARA VER EL PANEL DE LA COMPRA *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalPanelFacturaState } >
    <MostrarPanelDeLaCompra
      cerrarModal={()=> {
        set_modalPanelFacturaState(false)
      }}
      row={rowRegistroClon}      
    />
  </Modal>  
  
  {/* ************************* MODAL PARA LA FORMA DE PAGO *************** */}
  <Modal style={{ background:'blue',}} size={'lg'} isOpen={ modalFormaDePagoState } >
    <PedirFormaDePago        
      totalFull= { (rowRegistroClon==null) ? 0 : parseFloat(rowRegistroClon.Saldo) } // total a cobrar realmente (2 decimales)    
        mostrarRetSN= {'S'} //Mostrar retencion en la forma de pago        
        todoAbonoTA={'A'} //Para saber si debe pagar Todo, o puede hacer Abono
        recibirPago={async(miFaltanteFloat,miJsonTmp,seleccionOk)=>{ 
          //miJosonTmp es el detalle del pago
          set_modalFormaDePagoState(false)
          if (!seleccionOk) return

          //Aqui mando a registrar el pago en la BDD. Pero no mando a generar ningun PDF (solo le doy una alerta que todo salio bien)
          //al recibir un numero Negativo significa algun error. (lo ideal es un numero positivo. SQL me devuelve el numero de recibo generado)
          //Aqui guardo el monto del Abono/Pago
          let miAbono=parseFloat(rowRegistroClon.Saldo)-miFaltanteFloat
          miAbono=miAbono.toFixed(2)
          let miRespuesta=await registrarPagoDeCxPconSwal(rowRegistroClon.IDr,rowRegistroClon.Tipo.substring(0, 1),
            rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumFac,
            ((miFaltanteFloat<=0) ? "C" : "A"),miAbono,miJsonTmp,valueDC) 
          //La respuesta (numero de recibo de caja) la recibe como texto (pero es un numero)
          miRespuesta=parseInt(miRespuesta)
          if (miRespuesta>0){
            //Arreglo la tabela (Bien sea actualizar la columna de abonos y saldo. O bien eliminar de la tabela cuando sea cancelacion completa)
            actualizarTabelaDespuesDeAgregarRecibo(parseFloat(miAbono),miFaltanteFloat)
            //mando una alerta que todo salio bien
            mostrarSwalBotonAceptar("success","OK","Pago registrado correctamente")            
          }
          if (miRespuesta==-100){
            mostrarSwalBotonAceptar("error","ERROR","La compra ya estaba cancelada anteriormente")            
          }
          if (miRespuesta<0 && miRespuesta != -100){
            mostrarSwalBotonAceptar("error","ERROR","No se pudo registrar el pago")            
          }
        }
      }
    />
  </Modal>  

</div> //{/*  divMaster */}
) //del return interno
}}
</AppContextConsumer>
)
} 

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return Componente_ListaRegistros()
if (nombreComponenteParaVerState=='verPdf')
  return(<VerPdfOk
    documentoTitulo={"COMPRA"}
    documentoArchivo={"COMPRA_" + rowRegistroClon.Estab + "-" + rowRegistroClon.Punto + "-" + rowRegistroClon.NumFac }    
    corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
    tipoSriSN={"N"} //es un documento del sri?
    activarMenu={()=>{
      set_nombreComponenteParaVerState('lista') 
      set_idMasState(0)
    }}
  />)
  
} //del Export
