import React from 'react'
import {Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose ,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
 
export default class PedirPrecioUnItem1234 extends React.Component {   

MiIvaDef=null //aqui voy a guardar el %de iva para este producto
precio1234=null //precio seleccionado
i=null //para saber cual radio debo prender de antemano

seleccionarYsalir=()=>{
for (let i = 0; i < document.getElementsByName("radioPrecio").length; i++)  
  if (document.getElementsByName("radioPrecio")[i].checked)
    this.precio1234=document.getElementsByName("radioPrecio")[i].value

  //hago el cambio en el llamador. le mando this.precio1234 como texto
  this.props.cambiarPrecioUnItem1234(this.precio1234)
  
  //Cierro (espero varios milisegundos, para que el usuario vea el radio seleccionado)    
  setTimeout(()=>{ 
    this.props.cerrarModal()
  },400)
}

cambiarPuntoPorComa=(miTexto)=>{
  miTexto=miTexto.replace(".",",")
  return miTexto
}

componentDidMount(){  
//en la variable i, tengo el numero de radio que debo prender
if (this.i>=1){
    let miRadio='radP' + this.i
    document.getElementById(miRadio).setAttribute("checked","true")
}

this.MiIvaDef=(this.props.miFilaAzulJson.GravaIva=='No') ? 0 : parseFloat(this.props.MiIvaContext)

//pongo los precios (en el json, lo precios vienen como texto). 
//Debo reemplazar el punto por la coma
let pvpTexto=null
let pvpNumero=null

//PVP1
  document.getElementById("txtP1Sin").value=this.cambiarPuntoPorComa(this.props.miFilaAzulJson.P1)
  pvpNumero=parseFloat(this.props.miFilaAzulJson.P1)  
  pvpTexto=(pvpNumero + pvpNumero * this.MiIvaDef/100).toFixed(4)
  document.getElementById("txtP1Con").value=this.cambiarPuntoPorComa(pvpTexto)

//PVP2
  document.getElementById("txtP2Sin").value=this.cambiarPuntoPorComa(this.props.miFilaAzulJson.P2)
  pvpNumero=parseFloat(this.props.miFilaAzulJson.P2)  
  pvpTexto=(pvpNumero + pvpNumero * this.MiIvaDef/100).toFixed(4)
  document.getElementById("txtP2Con").value=this.cambiarPuntoPorComa(pvpTexto)

//PVP3
  document.getElementById("txtP3Sin").value=this.cambiarPuntoPorComa(this.props.miFilaAzulJson.P3) 
  pvpNumero=parseFloat(this.props.miFilaAzulJson.P3)
  pvpTexto=(pvpNumero + pvpNumero * this.MiIvaDef/100).toFixed(4)
  document.getElementById("txtP3Con").value=this.cambiarPuntoPorComa(pvpTexto)

//PVP4
  document.getElementById("txtP4Sin").value=this.cambiarPuntoPorComa(this.props.miFilaAzulJson.P4) 
  pvpNumero=parseFloat(this.props.miFilaAzulJson.P4)
  pvpTexto=(pvpNumero + pvpNumero * this.MiIvaDef/100).toFixed(4)
  document.getElementById("txtP4Con").value=this.cambiarPuntoPorComa(pvpTexto)
}

render(){    
this.i=this.props.miFilaAzulJson.Precio01234 //guardo el precio anterior
let descripcion40Letras=this.props.miFilaAzulJson.DescripcionModificada.substring(0,38)
return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Nombre del producto + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{descripcion40Letras}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ this.props.cerrarModal() }}>
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row>             
  <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink'}}>    
    {/* Precio 1  defaultValue={(this.props.miFilaAzulJson.P1*1)} */}
    <Row style={{margin:'0'}}>
      <Col xs="6" style={{display:'flex'}}>
        <Label> 
          <Input type="radio" id="radP1" value="1" name="radioPrecio"
            onClick = {(e) => { this.seleccionarYsalir() }} /> PVP 1</Label>
        <Input disabled style={{textAlign:'right', width:"60%",marginLeft:"4%"}} name="txtP1Sin" id="txtP1Sin"/>
      </Col>
      <Col xs="6" style={{display:'flex'}}>
        <Label >+ IVA</Label>
        <Input disabled style={{textAlign:'right', width:"70%",marginLeft:'4%'}} id="txtP1Con" name="txtP1Con"/> 
      </Col>  
    </Row>  
    {/* Precio 2 */}
    <Row style={{margin:'0',paddingTop:'2%'}}>
      <Col xs="6" style={{display:'flex'}}>
        <Label>
          <Input type="radio" id="radP2" value="2" name="radioPrecio"
            onClick = {(e) => { this.seleccionarYsalir() }} /> PVP 2</Label>
        <Input disabled style={{textAlign:'right', width:"60%",marginLeft:"4%"}} name="txtP2Sin" id="txtP2Sin"/>
      </Col>
      <Col xs="6" style={{display:'flex'}}>
        <Label >+ IVA</Label>
        <Input disabled style={{textAlign:'right', width:"70%",marginLeft:'4%'}} id="txtP2Con" name="txtP2Con"/> 
      </Col>  
    </Row>  
    {/* Precio 3 */}
    <Row style={{margin:'0',paddingTop:'2%'}}>
      <Col xs="6" style={{display:'flex'}}>
        <Label> 
          <Input type="radio" id="radP3" value="3" name="radioPrecio"
            onClick = {(e) => { this.seleccionarYsalir() }} /> PVP 3</Label>
          <Input disabled style={{textAlign:'right', width:"60%",marginLeft:"4%"}} name="txtP3Sin" id="txtP3Sin" />   
      </Col>
      <Col xs="6" style={{display:'flex'}}>
        <Label >+ IVA</Label>
        <Input disabled style={{textAlign:'right', width:"70%",marginLeft:'4%'}} id="txtP3Con" name="txtP3Con"/> 
      </Col>  
    </Row>  
    {/* Precio 4 */}
    <Row style={{margin:'0',paddingTop:'2%'}}>
      <Col xs="6" style={{display:'flex'}}>
        <Label> 
          <Input type="radio" id="radP4" value="4" name="radioPrecio"
            onClick = {(e) => { this.seleccionarYsalir() }} /> PVP 4</Label>
          <Input disabled style={{textAlign:'right', width:"60%",marginLeft:"4%"}} name="txtP4Sin" id="txtP4Sin"/>
      </Col>
      <Col xs="6" style={{display:'flex'}}>
          <Label >+ IVA</Label>
          <Input disabled style={{textAlign:'right', width:"70%",marginLeft:'4%'}} id="txtP4Con" name="txtP4Con"/> 
      </Col>  
    </Row>  
  </div>  {/* divBloque1 */ }
</div> // divMaster
)}
}