//Modifica el stock de UN producto en la BDD
import React, {useState,useEffect} from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import buscarPhpPath,{gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,sleepPepe,hayInternet } from './lib_basica'
import { aceptarSoloEnteros,aceptarDecimales } from './lib_basica'
 
export default function ModificarStockInventario(props){
  let miCodRojo=null //aqui guardo el codigo del producto
  let miAlmacen=null //aqui guardo el codigo del almacen
  let miCantEntrar=null //guardo la cantidad al entrar
  let miCantSalir=null //guardo la cantidad al salir (al comparar, si son diferentes entonces se guarda fisicamente)  
  let intentos=0 //me sirve para hacer 3 intentos en cada solicitud/peticion. El tope maximo viene en el context
  let resultadoPhp=false //para saber si pudo actualizar en PHP [true,false]

const guardarNuevoStock=async()=>{
  intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){  
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'  
    let data=new FormData()
    data.append('miSol','inv_modificarStock')
    data.append('miAlm',miAlmacen)   
    data.append('txtID',miCodRojo) //codigo rojo del producto    
    data.append('txtStock', miCantSalir) //nuevo stock  
    try{
      let response = await fetch(miPhpFile, {method:"POST", body:data} )
      let rjson = await response.json()
  
      //reviso mi cabecera de JSON de la respuesta
      let hayError=false
      let miE=null
      if (!rjson.ConexionBUC){
          hayError=true
          miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (rjson.ConexionBUC && !rjson.AgrModConExito){
          hayError=true
          miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
          throw miE
      }        
      //************ Rumbo Normal. Todo OK **********/      
      resultadoPhp=true
      apagarSwal()
      seguir=false      
    }   
    catch (e) {
      intentos++
      if (intentos<=props.valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(props.valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else{
        apagarSwal()
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        let miRespSwal = await gestionarCatch(e)
      
        //ha pulsado reintentar
        if (miRespSwal.isDenied) {
           mostrarSwalEspera()
           intentos=1
        }
        else{
          //ha pulsado salir en el swal
          //no hago nada. Pero me quedo en la pantalla de Edicion
          seguir=false
        }
      } //del else
    } //del catch
  }//del while  
}

const validarPantalla=()=>{
  miCantSalir=document.getElementById('txtCant').value
  miCantSalir=parseFloat(miCantSalir)

  if (isNaN(miCantSalir)) {
    mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un número válido")
    return false
  }

  if (miCantSalir<0) {
    mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un número Positivo")
    return false
  }

  miCantSalir=Math.round(miCantSalir*100)/100
  miCantSalir=miCantSalir.toFixed(2) 
  miCantSalir=parseFloat(miCantSalir) 
  
  return true
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{   
  //guardo de que almacen estamos hablando, y el codigo rojo del producto
  miAlmacen=parseInt(props.valueDC.estable.Almacen)
  miCodRojo=props.miProd.IDr

  //guardo el stock al entrar
  switch (miAlmacen) {
    case 0:
      miCantEntrar=props.miProd.A0_Stock
      break  
    case 1:
      miCantEntrar=props.miProd.A1_Stock
      break  
    case 2:
      miCantEntrar=props.miProd.A2_Stock
      break  
    case 3:
      miCantEntrar=props.miProd.A3_Stock
      break  
    case 4:
      miCantEntrar=props.miProd.A4_Stock
      break  
    case 5:
      miCantEntrar=props.miProd.A5_Stock
      break  
    case 6:
      miCantEntrar=props.miProd.A6_Stock
      break  
    case 7:
      miCantEntrar=props.miProd.A7_Stock
      break  
    case 8:
      miCantEntrar=props.miProd.A8_Stock
      break                                  
    case 9:
      miCantEntrar=props.miProd.A9_Stock
      break                                        
  }

    //lo convierto a numerico para luego poder hacer las comparaciones y validaciones
    miCantEntrar=parseFloat(miCantEntrar)
    document.getElementById("txtCant").value= (miCantEntrar)
},[]
)

const gestionarGuardarNuevoStock=async()=>{
  if (!validarPantalla()) return          
  if (await hayInternet()==false) return
  
  //guardo en la base de datos
  await guardarNuevoStock()  
  if (!resultadoPhp) return
  //cierro el modal
  props.cerrarModal("ok",miCodRojo,miCantSalir)
}

const Componente_Stock=()=>{ 
let descripcion40Letras=props.miProd.Descripcion.substring(0,38)
return (
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Nombre del producto + Boton Cerrar*/}
  <Row style={{margin:"0"}}>
    <Col xs="9" >
      <Label style={{fontWeight: 'bold'}}>{descripcion40Letras}</Label>
    </Col>
    <Col xs="3" style={{ padding:'0',textAlign:'right',}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ props.cerrarModal('close',null,null)  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 
  <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink'}}>
    <Row style={{margin:"0"}}>
      <Col xs="12" >
        <Label for="txtCant">Indíque el stock</Label>
        <Input type="number" inputMode="decimal"  style={{textAlign:'center', fontWeight: 'bold', height:'80px',fontSize:'50px' }} name="txtCant" id="txtCant"
          onKeyPress = {(e) => { 
            aceptarDecimales(e) 
            let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
            if (miKey==13) gestionarGuardarNuevoStock()
            }}/>
        <Label for="txtCant">(Máx 2 decimales)</Label>
      </Col>
    </Row>
    <Row style={{marginTop:'4%',marginBottom:'4%',marginLeft:'0',marginRight:'0'}}>
      <Col xs="12" style={{textAlign:'center',}}>    
        <Button id="btnGuardar" name="btnGuardar" color="success" solid="true" style={{width:"48%", marginRight:'3%',}} 
          onClick={async()=>{
            gestionarGuardarNuevoStock()
          }}>GUARDAR
        </Button>
      </Col>         
    </Row>  
  </div> {/* //div interno */}
</div> //div master
)}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (1==1) return Componente_Stock()

} //del export