//corona
//Este form, se demora demasiado en mostrarse. se queda mucho tiempo en swal
//es con la funcion refrescarClientes. y
//alert(JSON.stringify(registrosFullState,null,2) 
//observaciones: en clientes al buscar, editar, consultar funciona bien
//pero al buscar inventario se ve el fondo verde al lado derecho (PROBLEMA)
//pero si escribo en el cuadrito negro y pulso enter, queda bien
//FALTA: al finalizar la venta se debe imprimir el comprobante cuando se desea tickera
//FALTA: al usar display:grid para PVP1, al lado de cedula, se ve en el area de la factura (pero solo en el ipad viejo)
//el consumido final, (maximo 200$ incluyendo iva)
import React, {useContext} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import DataTable from 'react-data-table-component'
import buscarPhpPath, { aceptarSoloEnteros,aceptarDecimales,generarFacturaDeVentaSimple_ConSwal,determinarFormatoImpresoraGrande,hayInternet,buscarProformaDetalleParaClonarModificar,hacerRenumDeUnJson,ejecutarFetchGenericoConSwal } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalBotonAceptarBucle,mostrarSwalPos,mostrarSwalUnSegundo,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,devolverFechaHoyTextoYYYYMMDD,textoDelCombo,formatearFechaHoraDevuelveDDMMYY,sleepPepe } from './lib_basica'
//del context
import {AppContextConsumer} from './ContextBase'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDownload, faWindowClose,faClipboard ,faFlag,faParking,faFileAlt,faDoorClosed,faDoorOpen, faBroom,faBarcode, faArrowDown,faArrowCircleDown,faArrowAltCircleDown,faEye,faPager,faCaretDown,faChevronCircleDown,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faMoneyBillAlt, faCashRegister,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faEllipsisV,faSyncAlt, faArrowAltCircleLeft, faGrinTongueSquint } from '@fortawesome/free-solid-svg-icons' 
//Modales
import VerPdfOk from './VerPdfOk' 
import IncModCliente from './IncModCliente'
import IncModInventario from './IncModInventario'
import TabelaClientesBuscador from './TabelaClientesBuscador' 
import TabelaInventarioBuscador from './TabelaInventarioBuscador' 
import PedirTextoGenericoDescripcion from './PedirTextoGenerico' //Para pedir nueva descripcion del producto (le puse 300 caractares maximo)
import PedirTextoGenericoSerie from './PedirTextoGenerico' //Para pedir/cambiar la serie (le puse 100 caractares maximo)
import PedirCantidad from './PedirCantidad'
import PedirPrecioUnItem1234 from './PedirPrecioUnItem1234'
import PedirPrecioTodosItem1234 from './PedirPrecioTodosItem1234'
import PedirDescuentoP from './PedirDescuentoP'
import PedirPrecioEspecifico from './PedirPrecioEspecifico'
import PedirTextoComplementario from './PedirTextoComplementario' //Para pedir texto complementario de la Factura (maximo 300)
import PedirFormaDePago from './PedirFormaDePago'
import PedirCondicionesPresupuesto from './PedirCondicionesPresupuesto'
import PedirDiasCredito from './PedirDiasCredito'
import TabelaPresupuestosBuscador from './TabelaPresupuestosBuscador'
import ModalVacio from './ModalVacio'

const miEstilacho = {
	table: {
		style: {
			minHeight: '25vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'ITEMS SELECCIONADOS'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      background: 'pink', //ok hotpink
      color: 'gray',  //ok lavender
      fontSize:'16px',   //ok
      fontWeight: 'bold'
    },
  },
  rows: {    
    style: {
      minHeight: '40px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',
    }
  },  
  cells: {
    style: {
      fontSize:'16px',
      cursor: 'pointer',
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',                 
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL      
    },
  },
}

export default class Facturacion extends React.Component {
state = {         
        //miFilaAzulIndice es el INDICE en el que se encuentra en AZUL una fila
        miFilaAzulIndice:-1, //OJO: arranco con -1 importante
        datosInv:[],  //contiene todas las filas del inventario (pocas columnas)
        datosFullCli:[],  //contiene todas las filas de los clientes (pocas columnas)
        opcionCliente_imc:'', //para saber si deseo incluir nuevo cliente, modificar o consultar
        opcionInv_imc:'c',//Solo lo uso para CONSULTAR un producto del inventario
        cedulaCandidata:'', //numero de cedula cuando deseo agregar un cliente desde facturacion/presupuestos/nota de entrega, etc (desde tabela no se usa)
        datosGrid:[], 
        //Datos del cliente ponchado
        clientePonchado:false,
        clienteIDr:0,
        clienteCedula:'',
        clienteIdSecundario:'',
        clienteNombre:'', //lo uso para hacer el envio de la factura x correo
        clienteNombreComercial:'', //lo uso para actuaizar la latebla de presupuestos (solo cuando modifico un presupuesto o plan)
        clienteEmail:'', //lo uso para hacer el envio de la factura x correo        
        clienteActivo:null, //Si, No
        clienteTipoVenta:null, //[0:Solo contado],[1:contado y credito]
        //Datos del inventario ponchado (al darle ENTER en el cuadrito negro)
        inventarioNegroPonchado:false,
        inventarioNegroIDr:0,
        inventarioNegroCodigoPrincipal:'', //se aplica cuando estoy en compras y deseo crear un producto
        inventarioNegroActivo:null, //Si, No                
        inventarioNegroParaVenta:null,
        //todo lo que tenga que ver con DEFINITIVO
        def_doc:null, //FActura,PResupuesto,PR_MOD, NECONIVA nota entrega con iva
        def_repetitivo10:null,  //para quedarme siempre Facturando/vendiendo (es lo mas comun)
        def_afectaInvSN:null, //esta variable, luego la paso a caPiePhp
        def_afectaCajaSN:null,//esta variable, luego la paso a caPiePhp
        def_cobraIvaSN:null, //posiblemente se desee hacer notas de entrega sin iva
        //Variables para los modales
        estatusAbiertoModal_IncModCli:false, //para abrir el modal de incluir/modifar cliente
        estatusAbiertoModal_IncModInv : false, //solo lo uso para CONSULTAR un producto
        estatusAbiertoModal_TabelaClientesBuscador:false,
        estatusAbiertoModal_TabelaInvBuscador:false,
        estatusAbiertoModal_Cantidad:false,          
        estatusAbiertoModal_ModalVacio:false,          
        estatusAbiertoModal_Descripcion:false,          
        estatusAbiertoModal_Serie:false,          
        estatusAbiertoModal_Precio1234UnI:false,
        estatusAbiertoModal_Precio1234TodosI:false,
        estatusAbiertoModal_DescuentoP:false,
        estatusAbiertoModal_PrecioEspecifico:false,
        estatusAbiertoModal_FormaDePago:false,
        estatusAbiertoModal_TextoComplementario:false,  
        estatusAbiertoModal_PedirCondicionesPresupuesto:false,  
        estatusAbiertoDropdownMenu_InvMas:false,
        estatusAbiertoDropdownMenu_FacturaMas:false,        
        estatusAbiertoModal_PDFnoSri:false,        
        estatusAbiertoModal_PedirDiasCredito:false, 
        estatusAbiertoModal_PresupuestosBuscador:false, 
}     
    
permanecerEnForm=null //al tratar de leer un documento viejo (presupuesto, plan....) para modificarlo o convertirlo en Factura. Al no poder leer de la bdd entonces la idea es devolverme inmediatamente al modulo llamador
//Esta variable, fechaEmisionPresupuesto solo me sirve para enviar la fecha de emision al formulario de: PedirCondicionesPresupuesto
fechaEmisionPresupuesto=new Date() //arranca con la fecha actual, La funcion Date() automaticamente me devuelve la fecha actual 
//productoNegro, en este objeto ya tengo el Producto Listo en punta para ser ingresado al grid    
productoNegro=null //Va a ser un objeto JSON listo para meterlo al grid de venta
alPedirCantidadNuevoModificar="N" //al pedir la cantidad a vender, necesito saber si es de un producto Nuevo o estoy Modificando la cantidad en el grid
//Variables globales para este formulario y para enviar a los modales
miFilaAutonumerico=1 //Me sirve para ir calculando la fila en el grid (a su vez es una clave para el grid) 
//**** las variables estabDef y puntoDef guardan el Estab Y Punto defintivo para poder hacer el documento en cuestion */
estabDef=null
puntoDef=null
//la variable numGenericoDevuelto me sirve para atrapar el numero devuelto por el SP al crear/modificar proforma, plan. o al cear una factura, NE. Luego al ser ser positivo entonces la paso a la variable correspondiente: numFacturaDef, numPresupuestoDef, numNotaDef, numPlanDef
numGenericoDevuelto=-3
//estas 4 variables indican el numero definitivo de documento
numFacturaDef=null //numero de factura generado por el SP (Negativo: error). Aqui en JS la inicio con -3. (mysql devuelve -100 en adelante, al haber error en la class me devuelve -2)
numPresupuestoDef=null //numero de presupuesto generado por el SP (Negativo: error). Aqui en JS la inicio con -3. (mysql devuelve -100 en adelante, al haber error en la class me devuelve -2)
numNotaDef=null //numero de NotaEntrega generado por el SP (Negativo: error). Aqui en JS la inicio con -3. (mysql devuelve -100 en adelante, al haber error en la class me devuelve -2)
numPlanDef=null //numero de Plan generado por el SP (Negativo: error). Aqui en JS la inicio con -3. (mysql devuelve -100 en adelante, al haber error en la class me devuelve -2)
//otras variables
miFilaAzulJson=null //Variable tipo Json,para enviarla completa a los modales (pedir cantidad,descuento,precio1234,..... etc)
afectaItems1T=null //Para saber si una accion, me afecta al item azul o a todas las filas
deseoPvpSinCon=null //Sin.Con. Al llamar al modal de cambiar precio especifico
datosFormaPago=null //Para saber como ha pagado. (el modal de la forma de pago me develve un JSON)
miColorFilaGenerica='DeepSkyBlue' //por ahora esta asi. Luego debe venir del context
valueDC=null //Aqui hago una copia de los valores del context
sePudoLeerInv=false //para saber si se pudo leer el Inventario
sePudoLeerCli=false //para saber si se pudo leer la tabla de clientes
intentosInv=0 //me sirve para varios intentos para leer el inventario 
intentosCli=0 //me sirve para varios intentos para leer los clientes
intentosDocBDD=1 //me sirve para varios intentos para guadar el documento en la BDD
precio1234CandidatoParaBuscador=null //cuando llamo al buscador de inventario. Que precio se desea ver? (comunmente el pvp1)
//esta variable tipo Objeto/Json, me sirve para mandar a PHP como Cabecera y Pie
//los datos posibles son: AlContado,Plazo,Cancelado,CodClienteRojo,TipoPrecio,IdVendedor,IdFacturador,Observaciones,Pedido,TextoComplementario,GuiaRemPunto,GuiaRemNum,AfectaInventarioSN,AfectaCajaSN,
//para los presupuesto se agregan: ValidezDias,TiempoEntregaDias,Garantia,ImprimirCondiciones,FechaEmision 
//para las notas de entrega, se agrega CobraIvaSN 
caPiePhp={}
//aqui van los sutotales de la factura (se envia a PHP). La funcion que llena esta JSON es calcularTotal()
subtotalFacturaPhp={}
//estas 8 variables, me sirven para saber exactamente que columnas de precio mostrar en el buscador de Inventario (fue la unica manera de resolverlo)
//se podria ver 0 1 o 2 columnas nada mas. Segun el tipo de PVP del cliente 
pvp1InventarioSinIvaVerDefinitivo=null
pvp1InventarioConIvaVerDefinitivo=null
pvp2InventarioSinIvaVerDefinitivo=null
pvp2InventarioConIvaVerDefinitivo=null
pvp3InventarioSinIvaVerDefinitivo=null  
pvp3InventarioConIvaVerDefinitivo=null
pvp4InventarioSinIvaVerDefinitivo=null
pvp4InventarioConIvaVerDefinitivo=null
//***** fin de las 8 variables
//campos opcionales de BlockDeNotas y OpCrea (tanto de clientes, como de de inventario)
cli_deseoBlockSN="S" //para saber si deseo traerme el campo BlockDeNotas de clientes (generalmente S)
cli_deseoOpSN="N" //para saber si deseo traerme el campo OpCrea de clientes (generalmente N)
inv_deseoBlockSN="S" //para saber si deseo traerme el campo BlockDeNotas de inventario (generalmente S)
inv_deseoOpSN="N" //para saber si deseo traerme el campo OpCrea de inventario (generalmente N)

//Estructura para el DataTable de ventas
miEstructuraDataTable=[  
  {  
      name:'FILA',
      selector:row => row.Fila,
      center:true,
      //grow:0.2,
      omit:true, //debe estar oculta.
  },
  {  
      name:'IDR',
      selector:row => row.IDr,
      center:true,
      omit:true, //debe estar oculta.
  },   
  {
      name:'PRINCIPAL',
      selector:row => row.CodPrincipal,
      sortable:false,
      left:true,
      compact:true,
      grow:0.5, //es una medida relativa (1 es mi referencia)
  }, 
    {
      name:'DESCRIPCIONORIGINAL',
      selector:row => row.DescripcionOriginal,
      sortable:false,
      left:true,
      compact:true,
      omit:true, //debe estar oculta.
    },      
  {
      name:'DESCRIPCION',
      selector:row => row.DescripcionModificada,
      sortable:false,
      left:true,
      compact:true,
      grow:1.5,
  },         
  {  
      name:'CANT',
      selector:row => row.Cant, //Cantidad a vender
      sortable:false,
      center:true,
      grow:0.2,
      compact:true, //padding 0
  },      
  {  
      //name:'PVP UNIT SIN IVA', //Precio Unitario Sin Iva
      name:'PUsinIVA',  
      selector:row => row.PvpSinIva,
      sortable:false,
      center:true,
      grow:0.3,
      compact:true, //padding 0
      omit: !this.props.verPvpSinIva,
  },  
  {  
      //name:'PVP UNIT CON IVA', //Precio Unitario con IVA
      name:'PUconIVA',  
      selector:row => row.PvpConIva,
      sortable:false,
      center:true,
      grow:0.3,
      compact:true, //padding 0
      omit: !this.props.verPvpConIva
  },
  {  
      name:'TOTAL', //Total renglon sin iva (restado el descuento)
      selector:row => row.Total,
      sortable:false,
      center:true,
      grow:0.3,
      compact:true, //padding 0
  },      
  {  
      name:'DCTO%unit',
      selector:row => row.DescP, //Descuento Unitario en porcentaje
      sortable:false,
      center:true,
      grow:0.2,
      compact:true, //padding 0
  }, 
  {  
      name:'DCTO$unit',
      selector:row => row.DescD,  //Descuento unitario en dolares    
      sortable:false,
      center:true,
      grow:0.2,
      compact:true, //padding 0
  },                  
  {  
      name:'GRAVA', //Si,No
      selector:row => row.GravaIva,
      sortable:false,
      center:true,
      compact:true, //padding 0
      omit:true,  //debe estar oculta.
  },   
  {  
      //name:'TIPO PRECIO', //Codigo del precio 0,1,2,3,4
      name:'TP',  
      selector:row => row.Precio01234,
      sortable:false,
      center:true,
      grow:0.1,
      compact:true, //padding 0
  }, 
  //Los precios p1,p2,p3,p4 deben estar ocultos. Me sirve de backup
  {  
      name:'P1',  
      selector:row => row.P1,
      sortable:false,
      center:true,
      compact:true, //padding 0
      omit:true,  
  },   
  {  
      name:'P2',
      selector:row => row.P2,
      sortable:false,
      center:true,
      compact:true, //padding 0
      omit:true, 
  },   
  {  
      name:'P3',
      selector:row => row.P3,
      sortable:false,
      center:true,
      compact:true, //padding 0
      omit:true,  
  },   
  {  
      name:'P4',  
      selector:row => row.P4,
      sortable:false,
      center:true,
      compact:true, //padding 0
      omit:true,  
  },  
  {  
      name:'COSTOP',  
      selector:row => row.CostoP,
      sortable:false,
      center:true,
      //grow:0.1,
      compact:true, //padding 0
      omit:true,  //debe estar oculta.
  },  
  {  
      name:'STOCK',  //esta columna debe ir invisible
      selector:row => row.Adef_Stock,
      sortable:false,
      center:true,
      compact:true, //padding 0
      omit:true,  //debe estar oculta.
  },   
  {
      name:'SERIE',
      selector:row => row.DescripcionAmpliada, //Para la serie (100 caracteres)
      sortable:false,
      left:true,
      compact:true,
      grow:0.2,
  }, 
  {
      name:'VENCE',
      selector:row => row.Adef_FechaVence,
      sortable:false,
      left:true,
      compact:true,
      grow:0.1,
  }, 
  {
      name:'VenderSinStock',
      selector:row => row.VenderSinStock,
      sortable:false,
      left:true,
      compact:true,
      omit:true, //debe estar oculta.
  },
  {
      name:'VenderPorDebajoCostoP',
      selector:row => row.VenderPorDebajoCostoP,
      sortable:false,
      left:true,
      compact:true,
      omit:true, //debe estar oculta.
  }, 
]

filaCondicional=[
  //fila NO seleccionada
  {
    when: row => (row.Fila != this.state.miFilaAzulIndice ),
    style: row => ({ 
      backgroundColor: 'white' ,
      color: 'dimgray',
     }),    
  },  
  {
    //segun el SELECTOR
    when: (row) => (row.Fila == this.state.miFilaAzulIndice ),
    style: row => ({ 
      backgroundColor: this.valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

desmontar=()=>{
  //la idea es desmontar, limpiar variables 
  this.setState({datosInv:[],datosFullCli:[],datosGrid:[]})
}

salirPorBoton=()=>{
  //FALTA: al tratar de salir, se debe revisar si hay productos en el grid. tipo XOfIE  
  this.props.activarMenu()
  this.desmontar()
}

ParteTituloMasCerrar=()=> {   
return (
// parte para los datos del cliente
<div id="divTitulo" name="divTitulo" style={{height:'60px',borderStyle:'solid', borderWidth:'3px',borderColor:'dimgray'}}>        
  <Row style={{margin:'0'}}>
      <Col xs="10" style={{paddingTop:'5px'}}>
        <Label id="labelTipoDoc" name="labelTipoDoc" style={{fontSize:'30px'}} ></Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'7px'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ this.salirPorBoton()  }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>  
  </Row>
</div>
)}

validarVenderPorDebajoDelCostoP=async()=>{ 
  //aqui reviso si se puede o no vender por debajo del CostoP. En las Factura/Nota/Plan NO se puede vender por debajo
  //En el caso de de cotizar SI permite cotizar por debajo. Solo da la alerta
  //al convertir una NE o PLan en factura. ya no da la alerta, ni revisa nada
  
  let miDevolver=true //Asumo que todo va a estar bien
  let miArrayDeMensajes=[] //Aqui van los textos/mensajes que deseo ir mostrando uno a uno
  let i=0 //apuntador del array
  
  //recorro todo el grid para ir generando mi array de mensajes
  //Factura,nota,plan
  if ( this.state.def_doc=="FA" || this.state.def_doc=="NECONIVA" || this.state.def_doc=="NESINIVA" || this.state.def_doc=="PL"){
    this.state.datosGrid.forEach(eleGrid => {
      if (eleGrid.VenderPorDebajoCostoP=="0" && parseFloat(eleGrid.PvpSinIva)<parseFloat(eleGrid.CostoP)){
          miDevolver=false
          miArrayDeMensajes[i++]="El producto " + eleGrid.DescripcionModificada  + " no se puede vender por debajo del costo ponderado"
        }
    }) //del for each
  }

  //Para proformas (cuando es proforma SI puede procesar)
  if ( this.state.def_doc=="PR" || this.state.def_doc=="PR_MOD" ){
    this.state.datosGrid.forEach(eleGrid => {
      if (eleGrid.VenderPorDebajoCostoP=="0" && parseFloat(eleGrid.PvpSinIva)<parseFloat(eleGrid.CostoP)){
          miArrayDeMensajes[i++]="El producto " + eleGrid.DescripcionModificada  + " lo está cotizando por debajo del costo ponderado"
        }
    }) //del for each
  }

  //siempre va 0. significa desde la posicion 0 del array
  //Llamo al swal para dar las POSIBLES alertas
  if (miArrayDeMensajes.length > 0){
    await mostrarSwalBotonAceptarBucle("warning","ATENCION",miArrayDeMensajes,0,(miArrayDeMensajes.length))   
  }

  return miDevolver   
}

validarUltima=()=>{
  //esta debe ser la ultima validacion, se chequea que haya un cliente valido, vendedor, grid con filas y monto a pagar >0
  if (!this.state.clientePonchado){    
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un cliente")
    return false  
  }
  if (this.state.clientePonchado && this.state.clienteActivo=="No" ){    
    mostrarSwalBotonAceptar("error","ATENCION","El cliente no se encuentra activo")
    return false  
  }
  let codVendedor=(document.getElementById('comboVendedor').value).trim()
  if (codVendedor==''){    
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un vendedor")
    return false  
  }
  if (this.state.datosGrid.length<=0){    
    mostrarSwalBotonAceptar("error","ATENCION","Aún no ha seleccionado ningún producto del inventario")
    return false  
  }
  if (this.subtotalFacturaPhp.total<=0){    
    mostrarSwalBotonAceptar("error","ATENCION","El monto total debe ser mayor que cero")
    return false  
  }
  //********* reviso que el cliente consumidor final, no pase de 200$ incluyendo iva (solo lo valído cuando sea FACTURA) **********/
  if (this.state.def_doc=="FA"){
    let montoMaximo=parseFloat(this.valueDC.sistema.montoMaximoParaConsumidorFinal)    
    if (this.state.clienteCedula.includes("9999999999") && this.subtotalFacturaPhp.total>montoMaximo){
      mostrarSwalBotonAceptar("error","ATENCION",`Para CONSUMIDOR FINAL, no se puede facturar mas de ${this.valueDC.sistema.montoMaximoParaConsumidorFinal} Dolares`)
      return false  
    }
  }
  //******************* */

  return true
}
  
descontarInventario=()=>{
//descuento en el mismo JSON (en RAM) el inventario. La idea es ahorrar tiempo y no volver a leer de la BDD
//Recorro el GRID. y para cada elemento del GRID busco en el JSON del inventario: datosInv

this.state.datosGrid.forEach(eleGrid => {
  //se debe usar SOME, al encontar al registro se sale del ciclo
  //busco en el inventario y hago el descuento
  this.state.datosInv.some( inv => {
      if (eleGrid.IDr==inv.IDr){
        if (inv.Descontable=="1") 
          inv.Adef_Stock = inv.Adef_Stock - eleGrid.Cant

      return true          
      }//del if
  }) //del some
}) //del for each
}

buscarProductoPorPrincipal=(txtPrincipal)=>{
//OJO: es sensible a minusculas y mayusculas (pero le mando ya en minuscula)
//Recorro el inventario hasta encontrar el producto escrito
for(let i=0; i< this.state.datosInv.length; i++ ){
  let CodPrincipalBDD=this.state.datosInv[i].CodPrincipal
  CodPrincipalBDD=CodPrincipalBDD.toLocaleLowerCase()
  if (CodPrincipalBDD==txtPrincipal){     
    this.productoNegro={      
        //por ahora va 0, luego al meter al grid se determina el valor correcto
        Fila: 0,
        IDr: this.state.datosInv[i].IDr,
        CodPrincipal: this.state.datosInv[i].CodPrincipal,
        DescripcionOriginal:this.state.datosInv[i].Descripcion,
        DescripcionModificada:this.state.datosInv[i].Descripcion,
        DescripcionAmpliada:'',
        Cant:this.valueDC.sistema.cantidadPredeterminadaParaVender,
        //por ahora el PvpSinIva va con cero. Luego al dar enter (meter al grid) se determinada exactamente que precio le corresponde
        PvpSinIva:"0.0000", 
        //el PvpConIva no importa, esto se re-calcula despues
        PvpConIva:"0.0000", 
        Total:"0.00",
        DescP:"0.00",
        DescD:"0.0000",
        //Precio01234 por ahora va 0. Luego al meter en el grid de venta se determina exactamete que va
        Precio01234:"0",
        GravaIva: this.state.datosInv[i].TipoIvaVenta==0 ? 'No' :'Si', 
        //Estos precios estan escondidos
        P1:this.state.datosInv[i].Adef_P1,
        P2:this.state.datosInv[i].Adef_P2,
        P3:this.state.datosInv[i].Adef_P3,
        P4:this.state.datosInv[i].Adef_P4,
        CostoP:this.state.datosInv[i].CostoP,      
        Adef_Stock:this.state.datosInv[i].Adef_Stock,
        Adef_StockMin:this.state.datosInv[i].Adef_StockMin,
        Adef_FechaVence:this.state.datosInv[i].Adef_FechaVence,
        VenderSinStock:this.state.datosInv[i].VenderSinStock,
        VenderPorDebajoCostoP:this.state.datosInv[i].VenderPorDebajoCostoP,
    }
    //preparo lo que el cliente va a mirar al seleccionar en poducto: Descripcion + Stock (no le puse el precio ya que puede crear confusion)
    let nombreProductoMostrar=this.productoNegro.DescripcionOriginal + " // Stock: " + this.productoNegro.Adef_Stock
    document.getElementById("txtNombreProducto").value=nombreProductoMostrar
    document.getElementById("btnConsultarInv1").style.visibility='visible'
    document.getElementById("btnEnterInv").style.visibility='visible'
    this.setState({inventarioNegroPonchado:true,inventarioNegroIDr:this.productoNegro.IDr,inventarioNegroCodigoPrincipal:this.productoNegro.CodPrincipal,
      inventarioNegroActivo:this.state.datosInv[i].Adef_Activo,inventarioNegroParaVenta:this.state.datosInv[i].ParaVenta})
    break //rompo el ciclo for
  }  //del if del item encontrado
} //Del for
}

validarStockSuficiente=async(cantSolicitada,cantStock,descripcionProducto)=>{
  //OJO: las dos variables cantSolicitada y cantStock, deben venir como NUMBER
  if (cantSolicitada > cantStock){
    let miProductoEnviar="No hay stock suficiente para: " + descripcionProducto
    let miResp = await mostrarSwalConfirmacionEliminarAnular('DESEA CONTINUAR ?',miProductoEnviar)
    if (miResp.isDismissed) 
      return false //alomejor se arrepintio        
    else
     return true 
  }  
}

prepararEscenarioParaNuevoNegro=()=>{
  //preparo todo para un nuevo producto
  document.getElementById('txtNegro').value=''
  document.getElementById('txtNombreProducto').value=''
  document.getElementById("btnConsultarInv1").style.visibility='hidden'
  document.getElementById("btnEnterInv").style.visibility='hidden'

  this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:"0",inventarioNegroCodigoPrincipal:'',
  inventarioNegroActivo:null,inventarioNegroParaVenta:null,})  
}

meterProductoNegroEnGridDeVenta=async()=>{
  if (await this.validarStockSuficiente(parseFloat(this.productoNegro.Cant),parseFloat(this.productoNegro.Adef_Stock),this.productoNegro.DescripcionModificada)==false){
    this.prepararEscenarioParaNuevoNegro()
    this.calcularTotal()
    return    
  }
    
let precio1234texto=document.getElementById('labelPVP').textContent
precio1234texto=precio1234texto.substring(3,4)  
let gridLoco = [...this.state.datosGrid ]

//numero de fila
this.productoNegro.Fila=this.miFilaAutonumerico

//debo determinar exactamente el PVP en $ que le toca a ese cliente
this.productoNegro.PvpSinIva=precio1234texto=="1" ? this.productoNegro.P1 : 
precio1234texto=="2" ? this.productoNegro.P2 : 
precio1234texto=="3" ? this.productoNegro.P3 : 
this.productoNegro.P4

//escribo el tipo de precio que le toca a ese cliente (1,2,3,4)
this.productoNegro.Precio01234=precio1234texto
   
//empujo el item requerido por el cliente
gridLoco.push(this.productoNegro) 

//Al nuevo producto le pongo el numero de fila que le corresponde en el grid
await sleepPepe(50)
this.setState({miFilaAzulIndice: this.miFilaAutonumerico++ })
await sleepPepe(50)
this.setState({datosGrid:gridLoco})
await sleepPepe(50)

//preparo todo para un nuevo producto
this.prepararEscenarioParaNuevoNegro()

this.calcularTotal()
}
  
validarAzul=()=>{
//solo me perimite saber si alguna fila del carrito este seleccionada
  if (this.state.miFilaAzulIndice<=0){    
    mostrarSwalBotonAceptar("warning","ATENCION","Debe seleccionar un item de venta")
    return false  
  }

  //copio la fila azul en un Json para mandarla luego a cualquier Modal
  for(let i=0; i<this.state.datosGrid.length;i++)
  if (this.state.datosGrid[i].Fila==this.state.miFilaAzulIndice){
      this.miFilaAzulJson=this.state.datosGrid[i]
      break
   }

  return true
} 

gestionarVenderProductoNegro=()=>{
  //me permite meter UN nuevo item al grid de ventas
  let miCasilla=document.getElementById("txtNegro").value.trim().toLocaleLowerCase()
  if (miCasilla.length==0) return
  
  if (!this.state.inventarioNegroPonchado){
    mostrarSwalBotonAceptar("error","ATENCION","El producto no existe ")
    return
  }

  if (this.state.inventarioNegroActivo=="No"){
    mostrarSwalBotonAceptar("error","ATENCION","El producto no está activo")
    return
  }

  //Reviso que realmente sea producto para VENDER
  if (this.state.inventarioNegroParaVenta=="0"){
    mostrarSwalBotonAceptar("error","ATENCION","Este producto no es para vender")
    return
  }

  this.alPedirCantidadNuevoModificar="N"
  if (this.valueDC.sistema.ventanaParaPedirCantidadEnVentas=="Si"){
    this.setState({estatusAbiertoModal_Cantidad:true }) 
  }
  else{               
     this.meterProductoNegroEnGridDeVenta()
  }            
}

ParteCliente=()=> {   
  // Cuadros de texto referente al cliente: cedula, nombre, tipo de precio, nombre y correo
  return (
  <div id="divParteCliente" name="divParteCliente" style={{background:'white', marginLeft:'2%', marginRight:'2%',marginTop:'1%',paddingLeft:'10px',paddingRight:'10px',borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid',borderWidth:'2px',borderColor:'dimgray'}}>         
    <Row style={{margin:'0'}}>
      {/* columna 1: para cedula/ruc */}
      <Col xs="4" md="3" style={{padding:'0',}}>
        <label style={{color:'dimgray',}}>Cédula/Ruc</label>

{/* rosa */}
        <a class="btn btn-link" style={{color:"blue",fontWeight: 'bold',fontSize:'12px'}}
        onClick={async()=>{
          //cuando el cliente ya está ponchado, entonces le mando VACIO al modal
          //cuando el cliente No existe, le mando el contenido del cuadrito de cedula
          let miCandidata=''
          //alert(this.state.clientePonchado)
          if (this.state.clientePonchado==false){
            miCandidata=document.getElementById("txtCedulaCliente").value.trim()
          }
          this.setState({ opcionCliente_imc:"i",cedulaCandidata : miCandidata, estatusAbiertoModal_IncModCli:true})
        }}
        >Nuevo</a>             
        <Input style={{paddingTop:'0',paddingBottom:'0' }} placeholder="Escriba la Cédula/Ruc y pulse enter" maxLength="20" name="txtCedulaCliente" id="txtCedulaCliente"
          onChange={()=> {          
            let cadenaLoca=document.getElementById("txtCedulaCliente").value
            cadenaLoca=cadenaLoca.trim().toLocaleLowerCase()
            this.filtrarPorCedula(cadenaLoca)
          }}
          onKeyDown = {async(e) => { 
            //esta parte me sirve cuando doy enter/tabulador y el cliente NO existe. Me pegunta si deseo crearlo y llama al formulario para crear UN cliente nuevo
            //let miKey= e.keyCode  || e.which //Codigo de la tecla que esta entrando (version vieja)
            let miKey=e.key //Tab,Enter,Backspace...          
            let miCasilla=document.getElementById("txtCedulaCliente").value.trim() 
            if ( (miKey=="Enter" || miKey=="Tab") && miCasilla.length >0 && !this.state.clientePonchado) {
              //pregunto si desea crear un cliente nuevo
              let miMensaje="El cliente no existe, Desea crearlo ?"
              let miResp = await mostrarSwalConfirmacionEliminarAnular('NUEVO',miMensaje)
                if (miResp.isDenied) {
                    //cuando desea agregar un cliente desde aqui, debo enviarle la cedulaCandidata
                    await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok                         
                    this.setState({ opcionCliente_imc:"i",cedulaCandidata : miCasilla, estatusAbiertoModal_IncModCli:true})
                }                
            }            
          }}            
        />            
      </Col>
      {/* columna 2: tipo de precio */}
      <Col xs="2" md="1" style={{padding:'0',textAlign:'center',display:'grid'}} >
        <label style={{color:'dimgray'}}>Precio</label>
        <Label disabled id="labelPVP" name="labelPVP" style={{textAlign:'center',}}>PVP</Label>
      </Col>
      {/* columna 3: nombre del cliente */}
      <Col xs="6" md="8" style={{padding:'0'}} >            
        <label style={{color:'dimgray'}}>Cliente</label>
        <Input style={{paddingTop:'0',paddingBottom:'0'}} disabled placeholder="Nombre del Cliente"  name="txtNombreCliente" id="txtNombreCliente"/>
      </Col>  
    </Row>
  {/* aqui vienen los botones: BUSCAR, EDITAR, CONSULTA className="classVerOcultar"*/}
    <Row style={{marginTop:'5px',marginBottom:'0',marginLeft:'0',marginRight:'0'}}>  
      <Col style={{padding:'0'}}>      
        <Button id="btnBuscadorCliente" name="btnBuscadorCliente" className="btnCliFact" color="warning" 
          onClick={ (e) => { 
            this.setState({ estatusAbiertoModal_TabelaClientesBuscador : true })            
          }}>BUSCAR
        </Button>
        <Button id="btnModificarCliente" name="btnModificarCliente" className="btnCliFact"  color="warning" 
          onClick={ async(e) => { 
            this.setState( {opcionCliente_imc:'m',estatusAbiertoModal_IncModCli : true} )
          }}>EDITAR
        </Button>
        <Button  id="btnConsultarCliente" name="btnConsultarCliente" className="btnCliFact"  color="warning" 
          onClick={ async(e) => {
            this.setState( {opcionCliente_imc:'c',estatusAbiertoModal_IncModCli : true} )        
          }}>CONSULTAR
        </Button>
      </Col>    
    </Row>  
  </div>
  )}    
  
ParteVendedor=()=> {   
  //Para Vendedor, Almacen. y al final del div le pongo un borde inferior para que SEPARE de la parte de la venta
  return (
  <>
    <div id="parteVendedor" name="parteVendedor" style={{background:'white',marginLeft:'2%',marginRight:'2%',marginBottom:'5px',paddingLeft:'10px', paddingRight:'10px',paddingBottom:'10px',borderBottomStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid',borderWidth:'2px',borderColor:'dimgray'}}>           
      <Row style={{margin:'0',}}>
        <Col style={{padding:'0',}}>
          <Label for ="comboVendedor">Vendedor</Label>
          <Input style={{paddingTop:'0',paddingBottom:'0' }} type="select" name="comboVendedor" id="comboVendedor" className="AnchoFijo330px" /> 
        </Col>  
      </Row>     
      <div style={{display:'none'}}>
        <Label style={{marginRight:"2%"}} >Bodega:</Label>
        <Input disabled name="txtAlmacen" id="txtAlmacen" defaultValue="Gayaquil"/>
        <Button id="btnClientePonchado" name="btnClientePonchado" color="info" 
          onClick={ (e) => {
          alert(this.state.clientePonchado + " - " + this.state.clienteIDr + " - " + this.state.clienteCedula + "-" + this.state.clienteIdSecundario + " - " + this.state.clienteActivo + "-" + this.state.clienteNombre + "-" + this.state.clienteNombreComercial + "-" + this.state.clienteEmail + "-" + this.state.clienteTipoVenta )
          }}>PONCHADO
        </Button>            
      </div>
    </div >
    
  </>
  )}    
    
ParteBotoneraSuperior=()=>{   
return (
<div id="divParteBotoneraSuperior" name="divParteBotoneraSuperior" style={{background:'dimgray', marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%', borderStyle:'solid',borderColor:'black',borderWidth:'1px'}}>
  {/* Cuadrito negro,flechita hacia abajo, descripcion  */}
  <Row style={{margin:'0',padding:'4px'}}>
    <Col xs="4" md="3" style={{padding:'0',}} >
      <Input placeholder="Código y pulse enter" maxLength="30" name="txtNegro" id="txtNegro"
        onChange = {async(e) => { 
          //let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
          this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:0,inventarioNegroCodigoPrincipal:'',inventarioNegroActivo:null,})
          document.getElementById("txtNombreProducto").value=""
          document.getElementById("btnConsultarInv1").style.visibility='hidden'
          document.getElementById("btnEnterInv").style.visibility='hidden'
          this.productoNegro=null //pongo vacio en mi JSON del futuro producto que se va a meter al grid de venta                             
          let miCasilla=document.getElementById("txtNegro").value.trim().toLocaleLowerCase()
            if ( miCasilla.length >0){
                //hay que ponerle AWAI para que de chance que se actualice el estado
                await this.buscarProductoPorPrincipal(document.getElementById("txtNegro").value.trim().toLowerCase()) 
              }            
        }}
        onKeyPress = {(e) => { 
          let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
          if (miKey==13 ){
            this.gestionarVenderProductoNegro()
          }            
        }}/>
    </Col>
    <Col xs="2" md ="1" style={{paddingLeft:'4px',paddingRight:'4px',textAlign:'center',}} >

        <Button style={{width:'100%',height:'38px',padding:'0'}} id="btnEnterInv" name="btnEnterInv" color="warning" 
          onClick={ () => { this.gestionarVenderProductoNegro() }}>
          <span style ={{ fontSize:"22px",}}>            
            <FontAwesomeIcon color="black" icon={faDownload }/>
            </span>
        </Button>    
    </Col>  
    <Col xs="6" md="8" style={{padding:'0',}} >
      <Input disabled name="txtNombreProducto" id="txtNombreProducto"/>
    </Col>
  </Row>
  <Row style={{margin:'0',paddingTop:'0',paddingBottom:'4px', paddingLeft:'4px',paddingRight:'4px'}}>
    <Col style={{padding:'0'}} >
      <Button id="btnBuscadorInv" name="btnBuscadorInv" className="btnGestorProductoFact"  
        onClick={ (e) => { 
          let precio1234texto=document.getElementById('labelPVP').textContent
          precio1234texto=precio1234texto.substring(3,4)  
          this.precio1234CandidatoParaBuscador= parseInt(precio1234texto,10)
          this.determinarPVPvisibleEnBuscadorDeInventario() //determino que columna de PVP se reuiqre ver. Ademas se determina si tambine quiere ver la culumna con iva            
          this.setState({ estatusAbiertoModal_TabelaInvBuscador : true })            
        }}>BUSCAR    
      </Button>
      <Button id="btnRefrescarInv" name="btnRefrescarInv" className="btnGestorProductoFact"  
        onClick={ () => { 
          this.intentosInv=0
          document.getElementById('txtNegro').value=''
          document.getElementById('txtNombreProducto').value=''
          document.getElementById("btnConsultarInv1").style.visibility='hidden'
          document.getElementById("btnEnterInv").style.visibility='hidden'
          this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:0,inventarioNegroCodigoPrincipal:'',
          inventarioNegroActivo:null,inventarioNegroParaVenta:null,miFilaAzulIndice: 0 })
          this.refrescarInventarioExclusivamente() 
        }}>REFRESH
      </Button>
      <Button id="btnConsultarInv1" name="btnConsultarInv1" className="btnGestorProductoFact" 
        onClick={ (e) => { 
          this.setState( {opcionInv_imc:'c',estatusAbiertoModal_IncModInv : true} )
        }}>CONSULTAR     
      </Button>      
    </Col>
  </Row>

  {/* Botones de cantidad,Modificar Descr,Descuento.... etc 8 botones */}
  <Row style={{margin:'0',paddingTop:'0',paddingBottom:'6px',paddingLeft:'6px',paddingRight:'6px'}}>
    <Col style={{padding:'0'}}>
      <ButtonGroup>
        <Button className="menuItemsVenta" id="btnInvCant" name="btnInvCant" color="info" 
          onClick = { () => {
            if (!this.validarAzul()) return
            this.alPedirCantidadNuevoModificar="M"
            this.setState({estatusAbiertoModal_Cantidad:true }) 
          }}>
          <FontAwesomeIcon color="#303030" icon={faLayerGroup}/>
        </Button>
        <Button className="menuItemsVenta" id="btnInvEdit" name="btnInvEdit" color="info" 
          onClick={ () => {
            if (!this.validarAzul()) return
            this.setState({estatusAbiertoModal_Descripcion:true }) 
          }}>
          <FontAwesomeIcon color="#303030" icon={faEdit}/>
        </Button>
        <Button className="menuItemsVenta" id="btnInvP1234" name="btnInvP1234" color="info" 
          onClick = { () => {
            if (!this.validarAzul()) return
            this.setState({estatusAbiertoModal_Precio1234UnI:true }) 
          }}>
          <FontAwesomeIcon color="#303030" icon={faListOl}/>
        </Button>
        <Button className="menuItemsVenta" id="btnInvPorc" name="btnInvPorc" color="info"  
          onClick = { () => {
            if (!this.validarAzul()) return
            this.afectaItems1T='1'
            this.setState({estatusAbiertoModal_DescuentoP:true }) 
          }}>
          <FontAwesomeIcon color="#303030" icon={faPercent}/>
        </Button>
        <Button className="menuItemsVenta"  id="btnInvPvpSinIva" name="btnInvPvpSinIva" color="info" 
          onClick={ () => { 
            //rosa
            if (!this.validarAzul()) return
            this.deseoPvpSinCon='Sin'
            this.setState({estatusAbiertoModal_PrecioEspecifico:true})
          }}>
          <FontAwesomeIcon color="#303030" icon={faDollarSign}/>
        </Button>
        <Button className="menuItemsVenta" id="btnInvPvpConIva" name="btnInvPvpConIva" color="info" 
          onClick={ () => {
            if (!this.validarAzul()) return
            this.deseoPvpSinCon='Con'
            this.setState({estatusAbiertoModal_PrecioEspecifico:true})
          }}>
          <FontAwesomeIcon color="#303030" icon={faCommentDollar}/>
        </Button>
        <Button className="menuItemsVenta" id="btnInvEliminar" name="btnInvEliminar" color="info" 
          onClick={ () => { 
            if (this.validarAzul()) this.eliminarProducto()}}>
            <FontAwesomeIcon color="#303030" icon={faTrashAlt}/>          
        </Button>
        <Dropdown className="menuItemsVenta" id="btnInvMas" name="btnInvMas" isOpen={ this.state.estatusAbiertoDropdownMenu_InvMas } direction="right"  
        toggle= { ()=> { this.setState( {estatusAbiertoDropdownMenu_InvMas:!this.state.estatusAbiertoDropdownMenu_InvMas} )}}>    
          <DropdownToggle className="menuItemsVenta" >
            <FontAwesomeIcon color="#303030" icon={faEllipsisH} />
          </DropdownToggle>
          <DropdownMenu style={{backgroundColor:'pink',}}>        
            <DropdownItem id="btnInvP1234Todos" name="btnInvP1234Todos" 
              onClick={ async() =>{
                if (this.state.datosGrid.length<=0) return
                let miProductoEnviar="Seguro que desea poner el mismo TIPO DE PRECIO a todos los items"
                let miResp = await mostrarSwalConfirmacionEliminarAnular('ATENCION',miProductoEnviar)
                if (miResp.isDismissed) return //alomejor se arrepintio                    
                this.setState({estatusAbiertoModal_Precio1234TodosI:true})
              }}>
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faListOl} /> Todos los items
            </DropdownItem>        
            <DropdownItem id="btnInvPorcTodos" name="btnInvPorcTodos" 
              onClick={ async() => {
                if (this.state.datosGrid.length<=0) return
                let miProductoEnviar="Seguro que desea poner el mismo % de descuento a todos los items"
                let miResp = await mostrarSwalConfirmacionEliminarAnular('ATENCION',miProductoEnviar)
                if (miResp.isDismissed) return //alomejor se arrepintio                    
                this.afectaItems1T='T'
                this.setState({estatusAbiertoModal_DescuentoP:true }) 
              }}>
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faPercent} /> Todos los items
            </DropdownItem>        
            <DropdownItem id="btnInvBorrarTodo" name="btnInvBorrarTodo" 
              onClick={async () =>{
                if (this.state.datosGrid.length<=0) return
                let miProductoEnviar="Seguro desea eliminar todos los items"
                let miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miProductoEnviar)
                if (miResp.isDismissed) return //alomejor se arrepintio     
                await sleepPepe(50)               
                this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:"0",inventarioNegroCodigoPrincipal:'',
                  inventarioNegroActivo:null,inventarioNegroParaVenta:null,datosGrid:[],miFilaAzulIndice: -1})
                await sleepPepe(50)
                this.calcularTotal()
              }}>
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faBroom} /> Borrar todo
            </DropdownItem>                 
            <DropdownItem divider/>
            <DropdownItem id="btnInvSerie" name="btnInvSerie" 
              onClick={ () => {
                if (!this.validarAzul()) return
                this.setState({estatusAbiertoModal_Serie:true }) 
              }}> 
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faBarcode} /> Escribir Serie
            </DropdownItem>                
            <DropdownItem id="btnVerGrid" name="btnVerGrid" 
              onClick={ () => {
                alert(JSON.stringify(this.state.datosGrid,null,2))
              }}> 
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faBarcode} /> Ver JSON del grid
            </DropdownItem>                
            <DropdownItem divider/>             
            <DropdownItem > 
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faDoorClosed} /> Cerrar Menú
            </DropdownItem>             

          </DropdownMenu>
        </Dropdown>    
      </ButtonGroup>
    </Col>    
  </Row>   {/* fin de Botones de cantidad,Modificar Descr,Descuento.... etc 8 botones */}

</div> //divParteBotoneraSuperior
)
} 

ParteGrid=()=>{
return(
<div id="divParteGrid" name="divParteGrid" style={{marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBotto:'0',width:'96%', borderStyle:'solid',borderColor:'black',borderWidth:'1px'}}>
  <DataTable
  //************ DATA TABLA PROPERTIES (basic) ***********/
  title='Items Seleccionados'
  columns={this.miEstructuraDataTable}
  data={this.state.datosGrid}
  conditionalRowStyles={this.filaCondicional}  
  keyField ='Fila' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
  //highlightOnHover  
  onRowClicked={(row) => { this.setState({miFilaAzulIndice:row.Fila})}}
  //  noDataComponent = "<h1><span>Carrito Vacío</span></h1>"
  noDataComponent = "Aún no has seleccionado productos..."

  //************ DATA TABLA PROPERTIES (row selection) ***********/
  //selectableRows //aparece el checkbox
  //selectableRowsVisibleOnly
  //selectableRowsHighlight
  //selectableRowsNoSelectAll

  //************ DATA TABLA PROPERTIES (row expander) ***********/
  //expandableRows //interesante

  //************ DATA TABLA PROPERTIES (sorting) ***********/
  //defaultSortField

  //************ DATA TABLA PROPERTIES (header) ***********/
  //actions //component or array of components

  //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
  
  //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
  //subHeader //me gustaria esta opcion pero no funciona

  //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
  customStyles={miEstilacho} /* redefino algun estilo */
  />    { /* del componente DataTable */ }
  </div>
  )  
}

ParteSubTotales=()=> {  
let miLabelLoca = "Subtotal " + this.valueDC.iva.Porcentaje + "%" 
return (
<div id="divParteSubTotales" name="divParteSubTotales" style={{padding:'9px',background:'dimgray', marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%', borderBottomStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid',borderColor:'black',borderWidth:'1px'}}>    
  <Row style={{background:'dimgray',margin:'0'}}>
    <Col xs="7" md="9" style={{padding:"0"}}>
      <Input disabled id="labelDcto" name="labelDcto" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="Descuento"/>  
      <Input disabled id="label0" name="label0" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="SubTotal 0%"/>     
      <Input disabled id="label12" name ="label12" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue={miLabelLoca}  />  
      <Input disabled id="labelIva" name="labelIva" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="Iva"/>  
      <Input disabled id="labelTotal" name="labelTotal" style={{textAlign:'right', fontWeight: 'bold',paddingTop:'0',paddingBottom:'0'}} defaultValue="TOTAL"/>  
    </Col>
    <Col xs="5" md="3" style={{padding:"0"}}>
      <Input disabled id="txtDcto" name="txtDcto" defaultValue="0.00" style={{paddingTop:'0',paddingBottom:'0' }} />  
      <Input disabled id="txt0" name="txt0" defaultValue="0.00" style={{paddingTop:'0',paddingBottom:'0' }} />  
      <Input disabled id="txt12" name="txt12" defaultValue="0.00" style={{paddingTop:'0',paddingBottom:'0' }} />  
      <Input disabled id="txtIva" name="txtIva" defaultValue="0.00" style={{paddingTop:'0',paddingBottom:'0' }} />  
      <Input disabled id="txtTotal" name="txtTotal" style={{textAlign:'left', fontWeight: 'bold',paddingTop:'0',paddingBottom:'0'}} defaultValue="0.00"/>  
    </Col>
  </Row>
</div>
)
} 

parteBotonMasOpciones=()=>{
  // partecita para el boton de mas Opciones y observaciones
  return(  
  <div id="divMasOpciones" name="divMasOpciones" style={{marginTop:'6px',marginBottom:'6px',marginLeft:'2%',marginRight:'2%',}}>
  <Row style={{margin:'0'}}> 
    <Col xs="3" md="2" lg="1" style={{padding:'0',}}> 
      <Dropdown style={{marginTop:'0',}}  id="btnFacturaMas" name="btnFacturaMas" isOpen={ this.state.estatusAbiertoDropdownMenu_FacturaMas } direction="up"  
          toggle= { ()=> { this.setState( {estatusAbiertoDropdownMenu_FacturaMas:!this.state.estatusAbiertoDropdownMenu_FacturaMas} ) }}> 
          <DropdownToggle style={{width:'100%',fontSize:'16px',fontWeight: 'bold',color:'white'}} color="info" >
            MAS { }<FontAwesomeIcon color="gainsbro" icon={faCaretDown} />
          </DropdownToggle>
        <DropdownMenu style={{backgroundColor:'pink',}}>        
          <DropdownItem id="btnTextoComp" name="btnTextoComp" 
            onClick={ async() =>{
              this.setState({estatusAbiertoModal_TextoComplementario:true})
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileAlt} /> Texto Complementario
          </DropdownItem>        
          <DropdownItem divider/>

          <DropdownItem id="btnClonarProforma" name="btnClonarProforma"
            onClick={ async () => {         
              await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok     
              this.setState({estatusAbiertoModal_PresupuestosBuscador:true }) 
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faParking} /> Clonar Proforma
          </DropdownItem>        

          <DropdownItem id="btnClonarFactura" name="btnClonarFactura" style={{display:'none'}}
            onClick={async () =>{
              if (this.state.datosGrid.length<=0) return
              let miProductoEnviar="Seguro desea eliminar todos los items"
              let miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miProductoEnviar)
              if (miResp.isDismissed) return //alomejor se arrepintio                    
              this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:"0",inventarioNegroCodigoPrincipal:'',
              inventarioNegroActivo:null,inventarioNegroParaVenta:null,datosGrid:[],miFilaAzulIndice: -1})
              this.calcularTotal()
            }}>
            <FontAwesomeIcon style={{color:'dimGray',}} icon={faFlag} /> Clonar Factura
          </DropdownItem>                 
          <DropdownItem id="btnClonarNotaE" name="btnClonarNotaE" style={{display:'none'}}
            onClick={ () => {
              if (!this.validarAzul()) return
              this.setState({estatusAbiertoModal_Serie:true }) 
            }}> 
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faClipboard} /> Clonar Nota E
          </DropdownItem>                
          <DropdownItem divider/>

          <DropdownItem ><FontAwesomeIcon style={{color:'dimGray'}} icon={faDoorClosed} /> Cerrar Menú
          </DropdownItem>        
        </DropdownMenu>
      </Dropdown>  
    </Col>
    <Col xs="9" md="10" lg="11" style={{padding:'0',}}> 
      <Input style={{background:'white',marginLeft:'2%', width:'98%'}} placeholder="Observaciones" maxLength="100" name="txtObservaciones" id="txtObservaciones"/>    
    </Col>
  </Row>
  </div>    
  )
}
  
ParteBotoneraInferiorA=()=>{   
//Botones de [formas de pago]
return (
<div id="divBotoneraInferiorA" name="divBotoneraInferiorA" style={{marginLeft:'2%',marginRight:'2%',}} >
  <Row style={{margin:'0',padding:'0'}}>
    <ButtonGroup style={{padding:"0",}} >
      <Button style={{height:"90px", fontSize:'16px',color:'black',fontWeight:'bold',borderStyle:'solid',borderWidth:'1px',borderColor:'black'}} id="btnEfectivo" name="btnEfectivo" color="primary" 
        onClick = {async()=>{ 
          //valído que cada producto, NO esté por debajo del costo
          if (await this.validarVenderPorDebajoDelCostoP()==false) return
          //validar que se haya seleccionado algun cliente y hayan elementos en el grid
          if (!this.validarUltima()) return
          if (this.state.def_doc=="FA") {            
            if (await hayInternet()==false) return 
            this.gestionarFacturaEfectivo()
          }
          if (this.state.def_doc=="NECONIVA" || this.state.def_doc=="NESINIVA" ) {
            if (await hayInternet()==false) return 
            this.gestionarNotaEfectivo()  
          }
        }}>EFECTIVO
      </Button>
      <Button style={{height:"90px",fontSize:'60px',borderStyle:'solid',borderWidth:'1px',borderColor:'black'}} id="btnFormaPago" name="btnFormaPago" color="primary" 
        onClick = { async () => {
          //cuando se paga con otros metodos adicionales
          //valído que cada producto, NO esté por debajo del costo
          if (await this.validarVenderPorDebajoDelCostoP()==false) return
          //validar que se haya seleccionado algun cliente y hayan elementos en el grid
          if (!this.validarUltima()) return
          this.setState({estatusAbiertoModal_FormaDePago:true })         
        }}>
        <FontAwesomeIcon color="black" icon={faCashRegister}/>
      </Button>
      <Button style={{height:"90px", fontSize:'16px',fontWeight: 'bold',borderStyle:'solid',borderWidth:'1px',borderColor:'black'}} id="btnCredito" name="btnCredito" color="secondary"
        onClick = {async()=>{    
          //valído que cada producto, NO esté por debajo del costo
          if (await this.validarVenderPorDebajoDelCostoP()==false) return
          //validar que se haya seleccionado algun cliente y hayan elementos en el grid
          if (!this.validarUltima()) return
          //valido que el cliente sea de credito
          if (this.state.clienteTipoVenta=="0"){
            await mostrarSwalBotonAceptar("error","ATENCION","Este cliente es solamente de Contado")
            return
          } 
          //Abro un MODAL para pedir los dias de credito. Al cerrar el modal entonces se manda a procesar la Factura
          this.setState({estatusAbiertoModal_PedirDiasCredito:true }) 
        }}>CREDITO
      </Button>
    </ButtonGroup>
  </Row>   
</div>
)
} 

ParteBotoneraInferiorB=()=>{   
//Boton: SIGUIENTE (se usa para hacer una proforma o plan),
return (
<div id="divBotoneraInferiorB" name="divBotoneraInferiorB" style={{marginLeft:'2%',marginRight:'2%',}} >
  <Row style={{margin:'0',padding:'0'}}>
      <ButtonGroup style={{padding:'0'}}>
        <Button style={{height:"90px", fontSize:'16px',fontWeight: 'bold',borderStyle:'solid',borderWidth:'1px',borderColor:'black'}} id="btnSiguiente" name="btnSiguiente" color="primary" 
          onClick = {async()=>{    
            //valido el tema de la venta por debajo del costo (o bien para presupuesto SOLO se da la alerta pero si puede cotizar). Solo da alerta, pero si deja avanzar     
            if (await this.validarVenderPorDebajoDelCostoP()==false) return
            //validar que se haya seleccionado algun cliente,vendedor y hayan elementos en el grid
            if (!this.validarUltima()) return
            if (this.state.def_doc=="PR" || this.state.def_doc=="PR_MOD"){
            if (this.state.def_doc=="PR") this.prepararCaPiePhpCondicionesPresupuestoNuevo()      
                  this.setState({ estatusAbiertoModal_PedirCondicionesPresupuesto : true })            
            }          
            if (this.state.def_doc=="PL" || this.state.def_doc=="PL_MOD"){
              //this.setState({ estatusAbiertoModal_PedirCondicionesPresupuesto : true })            
            }     
          }}>SIGUIENTE
        </Button>  
      </ButtonGroup>
  </Row>   
</div>
)
} 

actualizarProductoNegroContraInventario=()=>{
  //al cargar un  documento viejo, hay que actualizar el stock disponible, costoP, descripcion original y otros....
  let CodPrincipalBDD=null
  this.state.datosInv.some(inv=>{
    CodPrincipalBDD=this.productoNegro.CodPrincipal  // .toLocaleLowerCase()
    if (CodPrincipalBDD==inv.CodPrincipal){     
      this.productoNegro.DescripcionOriginal=inv.Descripcion
      this.productoNegro.GravaIva= inv.TipoIvaVenta==0 ? 'No' :'Si'
      this.productoNegro.P1=inv.Adef_P1
      this.productoNegro.P2=inv.Adef_P2
      this.productoNegro.P3=inv.Adef_P3
      this.productoNegro.P4=inv.Adef_P4
      this.productoNegro.CostoP=inv.CostoP
      this.productoNegro.Adef_Stock=inv.Adef_Stock
      this.productoNegro.Adef_StockMin=inv.Adef_StockMin
      this.productoNegro.Adef_FechaVence=inv.Adef_FechaVence
      this.productoNegro.VenderPorDebajoCostoP=inv.VenderPorDebajoCostoP
      return true //para que se salga del some
    } //del if
  })
}
  
llenarGridDesdeDocumentoViejo=(miJsonViejo)=>{
  //recibo un Json de un documento viejo (PRresupuesto) , NE, FActura PLan ) y lleno el grid
  //tambien doy alertas (producto inactivo, producto no es para la venta, y cantidad disponible).
  //el tema si esta vendiendo por debajo del costo, se hace en la validacion final (aqui no)
  let gridLoco=this.state.datosGrid
  miJsonViejo.forEach( (item) => {
   this.productoNegro=null
   this.productoNegro={      
          Fila: this.miFilaAutonumerico,
          IDr: item.IDr_Inventario,
          CodPrincipal: item.CodPrincipal,
          DescripcionOriginal:'', //actualizar la descripcion orginal segun la tabla de inventario
          DescripcionModificada:item.DescripcionRealD,
          DescripcionAmpliada:(item.DescripcionAmpliadaD==null) ? '': item.DescripcionAmpliadaD,
          Cant:item.CantD,
          PvpSinIva:item.PrecioUnitFullD,          
          PvpConIva:"0.0000", //el PvpConIva no importa, esto se re-calcula despues
          Total:"0.00",
          DescP:item.DescuentoPorcentajeD,
          DescD:"0.0000", //el PvpConIva no importa, esto se re-calcula despues
          Precio01234:item.TipoPrecioD,
          GravaIva:'', //actualizar este valor desde el inventario [Si,No]
          //Estos precios estan escondidos y se deben actaalizar desde el inventario
          P1:"0.0000",
          P2:"0.0000",
          P3:"0.0000",
          P4:"0.0000",
          CostoP:"0.0000",      
          Adef_Stock:"0.00",
          Adef_StockMin:"0.00",
          Adef_FechaVence:'',
          VenderPorDebajoCostoP:'', //actualizar [0,1]
      }//del objeto producto negro

                /*
          if (this.state.inventarioNegroActivo=="No"){
            mostrarSwalBotonAceptar("error","ATENCION","El producto no está activo")
            return
          }
        
          //Reviso que realmente sea producto para VENDER
          if (this.state.inventarioNegroParaVenta=="0"){
            mostrarSwalBotonAceptar("error","ATENCION","Este producto no es para vender")
            return
          }
        */
//recupero las observaciones
document.getElementById("txtObservaciones").value=item.ObservacionesPresupuesto

this.actualizarProductoNegroContraInventario() //debo buscar el stock disponible actual, costoP actual,....

//empujo el item requerido por el cliente
gridLoco.push(this.productoNegro) 
this.miFilaAutonumerico++           
}) //del for each
}
  
buscarPresupuestoDetalleParaClonarModificar=async(fEstab,fPunto,fNumPre)=>{
//las variables f... se refieren a que son solo para esta funcion. (Ya que hay una diferencia grande entre Modificar y Clonar la proforma)
//Los valores posibles para la variable this.state.def_doc son: "PR" / "PR_MOD" / "PR_CLON"
//busco un presupuesto para clonarlo o Modificarlo
this.permanecerEnForm=true
let jsonDetalleViejo=null
jsonDetalleViejo= await buscarProformaDetalleParaClonarModificar(fEstab,fPunto,fNumPre,this.valueDC)
  
if (jsonDetalleViejo==null && this.state.def_doc=="PR_MOD"){
  //al no poder leer el detalle la proforma, me salgo
  this.permanecerEnForm=false
  this.salirPorBoton()
  return
}
if (jsonDetalleViejo!=null && jsonDetalleViejo.Data.length==0 && this.state.def_doc=="PR_MOD"){
  //al no haber datos en el datelle me salgo. //este caso creo que NUNCA se va a dar 
  await mostrarSwalBotonAceptar("error","ATENCION","El documento vino vacío...")   
  this.permanecerEnForm=false
  this.salirPorBoton()
  return
}

if (this.state.def_doc=="PR_CLON"){
  //voy a revisar que realmente hayan datos en el jsonDetalleViejo. (es decir que realmente hubo conexion)
  let hayDatos=await this.mostrarMensajesDeImportacionDeDocumentoViejo(jsonDetalleViejo)
  if (!hayDatos)
    return
}

/****************************************** */
//*******RUMBO NORMAL  ******************** */
/****************************************** */

//busco el cliente
document.getElementById("txtCedulaCliente").value=jsonDetalleViejo.Data[0].IdPrincipal.trim()
let cadenaLoca=jsonDetalleViejo.Data[0].IdPrincipal
cadenaLoca=cadenaLoca.trim().toLocaleLowerCase()
this.filtrarPorCedula(cadenaLoca)

//cargo el vendedor
document.getElementById("comboVendedor").value=jsonDetalleViejo.Data[0].IdVendedor

//empiezo a llenar el grid
this.miFilaAutonumerico=1 //Arranco con 1 obviamente.
this.llenarGridDesdeDocumentoViejo(jsonDetalleViejo.Data)
//Cuando sea CLONAR, debo hacer un renum del campo FILA. (ya que alomejor tengo algunas lineas en el grid y luego importé una proforma)
if (this.state.def_doc== "PR_CLON"){
  this.setState({datosGrid:hacerRenumDeUnJson(this.state.datosGrid)}) //renumero las filas, Es posible que esten repetidas
  this.miFilaAutonumerico=this.state.datosGrid.length //debo determinar el numero de fila mas alto para que todo siga su rumbo normal  
  this.miFilaAutonumerico++ 
}

//Doy alertas, (alomejor no hay suficiente stock, o no es un producto para vernder, o alomejor esta inactivo...)
//FALTA

this.setState({miFilaAzulIndice:-1}) //para que no se vea ninguna fila azul
//TRUCO
setTimeout(()=>{ 
  this.calcularTotal() //aqui es para forzar que se vea en el render()
},50)         

//alimento el caPiePhp con toda la informacion necesaria del presupuesto viejo
this.caPiePhp.AlContado=jsonDetalleViejo.Data[0].AlContado
this.caPiePhp.Plazo=jsonDetalleViejo.Data[0].Plazo      
this.caPiePhp.TextoComplementario=jsonDetalleViejo.Data[0].TextoComplementario
this.caPiePhp.FechaEmision=jsonDetalleViejo.Data[0].FechaEmision      
this.caPiePhp.ValidezDias=jsonDetalleViejo.Data[0].ValidezDias
this.caPiePhp.TiempoEntregaDias=jsonDetalleViejo.Data[0].TiempoEntregaDias
this.caPiePhp.Garantia=jsonDetalleViejo.Data[0].Garantia
this.caPiePhp.ImprimirCondiciones=jsonDetalleViejo.Data[0].ImprimirCondiciones

//esta parte de la fecha aplica solo cuando estoy MODIFICANDO LA PROFORMA
if (this.state.def_doc=="PR_MOD"){
  //aqui preparo la fecha de emision, para poder mostrarla en el dtPicker del formulario "PedirCondicions Presupuesto"
  let miDia=jsonDetalleViejo.Data[0].FechaEmisionFormat.substring(0,2)
  let miMes=jsonDetalleViejo.Data[0].FechaEmisionFormat.substring(3,5)
  let miAno=jsonDetalleViejo.Data[0].FechaEmisionFormat.substring(6)    
  //OJO: Al usar el metodo DATE con 3 string, el mes de enero es 0. OJO
  this.fechaEmisionPresupuesto=new Date( miAno,miMes-1,miDia)
}
}

refrescarInventarioExclusivamente=async()=>{
//solo la llamo desde el boton REFRESCAR INVENTARIO
this.intentosInv=1
let seguir=true
mostrarSwalEspera()
while (seguir){
  //OJO: hay que hacer 3 intentos, de no haber exito entonces se debe mostrar la ventana de REINTENTAR
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','inv_buscarTodosPocasColumnasAlmacenDef')
  data.append('miAlmacen',this.valueDC.estable.Almacen) //codigo del almacen
  data.append('deseoBlockSN',this.inv_deseoBlockSN)
  data.append('deseoOpSN',this.inv_deseoOpSN) 
  data.append('orderBy','Descripcion')
  data.append('forma','Asc')
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeJson = await response.json() 

    //analizo la cabecera del JSON de la respuesta
    let hayError=false
    let miE=null
    if (!pepeJson.ConexionBUC){
      hayError=true
      miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
    } 
    if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
      hayError=true
      miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
    }     
    if (hayError){
      throw miE      
    } 
    //rumbo normal*********        
    this.setState({ datosInv:pepeJson.Data,}) 
    apagarSwal()
    seguir=false
  } //del try
  catch (e) {   
    this.intentosInv++
    if (this.intentosInv<=this.valueDC.sistema.numeroDeReintentosPhp){      
      await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
    }
    else{  
      //llamo al gestor para que me aparezca la pantalla de REINTENTAR
      apagarSwal()
      let miRespSwal = await gestionarCatch(e)
      //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
      if (miRespSwal.isDenied) {
        mostrarSwalEspera()
        this.intentosInv=1
      }
      else{
        //ha pulsado salir en el swal. No hago nada para poder seguir en Facturacion
        seguir=false
      }
    }//del else
  }//del catch
}//del while
}

refrescarInventario=async()=>{    
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','inv_buscarTodosPocasColumnasAlmacenDef')
  data.append('miAlmacen',this.valueDC.estable.Almacen)
  data.append('deseoBlockSN',this.inv_deseoBlockSN)
  data.append('deseoOpSN',this.inv_deseoOpSN) 
  data.append('orderBy','Descripcion')
  data.append('forma','Asc')   
      
  try{
   const response = await fetch(miPhpFile, { method: 'POST',body:data })
   const pepeJson=await response.json()
   this.setState({ datosInv:pepeJson.Data })        

   this.sePudoLeerInv = (pepeJson.ConexionBUC && pepeJson.AgrModConExito)  
  }
  catch { 
    this.sePudoLeerInv = false
  }
}       

refrescarClientes=async()=>{ 
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','cli_buscarTodosPocasColumnas')
  data.append('deseoBlockSN',this.cli_deseoBlockSN)
  data.append('deseoOpSN',this.cli_deseoOpSN)   
  data.append('orderBy','RazonSocial')
  data.append('forma','Asc')
  data.append('selectAIT','T')
  
  try{
    const response = await fetch(miPhpFile, { method: 'POST',body:data })
    const pepeJson=await response.json() 

    await sleepPepe(50)
    this.setState({ datosFullCli:pepeJson.Data })        
    await sleepPepe(50)
    this.sePudoLeerCli = (pepeJson.ConexionBUC && pepeJson.AgrModConExito)  

  } //del try
  catch (e) {    
    this.sePudoLeerCli = false
  }   
}

calcularTotal=async() =>{
//Esta rutina, me recalcula varias casillas del grid (por ejemplo el precio unit con iva, el descuento en $ unitario, el total por renglon, etc....)
//tambien totaliza el documento
this.subtotalFacturaPhp.propina=0 //por ahora no se usa
this.subtotalFacturaPhp.dcto=0 
this.subtotalFacturaPhp.sub0=0
this.subtotalFacturaPhp.sub12=0
this.subtotalFacturaPhp.ivaD=0
this.subtotalFacturaPhp.total=0

//variables propias de esa rutina (j: de java)
let j_cant=0
let j_ivaP=0
let j_pvp=0
let j_descP=0
let j_dcto=0
let j_renglon=0
let j_qty=0

let vectorLoco= [...this.state.datosGrid ] //hago una copia del estado

for (let i=0; i<vectorLoco.length;i++){
    j_cant=parseFloat(vectorLoco[i].Cant)
    j_qty += j_cant
    j_ivaP=(vectorLoco[i].GravaIva=='No') ? 0 : parseFloat(this.valueDC.iva.Porcentaje)
    j_pvp=parseFloat(vectorLoco[i].PvpSinIva)
    j_descP=parseFloat(vectorLoco[i].DescP)

    j_renglon=j_cant * j_pvp
    j_dcto=j_renglon * j_descP /100
    j_renglon=j_renglon - j_dcto

    vectorLoco[i].DescD=(j_pvp * j_descP/100).toFixed(4) //descuento en $ unitario
    vectorLoco[i].Total=j_renglon.toFixed(4)  //total renglon sin impuesto
    
    this.subtotalFacturaPhp.dcto += j_dcto
    if (j_ivaP==0){
      vectorLoco[i].PvpConIva=j_pvp.toFixed(4) //se hace referencia al precio unitario
      this.subtotalFacturaPhp.sub0 += j_renglon
    }
    else{
      vectorLoco[i].PvpConIva=(j_pvp + j_pvp * j_ivaP/100).toFixed(4) //se hace referencia al precio unitario
      this.subtotalFacturaPhp.sub12 += j_renglon
      //this.subtotalFacturaPhp.ivaD += (j_renglon * j_ivaP /100)
    }
  } //del for

await sleepPepe(50)
this.setState({ datosGrid:[]})     //Lo hago solamente para forzar el render
await sleepPepe(50)
this.setState({datosGrid:vectorLoco})
await sleepPepe(50)

let numTexto=null
//*******hago los redondeos necesarios
numTexto=this.subtotalFacturaPhp.propina.toFixed(2) //la funcion toFixed redondea pero me devuelve un texto
this.subtotalFacturaPhp.propina=parseFloat(numTexto)
//base 0
numTexto=this.subtotalFacturaPhp.sub0.toFixed(2) 
this.subtotalFacturaPhp.sub0=parseFloat(numTexto)
//base 12
numTexto=this.subtotalFacturaPhp.sub12.toFixed(2) 
this.subtotalFacturaPhp.sub12=parseFloat(numTexto)
//base Iva Dolares
let porcentajeIvaTMP=parseFloat(this.valueDC.iva.Porcentaje)
let ivaD_sr=(this.subtotalFacturaPhp.sub12 * porcentajeIvaTMP/100) //iva dolares sin redondear
numTexto=ivaD_sr.toFixed(2)
this.subtotalFacturaPhp.ivaD=parseFloat(numTexto)

//total a pagar
numTexto=this.subtotalFacturaPhp.propina + this.subtotalFacturaPhp.sub0 + this.subtotalFacturaPhp.sub12 + this.subtotalFacturaPhp.ivaD
numTexto=numTexto.toFixed(2)
this.subtotalFacturaPhp.total = parseFloat(numTexto)

//escribo en el documento
document.getElementById('txtFilas').innerText=(vectorLoco.length).toFixed(0)
document.getElementById('txtQty').innerText=j_qty.toFixed(2) 

document.getElementById('txtDcto').value=this.subtotalFacturaPhp.dcto.toFixed(2)
document.getElementById('txt0').value=this.subtotalFacturaPhp.sub0.toFixed(2)
document.getElementById('txt12').value=this.subtotalFacturaPhp.sub12.toFixed(2)
document.getElementById('txtIva').value=this.subtotalFacturaPhp.ivaD.toFixed(2)
document.getElementById('txtTotal').value=this.subtotalFacturaPhp.total.toFixed(2)
}

eliminarProducto=async()=> {
  let miProductoEnviar="Seguro desea eliminar el producto: \n" + this.miFilaAzulJson.DescripcionModificada
  let miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miProductoEnviar)
  if (miResp.isDismissed) return //alomejor se arrepintio    

  let gridLoco=this.state.datosGrid.filter(item=>item.Fila != this.state.miFilaAzulIndice)
 
  await sleepPepe(50)
  this.setState( {datosGrid:gridLoco,miFilaAzulIndice:-1} ) 
  await sleepPepe(50)

  this.calcularTotal()  
}

ponerIconosInferioresSegunDocumento=()=>{
//Aqui analizo que tipo de documento voy a elaborar
switch (this.state.def_doc) {
  case "FA":
    this.state.def_afectaInvSN="S"  
    this.state.def_afectaCajaSN="S"
    this.state.def_repetitivo10=this.valueDC.usuario.FacturaRepetitiva
    this.state.def_cobraIvaSN="S"
    document.getElementById("divBotoneraInferiorB").style.display= 'none' //Los botones visibles se reacomodan
    break
  case "PR":
    this.state.def_afectaInvSN="N"  
    this.state.def_afectaCajaSN="N"
    this.state.def_repetitivo10=this.valueDC.usuario.ProformaRepetitiva
    this.state.def_cobraIvaSN="S"
    document.getElementById("divBotoneraInferiorA").style.display= 'none' //Los botones visibles se reacomodan
    break
  case "PR_MOD":
    this.state.def_afectaInvSN="N"  
    this.state.def_afectaCajaSN="N"
    this.state.def_repetitivo10="0"
    this.state.def_cobraIvaSN="S"
    document.getElementById("divBotoneraInferiorA").style.display= 'none' //Los botones visibles se reacomodan
    break      
  case "NECONIVA":
    this.state.def_afectaInvSN="S"  
    this.state.def_afectaCajaSN="S"
    this.state.def_repetitivo10=this.valueDC.usuario.NotaEntregaRepetitiva
    this.state.def_cobraIvaSN="S"
    document.getElementById("divBotoneraInferiorB").style.display= 'none' //Los botones visibles se reacomodan
    document.getElementById("btnCredito").style.display= 'none'  
    break            
  }
}

determinarEstab_y_PuntoDefinitivo=()=>{
  //segun el documento que deseo hacer, debe haber un estab y punto definitivo
  //el problema no es tanto con la Factura y nota e, sino cuando modifico un presupuesto o plan
  switch (this.state.def_doc) {
    case "FA":
    case "PR":
    case "NECONIVA":
    case "NESINIVA":      
      this.estabDef=this.valueDC.usuario.EstabUsu
      this.puntoDef=this.valueDC.usuario.PuntoUsu
      break  
    case "PR_MOD":
      this.estabDef=this.props.miEstab
      this.puntoDef=this.props.miPunto
      this.numPresupuestoDef=this.props.miNumPre
      break      
    case "PL_MOD":
      this.estabDef=this.props.miEstab
      this.puntoDef=this.props.miPunto
      this.numPlanDef=this.props.miNumPlan
      break          
    }
}

ponerTituloDeDocumento=()=>{
  switch (this.state.def_doc) {
    case "FA":
      document.getElementById("labelTipoDoc").innerText= 'FACTURA'      
      document.getElementById("divTitulo").style.background='purple'
      document.getElementById("divTitulo").style.color='white'
      //#d5b5d4 //morado medio
      //#eadae9 //morado bien claro
      document.getElementById("miFormularioFacturacion").style.background='#d5b5d4'      
      //etiqueta inferior
      document.getElementById("txtDocHaciendo").style.background='purple'      
      document.getElementById("txtDocHaciendo").innerText='F A C T U R A'
      document.getElementById("txtDocHaciendo").style.color='white'
      break
    case "PR":
      document.getElementById("labelTipoDoc").innerText= 'PROFORMA'      
      document.getElementById("divTitulo").style.background='#49b675'
      document.getElementById("divTitulo").style.color='black'      
      document.getElementById("miFormularioFacturacion").style.background='#8ccfa1'      
      //etiqueta inferior
      document.getElementById("txtDocHaciendo").style.background='#49b675'      
      document.getElementById("txtDocHaciendo").innerText='P R O F O R M A'
      document.getElementById("txtDocHaciendo").style.color='black'      
      break
    case "PR_MOD":
      document.getElementById("labelTipoDoc").innerText= 'PROFORMA MOD.'      
      document.getElementById("divTitulo").style.background='#49b675'
      document.getElementById("divTitulo").style.color='black'            
      document.getElementById("miFormularioFacturacion").style.background='#8ccfa1'      
      //etiqueta inferior
      document.getElementById("txtDocHaciendo").style.background='#49b675'      
      document.getElementById("txtDocHaciendo").innerText='P R O F O R M A   M O D.'
      document.getElementById("txtDocHaciendo").style.color='black'            
      break      
    case "NECONIVA":
      document.getElementById("labelTipoDoc").innerText= 'NOTA DE ENTREGA'      
      document.getElementById("divTitulo").style.background='#ffec4f'
      document.getElementById("divTitulo").style.color='dimGray'            
      //amarillo medio: #fff39b
      //amarillo muy claro: #fff7bc
      document.getElementById("miFormularioFacturacion").style.background='#fff7bc'      
      //etiqueta inferior
      document.getElementById("txtDocHaciendo").style.background='#ffec4f'      
      document.getElementById("txtDocHaciendo").innerText='N O T A    D E    E N T R E G A'
      document.getElementById("txtDocHaciendo").style.color='dimGray'            
      break            
    }
}

componentDidMount=async()=>{
  //aqui cargo que TIPO de documento deseo generar,truco para que se actualice el estado... es una locura el JS
  //los documentos posibles son: FA,PR,PR_MOD,NECONIVA
  await this.setState({def_doc:this.props.docElab}) 
  //muestro en el titulo, el tipo de documento que deseo hacer, y pongo los colores respectivos
  this.ponerTituloDeDocumento() //

  //muestro-oculto en la parte inferior, los div que corresponden a [formas de pago ] [boton siguiente]
  this.ponerIconosInferioresSegunDocumento() //

  //apago los botones de consultar y editar cliente
  this.ponerVisibleOculto_ClaseVerOcultar('hidden') //

  //apago el boton de consultar inventario (detalles) y otras cosas basicas
  this.prepararEscenarioParaNuevoNegro() //

  //Creo y limpio el objeto caPiePhp
  this.limpiar_caPiePhp() //

  //esa hay que dajarla SIEMPRE, para que me cree el JSON de totales y escriba puros 0 en la parte inferior
  this.calcularTotal()

  this.alimentarCombosBasicos() //cargo el combo de VENDEDORES 
  let sePudoAlimentarInvMasClientes=null
  sePudoAlimentarInvMasClientes = await this.gestionarAlimentarInventarioMasClientes() 
  
  this.permanecerEnForm=true
  if (sePudoAlimentarInvMasClientes==false){
    this.permanecerEnForm=false
    this.salirPorBoton()
    return
  }
  else{
    //necesito saber el Estab y Punto definitivo para trabajar
    this.determinarEstab_y_PuntoDefinitivo()
    if (this.state.def_doc=="PR_MOD") {
      await this.buscarPresupuestoDetalleParaClonarModificar(this.estabDef,this.puntoDef,this.numPresupuestoDef)
    }
  }
}

correoConBarra=(correoLoco)=>{
  correoLoco=correoLoco.trim()
  if (correoLoco.length==0)
    return('')
  else
    return(' / ' + correoLoco)
}

clientePredeterminado=()=>{
  //pongo los datos del cliente predeterminado, generalmente 9999999999999
  let cedulaBusq=this.valueDC.sistema.identificacionDeClientePredeterminado.trim().toLowerCase()
  document.getElementById("txtCedulaCliente").value=this.valueDC.sistema.identificacionDeClientePredeterminado.trim()

  if (cedulaBusq.length==0)
    return

  this.state.datosFullCli.some( cli => {
    if (cli.IdPrincipal.trim().toLowerCase()==cedulaBusq) {    
      document.getElementById("txtCedulaCliente").value=this.valueDC.sistema.identificacionDeClientePredeterminado.trim()
      document.getElementById("txtNombreCliente").value=cli.RazonSocial.trim() + this.correoConBarra(cli.Email)
      this.ponerPVPx(cli.PrecioUsualDef.trim())
      this.ponerVisibleOculto_ClaseVerOcultar('visible')

      //guardo en el estado, ya que ya se consiguio al cliente
      this.setState({ clientePonchado:true, clienteIDr:cli.IDr, clienteCedula:cli.IdPrincipal, clienteIdSecundario:cli.IdSecundario,clienteActivo:cli.Activo, clienteNombre:cli.RazonSocial,clienteNombreComercial:cli.NombreComercial, clienteEmail:cli.Email,clienteTipoVenta:cli.TipoVenta})
      //en caso que sea INACTIVO le muestro una ALERTA
      if (cli.Activo=="No"){
        mostrarSwalBotonAceptar("error","ATENCION","El cliente no está activo")
      }        
      return true
    }    
    }) //del some
}

copiarVariablesDesdeContext=(value)=>{
  this.valueDC=value
}

limpiar_caPiePhp=()=>{
  //Creo y limpio este objeto (al crear la factura/presupuesto/nota/plan se va a PHP)
  this.caPiePhp.AlContado=null //1,0
  this.caPiePhp.Plazo=null
  this.caPiePhp.Cancelado=null //1,0
  this.caPiePhp.CodClienteRojo=null //luego se actualiza desde el estado
  this.caPiePhp.TipoPrecio=null
  this.caPiePhp.IdVendedor=null
  this.caPiePhp.IdFacturador=null
  this.caPiePhp.Observaciones=null
  this.caPiePhp.Pedido=null
  this.caPiePhp.TextoComplementario=null
  this.caPiePhp.GuiaRemPunto=null
  this.caPiePhp.GuiaRemNum=null
  this.caPiePhp.AfectaInventarioSN=null //luego se actualiza desde el estado
  this.caPiePhp.AfectaCajaSN=null //luego se actualiza desde el estado

  //esta parte es para los presupuestos
  this.caPiePhp.ValidezDias=null
  this.caPiePhp.TiempoEntregaDias=null
  this.caPiePhp.Garantia=null
  this.caPiePhp.ImprimirCondiciones=null //[1,0]
  this.caPiePhp.FechaEmision=null //para irse a PHP, debe ser "string" en formato DDMMYYYY

  //esta parte seria solo para Notas de entrega cuando no se vaya a cobrar IVA
  this.caPiePhp.CobraIvaSN=null //tipicamenta debe ir "S"
}

prepararNuevaVenta=()=>{
  this.afectaItems1T=null

  document.getElementById("txtObservaciones").value=""
  this.limpiar_caPiePhp()
  
  //combos, pongo el vendedor preferido
  this.alimentarCombosBasicos()

  //*****cliente
  this.ponerVisibleOculto_ClaseVerOcultar('hidden')
  document.getElementById("txtCedulaCliente").value=''
  document.getElementById("txtNombreCliente").value=''
  this.ponerPVPx(this.valueDC.sistema.precioComun)
  //guardo en el estado, que AUN NO se consiguio al cliente
  this.setState({ clientePonchado:false, clienteIDr:0,clienteCedula:'',clienteIdSecundario:'',clienteActivo:null, clienteNombre:'',clienteNombreComercial:'',clienteEmail:''})
  this.clientePredeterminado()

  //***** limpio el grid
  this.miFilaAutonumerico=1 //reinicio mi clave del grid
  this.setState({datosGrid:[]}) //truco para que se limpie realmente (es una locura el JS)
  //truco para poder ver el refrescamiento ya que de lo contrario, no se ve            
    setTimeout(()=>{ 
        this.calcularTotal() //aqui es para forzar que se vea en el render()
    },50)  
}

prepararDatosGridResumido=()=>{
let vectorLoco=[]
for(let i=0; i<this.state.datosGrid.length; i++ ){
      const x={
      IDr: this.state.datosGrid[i].IDr,
      Cant:this.state.datosGrid[i].Cant,
      DescripcionModificada: (this.state.datosGrid[i].DescripcionOriginal.trim() != this.state.datosGrid[i].DescripcionModificada.trim()) ? this.state.datosGrid[i].DescripcionModificada.trim() : '',
      DescripcionAmpliada:this.state.datosGrid[i].DescripcionAmpliada.trim(), 
      GravaIva: (this.state.datosGrid[i].GravaIva=='Si') ? "1":"0",
      Precio01234: this.state.datosGrid[i].Precio01234,
      PvpSinIva:this.state.datosGrid[i].PvpSinIva,
      DescP:this.state.datosGrid[i].DescP,
      Costo:this.state.datosGrid[i].CostoP,
    }
    vectorLoco.push(x) //empujo el item requerido por el cliente
  }
return vectorLoco   
} 

prepararCaPiePhpCosasComunes=()=>{
  //aqui preparo solo algunas variables del Json caPiePhp
  this.caPiePhp.CodClienteRojo=this.state.clienteIDr
  this.caPiePhp.TipoPrecio=document.getElementById('labelPVP').textContent.substring(3,4)
  this.caPiePhp.IdVendedor=document.getElementById("comboVendedor").value
  this.caPiePhp.IdFacturador=1
  this.caPiePhp.Observaciones=document.getElementById("txtObservaciones").value.trim()
  this.caPiePhp.Observaciones=(this.caPiePhp.Observaciones.length==0) ? null : this.caPiePhp.Observaciones
  if (this.caPiePhp.TextoComplementario!=null)
    this.caPiePhp.TextoComplementario=(this.caPiePhp.TextoComplementario.length==0) ? null : this.caPiePhp.TextoComplementario //me interesa que se vaya con null o con texto

  this.caPiePhp.AfectaInventarioSN=this.state.def_afectaInvSN
  this.caPiePhp.AfectaCajaSN=this.state.def_afectaCajaSN    
  this.caPiePhp.CobraIvaSN=this.state.def_cobraIvaSN
}

prepararCaPiePhpCondicionesPresupuestoNuevo=()=>{
  //aqui preparo solo las variables del Json caPiePhp cuando el presupuesto es nuevo.
  //me refiero a las condiciones del presupuesto (contado, credito, plazo.. etc). Esta informacion la obtengo del context
  this.caPiePhp.AlContado=(this.valueDC.sistema.proformasFormaDePago=="CONTADO") ? "1":"0"
  this.caPiePhp.Plazo=this.valueDC.sistema.proformasPlazoDelCredito
  this.caPiePhp.ValidezDias=this.valueDC.sistema.proformasValidezPredeterminada
  this.caPiePhp.TiempoEntregaDias=this.valueDC.sistema.proformasTiempoDeEntregaPredeterminado
  this.caPiePhp.Garantia=this.valueDC.sistema.proformasGarantia
  this.caPiePhp.ImprimirCondiciones=(this.valueDC.sistema.proformasImprimirCondiciones=="Si") ? "1" : "0"
  //la variable this.caPiePhp.FechaEmision se usa para recibir la fecha cuando vengo de regreso del formulario PedirCondicionesPresupuesto
  this.caPiePhp.FechaEmision=null //cuando es nuevo, le mando la fecha del sistema pero en la variable this.fechaEmisionPresupuesto.
}

gestionar_FA_6pasos=()=>{
  //busco el formato del usuario logeado(this.valueDC.estable.Estab), generalmente: A4v
  let miFormato=determinarFormatoImpresoraGrande(this.valueDC,this.valueDC.estable.Estab,'FormatoFacturaSri')
  if (miFormato==''){
    console.log("No se consigue el formato, desde el modulo de facturacion para formato de factura sri")          
    return
  }  
  //llamo a una funcion en PHP que hace los 6 pasos. Pero NUNCA espero la respuesta
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','gestionar_DocumentoSri_6pasos')
  data.append('prefijoDoc',"FA") 
  data.append('miFormato',miFormato)  //generalmente A4v
  data.append('miEstab',this.estabDef) 
  data.append('miPunto',this.puntoDef) 
  data.append('numDoc',this.numFacturaDef) //numero de factura que se acaba de generar 
  data.append('miContext', JSON.stringify(this.valueDC) ) //le mando el context completo
  data.append('destinoNombre',this.state.clienteNombre)
  data.append('destinoEmail',this.state.clienteEmail)  
  data.append('resolverSN', "N") // no se desea resolver, ya que eso se usa en la tabela, para documEntos ROJOS
  fetch(miPhpFile, { method: 'POST',body:data }) //No espero la respuesta 
}   
  
procesarFacturaEnBDD=()=> {  
  this.prepararCaPiePhpCosasComunes()
  return new Promise( async(resolve) =>{
    this.intentosDocBDD=1
    let seguir=true
    this.numFacturaDef=-3 //-3 es un codigo para diferenciar Javascript del Procedimiento almacenado     
    mostrarSwalEspera()  
    while (seguir) {    
      let vectorDV=this.prepararDatosGridResumido()
      let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
      let data=new FormData()
      data.append('miSol','procesarFacturaEnBDD') 
      data.append('miContext',JSON.stringify(this.valueDC)) //le mando el context completo
      data.append('caPiePhp',JSON.stringify(this.caPiePhp)) //Cabecera y Pie de la factura     
      data.append('subtotalFacturaPhp',JSON.stringify(this.subtotalFacturaPhp)) //Subtotales del documento
      data.append('miJsonDV',JSON.stringify(vectorDV)) //Aqui se va el detalle de la Venta (el grid)
      data.append('miJsonDP',JSON.stringify(this.datosFormaPago)) //Aqui se va el detalle de la Forma de Pago        
      try{
        const response=await fetch(miPhpFile, { method: 'POST',body:data })
        const pepeJson=await response.json()

        //analizo la cabecera del JSON de la respuesta
        let hayError=false
        let miE=null
        if (!pepeJson.ConexionBUC){
          hayError=true
          miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
        } 
        if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
          hayError=true
          miE="No se pudo ejecutar la solicitud=>" + " No se pudo registrar la Factura" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
        }     
        if (hayError){
          throw miE      
        } 
        //rumbo normal y me salgo del while *********
        this.numFacturaDef=pepeJson.Data //en Data, viene el numero de la Factura en texto (o negativo si hubo un error en MySql, o Null al no haber conexion)
        apagarSwal()
        seguir=false
      } //del try
      catch (e) {    
        this.intentosDocBDD++
        if (this.intentosDocBDD<=this.valueDC.sistema.numeroDeReintentosPhp){      
          await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
        }
        else{
          //llamo al gestor para que me aparezca la pantalla de REINTENTAR
          apagarSwal()          
          let miRespSwal = await gestionarCatch(e)
          //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
          if (miRespSwal.isDenied) {
            mostrarSwalEspera()
            this.intentosDocBDD=1
          }
          else{
            //ha pulsado salir en el swal. no hago nada         
            seguir=false
          }
        } //del else    
      }  //del catch  
  } //del while  
  resolve()
  })// de la promesa
}

procesarPresupuestoEnBDD=()=> {
  //esta funcion me sirve para presupuesto NUEVO o MODIFICAR
  this.prepararCaPiePhpCosasComunes()
  return new Promise( async(resolve) =>{
    this.intentosDocBDD=1
    let seguir=true
    this.numGenericoDevuelto=-3 //-3 es un codigo para diferenciar Javascript del Procedimiento almacenado     
    mostrarSwalEspera()  
    while (seguir) {    
      let vectorDV=this.prepararDatosGridResumido()
      let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
      let data=new FormData()
      data.append('miSol', 'procesarPresupuestoEnBDD')
      //cuando es nuevo
      if (this.state.def_doc=="PR"){
        data.append('esNuevoModificar', 'Nuevo')
        data.append('miNumPre',"0" )
      }
      if (this.state.def_doc=="PR_MOD"){
        data.append('esNuevoModificar', 'Modificar')
        data.append('miNumPre',this.numPresupuestoDef)
      }
      data.append('miEstab',this.estabDef)
      data.append('miPunto',this.puntoDef)
      data.append('miContext',JSON.stringify(this.valueDC)) //le mando el context completo
      data.append('caPiePhp',JSON.stringify(this.caPiePhp)) //Cabecera y Pie del Presupuesto
      data.append('subtotalPresupuestoPhp',JSON.stringify(this.subtotalFacturaPhp)) //Subtotales del documento
      data.append('miJsonDV',JSON.stringify(vectorDV)) //Aqui se va el detalle del Presupuesto (el grid)
      try{
        const response=await fetch(miPhpFile, { method: 'POST',body:data })
        const pepeJson=await response.json()

        //analizo la cabecera del JSON de la respuesta
        let hayError=false
        let miE=null
        if (!pepeJson.ConexionBUC){
          hayError=true
          miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
        } 
        if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
          hayError=true
          miE="No se pudo ejecutar la solicitud=>" + " No se pudo registrar la Proforma" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
        }     
        if (hayError){
          throw miE      
        } 
        //rumbo normal y me salgo del while *********
        this.numGenericoDevuelto=pepeJson.Data //en Data, viene el numero del Presupuesto en texto (o negativo si hubo un error en MySql, o Null al no haber conexion)
        apagarSwal()
        seguir=false
      } //del try
      catch (e) {    
        this.intentosDocBDD++
        if (this.intentosDocBDD<=this.valueDC.sistema.numeroDeReintentosPhp){      
          await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
        }
        else{
          //llamo al gestor para que me aparezca la pantalla de REINTENTAR
          apagarSwal()          
          let miRespSwal = await gestionarCatch(e)
          //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
          if (miRespSwal.isDenied) {
            mostrarSwalEspera()
            this.intentosDocBDD=1
          }
          else{
            //ha pulsado salir en el swal. no hago nada         
            seguir=false
          }
        } //del else    
      }  //del catch  
  } //del while  
  resolve()
  })// de la promesa
}

procesarNotaEnBDD=()=> {
  this.prepararCaPiePhpCosasComunes()
  return new Promise( async(resolve) =>{
    this.intentosDocBDD=1
    let seguir=true
    this.numNotaDef=-3 //-3 es un codigo para diferenciar Javascript del Procedimiento almacenado     
    mostrarSwalEspera()  
    while (seguir) {    
      let vectorDV=this.prepararDatosGridResumido()
      let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
      let data=new FormData()
      data.append('miSol','procesarNotaEnBDD') 
      data.append('miContext',JSON.stringify(this.valueDC)) //le mando el context completo
      data.append('caPiePhp',JSON.stringify(this.caPiePhp)) //Cabecera y Pie de la factura     
      data.append('subtotalFacturaPhp',JSON.stringify(this.subtotalFacturaPhp)) //Subtotales del documento
      data.append('miJsonDV',JSON.stringify(vectorDV)) //Aqui se va el detalle de la Venta (el grid)
      data.append('miJsonDP',JSON.stringify(this.datosFormaPago)) //Aqui se va el detalle de la Forma de Pago        
      try{
        const response=await fetch(miPhpFile, { method: 'POST',body:data })
        const pepeJson=await response.json()

        //analizo la cabecera del JSON de la respuesta
        let hayError=false
        let miE=null
        if (!pepeJson.ConexionBUC){
          hayError=true
          miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
        } 
        if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
          hayError=true
          miE="No se pudo ejecutar la solicitud=>" + " No se pudo registrar la Nota de Entrega" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
        }     
        if (hayError){
          throw miE      
        } 
        //rumbo normal y me salgo del while *********
        this.numNotaDef=pepeJson.Data //en Data, viene el numero de la Nota en texto (o negativo si hubo un error en MySql, o Null al no haber conexion)
        apagarSwal()
        seguir=false
      } //del try
      catch (e) {    
        this.intentosDocBDD++
        if (this.intentosDocBDD<=this.valueDC.sistema.numeroDeReintentosPhp){      
          await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
        }
        else{
          //llamo al gestor para que me aparezca la pantalla de REINTENTAR
          apagarSwal()          
          let miRespSwal = await gestionarCatch(e)
          //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
          if (miRespSwal.isDenied) {
            mostrarSwalEspera()
            this.intentosDocBDD=1
          }
          else{
            //ha pulsado salir en el swal. no hago nada         
            seguir=false
          }
        } //del else    
      }  //del catch  
  } //del while  
  resolve()
  })// de la promesa
}

alimentarCombosBasicos=()=>{
  //el combo vendedor solamente lo debo carga UNA vez OJO
  //comboVendedor (voy a ir metiendo solo los vendedores activos)
  let miSelectVen= document.getElementById("comboVendedor")
  if (miSelectVen.length==0){
    this.valueDC.vendedores.forEach( (item) => {
      if (item.ActivoVen==1){
        let miOption=document.createElement("option")
        miOption.value= item.IDv
        miOption.text=item.NombreVen
        miSelectVen.appendChild(miOption)
      }
    })  
}
   
  //pongo los combos con el valor predeterminado segun el usuario logeado
  document.getElementById("comboVendedor").value=this.valueDC.usuario.VendedorUsual
}

leerInventarioVariasVeces=async()=>{
//Leo el Inventario, sin mostrar la pantalla de REINTENTAR
this.intentosInv=0
mostrarSwalEspera()
while (!this.sePudoLeerInv && this.intentosInv<this.valueDC.sistema.numeroDeReintentosPhp){   
  //console.log('intento de inventario==>',this.intentosInv)  
  await this.refrescarInventario()
  this.intentosInv++
  if (!this.sePudoLeerInv && this.intentosInv<this.valueDC.sistema.numeroDeReintentosPhp){      
    await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
  }
}
apagarSwal()
}

leerClientesVariasVeces=async()=>{
//Leo los clientes, sin mostrar la pantalla de REINTENTAR
this.intentosCli=0
mostrarSwalEspera()  
while (!this.sePudoLeerCli && this.intentosCli<this.valueDC.sistema.numeroDeReintentosPhp){   
  //console.log('intento de cliente==>',this.intentosCli)     
  await this.refrescarClientes()
  this.intentosCli++  
  if (!this.sePudoLeerCli && this.intentosCli<this.valueDC.sistema.numeroDeReintentosPhp){      
    await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
  }
}
apagarSwal()
}
  
gestionarAlimentarInventarioMasClientes=async()=>{  
this.ponerPVPx(this.valueDC.sistema.precioComun)
this.sePudoLeerInv=false
this.sePudoLeerCli=false

let seguirBucle=true 
  while (seguirBucle){
    await this.leerInventarioVariasVeces()
    await this.leerClientesVariasVeces()

    if (this.sePudoLeerInv && this.sePudoLeerCli){
      seguirBucle=false      
    }    
    if (!this.sePudoLeerInv || !this.sePudoLeerCli){
      //llamo al gestor para que me aparezca la pantalla de REINTENTAR
      let miRespSwal = await gestionarCatch("No se pudo ejecutar la solicitud=>Al leer Inventario o Clientes")

      //ha pulsado en cancelar
      if (miRespSwal.isDismissed) {
        seguirBucle=false
      }
    } 

    if (this.sePudoLeerCli){
      this.clientePredeterminado()  
    }
  }  

return (this.sePudoLeerInv && this.sePudoLeerCli)
}

ponerPVPx=(precioCliente)=>{
  //en precioCliente, puede venir COMUN,1,2,3,4
  let PVPx=''
  if (precioCliente=='COMUN'){
    //Pongo la etiqueta de PVP segun lo que manda el context
    PVPx="PVP" + this.valueDC.sistema.precioComun
  }
  else{
    PVPx="PVP" + precioCliente
  }

  document.getElementById("labelPVP").innerText=PVPx
}

ponerVisibleOculto_ClaseVerOcultar=(miEstatus)=>{
  //Los posible estatus son:  
  // 'hidden' //Lo botones visibles se quedan en su lugar original
  // 'visible'
  document.getElementById("btnModificarCliente").style.visibility=miEstatus
  document.getElementById("btnConsultarCliente").style.visibility=miEstatus
}

filtrarPorCedula=(cadenaLoca) =>{
  this.ponerVisibleOculto_ClaseVerOcultar('hidden')
  document.getElementById("txtNombreCliente").value=''
  this.ponerPVPx(this.valueDC.sistema.precioComun)

  //guardo en el estado, que AUN NO se consiguio al cliente
  this.setState({ clientePonchado:false, clienteIDr:0,clienteCedula:'',clienteIdSecundario:'',clienteActivo:null, clienteNombre:'',clienteNombreComercial:'',clienteEmail:'',clienteTipoVenta:''})

  if (cadenaLoca.length==0)
    return
 
  this.state.datosFullCli.some( cli => {
      if (cli.IdPrincipal.trim().toLowerCase()==cadenaLoca) {    
        this.ponerPVPx(cli.PrecioUsualDef.trim())
        document.getElementById("txtNombreCliente").value=cli.RazonSocial.trim() + this.correoConBarra(cli.Email)    
        this.ponerVisibleOculto_ClaseVerOcultar('visible')
       //guardo en el estado, que ya se consiguio al cliente
       this.setState({ clientePonchado:true, clienteIDr:cli.IDr,clienteCedula:cli.IdPrincipal,clienteIdSecundario:cli.IdSecundario,clienteActivo:cli.Activo, clienteNombre:cli.RazonSocial,clienteNombreComercial:cli.NombreComercial,clienteEmail:cli.Email,clienteTipoVenta:cli.TipoVenta})
       //en caso que sea INACTIVO le muestro una ALERTA 

       if (cli.Activo=="No"){
        mostrarSwalBotonAceptar("error","ATENCION","El cliente no está activo")
       }  
        return true
      }
  })
} 

incluirModificarJsonCliente=async(jsonRegistro)=>{
  //Lo que hace es Incluir o Modificar un Item en el array JSON . (asi me ahorro la lectura de la BDD)
  //OJO: vuelvo a escribir la cedula en textBox, alomejor el usuario la modifico en el formulario: IncModCliente
  //en el modulo de ventas, no se usa BlockDeNotas ni OpCrea de la lista de clientes
  document.getElementById("txtCedulaCliente").value=jsonRegistro.IdPrincipal
  
  let itemLoco={
    "IDr":jsonRegistro.IDr,
    "IdPrincipal":jsonRegistro.IdPrincipal,
    "IdSecundario":jsonRegistro.IdSecundario,
    "RazonSocial":jsonRegistro.RazonSocial,
    "NombreComercial":jsonRegistro.NombreComercial,
    "PrecioUsualDef":jsonRegistro.PrecioUsualDef, //"comun",1,2,3,4
    "TipoVenta":jsonRegistro.TipoVenta, //0=>contado solamente, 1=>contado y credito
    "TlfCel":jsonRegistro.TlfCel,
    "Email":jsonRegistro.Email,
    "Clase":jsonRegistro.Clase,
    "Ciudad":jsonRegistro.Ciudad,
    "Activo":jsonRegistro.Activo,
    "VisibleSN":'S', //siempre es 'S' [En las tabelas podria ser 'N', 'S']
  }

  //***********  cuando es modificar *************
  if (this.state.opcionCliente_imc=='m'){
    let datosFullCopia=this.state.datosFullCli.map(item=>{ return (item.IDr==jsonRegistro.IDr) ? itemLoco : item })
    await sleepPepe(50)
    this.setState( {datosFullCli:datosFullCopia} )       
    await sleepPepe(50)
  }
  
  //******  cuando es nuevo *************
  //en este modulo no importa si estan o no ordenados los datos
  if (this.state.opcionCliente_imc=='i'){  
    let datosFullCopia=this.state.datosFullCli.filter(item=>true)
    datosFullCopia.push(itemLoco) 
    await sleepPepe(50)
    this.setState({datosFullCli:datosFullCopia}) 
    await sleepPepe(50)
  }

  //ahora, forzo la busqueda (estamos seguros que lo va a encontrar)
  let cadenaLoca=document.getElementById("txtCedulaCliente").value
  cadenaLoca=cadenaLoca.trim().toLocaleLowerCase()
  this.filtrarPorCedula(cadenaLoca)
}

eliminarJsonCliente=async(IDr)=>{
  //esta funcion elimina un cliente del JSON  

  let datosFullCopia=this.state.datosFullCli.filter(item=>item.IDr != IDr)
  await sleepPepe(50)
  this.setState({datosFullCli:datosFullCopia,}) 
  await sleepPepe(50)

  //llamo a la funcion filtrarPorCedula, para que me ponga los valores predeterminados de PVP, apague clientePonchado....
  let cadenaLoca=document.getElementById("txtCedulaCliente").value
  cadenaLoca=cadenaLoca.trim().toLocaleLowerCase()
  this.filtrarPorCedula(cadenaLoca)    
}

eliminarJsonInventario=()=>{
  //esta funcion elimina un producto del JSON 
  let datosFullCopia=this.state.datosInv.filter(item=>item.IDr != this.state.inventarioNegroIDr)
  this.setState({datosInv:datosFullCopia,}) 
  this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:0,inventarioNegroCodigoPrincipal:'',inventarioNegroActivo:null,})
  document.getElementById("txtNombreProducto").value=""
  document.getElementById("btnConsultarInv1").style.visibility='hidden'
  document.getElementById("btnEnterInv").style.visibility='hidden'
  this.productoNegro=null //pongo vacio en mi JSON del futuro producto que se va a meter al grid de venta                             
}
   
gestionarFacturaComun=async()=>{
  await this.procesarFacturaEnBDD()
  if (this.numFacturaDef<=0) {
    mostrarSwalBotonAceptar("error","ATENCION","No se pudo registrar la factura. Código de error # " + this.numFacturaDef)
    return
  }
  this.gestionar_FA_6pasos() //aqui NO espero la respuesta 
  if(this.valueDC.usuario.FacturaImprimirEn=="NO") 
    await mostrarSwalUnSegundo("Factura " + this.numFacturaDef + " Registrada",1000)    
  //aqui Descuento el inventario pero directamente en RAM (en el json). Para evitar tener que leer de la base de datos
  if (this.state.def_afectaInvSN=="S" && this.state.def_repetitivo10=="1") 
    this.descontarInventario()

  //mando a imprimir el comprobante normalmente A5v (o ticket)
  if(this.valueDC.usuario.FacturaImprimirEn=="PA"){   
    let miFormato=determinarFormatoImpresoraGrande(this.valueDC,this.valueDC.estable.Estab,'FormatoFacturaSimple')
    if (miFormato==''){
      console.log("No se consigue el formato, en modulo de facturacion para formato simple")          
    }
    else{ 
      let data=new FormData()
      data.append('miSol','facturaDeVentaSimple_generarPDF')
      data.append('miFormato',miFormato)
      data.append('miEstab',this.estabDef)
      data.append('miPunto',this.puntoDef)
      data.append('miNumFac',this.numFacturaDef)
        
      let dataApi=await ejecutarFetchGenericoConSwal(this.valueDC.sistema.numeroDeReintentosPhp,this.valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
      //en dataApi, puede venir null o el mismo numero de Factura enviada. Nunca viene negativo
      if (dataApi==null){
        await mostrarSwalBotonAceptar("error","ATENCION","No se pudo crear el PDF correctamente")
        //cuando no se puede generar el PDF, prefiero que se salga al llamador
        this.salirPorBoton()             
      }
      else
      {
        //por qui, es lo ideal (factura)
        await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
        this.setState( {estatusAbiertoModal_PDFnoSri:true} )
      }
    }
  } //**** fin de mandar a ver la copia simple de la factura */

  if(this.valueDC.usuario.FacturaImprimirEn=="NO"){
    if (this.state.def_repetitivo10=="1") this.prepararNuevaVenta() 
    if (this.state.def_repetitivo10=="0")  this.salirPorBoton()
  }
}
 
gestionarFacturaEfectivo=()=>{
  //Primero debo alimentar el JSON con la forma depago
  this.datosFormaPago=
  [
    {
      IdForma:"5", //efectivo solamente
      IdBancoOrigen:0,
      IdCuentaBancaria:0,
      Referencia: '',
      Monto:this.subtotalFacturaPhp.total,
    },
  ]
 //alimento una parte de caPiePhp
 this.caPiePhp.AlContado="1"
 this.caPiePhp.Plazo="0"
 this.caPiePhp.Cancelado="1"

 //llamo a la funcion que es comun a todas las formas de pago
 this.gestionarFacturaComun()
} 

gestionarFacturaConFormaDePago=()=>{
 //alimento una parte de caPiePhp
 this.caPiePhp.AlContado="1"
 this.caPiePhp.Plazo="0"
 this.caPiePhp.Cancelado="1"

 //llamo a la funcion que es comun a todas las formas de pago
 this.gestionarFacturaComun()
} 

gestionarNotaComun=async()=>{  
  await this.procesarNotaEnBDD()
  if (this.numNotaDef<=0) {
   mostrarSwalBotonAceptar("error","ATENCION","No se pudo registrar la nota. Código de error # " + this.numNotaDef)
   return
  }  

  //de aqui en adelante es porque se registro la Nota OK
  if(this.valueDC.usuario.NotaEntregaImprimirEn=="NO") await mostrarSwalUnSegundo("Nota " + this.numNotaDef + " Registrada",1000)    

  //aqui Descuento el inventario pero directamente en RAM (en el json). Para evitar tener que leer de la base de datos
  if (this.state.def_afectaInvSN=="S" && this.state.def_repetitivo10=="1") this.descontarInventario()
  //muestro en pantalla la nota pdf (segun las preferencias del usuario)
  if(this.valueDC.usuario.NotaEntregaImprimirEn=="PA"){ 
    //por ahora, asumo que siempre la NOTA es con iva (caso tipico: 'FormatoNotaEntregaTipica'). Luego podria ser:'FormatoNotaEntregaSinIva'
    let miFormato=determinarFormatoImpresoraGrande(this.valueDC,this.valueDC.estable.Estab,'FormatoNotaEntregaTipica')
    if (miFormato==''){
      await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato en facturacion, para Nota de entrega con iva")         
      this.salirPorBoton()   
      return
    }      
    //genero el pdf en disco
    let data=new FormData()
    data.append('miSol','nota_gestionarGenerarPDF')
    data.append('miFormato',miFormato)
    data.append('miEstab',this.estabDef)
    data.append('miPunto',this.puntoDef)
    data.append('miNumNE',this.numNotaDef)
      
    let dataApi=await ejecutarFetchGenericoConSwal(this.valueDC.sistema.numeroDeReintentosPhp,this.valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
    //en dataApi, puede venir null o el mismo numero de Nota enviada. Nunca viene negativo
    if (dataApi==null){
      //cuando no se puede generar el PDF, prefiero que se salga al menu principal
      this.salirPorBoton()   
    }
    //al estar todo bien, muestro la nota en un poderosisimo modal (notaentrega)
    await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
    this.setState( {estatusAbiertoModal_PDFnoSri:true} )
  }        

  if(this.valueDC.usuario.NotaEntregaImprimirEn=="NO"){ 
    if (this.state.def_repetitivo10=="1") this.prepararNuevaVenta()
    if (this.state.def_repetitivo10=="0") this.salirPorBoton()    
  }
}

gestionarNotaEfectivo=async()=>{
  //Primero debo alimentar el JSON con la forma depago
  this.datosFormaPago=
  [
    {
      IdForma:"5", //efectivo solamente
      IdBancoOrigen:0,
      IdCuentaBancaria:0,
      Referencia: '',
      Monto:this.subtotalFacturaPhp.total,
    },
  ]
 //alimento una parte de caPiePhp
 this.caPiePhp.AlContado="1"
 this.caPiePhp.Plazo="0"
 this.caPiePhp.Cancelado="1"
 
 this.gestionarNotaComun()
}

gestionarNotaConFormaDePago=async()=>{
 //alimento una parte de caPiePhp
 this.caPiePhp.AlContado="1"
 this.caPiePhp.Plazo="0"
 this.caPiePhp.Cancelado="1"
 
 this.gestionarNotaComun()
} 

determinarPVPvisibleEnBuscadorDeInventario=()=>{
  //determino cuales columnas de los 4 precios deseo ver. 
  //cuando sea ventas, depende del tipo de cliente (comunmente PVP1). cuando sea compras, siempre PVP1.
  //sin emargo desde la configuracion del sistema se puede elegir ver o no ver la columna con IVA.

  //blanqueo las variables involucradas
  this.pvp1InventarioSinIvaVerDefinitivo=false 
  this.pvp1InventarioConIvaVerDefinitivo=false
  this.pvp2InventarioSinIvaVerDefinitivo=false  
  this.pvp2InventarioConIvaVerDefinitivo=false 
  this.pvp3InventarioSinIvaVerDefinitivo=false  
  this.pvp3InventarioConIvaVerDefinitivo=false 
  this.pvp4InventarioSinIvaVerDefinitivo=false  
  this.pvp4InventarioConIvaVerDefinitivo=false 

  //determino si se desea ver con iva, sin iva, o ambas
  let verPvpSinIva=(this.valueDC.sistema.mallaDeInventarioVerPreciosSinIva=="Si") ? true : false
  let verPvpConIva=(this.valueDC.sistema.mallaDeInventarioVerPreciosConIva=="Si") ? true : false

    if (this.precio1234CandidatoParaBuscador==1){
      this.pvp1InventarioSinIvaVerDefinitivo=true
      this.pvp1InventarioConIvaVerDefinitivo=true
      //ahora, una segunda validacion 
      this.pvp1InventarioSinIvaVerDefinitivo=verPvpSinIva
      this.pvp1InventarioConIvaVerDefinitivo=verPvpConIva
    }
    if (this.precio1234CandidatoParaBuscador==2){
      this.pvp2InventarioSinIvaVerDefinitivo=true
      this.pvp2InventarioConIvaVerDefinitivo=true
      //ahora, una segunda validacion 
      this.pvp2InventarioSinIvaVerDefinitivo=verPvpSinIva
      this.pvp2InventarioConIvaVerDefinitivo=verPvpConIva
    }
    if (this.precio1234CandidatoParaBuscador==3){
      this.pvp3InventarioSinIvaVerDefinitivo=true
      this.pvp3InventarioConIvaVerDefinitivo=true
      //ahora, una segunda validacion 
      this.pvp3InventarioSinIvaVerDefinitivo=verPvpSinIva
      this.pvp3InventarioConIvaVerDefinitivo=verPvpConIva
    }
    if (this.precio1234CandidatoParaBuscador==4){
      this.pvp4InventarioSinIvaVerDefinitivo=true
      this.pvp4InventarioConIvaVerDefinitivo=true
      //ahora, una segunda validacion 
      this.pvp4InventarioSinIvaVerDefinitivo=verPvpSinIva
      this.pvp4InventarioConIvaVerDefinitivo=verPvpConIva
    }
} 

mostrarMensajesDeImportacionDeDocumentoViejo=async(jsonDetalleClonar)=>{ 
//cuando jsonDetalleClonar venga NULL significa que no Hubo conexion o que no consigue la consulta. o cualquier otro error
//lo uso solamente cuando se estra CLONANDO
let hayDatos=true //asumo que realmente hay datos

if (jsonDetalleClonar==null){
  await mostrarSwalBotonAceptar("error","ATENCION","No hubo conexión")
  hayDatos=false
}
if (jsonDetalleClonar!=null && jsonDetalleClonar.Data.length==0){
  await mostrarSwalBotonAceptar("error","ATENCION","El documento vino vacío")
  hayDatos=false
}

return hayDatos
}

render(){  
return (               
<AppContextConsumer>
{ (value) =>{ 
return (
<div id="miFormularioFacturacion" name="miFormularioFacturacion" style={{margin:'0',width:"100%",minHeight:'100vh',paddingBottom:'1%'}}>    
{/* minHeight:'100vh', */}
  {this.copiarVariablesDesdeContext(value)}
  <this.ParteTituloMasCerrar/>
  <this.ParteCliente/>
  <this.ParteVendedor/>
  <this.ParteBotoneraSuperior/> 
  <this.ParteGrid/>
  <div id="divQty" name="divQty" style={{marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%',background:'dimgray', color:'white',borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderColor:'black',borderWidth:'1px'}}>
    <Label id="labelFilas" name="labelFilas" style={{marginLeft:'1%',marginRight:'1%'}}>Filas:</Label>
    <Label id="txtFilas" name="txtFilas" style={{marginRight:'5%'}}>.</Label>
    <Label id="labelQty" name="labelQty" style={{marginRight:'1%'}} >Qty:</Label>
    <Label id="txtQty" name="txtQty" >.</Label>
  </div>
  <this.ParteSubTotales/>
  <this.parteBotonMasOpciones/> {/* boton MAS OPCIONES y observaciones */}
  {/* etiqueta para recordarle al usuario, que documento esta haciendo 
style={{marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%',background:'dimgray', color:'white',borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderColor:'black',borderWidth:'1px'}}
  */}
  <div id="divDocHaciendo" name="divDocHaciendo" style={{marginLeft:'2%',marginRight:'2%',marginBottom:'0'}}>
    <Label name="txtDocHaciendo" id="txtDocHaciendo" style={{width:'100%',height:'28px',textAlign:'center'}}></Label>
  </div>
  <this.ParteBotoneraInferiorA/> {/* botones forma de pago */}
  <this.ParteBotoneraInferiorB/> {/* boton siguiente, para proformas o planes */}

{/************************** MODAL PARA CANTIDAD ****************/}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_Cantidad } >
  <PedirCantidad  
    miTitulo={(this.alPedirCantidadNuevoModificar=="M") ? this.miFilaAzulJson.DescripcionModificada.substring(0,38) : (this.productoNegro && this.productoNegro.DescripcionModificada ) ? this.productoNegro.DescripcionModificada.substring(0,38) : "" } //viene siendo la descripción del producto    
    miSubTitulo={'Indíque la cantidad'}
    miValorTexto2D={(this.alPedirCantidadNuevoModificar=="M") ? this.miFilaAzulJson.Cant : (this.productoNegro && this.productoNegro.Cant) ? this.productoNegro.Cant : '0.00' } //se lo envio como texto pero con dos decimales  
    miAceptaCero={false} //para saber si se acepta 0
    cerrarModal={async (miStatus,miCantSolicitada) => { 
    // la variable miStatus es boolean, al estar en true quiere decir que si desea editar
    //la variable miCantSolicitada ya viene como NUMBER y redondeada a 2 decimales
    //hay dos escenarios: Cuando estoy modificando => entonces modifico el grid en la linea respectiva
    //Cuando es nuevo item => entonces agrego un item al final

    this.setState({ estatusAbiertoModal_Cantidad : false })
    if (miStatus==false) return

    //caso 1: cuando es Modificar la cantidad
    if (this.alPedirCantidadNuevoModificar=="M"){  
      if (await this.validarStockSuficiente(miCantSolicitada,parseFloat(this.miFilaAzulJson.Adef_Stock),this.miFilaAzulJson.DescripcionModificada)==false)
        return

      let gridLoco=[...this.state.datosGrid]
      for(let i=0; i<gridLoco.length;i++)
        if (gridLoco[i].Fila==this.state.miFilaAzulIndice){
          gridLoco[i].Cant=miCantSolicitada.toFixed(2) //aqui lo formateo siempre a dos decimales (y lo convierte a TEXTO)
       }

       await sleepPepe(50)
       this.setState({datosGrid:gridLoco})
       await sleepPepe(50)
       this.calcularTotal()
    }

    //caso 2: cuando es nuevo item 
    //rosa
    if (this.alPedirCantidadNuevoModificar=="N"){
      this.productoNegro.Cant=miCantSolicitada.toFixed(2) //aqui lo formateo siempre a dos decimales (y lo convierte a TEXTO)
      this.meterProductoNegroEnGridDeVenta()
    }
    }}/>
</Modal> {/* fin de modal de cantidad */}

{/************************** MODAL PARA MODAL VACIO ****************/}
<Modal className="sinScrollXY" style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_ModalVacio } >
  <ModalVacio  
    cerrarModal={()=> this.setState({ estatusAbiertoModal_ModalVacio : false })}
    />
</Modal>

{/************************** MODAL PARA CAMBIAR LA DESCRIPCION DEL PRODUCTO ****************/}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_Descripcion } >
  <PedirTextoGenericoDescripcion
    miTitulo={"Nueva Descripción"}
    miLongitudMaxima={300}
    textoViejo={ this.miFilaAzulJson==null ? "" : this.miFilaAzulJson.DescripcionModificada }
    cerrarModal={()=> this.setState({ estatusAbiertoModal_Descripcion:false })}
    regresarAlLlamador={async(textoNuevo) => { 
      //Aqui debo revisar que NO sea vacio
      if (textoNuevo.length==0){    
        mostrarSwalBotonAceptar("warning","ATENCION","No se permite vacío")
        return 
      }     
      
      let gridLoco=this.state.datosGrid.map(item=>{  
        if (item.Fila==this.state.miFilaAzulIndice) item.DescripcionModificada=textoNuevo
        return item
        })

      await sleepPepe(50)
      this.setState({datosGrid:gridLoco})
      await sleepPepe(50)
      this.calcularTotal()
    }
    }/>
</Modal> {/* fin de modal de cambiar al descripcion del producto */}

{/************************** MODAL PARA CAMBIAR LA SERIE ****************/}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_Serie } >
  <PedirTextoGenericoSerie
    miTitulo={"Indíque la Serie"}
    miLongitudMaxima={100}
    textoViejo={ this.miFilaAzulJson==null ? "" : this.miFilaAzulJson.DescripcionAmpliada }
    cerrarModal={()=> this.setState({ estatusAbiertoModal_Serie:false })}
    regresarAlLlamador={async (textoNuevo) => { 
      //para la serie, se acepta vacio
      let gridLoco=[...this.state.datosGrid]

      for(let i=0; i<gridLoco.length;i++)
        if (gridLoco[i].Fila==this.state.miFilaAzulIndice){
          gridLoco[i].DescripcionAmpliada=textoNuevo
        }

       await sleepPepe(50)
       this.setState({datosGrid:gridLoco})
       await sleepPepe(50)
       this.calcularTotal()      
    }
    }/>
</Modal> {/* fin de modal de cambiar la serie */}

{/************************** MODAL PARA PRECIO 1234 (1 item) ****************/}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_Precio1234UnI } >
  <PedirPrecioUnItem1234 
    MiIvaContext={this.valueDC.iva.Porcentaje} //% de iva segun preferencias del sistema/context 
    miFilaAzulJson={this.miFilaAzulJson}        
    cerrarModal={()=> this.setState({ estatusAbiertoModal_Precio1234UnI : false })}
    cambiarPrecioUnItem1234 = {async(precio1234) => { 
      //el parametro precio1234, viene como texto
      //al haber un % de descuento, se procedera a limpiarlo y dar el mensaje
      let gridLoco=[...this.state.datosGrid ]
      let habiaDescuento=false
      let i=0
      for(i=0; i<gridLoco.length;i++)
        if (gridLoco[i].Fila==this.state.miFilaAzulIndice){
         gridLoco[i].Precio01234=precio1234
          switch (precio1234) {
            case "1":
              gridLoco[i].PvpSinIva=gridLoco[i].P1
              break
            case "2":
              gridLoco[i].PvpSinIva=gridLoco[i].P2
              break
            case "3":
              gridLoco[i].PvpSinIva=gridLoco[i].P3
              break
            case "4":
              gridLoco[i].PvpSinIva=gridLoco[i].P4
              break                  
          } 
          break //break del for (me interesa conservar el valor de i)   
        }

      habiaDescuento=(gridLoco[i].DescP>0) ? true : false   
      gridLoco[i].DescP="0.00"

      if (habiaDescuento)
        await mostrarSwalBotonAceptar("warning","ATENCION","El descuento se perderá")

      //truco para poder ver el refrescamiento ya que de lo contrario, no se ve      
      await sleepPepe(50)
      this.setState({datosGrid:gridLoco})
      await sleepPepe(50)
      this.calcularTotal()

    }}// fin de la funcion cambiarPrecioUnItem1234
  />
</Modal> {/* fin de modal PARA PRECIO 1234 (1 item) */}

{/************************** MODAL PARA PRECIO 1234 (todos los items) ****************/}
<Modal style={{ background:'blue',}} size={'sm'} isOpen={ this.state.estatusAbiertoModal_Precio1234TodosI } >
  <PedirPrecioTodosItem1234 
    cerrarModal={()=> this.setState({ estatusAbiertoModal_Precio1234TodosI : false })}
    cambiarPrecioTodosItem1234 = {async(precio1234) => { 
      //el parametro precio1234, viene como texto
      //al haber un % de descuento, se procedera a limpiarlo y dar el mensaje
      let gridLoco=[...this.state.datosGrid]

      let habiaDescuento=false //por lo menos de un 1 elemento
      for(let i=0; i<gridLoco.length;i++){
        if (gridLoco[i].DescP>0) habiaDescuento=true
        gridLoco[i].Precio01234=precio1234
        gridLoco[i].DescP="0.00"
        switch (precio1234) {
            case "1":
              gridLoco[i].PvpSinIva=gridLoco[i].P1
              break
            case "2":
              gridLoco[i].PvpSinIva=gridLoco[i].P2
              break
            case "3":
              gridLoco[i].PvpSinIva=gridLoco[i].P3
              break
            case "4":
              gridLoco[i].PvpSinIva=gridLoco[i].P4
              break                  
        } 
    }

    if (habiaDescuento)
        await mostrarSwalBotonAceptar("warning","ATENCION","El descuento se perderá")

    await sleepPepe(50)
    this.setState({datosGrid:gridLoco})
    await sleepPepe(50)
    this.calcularTotal()

    }}// fin de la funcion cambiarPrecioTodosItem1234
  />
</Modal> {/* fin de modal PARA PRECIO 1234 (todos los item) */}

{/* ************************* MODAL PARA PORCENTAJE DE DESCUENTO *************** */}
{/* ******************** SIRVE PARA ('1') ITEM O ('T') TODOS LOS ITEMS ********* */}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_DescuentoP } >
  <PedirDescuentoP 
    miTitulo={this.afectaItems1T=="1" ? this.miFilaAzulJson.DescripcionModificada.substring(0,38) : "Descuento % para todos"}
    miSubTitulo={"% de descuento (Máx 2 decimales)"}
    miValorOriginal={this.afectaItems1T=="1" ? parseFloat(this.miFilaAzulJson.DescP): 0 } //se le debo mandar como float
    mi234Decimales={2}
    miColorFondo={this.afectaItems1T=="1" ? "purple" : "red"}    
    miAceptaCero={true}
    cerrarModal={async(miStatus,miValorNuevo)=>{
      //miStatus es boolean y debe venir como true/false para saber si realmente hizo el cambio
      //ojo: miValorNuevo debe venir como number y ya redondeado a 2 decimales
      this.setState({ estatusAbiertoModal_DescuentoP : false })   
      if (miStatus==false)  return

      //rumbo normal
      let gridLoco=[...this.state.datosGrid ]

      for(let i=0; i<gridLoco.length;i++){
        if (this.afectaItems1T=='1' && gridLoco[i].Fila==this.state.miFilaAzulIndice)               
          gridLoco[i].DescP=miValorNuevo.toFixed(2)
        if (this.afectaItems1T=='T')
          gridLoco[i].DescP=miValorNuevo.toFixed(2)
      }

      await sleepPepe(50)
      this.setState({datosGrid:gridLoco})
      await sleepPepe(50)
      this.calcularTotal()
}}
/>
</Modal> {/* fin de modal de descuento % */}

{/*********************** MODAL PARA PRECIO ESPECIFICO ****************/}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_PrecioEspecifico } >
  <PedirPrecioEspecifico      
    etiquetaPrecioCosto="Precio" 
    deseoPvpSinCon={this.deseoPvpSinCon}
    miFilaAzulJson={this.miFilaAzulJson}
    miIvaDef= { ( this.miFilaAzulJson != null && this.miFilaAzulJson.GravaIva=='Si') ? this.valueDC.iva.Porcentaje : 0 }
    cerrarModal={()=> this.setState({ estatusAbiertoModal_PrecioEspecifico : false })}
    cambiarPrecioEspecifico = { async (miPvpSin) => { 
    //al haber un % de descuento, se procedera a limpiarlo y dar el mensaje
    //el parametro viene como number
    let gridLoco=[...this.state.datosGrid ]
    
    let habiaDescuento=false
    let i=0
    for(i=0; i<gridLoco.length;i++)
      if (gridLoco[i].Fila==this.state.miFilaAzulIndice){
          gridLoco[i].PvpSinIva=miPvpSin.toFixed(4)
          gridLoco[i].Precio01234="0"
          break //ojo, esta linea es obligatoria para mantener el apuntador i 
      }
    habiaDescuento=(gridLoco[i].DescP>0) ? true : false   
    gridLoco[i].DescP="0.00"

    if (habiaDescuento)
      await mostrarSwalBotonAceptar("warning","ATENCION","El descuento se perderá")

    await sleepPepe(50)
    this.setState({datosGrid:gridLoco})
    await sleepPepe(50)
    this.calcularTotal()
    }}/>
</Modal> {/* fin de modal de precio especifico */}

{/* ************************* MODAL PARA LA FORMA DE PAGO *************** */}
<Modal style={{ background:'blue',}} size={'lg'} isOpen={ this.state.estatusAbiertoModal_FormaDePago } >
  <PedirFormaDePago        
        totalFull= {this.subtotalFacturaPhp.total} //total a cobrar realmente (2 decimales)        
        mostrarRetSN= {(this.state.def_doc=='FA') ? 'S' : 'N'} //Mostrar retencion en la forma de pago        
        todoAbonoTA={'T'} //Para saber si debe pagar Todo, o puede hacer Abono
        recibirPago = { async(miFaltanteFloat,miJsonTmp,seleccionOk) => { 
          this.setState({ estatusAbiertoModal_FormaDePago : false })
          if (!seleccionOk) return

          //aqui paso del temporal, al json real de la forma de pago
          this.datosFormaPago=miJsonTmp       
          if (this.state.def_doc=="FA") {          
            this.gestionarFacturaConFormaDePago()            
          }
          if (this.state.def_doc=="NECONIVA" || this.state.def_doc=="NESINIVA" ) {            
            this.gestionarNotaConFormaDePago()          
          }
        }}
  />
</Modal> {/* fin de modal FORMA DE PAGO */}

{/************************** MODAL PARA CAMBIAR TEXTO COMPLEMENTARIO ****************/}
<Modal centered={true} size={'md'} isOpen={ this.state.estatusAbiertoModal_TextoComplementario } >
  <PedirTextoComplementario
    miTitulo={"Texto Complementario"}
    miLongitudMaxima={300}
    textoViejo={ this.caPiePhp.TextoComplementario}
    cerrarModal={()=> this.setState({ estatusAbiertoModal_TextoComplementario:false })}
    regresarAlLlamador={(textoNuevo) => { 
      //se acepta vacio
      this.caPiePhp.TextoComplementario=textoNuevo
    }
    }/>
</Modal> {/* fin de modal Texto Complementario */}

{/**** MODAL PARA BUSCADOR DE CLIENTES ******** size={'md'} size={'lg'} size={'xl'} NO GARRA xl, lo toma como lg */}
<Modal style={{ background:'blue',}} size={'xl'}  isOpen={ this.state.estatusAbiertoModal_TabelaClientesBuscador} >
  <TabelaClientesBuscador 
    cerrarModal={(accion,IdPrincipal_Select)=>{
      this.setState({ estatusAbiertoModal_TabelaClientesBuscador : false })
      if (accion=='close') return
      //aqui viene la parte de mostrar al cliente seleccionado
      document.getElementById("txtCedulaCliente").value = IdPrincipal_Select    
      let cadenaLoca=IdPrincipal_Select.trim().toLocaleLowerCase() //ya que podria tener texto
      this.filtrarPorCedula(cadenaLoca)
    }}
    registrosFull={this.state.datosFullCli}

  />
</Modal> {/* fin de modal de Buscador de clientes */}

{/**** MODAL PARA BUSCADOR DE INVENTARIO ******** size={'md'} size={'lg'} size={'xl'} NO GARRA xl, lo toma como lg */}
<Modal style={{ background:'blue',}} size={'xl'}  isOpen={ this.state.estatusAbiertoModal_TabelaInvBuscador} >
  <TabelaInventarioBuscador 
    datosInv={this.state.datosInv} //todas las filas del inventario, pero pocas columnas
    pvp1InventarioSinIvaVerDefinitivo={this.pvp1InventarioSinIvaVerDefinitivo}
    pvp1InventarioConIvaVerDefinitivo={this.pvp1InventarioConIvaVerDefinitivo}
    pvp2InventarioSinIvaVerDefinitivo={this.pvp2InventarioSinIvaVerDefinitivo}
    pvp2InventarioConIvaVerDefinitivo={this.pvp2InventarioConIvaVerDefinitivo}
    pvp3InventarioSinIvaVerDefinitivo={this.pvp3InventarioSinIvaVerDefinitivo}
    pvp3InventarioConIvaVerDefinitivo={this.pvp3InventarioConIvaVerDefinitivo}
    pvp4InventarioSinIvaVerDefinitivo={this.pvp4InventarioSinIvaVerDefinitivo}
    pvp4InventarioConIvaVerDefinitivo={this.pvp4InventarioConIvaVerDefinitivo}
    ParaCompraParaVenta="ParaVenta"
    cerrarModal = { async(huboSeleccion,codigoOriginal) => {
      //huboSeleccion es boolean. Me indica si realmente se ha seleccionado un item en el buscador
      //codigoOriginal, me trae el IDprincipal del producto seleccionado
      this.setState({ estatusAbiertoModal_TabelaInvBuscador : false })
      if (!huboSeleccion) return

      this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:0,inventarioNegroCodigoPrincipal:'',inventarioNegroActivo:null,})
      await sleepPepe(100) //ok
      document.getElementById("txtNegro").value = codigoOriginal     
      document.getElementById("txtNombreProducto").value=""
      document.getElementById("btnConsultarInv1").style.visibility='hidden'
      this.productoNegro=null //pongo vacio en mi JSON del futuro producto que se va a meter al grid de venta                     
      await sleepPepe(100) //ok
      //hay que poner AWAIT para forzar la actualizacion del estado (es extrano pero solo asi funciona)
      await this.buscarProductoPorPrincipal(codigoOriginal.trim().toLowerCase()) 
      await sleepPepe(100) //ok
      this.gestionarVenderProductoNegro()      
    }}
/>
</Modal> {/* fin de modal de Buscador de inventario */}

{/* ************ MODAL PARA CONSULTAR INVENTARIO **********/}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ this.state.estatusAbiertoModal_IncModInv } >
  <IncModInventario 
    ocultarModal_Inv={(accion,jsonRegistro)=>{
      this.setState( { estatusAbiertoModal_IncModInv : false,})  
      //accion puede ser: close/save/notfound
      if (accion=='close') return
      //al regresar del modal, no es necesario refrescar leyendo de nuevo la BDD, lo que hago es modificar el JSON segun lo sucedido          
      //if (accion=='save') incluirModificarJsonInventario(jsonRegistro)
      //el notfound, significa que NO se encontró al registro que se deseaba editar/consultar (por lo tanto debo eliminarlo de la tabela)
      if (accion=='notfound') this.eliminarJsonInventario()      
    }} 
    opcionRegistro_imc={this.state.opcionInv_imc} // por ahora solo estoy consultando 
    registroIDr={this.state.inventarioNegroIDr} //codigo rojo del producto que deseo consultar 
    codigoCandidato={''} // por ahora, no voy a crear items desde aqui
    registrosFull={this.state.datosInv} //todas las filas del inventario, pero pocas columnas
  />
</Modal>

{/* ************************* MODAL PARA AGREGAR/MODIFICAR CLIENTES *************** */}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ this.state.estatusAbiertoModal_IncModCli } >
  <IncModCliente 
    ocultarModal_Cli={(accion,jsonRegistro)=>{
      this.setState( { estatusAbiertoModal_IncModCli : false, })
      //accion puede ser: close/save/notfound

      if (accion=='close') return
      //al regresar del modal, no es necesario refrescar leyendo de nuevo la BDD, lo que hago es modificar el JSON segun lo sucedido          
      if (accion=='save') this.incluirModificarJsonCliente(jsonRegistro)
      //el notfound, significa que NO se encontró al registro que se deseaba editar/consultar (por lo tanto debo eliminarlo de la tabela)
      if (accion=='notfound') this.eliminarJsonCliente(this.state.clienteIDr)
    }}    
    opcionRegistro_imc = {this.state.opcionCliente_imc}
    registroIDr = {this.state.clienteIDr}
    cedulaCandidata = {this.state.cedulaCandidata} //la uso para crear un cliente desde aqui: FACTURACION
    registrosFull = {this.state.datosFullCli}
  />
</Modal>

{/* ************************* MODAL PARA CONDICIONES DE LA PROFORMA *************** */}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ this.state.estatusAbiertoModal_PedirCondicionesPresupuesto } >
  <PedirCondicionesPresupuesto 
    ocultarModal_Condiciones =  {()=>{
      this.setState( { estatusAbiertoModal_PedirCondicionesPresupuesto : false, })
    }}     
    caPiePhp={this.caPiePhp}
    fechaEmision={this.fechaEmisionPresupuesto}  
    actualizar_caPiePhp={ (AlContado,Plazo,ValidezDias,TiempoEntregaDias,Garantia,ImprimirCondiciones,FechaEmisionDDMMYYYY)=>{
      this.caPiePhp.AlContado=AlContado
      this.caPiePhp.Plazo=Plazo
      this.caPiePhp.ValidezDias=ValidezDias
      this.caPiePhp.TiempoEntregaDias=TiempoEntregaDias
      this.caPiePhp.Garantia=Garantia
      this.caPiePhp.ImprimirCondiciones=ImprimirCondiciones
      this.caPiePhp.FechaEmision=FechaEmisionDDMMYYYY //para irse a php debe ser STRING dd/mm/yyyy
    }}
    procesarProforma={ async()=>{      
      await this.procesarPresupuestoEnBDD()
      if (this.numGenericoDevuelto>0) {
        this.numPresupuestoDef=this.numGenericoDevuelto
        if (this.valueDC.usuario.ProformaImprimirEn=="NO") await mostrarSwalUnSegundo("Presupuesto " + this.numPresupuestoDef + " Registrado",1000)    
  
        //cuando sea modificar presupuesto, me debo regresar obligatoriamente al llamador.
        if (this.state.def_doc=="PR_MOD"){
          this.props.actualizarUnaFilaDelJson(
            this.caPiePhp.FechaEmision,
            this.state.clienteCedula,
            this.state.clienteIdSecundario,
            this.state.clienteNombre,
            this.state.clienteNombreComercial,  
            this.subtotalFacturaPhp.total.toFixed(2),
            textoDelCombo(document.getElementById("comboVendedor")),
            '', //el nombre del facturador va vacio ya que aun no esta implementado
            (this.caPiePhp.AlContado=="1") ? "CONTADO" : "CREDITO",
            this.state.clienteEmail,
            this.state.clienteIDr,
            )            
        }    
        
        //muestro en pantalla el presupuesto pdf (segun las preferencias del usuario)
        if(this.valueDC.usuario.ProformaImprimirEn=="PA"){ 
          let miFormato=determinarFormatoImpresoraGrande(this.valueDC,this.valueDC.estable.Estab,'FormatoProforma')
          if (miFormato==''){
            console.log("No se consigue el formato en modulo de facturacion, para proforma")          
            return
          }    
          //genero el pdf en disco
          let data=new FormData()
          data.append('miSol','presupuesto_gestionarGenerarPDF')
          data.append('miFormato',miFormato)
          data.append('miEstab',this.estabDef)
          data.append('miPunto',this.puntoDef)
          data.append('miNumPre',this.numPresupuestoDef)
            
          let dataApi=await ejecutarFetchGenericoConSwal(this.valueDC.sistema.numeroDeReintentosPhp,this.valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
          //en dataApi, puede venir null o el mismo numero de proforma enviada. Nunca viene negativo
          if (dataApi==null) return

          // por acá lo ideal
          await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok (proforma)
          this.setState( {estatusAbiertoModal_PDFnoSri:true} )
        }        

        if(this.valueDC.usuario.ProformaImprimirEn=="NO" && this.state.def_repetitivo10=="1")
            this.prepararNuevaVenta()
        if(this.valueDC.usuario.ProformaImprimirEn=="NO" && this.state.def_repetitivo10=="0")            
            this.salirPorBoton()                          
      } //del if this.numGenericoDevuelto>0

      if (this.numGenericoDevuelto<=0){
        mostrarSwalBotonAceptar("error","ATENCION","No se pudo registrar la proforma. Código de error # " + this.numGenericoDevuelto)
      }
    }}
  />
</Modal>

{/************************** MODAL PARA VER UN PDF QUE NO SEA DEL SRI: ej: Proforma, Nota E, Plan y comprobante de venta  *************** */}
<Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ this.state.estatusAbiertoModal_PDFnoSri } >
  <VerPdfOk 
    documentoTitulo={ (this.state.def_doc=="PR" || this.state.def_doc=="PR_MOD" ) ? "PROFORMA" : (this.state.def_doc=="FA") ? "FACTURA": "NOTA DE ENTREGA" }   
    documentoArchivo={
      (this.state.def_doc=="PR" || this.state.def_doc=="PR_MOD" ) ?      
      "PR_" + this.estabDef + "-" + this.puntoDef + "-" + this.numPresupuestoDef  :
      (this.state.def_doc=="FA") ? "FS_" + this.estabDef + "-" + this.puntoDef + "-" + this.numFacturaDef :
      "NE_" + this.estabDef + "-" + this.puntoDef + "-" + this.numNotaDef      
    }
    corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
    tipoSriSN={"N"} //es un documento del sri?
    estiloPantallaPG={"P"} //Para modal le mando P
    activarMenu =  {()=>{
      this.setState( { estatusAbiertoModal_PDFnoSri : false, })
      //de ser un documento repetitivo, me quedo en el formulario. Caso contrario me salgo
        if (this.state.def_repetitivo10=="1") this.prepararNuevaVenta()
        else this.salirPorBoton()        
    }}       
  />
</Modal>

{/************************** MODAL PARA PEDIR LOS DIAS DE CREDITO *************** */}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ this.state.estatusAbiertoModal_PedirDiasCredito } >
  <PedirDiasCredito 
    cerrarModal={async(estatus,misDias)=>{
      this.setState( { estatusAbiertoModal_PedirDiasCredito : false, })
      //estatus: true/false
      //misDias: entero (maximo 999)      
      if (estatus==false) return
      //******* rumbo ideal *********
      //alimento una parte de caPiePhp
      this.caPiePhp.AlContado="0"
      this.caPiePhp.Plazo=misDias
      this.caPiePhp.Cancelado="0"
      //No afecta caja,
      this.state.def_afectaCajaSN="N"
      //reviso el internet
      if (await hayInternet()==false) return 
      //Mando a gestionar la factura
      this.gestionarFacturaComun() 
    }}     
    miTitulo = { "Dias de crédito" }    
    valorPorDefecto={30}
    topeMaximo={999}
  />
</Modal>

{/* ************************* MODAL PARA SELECCIONAR UNA PROFORMA Y CLONARLA *************** */}
<Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ this.state.estatusAbiertoModal_PresupuestosBuscador } >
  <TabelaPresupuestosBuscador
    cerrarModal={async(accion,jsonSelect)=>{
      //en jsonSelect vienen los 3 campos importantes Estab,Punto,NumDoc
      this.setState( { estatusAbiertoModal_PresupuestosBuscador : false, })
      //accion puede ser: close/select
      if (accion=='close') return
      //empiezo el tramite de importar la proforma
      if (accion=='select'){
        //OJO: debo detectar que tipo de documento estaba haciendo originalamente al entrar
        let miDocAlEntrar=this.state.def_doc
        this.setState({def_doc:"PR_CLON"})
        await this.buscarPresupuestoDetalleParaClonarModificar(jsonSelect.Estab,jsonSelect.Punto,jsonSelect.NumPre)
        //aqui vuelvo a poner el tipo de documento que originalmente estaba haciendo el usuario. Esto con la idea que siga el procedimiento Normal
        this.setState({def_doc:miDocAlEntrar})  
      }
    }}    
  />
</Modal>

</div> // div principal (miFormularioFacturacion)
) //del return interno
}
}
</AppContextConsumer>
) //del return externo
} //del render
}
