//Este Form me sirve solamente para seleccionar una proforma para clonarla
//tambien me permite verla como PDF. la idea es que el usuario esté seguro del contenido de la proforma
import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, { sleepPepe,devolverFechaHoyTexto,devolverMesTextoDesdeUnNumero,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal,tildarCampoEnviadoPorCorreoNoSriSinSwal,determinarFormatoImpresoraGrande } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,ejecutarFetchGenericoConSwal } from './lib_basica'
import DataTable, { createTheme } from 'react-data-table-component'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose,faSun,faPencilAlt,faPen,faReceipt,faVoteYea,faFlag,faThumbtack,faAlignCenter,faClipboard,faRunning,faDoorOpen,faGlasses,faBroom,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import PedirMesYano_OK from './PedirMesYano_OK'
import VerPdfOk from './VerPdfOk'
//Context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let mesVer= null //(es el mes y ano que deso ver las proformas)
let anoVer= null 
let jsonSelect={} //Aqui se van 3 datos, Estab,Punto,NumPre seleccionado

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE PROFORMAS'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
		},
	},     
  headCells: {
    style: {
      background: 'hotpink', //ok
      color: 'lavender',  //ok
      fontSize:'18px',   //ok
      fontWeight: 'bold'
    },
  },  
  rows: {      
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }, 
  },
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: row => (row.Proforma != filaClonada?.Proforma ),
    style: row => ({ 
      backgroundColor:'white',
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (row.Proforma == filaClonada?.Proforma ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaSecundaria,
      color: 'black',
    }),
  },
]

const TabelaPresupuestosBuscador=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
const [textoBuscarState,set_textoBuscarState]=useState('') //para el texto que va en el cuadrito de buscar
const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para el color de la fila seleccionada y la tabela
const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable (por ahora no lo voay a usar. Quizá en el futuro)
//para modales
const [modalVerProformaPDF,set_modalVerProformaPDF] =useState(false) //para ver el pdf de la proforma
const [modalPedirMesAnoState,set_modalPedirMesAnoState]=useState(false) 

const miEstructura=[  
  {  
    name:'PROFORMA', //lo que el usario ve. 
    selector:row => row.Proforma, //Tal como viene en el Json desde las Base de datos
    sortable:true,
    left:true,
    grow:1.5, //es una medida relativa (1 es mi referencia)
    compact:true, //padding 0
    omit:false, //en algunos casos, se debe ocultar algunas columnas
  },
  {
    name:'RAZON SOCIAL',
    selector:row => row.RazonSocial, 
    sortable:true,
    left:true,
    compact:true,
    grow:1.5,    
  },    
//******aqui van las ACCIONES con iconos: solo LISTO y VER
{
  name:'',
  sortable:false,
  center:true,
  compact:true,
  grow:1.3, 
        
  cell: (row) => 
  <div style={{ display:'flex' }}>            
  {/* boton LISTO y VER   */}
  <Button id="btnSeleccionar" name="btnSeleccionar" style={{ marginRight:'2px',color:'white',fontWeight: 'bold' }} raised = "true" primary='false' color="info" 
    onClick={ async(e) => { 
      set_idMasState(0)
      set_rowRegistroState(row)
      rowRegistroClon=row
      //preparo lo que voy a devolver
      jsonSelect.Estab=rowRegistroClon.Estab
      jsonSelect.Punto=rowRegistroClon.Punto
      jsonSelect.NumPre=rowRegistroClon.NumPre
      props.cerrarModal('select',jsonSelect)
    }}>Listo      
  </Button>

  <Button id="btnVerPdf" name="btnVerPdf" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
    onClick={async(e) => {
      set_idMasState(0)
      set_rowRegistroState(row)
      rowRegistroClon=row      

      let miFormato=determinarFormatoImpresoraGrande(valueDC, rowRegistroClon.Estab,'FormatoProforma')
      if (miFormato==''){
        await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato")  
        return        
      }    
      //debo generar el PDF y luego lo muestro  
      let data=new FormData()
      data.append('miSol','presupuesto_gestionarGenerarPDF')
      data.append('miFormato',miFormato)
      data.append('miEstab',rowRegistroClon.Estab)
      data.append('miPunto',rowRegistroClon.Punto)
      data.append('miNumPre',rowRegistroClon.NumPre)
        
      let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
      //en dataApi, puede venir null o el mismo numero de proforma enviada. Nunca viene negativo
      if (dataApi==null) return
      // por acá lo ideal      
      set_modalVerProformaPDF(true)
    }}>
    <FontAwesomeIcon size='lg' color="white" icon={faGlasses} />
  </Button>    
  </div>,
}, 
//*** fin de las ACCIONES
  {
    name:'FECHA',
    selector:row => row.FechaEmisionFormat,  
    sortable:true,
    left:true,
    compact:true,
    grow:0.7,   
  },   
  {
    name:'IDENTIFIC.',
    selector:row => row.IdPrincipal,  
    sortable:true,
    left:true,
    compact:true,
    grow:0.9,  
  },  
  {
    name:'TOTAL',
    selector:row => row.TotalPresupuesto,  
    sortable:true,
    right:true,
    compact:true,
    grow:0.7,
  }, 
  {
    name:'ExC',
    selector:row => row.EnviadoAlReceptor,  
    sortable:true,
    center:true,
    compact:true,
    grow:0.25,
    omit:true,
  },              
  {
    name:'CORREO',
    selector:row => row.EmailCliente,
    sortable:true,
    left:true,
    compact:true,
    grow:0.75,
    omit:true,
    //hide:'sm',
  },     
  {
    name:'CREA',
    selector:row => row.OpCrea,  
    sortable:true,
    center:true,
    compact:true,
    grow:0.25,
    omit:true,
  },  
]

const refrescarData=async()=>{
  let data=new FormData()
  data.append('miSol','presupuestos_buscarUnMesPocasColumnas')
  data.append('miMes',mesVer)
  data.append('miAno',anoVer)
  data.append('orderBy','Proforma')
  data.append('forma','Desc') 

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_idMasState(0)
  set_registrosFullState(dataApi)   
}

const ponerMesYanoTitulo=()=>{
  let miPeriodo=devolverMesTextoDesdeUnNumero(mesVer) 
  miPeriodo = miPeriodo + "-" + anoVer
  document.getElementById("miPeriodo").innerText=miPeriodo
}

const determinarMesAnoActual=()=>{
  mesVer= (new Date().getMonth()) + 1 //OJO: enero es 0 (es el mes y ano que deso ver las facturas)
  anoVer= (new Date().getFullYear()) 
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  determinarMesAnoActual()
  refrescarData()  

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //variables normales
    rowRegistroClon=null 
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }    
  },[]
)

// *** cuando recibo NULL de mi API, entonces me devuelvo al menu principal
useEffect(()=>{  
  if (registrosFullState==null){    
    props.activarMenu() 
  }
  },[registrosFullState]
)

const filtrarElementos=(cadenaLoca)=>{
  cadenaLoca=cadenaLoca.toLocaleLowerCase()  
  let resultado=registrosFullState.filter(item=>{
    if (
      item.Proforma.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.TotalPresupuesto.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreVen.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreFac.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Condicion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.EmailCliente.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.OpCrea.toString().toLocaleLowerCase().includes(cadenaLoca)
      )  
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item        
    })
    set_registrosFullState(resultado)
    set_rowRegistroState(null)
    set_idMasState(0)
    rowRegistroClon=null  
} 

const ComponentePrincipalBody=()=> {  
return(  
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'80vh', background: 'gray',}} > 
    {/**** barra de titulo  */}
    <div id="divBarraTitulo" name="divBarraTitulo"> 
      <Row style={{margin:'0'}}> 
        <Col xs="10">       
          <Label style={{marginTop:'5px',fontWeight: 'bold',color:"pink"}}>{"Lista de Proformas"}</Label>
        </Col>
        <Col xs="2" style={{textAlign:'right',padding:'0'}}>
          <Button style={{width:'40px', height:'40px', }} id="btnCerrar" name="btnCerrar" color="danger" 
            onClick= { () =>{ 
              props.cerrarModal('close') 
            }} >
            <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
          </Button>
        </Col>
      </Row>
    </div> { /* divBarraTitulo */ }    
 
    {/******* para input de busqueda, limpiar y cambiar mes  */}
      <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
        <Input 
          className = "textoBusquedaClientes" 
          type="text" placeholder="Texto de búsqueda" id="inputBusqueda" name="inputBusqueda"  
          value={textoBuscarState}
          onChange={()=>{
            set_textoBuscarState( document.getElementById("inputBusqueda").value.trim() )
            filtrarElementos( document.getElementById("inputBusqueda").value.trim() )
        }}/>
        <Button  
          id="btnLimpiar" name="btnLimpiar" color="secondary"  style = { {marginLeft:'5px', marginRight:'5px' }}
          onClick={() => {           
            set_textoBuscarState('')        
            filtrarElementos('')                      
          }}> 
          <FontAwesomeIcon color="paleGreen" icon={faBroom} style = { {marginRight:'5px' }} />
          Limpiar
        </Button>   
          <Label id="miPeriodo" name="miPeriodo" style={{marginLeft:"10px",marginRight:"10px",marginTop:"5px",fontSize:'18px', fontWeight:'bold',color:"white" }}>
            {
              devolverMesTextoDesdeUnNumero(mesVer) + "-" + anoVer
            }
          </Label> 
          <Button size="md" id="btnPeriodo" name="btnPeriodo" color="info" solid="true" 
           onClick={()=>{
              set_modalPedirMesAnoState(true)  
            }}>
          Cambiar
        </Button>
      </div> { /* divBuscador1 */ }
  
    { /* inicio del DIV para la tabela height:'70vh', */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
      <DataTable
      //************ DATA TABLA PROPERTIES (basic) ***********/
      title='Lista de Proformas'
      columns={miEstructura}
      data={registrosFullState?.filter(item=>item.VisibleSN=='S')}
      conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
      keyField ='Presupuesto' /* Se puede poner 'id' (valor por omision), 'Factura' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */    
      onRowClicked={(row) =>{
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_idMasState(0)    
      } }  
      highlightOnHover  
      //pointerOnHover='true'
      //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
      noDataComponent = ""     
      //className='string' //override the className on the Table wrapper
      //style= object overrade the className on the Table wrapper
      //style= {{minHeight:'60vh'}} //ahora se ponde dentro de estilacho
      //responsive='true' //true es el valor por defecto
      //disabled='false' //por defecto false
      //onRowClicked={miOnRowClicked()} //callback to access the row, even on row click (no pude hacerle funcionar)
      //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
      //noTableHead //oculta las cabeceras
  
      //************ DATA TABLA PROPERTIES (row selection) ***********/
      //selectableRows //aparece el checkbox
      //selectableRowsVisibleOnly
      //selectableRowsHighlight
      //selectableRowsNoSelectAll
  
      //************ DATA TABLA PROPERTIES (row expander) ***********/
      //expandableRows //interesante
  
      //************ DATA TABLA PROPERTIES (sorting) ***********/
      //defaultSortField
  
      //************ DATA TABLA PROPERTIES (pagination) ***********/
      pagination
      //paginationServer //change de default pagination to work with server pagination
      paginationPerPage={ valueDC.sistema.paginadoParaProformas } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
      paginationComponentOptions={paginacionOpciones}
  
      //************ DATA TABLA PROPERTIES (header) ***********/
      //actions //component or array of components
      //pepe. no poner fixedHeader
      //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
      //fixedHeaderScrollHeight = "400" //valor por defecto 100vh 
      //subHeader //me gustaria esta opcion pero no funciona
  
      //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
      //theme='dark' //interesante, ya dark existe
      //theme="solarized" //muy interesante (no implementado)
      customStyles={miEstilacho} /* redefino algun estilo */
      />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }
   
    {/* *********** MODAL PARA PEDIR MES Y ANO *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalPedirMesAnoState } >
      <PedirMesYano_OK 
        cerrarModal={async(accion,mesNuevo,anoNuevo)=>{
          set_modalPedirMesAnoState(false)
          //accion puede ser: close/select 
          if (accion=='close') return
          //rumbo ideal....          
          set_textoBuscarState('')
          set_rowRegistroState(null)
          rowRegistroClon=null
          set_idMasState(0)
          mesVer=mesNuevo
          anoVer=anoNuevo
          ponerMesYanoTitulo()
          refrescarData()            
        }}
        mesActual={mesVer}
        anoActual={anoVer}
      />
    </Modal>  

    {/* *********** MODAL PARA VER LA PROFORMA  **************** */}
    <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalVerProformaPDF } >
      <VerPdfOk
        documentoTitulo={"PROFORMA"}
        documentoArchivo={"PR_" + rowRegistroClon?.Estab + "-" + rowRegistroClon?.Punto + "-" + rowRegistroClon?.NumPre }
        corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
        tipoSriSN={"N"} //es un documento del sri?
        estiloPantallaPG={"P"} //Para modal le mando P
        activarMenu={ () => set_modalVerProformaPDF(false) } 
      />
    </Modal>            
</div> //divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} // del export

export default TabelaPresupuestosBuscador
