//alert(JSON.stringify(rowRegistroState,null,2))
import React, {useState,useEffect} from 'react'
import {Row,Col,Jumbotron,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, {ejecutarFetchGenericoConSwal,buscarCarpeta_certificadofirma,mostrarSwalUnSegundo,sleepPepe,devolverMesTextoDesdeUnNumero,enviarCorreoSriPdfMasXmlConSwal,resolverComprobanteRojoSriConSwal,determinarFormatoImpresoraGrande,hayInternet} from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,firmarDocumentoXmlConSwal,recepcionValidarSriConSwal,consultarAutorizacionSriConSwal,generarDocumentoPDF_SriConSwal} from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSun, faBolt, faCalendarAlt ,faRunning,faVoteYea,faGlasses,faStickyNote,faCopy,faBan,faMoneyCheckAlt,faDoorOpen,faReceipt,faBroom,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed } from '@fortawesome/free-solid-svg-icons' 
//Context
import {AppContextConsumer} from './ContextBase'
//componentes necesarios
import PedirMesYano_OK from './PedirMesYano_OK' //Copiado a corona
import MostrarPanelDeLaFactura from './MostrarPanelDeLaFactura' 
import PedirClaveEspecial from './PedirClaveEspecial' //Copiado a corona
import MostrarConsultaAutorizacionSri from './MostrarConsultaAutorizacionSri'  //falta probar y luego copiarlo
import MostrarResultadosResolver from './MostrarResultadosResolver' //falta probra en bonanza y luego copiar a corona
import RecibosIngresoDeUnDocumento from './RecibosIngresoDeUnDocumento' 
import VerPdfOk from './VerPdfOk'

//Variables normales globales que No son parte del estado
let valueDC=null //Aqui hago una copia de los valores del context
let pepeJsonConsultaSri=null //aqui guardo todo el resultado
let resolverJson=null //resolverJson={}. aqui guardo el json de resolver un documento rojo
let rowRegistroClon=null //obtengo una Fila (row) clonada, completa para mandarla al PANEL y que el usuario pueda ver mas detalles en un modal. Y para multiples usos
let mesVer= null //(es el mes y ano que deso ver las facturas)
let anoVer= null 

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho={
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE FACTURAS'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
		},
	}, 
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender
      fontSize:'18px',   //ok
      fontWeight: 'bold'
    },
  },    
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',
      //borderStyle:"solid",             
    }
  }, 
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
      //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: row => (row.Factura != filaClonada?.Factura),
    style: row => ({ 
      backgroundColor:row.AutorizacionSri=="0"  ? valueDC.sistema.coloresSriNoAutorizado : (row.EnviadoAlReceptor == "0") ? valueDC.sistema.coloresSriNoCorreo : valueDC.sistema.coloresSriPerfecto,
      textDecoration: row.Anulado=="1" ? 'line-through' : 'none',
      color: 'dimgray', 
     }),    
  },
  //fila seleccionada    
  { 
    //al tocar una fila se debe poner azul. Pero si la fila esta anulada se debe mantener el tachado
    when: row => (row.Factura == filaClonada?.Factura ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      textDecoration: row.Anulado=="1" ? 'line-through' : 'none',
      color: 'white', 
    }),
  },
]

const TabelaFacturasLista=(props)=>{
  //variables de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros Osea al inico es lo que está liago a la tabela
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela     
  const [textoBuscarState,set_textoBuscarState]=useState('') //para el texto que va en el cuadrito de buscar
  const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa. Puede tener 0 o 17 caracteres de row.Factura
  //Modales
  const [modalPedirMesYano,set_modalPedirMesYano]=useState(false) //para pedir periodo
  const [modalConsultarSri,set_modalConsultarSri]=useState(false) //para poder ver el resultado de la consulta
  const [modalRecibosCaja,set_modalRecibosCaja] =useState(false) //para ver una lista de los recibos de caja
  const [modalResolverSri,set_modalResolverSri] =useState(false) //para ver los resultados de resolver un docuemento ROJO
  const [modalPanelFactura,set_modalPanelFactura] =useState(false) //para ver los datos ocultos de la factura: fecha y hora,correo del cliente.....
  const [modalPedirClaveEspecial,set_modalPedirClaveEspecial] =useState(false) //para pedir la clave especial
  const [modalTicket,set_modalTicket] =useState(false) //para ver el ticket en PDF
  const [modalFacturaSri,set_modalFacturaSri] =useState(false) //para ver la factura SRI en modal
  const [modalFacturaSimple,set_modalFacturaSimple] =useState(false) //para ver la factura Simple en el Modal

const miEstructura=[  
    {  
      name:'FACTURA', //lo que el usario ve. 17 caracteres
      selector:row => row.Factura, //Tal como viene en el Json desde las Base de datos
      sortable:true,
      left:true,
      grow:1.5, //es una medida relativa (1 es mi referencia)
      compact:true, //sets cell padding to 0
      omit:false, //en algunos casos, se debe ocultar algunas columnas
      //style:{ background: 'gray' },
    },
    {
      name:'RAZON SOCIAL',
      selector:row => row.RazonSocial,
      sortable:true,
      left:true,
      compact:true,
      grow:1.5,
    },     
    //=========================
    //======= ACCIONES 
    //=========================
    {
      name:'',
      sortable:false,
      center:true,
      compact:true,
      grow:1.3,  
  
      //esto funciona fino
      //cell: (row) => <div><div style={{ fontWeight: 'bold' }}>{row.factura17}</div>{row.IdPrincipal}</div>,
      //esto funciona fino
      //cell: (row) => <div style={{ fontWeight: 900 }}>{row.factura17}</div>,
      //<div style={{ display:'flex' }}>            
      cell: (row) => 
      <div style={{ display:'flex' }}>            
        {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
        {/* tambien da error al poner raised. se debe poner raised="true"  */}
        {/* o se puede poner raised= {miVariable.toString() }  */}
  
      {/* boton Anular y Enviar */}
      <Button id="btnAnular" name="btnAnular" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
       onClick={ (e) => {
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_idMasState(0)   
        //anulo la factura
        gestionarAnularFactura()          
        }}>
        <FontAwesomeIcon size='lg' color="coral" icon={faBan}/></Button>
  
      <Button id="btnEnviarEmail" name="btnEnviarEmail" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
       onClick={(e)=>{      
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_idMasState(0)           
        if (row.Anulado=='1'){
          mostrarSwalBotonAceptar("error","ATENCION","El comprobante está anulado. No se puede enviar")                          
          return
        }      
        if (row.AutorizacionSri=='0'){
          mostrarSwalBotonAceptar("warning","ATENCION","El comprobante aún no está Autorizado")                          
          return
        }      
        if (row.EmailCliente.trim()==''){
          mostrarSwalBotonAceptar("warning","ATENCION","El cliente no tiene correo")                          
          return
        } 
        //en caso que la fila sea amarilla, le envio un parametro para que se TILDE en la bdd     
        let tildarSN=(row.EnviadoAlReceptor=="0") ? "S" : "N"
        gestionarEnviarCorreoSriPdfMasXmlConSwal(tildarSN)       
        }}>
        <FontAwesomeIcon size='lg' color="white" icon={faEnvelope} /></Button>          
      {/** dropdown para el boton MAS... */}

      <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.Factura } direction="right"  
        toggle= { ()=> {      
          set_rowRegistroState(row) //obligatorio
          rowRegistroClon=row
          set_idMasState((idMasState == 0) ? row.Factura : 0)
        }}>    
          <DropdownToggle color="info">
            <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
          </DropdownToggle>
        <DropdownMenu style={{backgroundColor:'pink',}} >            
          <DropdownItem onClick={ async() => {
            await gestionarResolverRojo() 
          }}><FontAwesomeIcon style={{color:'dimGray'}} icon={faBolt} />  Resolver SRI</DropdownItem>        
          <DropdownItem divider />
          <DropdownItem onClick={ async() => await gestionarVerFacturaPdfSri() }>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faGlasses}  /> Ver pdf</DropdownItem>        
          <DropdownItem onClick={ () => {
            set_modalPanelFactura(true)
          }}><FontAwesomeIcon style={{color:'dimGray'}} icon={faVoteYea}  /> Ver panel</DropdownItem>        
          <DropdownItem onClick={ async() => {
              if (await verificarSiEstaAnuladoSN()=="S") return          
              set_modalRecibosCaja(true)           
              }}>
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faMoneyCheckAlt}  /> Ver recibos de caja</DropdownItem>
          <DropdownItem divider />
          <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>          
        </DropdownMenu>
      </Dropdown>
      </div>,
    },
    //======= fin de acciones
    {
      name:'FECHA',
      selector:row => row.FechaEmisionFormat,
      sortable:true,
      left:true,
      compact:true,
      grow:0.7,  //originalmente 0.9
    }, 
    {
      name:'IDENTIFIC.',
      selector:row => row.IdPrincipal,
      sortable:true,
      left:true,
      compact:true,
      grow:0.9,  
    },
    {
      name:'TOTAL',
      selector:row => row.TotalFactura,
      sortable:true,
      right:true,
      compact:true,
      grow:0.7, 
    },           
    {
      name:'CREA',
      selector:row => row.OpCrea,
      sortable:true,
      center:true,
      compact:true,
      grow:0.25,
      hide:'sm', //se oculta en pantallas pequenas por debajo de 600px de ancho
    }, 
]

const hayFilaSeleccionada=async()=>{ 
  //solo me sirve para determinar si hay UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    await mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Factura")          
    miDevolver=false
  }
  return miDevolver
}

const gestionarFirmarXml=async()=>{
  //primero debo ver que la licencia, tenga permiso de usar firma
  if (valueDC.licencia.FirmaElectronicaUsar=="0"){
    await mostrarSwalBotonAceptar("error","ERROR","Usted no tiene una firma asociada")
    return
  }
  //mando a firmar
  let firmadoCorrectamenteSN=await firmarDocumentoXmlConSwal("FA",rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumFac,valueDC)   
}

const tildarAnuladaEnElJson=()=>{ 
  //luego de anular en la BDD, debo tildar en el JSON. Asi me ahorro tener que volver a leer de la BDD
  let copiaFull=registrosFullState.map(item=>{
    if (item.Estab==rowRegistroClon.Estab && item.Punto==rowRegistroClon.Punto && item.NumFac==rowRegistroClon.NumFac) {
       item.Anulado="1"
       item.OpAnula=valueDC.usuario.LoginUsu
    }
    return item      
  })    
  set_registrosFullState(copiaFull)
} 

const anularFacturaEnBDD=async()=>{
  let data=new FormData()
  data.append('miSol','factura_Anular')
  data.append('miEstab',rowRegistroClon.Estab)
  data.append('miPunto',rowRegistroClon.Punto)
  data.append('miNumFac',rowRegistroClon.NumFac)
  data.append('opAnula',valueDC.usuario.LoginUsu)
  
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  return dataApi
}

const gestionarAnularFactura=()=>{    
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Factura")          
    return
  }
  //reviso si ya esta anulada previamente en el grid (es decir si ya estaba tachada)
  if (rowRegistroClon.Anulado==1){
    mostrarSwalBotonAceptar("warning","ATENCION","Esta Factura ya estaba anulada")          
    return
  }
  //llamo al modal de pedir clave
  set_modalPedirClaveEspecial(true)
}

const gestionarResolverRojo=async()=>{
  if (await verificarSiEstaAnuladoSN()=="S") return
  
  //primero debo ver que la licencia, tenga permiso de usar firma
  if (valueDC.licencia.FirmaElectronicaUsar=="0"){
    await mostrarSwalBotonAceptar("error","ERROR","Usted no tiene una firma asociada")
    return
  }
  let miFormato=determinarFormatoImpresoraGrande(valueDC,rowRegistroClon.Estab,'FormatoFacturaSri')
  if (miFormato==''){
    await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato")          
    return false
  }
  //mando a resolver, recibo la respuesta para mostrarla al usuario y para poder tildar el grid
  resolverJson=await resolverComprobanteRojoSriConSwal(
    "FA",miFormato,rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumFac,valueDC,rowRegistroClon.RazonSocial,rowRegistroClon.EmailCliente)
  
  //voy a tildar el grid segun la respuesta
  if (resolverJson !=null && resolverJson.Autorizado){
    let miCorreo=(resolverJson.EnviadoPorCorreo) ? "1" : "0"
    let copiaFull=registrosFullState.map(item=>{
      if (item.Estab==rowRegistroClon.Estab && item.Punto==rowRegistroClon.Punto && item.NumFac==rowRegistroClon.NumFac) {
         item.AutorizacionSri="1"
         item.MensajeErrorAutorizacion=null
         item.EnviadoAlReceptor=miCorreo
      }
      return item      
    })    
    set_registrosFullState(copiaFull)
  }
  
  //llamo a mi formulario para que el cliente vea el resumen  
  if (resolverJson !=null){
    await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
    set_modalResolverSri(true) 
  }
}

const gestionarEnviarValidarSri=async()=>{
  //NOTAS: si la firmo con otro RUC la recibe OK. pero en la consulta/autorizacion me da el error: FIRMA INVALIDA, El certificado utilizado, no es del tipo firma digital</informacionAdicional
  //El SRI solo permite intentar 3 veces. Luego al enviarle me da error # 90, mensaje: LIMITE DE INTENTOS EXCEDE
  //OJO: a veces no se por que causa, pero el primer intento no hace nada, luego el segundo me viene con html y el tercero lo hace bien. Solo a veces
  let digitoAmbiente12=rowRegistroClon.ClaveAcceso.substring(23,24) //1:pruebas, 2:produccion
  let archivoTmp="FA_FIR_" + rowRegistroClon.Estab + "-" + rowRegistroClon.Punto + "-" + rowRegistroClon.NumFac + ".xml"
  //en caso de no haber conexion con el SRI o no se puedo convertir en JSON la respuesta, entonces recibo un NULL
  let pepeJsonEnvioSri=await recepcionValidarSriConSwal(digitoAmbiente12,archivoTmp,valueDC) 

  //Al ser null, es que no se pudo codificar el JSON.
  if (pepeJsonEnvioSri==null) {
    //al ser nulo, no hubo conexion y seguramente ya se mostro la pantalla negra
    return
  }
  if (!pepeJsonEnvioSri.ConexionSRI) {
    //al ser false, seguramente ya dio la pantalla negra
    return
  }
  //al ser DEVUELTA 
  if (pepeJsonEnvioSri.Estado=="DEVUELTA"){
    let mensajeSri="Identificador: " + pepeJsonEnvioSri.Identificador + " Mensaje: " + pepeJsonEnvioSri.Mensaje
    await mostrarSwalBotonAceptar("error","DEVUELTA",mensajeSri)
    return
  }

  //RECIBIDA: muestro el mensaje del SRI
  if (pepeJsonEnvioSri.Estado=="RECIBIDA"){
    await mostrarSwalBotonAceptar("success","OK","RECIBIDA")
    return
  }
  
  //al estar todo ok----- lo ideal---- entonces actualizo en la bdd (solo en caso de ser necesario)
  //falta: si estaba roja, entonces actualizo en la BDD y lo dejo actualizando en segundo plano  
}

const gestionarConsultarAutorizacionSri=async()=>{
  //esta funcion consulta en el SRI, pero SI descarga el xml autorizado desde el SRI y lo guarda en disco. 
  //Se busca la autorizacion dependiendo del digito de AMBIENTE que se encuentra dentro de los 49 digitos
  //OJO: no hace ningun cambio en la BDD
  let claveAccesoTmp=rowRegistroClon.ClaveAcceso
 
  //en caso de no haber conexion con el SRI o no se puedo convetir en JSON la respuesta, entonces recibo un NULL
  pepeJsonConsultaSri=await consultarAutorizacionSriConSwal(claveAccesoTmp,valueDC) 
  //Al recibIr un JSON bueno, con NumeroDeComprobantes==1 entonces muestro el formulario con la respuesta
  if (pepeJsonConsultaSri==null) {
    //al ser nulo, no hubo conexion y seguramente ya se mostro la pantalla negra
    return
  }
  if (!pepeJsonConsultaSri.ConexionSRI) {
    //al ser false, seguramente ya dio la pantalla negra
    return
  }
  if (pepeJsonConsultaSri.NumeroComprobantes==0) {
    //significa que hubo conexion, pero el comprobante NO EXISTE
    await mostrarSwalBotonAceptar("warning","ATENCION","El comprobante no existe")
    return
  }

  //llamo a mi formulario para que el cliente lo vea
  set_modalConsultarSri(true)
}

const gestionarEnviarCorreoSriPdfMasXmlConSwal=async(tildarSN)=>{
  //*******llamo a una funcion que haga el envio. Y le mando un parametro para que se TILDE en la bdd. (pero el cambio de color solo se ve al darle refrescar o salir y volver a entrar)
  let correoEnviadoCorrectamenteSN = await enviarCorreoSriPdfMasXmlConSwal(
    rowRegistroClon.RazonSocial,rowRegistroClon.EmailCliente,valueDC,rowRegistroClon.ClaveAcceso,
    "FA",rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumFac,tildarSN
  )

  if (correoEnviadoCorrectamenteSN=="N" || tildarSN=="N")
    return

  //al estar enviado, entonces lo tildo en el grid 
  let copiaFull=registrosFullState.map(item=>{
    if (item.Estab==rowRegistroClon.Estab && item.Punto==rowRegistroClon.Punto && item.NumFac==rowRegistroClon.NumFac) item.EnviadoAlReceptor="1"  
    return item
  })
  set_registrosFullState(copiaFull)
}

const refrescarData=async()=>{ 
  let data=new FormData()
  data.append('miSol','facturasventas_buscarUnMesPocasColumnas')
  data.append('miMes',mesVer)
  data.append('miAno',anoVer)
  data.append('orderBy','FechaEmision')
  data.append('forma','Desc') 

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_idMasState(0)
  set_registrosFullState(dataApi)   
}

const ponerMesYanoTitulo=()=>{
  let miPeriodo=devolverMesTextoDesdeUnNumero(mesVer) 
  miPeriodo = miPeriodo + "-" + anoVer
  document.getElementById("miPeriodo").innerText=miPeriodo
}

const determinarMesAnoActual=()=>{
  mesVer= (new Date().getMonth()) + 1 //OJO: enero es 0 (es el mes y ano que deso ver las facturas)
  anoVer= (new Date().getFullYear()) 
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  determinarMesAnoActual()
  refrescarData() 

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //variables normales
    rowRegistroClon=null 
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }  
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al menu principal
useEffect(()=>{  
  if (registrosFullState==null){    
    props.activarMenu() 
  }
  },[registrosFullState]
)

const filtrarElementos=(cadenaLoca)=>{
  cadenaLoca=cadenaLoca.toLocaleLowerCase()  
  
  let resultado=registrosFullState.map(item=>{  
  if (
      item.Factura.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.FechaEmisionFormat.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.TotalFactura.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreVen.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreFac.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Condicion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.ClaveAcceso.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.EmailCliente.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.OpCrea.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.OpAnula.toString().toLocaleLowerCase().includes(cadenaLoca)            
      )   
      item.VisibleSN='S'
    else
      item.VisibleSN='N'

    return item         
  })

  set_registrosFullState(resultado)
  set_idMasState(0)
  rowRegistroClon=null  
} 

const verificarSiEstaAnuladoSN=async()=>{
  //reviso si el documento esta anulado
  let estaAnuladoSN=(rowRegistroClon.Anulado=="1") ? "S" : "N"
  if (estaAnuladoSN=="S"){
    await mostrarSwalBotonAceptar("warning","ATENCION","La Factura está anulada.... No se puede continuar")
  }
  return estaAnuladoSN
}

const gestionarVerFacturaPdfSri=async()=>{
  if (await verificarSiEstaAnuladoSN()=="S") return

  let miFormato=determinarFormatoImpresoraGrande(valueDC,rowRegistroClon.Estab,'FormatoFacturaSri')
  if (miFormato==''){
    await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato")          
    return false
  }

  //debo generar el PDF del sri y luego lo muestro  
  let dataApi=await generarDocumentoPDF_SriConSwal("FA",miFormato,rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumFac,valueDC)   
  //en dataApi, puede venir null o el mismo numero de Factura enviada. Nunca viene negativo
  if (dataApi==null) return

  await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
  set_modalFacturaSri(true)                  
}

const gestionarVerFacturaSimple=async()=>{
  if (await verificarSiEstaAnuladoSN()=="S") return

  let miFormato=determinarFormatoImpresoraGrande(valueDC,rowRegistroClon.Estab,'FormatoFacturaSimple')
  if (miFormato==''){
    await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato")          
    return
  }

  let data=new FormData()
  data.append('miSol','facturaDeVentaSimple_generarPDF')
  data.append('miFormato',miFormato)
  data.append('miEstab',rowRegistroClon.Estab)
  data.append('miPunto',rowRegistroClon.Punto)
  data.append('miNumFac',rowRegistroClon.NumFac)
    
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  //en dataApi, puede venir null o el mismo numero de Factura enviada. Nunca viene negativo
  if (dataApi==null) return

  await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
  set_modalFacturaSimple(true)   
}

/*
const gestionarVerTicketBonanza=async()=>{
  let data=new FormData()
  data.append('miSol','ticketBonanza_generarPDF')
  data.append('miEstab',rowRegistroClon.Estab)
  data.append('miPunto',rowRegistroClon.Punto)
  data.append('miNumFac',rowRegistroClon.NumFac)
    
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  //en dataApi, puede venir null o el mismo numero de Factura enviada. Nunca viene negativo
  if (dataApi==null) return
  set_modalTicket(true)   
}  
*/

const actualizarTabelaDespuesDeEliminarRecibo=(sumaDeAbonos)=>{
  //esta funcion, actualiza la columna [Cancelado], solo se usa esta funcion cuando la factura es a CREDITO
  //el valor sumaDeAbonos, viene como float  
  let copiaFull=registrosFullState.map(item=> { 
    if (item.Factura==rowRegistroClon.Factura) {
      let tf=parseFloat(item.TotalFactura)
      let s= tf - sumaDeAbonos
      if (s>0)
        item.Cancelado="0"
    }
    return item
    })
  
  //refresco la tabela con los cambios
  set_registrosFullState(copiaFull)
}

const ComponentePrincipalBody=()=>{
return(  
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 

  <div id="divSubMaster" name="divSubMaster">
  {/******* Boton superior de OPCIONES (utilidades) ************/}
    <Row style={{margin:'0',paddingLeft:'0px'}} >     
      <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidadesState" name="dropUtilidadesState" isOpen={ dropUtilidadesState }  size="md"
        toggle= { ()=> {
          set_dropUtilidadesState(!dropUtilidadesState)
        }}>
        <DropdownToggle caret color="primary">
          Opciones
        </DropdownToggle>  
        <DropdownMenu style={{backgroundColor:'cyan',}} >        
          <DropdownItem onClick={ () => alert(JSON.stringify(rowRegistroClon,null,2) )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> JSON de la fila seleccionada</DropdownItem>        
          <DropdownItem onClick={ () => alert('Editar seriales.. ' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Editar Serie</DropdownItem>        
          <DropdownItem onClick={ () => alert('Editar observaciones.. ' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Editar Observaciones</DropdownItem>        
          <DropdownItem divider />
          <DropdownItem onClick={async()=>{
            if (await hayFilaSeleccionada()==false ) return
            if (await verificarSiEstaAnuladoSN()=="S") return

            let data=new FormData()
            data.append('miSol','generarXMLdocumento')
            data.append('miPrefijo','FA')
            data.append('miEstab',rowRegistroClon.Estab)
            data.append('miPunto',rowRegistroClon.Punto)
            data.append('miNumDoc',rowRegistroClon.NumFac) 
          
            let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
            if (dataApi==null) return
            await mostrarSwalBotonAceptar("success","OK","XML creado correctamente")
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} /> Generar XML</DropdownItem>        
          <DropdownItem onClick={async()=>{ 
            if (await hayFilaSeleccionada()==false ) return
            if (await verificarSiEstaAnuladoSN()=="S") return
            gestionarFirmarXml() 
          }}> 
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} />  Firmar XML</DropdownItem>        
          <DropdownItem onClick={ async() => {
            if (await hayFilaSeleccionada() ) 
            if (await verificarSiEstaAnuladoSN()=="S") return
            gestionarEnviarValidarSri() 
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} /> Enviar al SRI</DropdownItem>        
          <DropdownItem onClick={ async() => {
             if (await hayFilaSeleccionada() ) 
              gestionarConsultarAutorizacionSri()
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} /> Consultar autorizacion SRI
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
        </DropdownMenu>
      </Dropdown>
    </Row>

    {/******* Menu superior, para telefono vertical: solo 4 botones *************/}
    <div id="divMenuSuperior" name="divMenuSuperior"> 
    <Row style={{margin:'0px',}} >
      <Col>      
        <ButtonGroup> 
        <Button id="btnVerPdfSri" name="btnVerPdfSri" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
          onClick = { async() => { 
            if (await hayFilaSeleccionada()==false )  return
            await gestionarVerFacturaPdfSri() 
            }}>
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faGlasses}/></span>
          <span><br/>Ver pdf</span>
        </Button>
        <Button id="btnVerFacturaSimple" name="btnVerFacturaSimple" className="btnPpal btnBordebtnPpal"  
          onClick = { async() => { 
            if (await hayFilaSeleccionada()==false ) return
            await gestionarVerFacturaSimple() 
          }}>
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faCopy}/></span>
          <span><br/>Simple</span>
        </Button>
        <Button id="btnTicket" name="btnTicket" className="btnPpal btnBordebtnPpal"  
          onClick = { async() => {    
            /*     
            if (await hayFilaSeleccionada() ) 
              gestionarVerTicketBonanza() 
            */
          }}> 
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faReceipt}/></span>
          <span><br/>Ticket</span>
        </Button>
        <Button id="btnPanel" name="btnPanel" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
          onClick = { async() => { 
            if (await hayFilaSeleccionada() ) set_modalPanelFactura(true)
            }}>  
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faVoteYea} /></span>
          <span><br/>Panel</span>          
        </Button>
        <Button id="btnRecibos" name="btnRecibos" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
            onClick = { async () => { 
              if (await hayFilaSeleccionada() ) {
                if (await verificarSiEstaAnuladoSN()=="S") return
                set_modalRecibosCaja(true)
              }
            }}>  
            <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faMoneyCheckAlt}/></span>
            <span><br/>Recibos</span>          
        </Button>
        <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
          onClick = { async() => { 
              set_textoBuscarState('')
              //pongo a 0 los codigos del estado para que se quite la franja AZUL
              set_rowRegistroState(null)
              rowRegistroClon=null
              set_idMasState(0)

              determinarMesAnoActual() 
              ponerMesYanoTitulo() //solo escribe como titulo
              refrescarData()
            }} >  
            <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /></span>
            <span><br/>Refresh</span>          
        </Button>
        <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"  
            onClick = { () => { 
              props.activarMenu()
            }} >  
            <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /></span>
            <span><br/>Salir</span>          
        </Button>
        </ButtonGroup>          
      </Col>
    </Row>
    </div> {/* divMenuSuperior */}

    {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
      <Input
        className = "textoBusquedaClientes"
        type="text" placeholder="Texto de búsqueda" name="inputBusqueda" id="inputBusqueda" 
        value={textoBuscarState}
        onChange={()=>{
          set_textoBuscarState( document.getElementById("inputBusqueda").value )
          filtrarElementos( document.getElementById("inputBusqueda").value.trim() )
      }}/>
      <Button  
        id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
        onClick={()=>{
          set_textoBuscarState('')
          set_rowRegistroState(null)
          rowRegistroClon=null
          set_idMasState(0)          
          filtrarElementos('')                   
        }}> 
        <FontAwesomeIcon color="paleGreen" icon={faBroom} />
      </Button>
      <Label id="miPeriodo" name="miPeriodo" style={{marginLeft:"10px",marginRight:"10px",marginTop:"5px",fontSize:'18px', fontWeight:'bold',color:"white" }}>
        {
          devolverMesTextoDesdeUnNumero(mesVer) + "-" + anoVer
        }
      </Label> 
      <Button size="md" id="btnPeriodo" name="btnPeriodo" color="info" solid="true" 
        onClick={()=>{
          set_modalPedirMesYano(true)
        }}>Cambiar
      </Button>        
    </div> { /* divBuscador1 */ }

  </div> { /* div SubMaster */ }

  { /* inicio del DIV para la tabela */ }
  <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable
      //************ DATA TABLA PROPERTIES (basic) ***********/
      title='Lista de Facturas'
      columns={miEstructura}
      data={registrosFullState?.filter(item=>item.VisibleSN=='S')}
      conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
      keyField ='Factura' // este campo contiene ya los 17 caracteres
      onRowClicked={(row) => {
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_idMasState(0)         
      }}
      highlightOnHover  
      //pointerOnHover='true'
      //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
      noDataComponent = ""  //Nada por aquí
      //className='string' //override the className on the Table wrapper
      //style= object overrade the className on the Table wrapper
      //style= {{minHeight:'60vh'}} //ahora se usa dentro de estilacho
      //responsive='true' //true es el valor por defecto
      //disabled='false' //por defecto false
      //onRowClicked={miOnRowClicked()} //callback to access the row, even on row click (no pude hacerle funcionar)
      //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
      //noTableHead //oculta las cabeceras
      //************ DATA TABLA PROPERTIES (row selection) ***********/
      //selectableRows //aparece el checkbox
      //selectableRowsVisibleOnly
      //selectableRowsHighlight
      //selectableRowsNoSelectAll

      //************ DATA TABLA PROPERTIES (row expander) ***********/
      //expandableRows //interesante

      //************ DATA TABLA PROPERTIES (sorting) ***********/
      //defaultSortField

      //************ DATA TABLA PROPERTIES (pagination) ***********/
      pagination
      //paginationServer //change de default pagination to work with server pagination
      paginationPerPage={ valueDC.sistema.paginadoParaFacturas } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
      paginationComponentOptions={paginacionOpciones}

      //************ DATA TABLA PROPERTIES (header) ***********/
      //actions //component or array of components
      //pepe. no poner fixedHeader
      //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
      //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
      //subHeader //me gustaria esta opcion pero no funciona

      //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
      //theme='dark' //interesante, ya dark existe
      //theme="solarized" //muy interesante (no implementado)
      customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
  </div> { /* divTabela */ }

{/************ MODAL PARA PEDIR MES Y ANO *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalPedirMesYano } >
      <PedirMesYano_OK
        cerrarModal={async(accion,mesNuevo,anoNuevo)=>{
          set_modalPedirMesYano(false)
          //accion puede ser: close/select 
          if (accion=='close') return

          //rumbo ideal....          
          set_textoBuscarState('')
          set_rowRegistroState(null)
          rowRegistroClon=null
          set_idMasState(0)
          mesVer=mesNuevo
          anoVer=anoNuevo
          ponerMesYanoTitulo()
          refrescarData()                  
        }}
        mesActual={mesVer}
        anoActual={anoVer}
      />
    </Modal>      

{/************ MODAL PARA VER LA CONSULTA DEL SRI *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalConsultarSri } >
      <MostrarConsultaAutorizacionSri
        cerrarModal={()=> {
          set_modalConsultarSri(false)
        }}
        pepeJson={pepeJsonConsultaSri}      
      />
    </Modal>   

{/* ******* MODAL PARA VER UNA LISTA DE LOS RECIBOS DE CAJA ********/}
    <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalRecibosCaja } >
      <RecibosIngresoDeUnDocumento
        cerrarModal={(todoOKsn,sumaDeAbonos)=> {
          //todoOKsn, es una variable para saber si cargó exitosamente la lista de recibos 
          //sumaDeAbonos: contiene la sumatoria de los abonos
          //actualizo las columnas de Cancelado (alomejor se eliminaron recibos, y se debe cambiar el estatus de Cancelado)
          if (todoOKsn=="s" && rowRegistroClon.Condicion=="CREDITO"){
            actualizarTabelaDespuesDeEliminarRecibo(sumaDeAbonos) //se eliminen o no los recibos, siempre hay que actualizar la tabela siempre y cuando la factura sea credito
          }
          set_modalRecibosCaja(false)
          set_idMasState(0)
        }}
        clienteNombre={rowRegistroClon?.RazonSocial}
        clienteEmail={rowRegistroClon?.EmailCliente}
        doc17DG={rowRegistroClon?.Factura}
        estabDG={rowRegistroClon?.Estab}
        puntoDG={rowRegistroClon?.Punto}
        numDG={rowRegistroClon?.NumFac}
        fechaDG={rowRegistroClon?.FechaEmisionFormat}
        montoDG={rowRegistroClon?.TotalFactura}
        prefijoDG={'FA'}     
        condicion={rowRegistroClon!=null ? rowRegistroClon.Condicion : null }
      />
    </Modal>
   
{/************ MODAL PARA VER LOS RESULTADOS DE RESOLVER SRI *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalResolverSri } >
      <MostrarResultadosResolver
        cerrarModal={()=> {
          set_modalResolverSri(false)
        }}
        documentoTipoYnum={"FACTURA: " + rowRegistroClon?.Factura }
        resolverJson={resolverJson}      
      />
    </Modal>    
    
{/************ MODAL PARA VER EL PANEL DE LA FACTURA *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalPanelFactura } >
    <MostrarPanelDeLaFactura
        cerrarModal={()=> {
          set_modalPanelFactura(false)
        }}
        row={rowRegistroClon}      
      />
  </Modal>  

{/************ MODAL PARA VER EL TICKET DE LA FACTURA *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalTicket } >
    <VerPdfOk
      documentoTitulo={"FACTURA"}
      documentoArchivo={"TI_" + rowRegistroClon?.Estab + "-" + rowRegistroClon?.Punto + "-" + rowRegistroClon?.NumFac }
      corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
      tipoSriSN={"N"} //es un documento del sri?
      estiloPantallaPG={"P"} //Para modal le mando P
      activarMenu={ () => set_modalTicket(false) } 
    />
  </Modal>  

{/************ MODAL PARA VER LA FACTURA SRI *****************/}
  <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalFacturaSri } >
    <VerPdfOk
      documentoTitulo={"FACTURA"}
      documentoArchivo={"FA_" + rowRegistroClon?.Estab + "-" + rowRegistroClon?.Punto + "-" + rowRegistroClon?.NumFac }
      corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
      tipoSriSN={"S"} //es un documento del sri?
      estiloPantallaPG={"P"} //Para modal le mando P
      activarMenu={ () => set_modalFacturaSri(false) } 
    />
  </Modal>    

{/************ MODAL PARA VER LA FACTURA SIMPLE  *****************/}
<Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalFacturaSimple } >
    <VerPdfOk
      documentoTitulo={"FACTURA"}
      documentoArchivo={"FS_" + rowRegistroClon?.Estab + "-" + rowRegistroClon?.Punto + "-" + rowRegistroClon?.NumFac }
      corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
      tipoSriSN={"N"} //es un documento del sri?
      estiloPantallaPG={"P"} //Para modal le mando P
      activarMenu={ () => set_modalFacturaSimple(false) } 
    />
  </Modal>      

{/************ MODAL PARA PEDIR LA CLAVE ESPECIAL y luego anular*************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalPedirClaveEspecial } >
    <PedirClaveEspecial
        cerrarModal={async(claveCorrecta)=> {
          set_modalPedirClaveEspecial(false)
          if (!claveCorrecta) return

          //rumbo ideal
          let miNumDevuelto=await anularFacturaEnBDD()  //Al ser la clave correcta, entonces anulo en la BDD
          //la variable miNumDevuelto, puede venir con null, numero negativo, numero positivo (ideal)
          if (miNumDevuelto==null){
            set_registrosFullState(null) //Hago esto para forzar que se cierre el form automaticamente
            return
          }
          if (miNumDevuelto==-100){
            await mostrarSwalBotonAceptar("warning","NO SE PUEDE ANULAR DOS VECES","la factura ya había sido anulada previamente")
            //marco en el JSON que la factura está anulada
            tildarAnuladaEnElJson() 
            return                                    
          }     
          if (miNumDevuelto==-130 || miNumDevuelto==-140 || miNumDevuelto==-150 ){
            await mostrarSwalBotonAceptar("warning","NO SE PUDO ELIMINAR EL RECIBO DE CAJA ASOCIADO", `Código de error # ${miNumDevuelto}`)
            return
          }     
          if (miNumDevuelto==-160 || miNumDevuelto==-200 || miNumDevuelto==-300 ){
            await mostrarSwalBotonAceptar("warning","NO SE PUDO ANULAR LA FACTURA", `Código de error # ${miNumDevuelto}`)
            return
          }  
          if (miNumDevuelto>0){
            //marco en el JSON que la factura esta anulada
            tildarAnuladaEnElJson()                    
            //le informo al usuario que todo OK
            await mostrarSwalBotonAceptar("success","OK","La Factura se anuló correctamente. Usted también deberá anular la Factura desde la Página del SRI")
            return
          }        
        }}
        claveEnBDD={valueDC.licencia.ClaveOperacionesEspeciales}      
        subtitulo={"anular la factura: " + rowRegistroClon?.Factura}
      />
  </Modal>  
</div> // divMaster 
) //del return interno
}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} //de TabelaFacturasLista

export default TabelaFacturasLista
