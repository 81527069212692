//Me permite hacer retenciones: de renta/IVA
import React, {useState,useEffect} from 'react'
import { Input,Label,Button,Row,Col,Modal} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,buscarCabeceraEnRetencionesElaboradasConSwal,procesarRetencionEnComprasEnBDDconSwal} from './lib_basica'
import RetenerRenta from './RetenerRenta'
import RetenerIva from './RetenerIva'
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let respuestaMontosOriginalesJson=null //aqui recibo desde PHP los totales del documento generador
//Variables numericas DEFINITIVAS para el encabezado
let base0=0
let base12=0
let baseImponibleRenta=0 //Base0 + Base12
let baseImponibleIva=0 //Iva en $
let porcIva=0 //% de iva del documento generador
let totalComprobante=0 //total del comprobante cabecera
let jsonGridRenta=[] //este es un array/json con los items (generalmente 1) del detalle de la retencion de Renta
let jsonGridIva=[] //este es un array/json con los items (generalmente 1) del detalle de la retencion de Iva
let montoRetenidoTotalClon=0 //monto en $ global retenido. (lo uso mas que todo para validar si el usuario quiere hacer la retención en CERO)
//la estructura del json que viene desde los modales deberia ser: CodigoRet,Descripcion,Base,Porcentaje,ValorRetenido (ya redondeado a 2 decimales)

export default function RetenerFormulario(props){
const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('retener') //por default: 'retener'. Alomejor luego un componente para ver otras cosas
const [montoRetenidoRenta,set_montoRetenidoRenta]=useState(0)  
const [montoRetenidoIva,set_montoRetenidoIva]=useState(0)  
const [montoRetenidoTotal,set_montoRetenidoTotal]=useState('Monto total retenido: 0.00')  
const [modalRetenerRentaState,set_modalRetenerRentaState]=useState(false)
const [modalRetenerIvaState,set_modalRetenerIvaState]=useState(false)

const buscarCabecera=async()=>{
//Alguna informacion ya viene en las props. Otra Info hay que buscarla en la BDD
//empiezo con la que viene en las props
document.getElementById("txtRuc").value=props.IdPrincipal
document.getElementById("txtRazonSocial").value=props.RazonSocial
document.getElementById("txtTipoDoc").value=(props.TipoDocFNL=="F") ? "FACTURA" : (props.TipoDocFNL=="L") ? "LIQUIDACION" : "NOTA DE DEBITO"
document.getElementById("txtNumDoc").value=props.doc17DG
document.getElementById("txtFechaDoc").value=props.fechaDGformat

//busco en la BDD, los datos numericos (Subtotal0,subtotal12,% de Iva)
//OJO: puede venir null cuando hay un error de sintaxis / o viene un json con varios campos (5)
respuestaMontosOriginalesJson = await buscarCabeceraEnRetencionesElaboradasConSwal(
  props.IDr,props.TipoDocFNL,props.estabDG,props.puntoDG,props.numDG,valueDC)
  
//evaluo la respuesta (en caso venir null o un numero negativo en v_valorPosNeg, me salgo)  
let salirme=false
if (respuestaMontosOriginalesJson==null){
  salirme=true
}
if (respuestaMontosOriginalesJson!=null && respuestaMontosOriginalesJson[0].v_ValorPosNeg<0){
  salirme=true
  await mostrarSwalBotonAceptar("error","ATENCION","No se pudo accesar al servidor")
}  
if (respuestaMontosOriginalesJson!=null && respuestaMontosOriginalesJson[0].v_NumRetencionesPrevias>0){
  let miTexto=  (respuestaMontosOriginalesJson[0].v_NumRetencionesPrevias==1) ? "1 retención previa" : respuestaMontosOriginalesJson[0].v_NumRetencionesPrevias + " retenciones previas"
  let miBotonRespuesta=await mostrarSwalConfirmacionEliminarAnular("PRECAUCION",`Este comprobante ya tiene ${miTexto} DESEA CONTINUAR ?`)
  if (miBotonRespuesta.isDismissed)
    salirme=true
}  
if (salirme==true){
  props.cerrarModal_Retener(false,false)    
  desmontar() //de ultimo
}

if (salirme) return
//rumbo normal, convierto el JSON de php en variables locales individuales
base0=parseFloat(respuestaMontosOriginalesJson[0].v_Base0DG)
base12=parseFloat(respuestaMontosOriginalesJson[0].v_Base12DG)
porcIva=parseFloat(respuestaMontosOriginalesJson[0].v_PorcIvaDG)
baseImponibleRenta=base0 + base12
baseImponibleIva= (base12 * porcIva)/100 
//obligatoriamente debo redonder el monto del iva 
baseImponibleIva=baseImponibleIva.toFixed(2) //aqui queda en modo texto
baseImponibleIva=parseFloat(baseImponibleIva) //lo vuelvo a convertir en numerico
totalComprobante= base0 + base12 + baseImponibleIva
//muestro los valores numericos
document.getElementById("txtSubtotal").value=baseImponibleRenta.toFixed(2)
document.getElementById("txtIva").value=baseImponibleIva
document.getElementById("txtTotal").value=totalComprobante
}

const desmontar=()=>{
//
}

const validarFormulario=async()=>{
  //los dos grid no pueden estar vacios. Pero uno si.
  if (jsonGridRenta.length==0 && jsonGridIva.length==0){
    mostrarSwalBotonAceptar("warning","ATENCION","Aún no ha seleccionado ningún código de retención")
    return false  
  }
  //Doy una alerta si la retención se va en cero (según el SRI es totalmente posible)
  if (montoRetenidoTotalClon==0){
    let miRespCero=await mostrarSwalConfirmacionEliminarAnular("Retención en Cero","Realmente desea hacer la retención en CERO ?")
      //ha pulsado reintentar,  
      if (miRespCero.isDismissed){
        return false
      }
  }

return true
}
 
const gestionarRetencionEnCompras=async()=>{
  //valido que todo este OK
  if (await validarFormulario()==false) return

  //registro en la BDD, la retencion
  //OJO: puede venir un numero positivo o negativo (el # de retencion obtenido)
  let numeroObtenido = await procesarRetencionEnComprasEnBDDconSwal(jsonGridRenta,jsonGridIva,valueDC,props.TipoDocFNL,props.IDr,props.estabDG,props.puntoDG,props.numDG,props.fechaDGamd,document.getElementById("txtObservaciones").value.trim() )

  await mostrarSwalBotonAceptar("success","LISTO","Retención registrada correctamente")
  props.cerrarModal_Retener(true,true) //se leyó OK la cabecera y se hizo OK la retención
  desmontar()
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  buscarCabecera() //Busco la cabecera
  jsonGridRenta=[]
  jsonGridIva=[]
  montoRetenidoTotalClon=0  

},[]
)

const copiarVariablesDesdeContext=(value)=>{
  valueDC=value
}

const calcularMontoRetenido=()=>{
  //primero sumo RENTA
  let tmpRenta=0
  jsonGridRenta.map(item=>{
    tmpRenta += parseFloat(item.ValorRetenido) //en el JSON ya viene con dos decimales solamente
  })
  tmpRenta=tmpRenta.toFixed(2) //hago esto para quitar los posibles decimales locos ej: 15.000000001
  tmpRenta=parseFloat(tmpRenta)
  set_montoRetenidoRenta(tmpRenta)

  //ahora sumo IVA
  let tmpIva=0
  jsonGridIva.map(item=>{
    tmpIva += parseFloat(item.ValorRetenido) //en el JSON ya viene con dos decimales solamente
  })
  tmpIva=tmpIva.toFixed(2) //hago esto para quitar los posibles decimales locos ej: 15.000000001
  tmpIva=parseFloat(tmpIva)
  set_montoRetenidoIva(tmpIva)

  //ahora, el global
  montoRetenidoTotalClon=tmpRenta+tmpIva
  set_montoRetenidoTotal(`Monto total retenido: ${montoRetenidoTotalClon.toFixed(2)}`)  
}

const Componente_Formulario=()=>{  
return( 
<AppContextConsumer>
{value =>{         
return(               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}> 
  {/* copio desde el context a una variable propia del componente  */}
  { copiarVariablesDesdeContext(value) } 

  {/* Titulo + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10">
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"Nueva Retención"}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ 
          props.cerrarModal_Retener(false,false)
          desmontar() //de ultimo
        }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 

  <div id="divSujetoRetenido" name="divSujetoRetenido" style={{marginLeft:"3%",marginRight:"3%",marginTop:"2%",marginBottom:'3%',padding:'2%',background:'gray', color:'pink'}}>
    <Row style={{margin:'0',}}>
      {/* row para cedula y razon social (etiquetas) */}
      <Col xs="4">     
        <Label>Ci/Ruc</Label>   
      </Col>
      <Col xs="8">
        <Label>Sujeto Retenido</Label>   
      </Col>
    </Row>  
    <Row style={{margin:'0',}}>
      {/* row para cedula y razon social (textBox) */}
      <Col xs="4">        
        <Input disabled name="txtRuc" id="txtRuc" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray' }}/>            
      </Col>
      <Col xs="8">
        <Input disabled name="txtRazonSocial" id="txtRazonSocial" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px',color:'dimgray' }}/>            
      </Col>
    </Row>  

    <Row style={{margin:'0',marginTop:'10px'}}>
      {/* row para tipo doc,Num Doc,Fecha Doc (etiquetas) */}
      <Col xs="4">     
        <Label>Tipo Doc.</Label>   
      </Col>
      <Col xs="5">     
        <Label>Numero Doc.</Label>   
      </Col>
      <Col xs="3">
        <Label>Fecha Doc</Label>   
      </Col>
    </Row>  
    <Row style={{margin:'0',}}>
      {/* row para tipo doc,Num Doc,Fecha Doc (txtBox) */}
      <Col xs="4">        
        <Input disabled name="txtTipoDoc" id="txtTipoDoc" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray' }}/>            
      </Col>
      <Col xs="5">        
        <Input name="txtNumDoc" id="txtNumDoc" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray', color:'dimgray'}}/>            
      </Col>
      <Col xs="3">
        <Input disabled name="txtFechaDoc" id="txtFechaDoc" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray'}}/>            
      </Col>
    </Row> 

    <Row style={{margin:'0',marginTop:'10px'}}>
      {/* row para montos originales del documento (etiquetas) */}
      <Col xs="4">     
        <Label>Subtotal</Label>   
      </Col>
      <Col xs="4">     
        <Label>Iva $</Label>   
      </Col>
      <Col xs="4">
        <Label>Total</Label>   
      </Col>
    </Row>      
    <Row style={{margin:'0',}}>
      {/* row para montos originales del documento (textbox) */}
      <Col xs="4">        
        <Input disabled name="txtSubtotal" id="txtSubtotal" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray'}}/>            
      </Col>
      <Col xs="4">        
        <Input disabled name="txtIva" id="txtIva" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray'}}/>            
      </Col>
      <Col xs="4">
        <Input disabled name="txtTotal" id="txtTotal" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray'}}/>            
      </Col>
    </Row>     

  </div> {/* divSujetoRetenido */}

  <div id="div2Botones" name="div2Botones" style={{marginLeft:"3%",marginRight:"3%",marginTop:"1%",marginBottom:'0%',padding:'2%',background:'purple', color:'pink'}}>
    {/* En este div, van los botones de RETENER RENTA y RETENER IVA. ademas otra fila donde se ven los montos retenidos */}
    <Row style={{margin:'0',}}>
      <Col xs="5" style={{textAlign : 'center' }}>     
        <Button size="lg" id="btnRetenerRenta" name="btnRetenerRenta" color="primary" solid="true" 
          onClick={()=>{
            set_modalRetenerRentaState(true)
            }}>Retener RENTA
        </Button>
      </Col>
      <Col xs="2">        
      </Col>
      <Col xs="5" style={{textAlign : 'center' }}>        
        <Button size="lg" id="btnRetenerIva" name="btnRetenerIva" color="primary" solid="true" 
          onClick={()=>{
            set_modalRetenerIvaState(true)
            }}>Retener IVA
        </Button>
      </Col>
     </Row>  

    <Row style={{margin:'0',}}>
      <Col xs="5" style={{textAlign : 'center'}}>        
        <Label>Retenido x Renta</Label>   
      </Col>
      <Col xs="2">        
      </Col>
      <Col xs="5" style={{textAlign : 'center' }}>        
        <Label>Retenido x Iva</Label>   
      </Col>
    </Row>  

    <Row style={{margin:'0',}}>
      <Col xs="5">        
        <Input disabled name="txtMontoRetenidoRenta" id="txtMontoRetenidoRenta" value={montoRetenidoRenta.toFixed(2)} style={{paddingLeft:'3px', textAlign:'center',width:'100%',height:'30px', fontSize:'18px', color:'dimgray'}}/>            
      </Col>
      <Col xs="2">        
      </Col>
      <Col xs="5">
        <Input disabled name="txtMontoRetenidoIva" id="txtMontoRetenidoIva" value={montoRetenidoIva.toFixed(2)} style={{paddingLeft:'3px', textAlign:'center',width:'100%',height:'30px', fontSize:'18px', color:'dimgray'}}/>            
      </Col>
    </Row>  
  </div> {/* div2Botones */}

  <div id="divTotalRetenido" name="divTotalRetenido" style={{marginLeft:"0%",marginRight:"0%",marginTop:"0%",marginBottom:'0%',padding:'2%',background:'hotpink', color:'white'}}>
    {/* En este div va:Observaciones,  el total retenido mas el boton PROCESAR */}
    <Row style={{margin:'0',}}>
      <Col>     
        <Input name="txtObservaciones" id="txtObservaciones" placeholder="Observaciones" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray'}} />            
      </Col>
    </Row>     
    <Row style={{marginTop:'8px',marginBottom:'0',marginLeft:'0',marginRight:'0',}}>
      <Col xs="8" style={{marginTop:'5px',fontSize:'20px',fontWeight: 'bold'}}>
        <Label>{montoRetenidoTotal}</Label> 
      </Col>
      <Col xs="4" style={{textAlign : 'right' }}>        
        <Button size="lg" id="btnProcesar" name="btnProcesar" color="success" solid="true" 
          onClick={()=>{
            //alert(JSON.stringify(jsonGridRenta,null,2))
            //alert(JSON.stringify(jsonGridIva,null,2))
            gestionarRetencionEnCompras()
            }}>Procesar
        </Button>
      </Col>
     </Row>  
   
  </div> {/* div2Botones */}

{/* ******* MODAL PARA HACER UNA LLAMAR A: RetenerRenta ********/}
<Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalRetenerRentaState } >
    <RetenerRenta
      cerrarModal_RetenerRenta={(statusRetencionRenta,jsonRenta)=> {
        //en statusRetencionRenta viene un boolean para saber si quiere hacer retencion de renta
        //jsonRenta, es un array con el detalle de los codigos a retener
        set_modalRetenerRentaState(false)
        if (!statusRetencionRenta) return
        //rumbo normal
        jsonGridRenta=jsonRenta
        //hago una sumatoria de lo retenido en Renta e Iva
        calcularMontoRetenido()
      }}
      baseImponibleRenta={baseImponibleRenta}
      jsonGridRenta={jsonGridRenta}
    />
</Modal>  
{/* ******* MODAL PARA HACER UNA LLAMAR A: RetenerIva ********/}
<Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalRetenerIvaState } >
    <RetenerIva
      cerrarModal_RetenerIva={(statusRetencionIva,jsonIva)=> {
        //en statusRetencionIva viene un boolean para saber si quiere hacer retencion de iva
        //jsonIva, es un array con el detalle de los codigos a retener
        set_modalRetenerIvaState(false)
        if (!statusRetencionIva) return
        //rumbo normal
        jsonGridIva=jsonIva
        //hago una sumatoria de lo retenido en Renta e Iva
        calcularMontoRetenido()
      }}
      baseImponibleIva={baseImponibleIva}
      jsonGridIva={jsonGridIva}
    />
</Modal>  
</div> //divMaster)
) //del return interno
} //del value
}
</AppContextConsumer>  
)}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='retener') return Componente_Formulario()

} //del export
