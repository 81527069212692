import React from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,sleepPepe } from './lib_basica'
import { aceptarSoloEnteros,aceptarDecimales } from './lib_basica'
 
export default class ModalVacio extends React.Component {

componentDidMount(){    
  //window.scrollTo(0, 0);
}  
  
render(){ 
return (
  <div id="divMaster" name="divMaster" style={{background:'hotPink',width:'100%',}} className="sinScrollXY">    
    {/* Boton Cerrar*/}
    <Row style={{margin:'0',}}>
      <Col xs="10" >
        <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{'MODAL VACIO'}</Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'0'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ this.props.cerrarModal()  }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>
    </Row> 
  </div> //divMaster
)
}
}