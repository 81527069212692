import React from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
 
export default class PedirTextoComplementario extends React.Component {
miLongitudMaxima=null

regresarAlLlamador=()=>{
//puede haber vacio
let textoNuevo=document.getElementById('txtTexto').value.trim()

this.props.cerrarModal()
this.props.regresarAlLlamador(textoNuevo)  
}

componentDidMount(){    
  //FALTA: poner el cursor al final del texto viejo. 
  //NO es necesario en fondo AZUL 
  document.getElementById('txtTexto').value=this.props.textoViejo
}

render(){          
let miTitulo=this.props.miTitulo
this.miLongitudMaxima=this.props.miLongitudMaxima
return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    

{/* Titulo + Boton Cerrar*/}
<Row style={{margin:'0',}}>
  <Col xs="10" >
    <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{miTitulo}</Label>
  </Col>
  <Col xs="2" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ this.props.cerrarModal()  }} >
    <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
  </Col>
</Row> 

{/* Fila para poner el texto */}
<div style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink'}}>
  <Row >
    <Col xs="12">
    <textarea maxLength = {this.miLongitudMaxima} name="txtTexto" id="txtTexto" style={{textAlign:'left',width:'100%',height:'45vh', fontSize:'14px' }}/>            
    </Col>
    </Row>  

    {/* Boton Aceptar style={{marginRight:'10%'}} */}
    <Row>
    <Col xs="6" style={{textAlign:'left',marginTop:'40px', color:'silver',}}>           
    </Col>   

    <Col xs="6" style={{textAlign:'right',marginTop:'20px'}}>    
     <Button size="lg" id="btnListo" name="btnListo" color="info" solid="true" 
     onClick={()=>{this.regresarAlLlamador()}}>
     LISTO</Button>
     </Col>         
    </Row>    
  
  </div> {/* //div interno  */}
</div> // div externo (master)
)}
}
