//alert(JSON.stringify(rowRegistroState,null,2))
//FALTA, la funcion incluirModificarJson() recibe las variables de pvp sin iva y costo ponderado en modo texto. hasta ahi todo bien pero a veces
//viene con 1 2 3 o 4 decimales. (igualmente pasa con stock y stockMinimo) la idea seria hacer una funcion para que siempre quede con 4 decimales y se vea uniforme en la tabela.
//falta: super importante. cada vez que cambio el stock la pantalla se va poniendo mas pequena y se ve el fondo verde (pero al modificar producto no pasa ese problema)
import React, {useState,useEffect} from 'react'
import {Row,Col,Button,ButtonGroup,Input,Modal} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, {sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,buscarUnProductoPorCodRojoConSwal,ejecutarFetchGenericoConSwal} from './lib_basica'
import DataTable, { createTheme } from 'react-data-table-component'
//import XLSX from 'xlsx'
//************ modales */
import IncModInventario from './IncModInventario'
import Ver4PreciosDeUnProducto from './Ver4PreciosDeUnProducto'
import VerStockPreciosOtrosAlmacenes from './VerStockPreciosOtrosAlmacenes'
import ModificarStockInventario from './ModificarStockInventario'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWarehouse,faListOl,faBold,faPencilAlt,faBarcode,faCopy,faClone,faCamera,faHandHoldingMedical,faTruck, faDoorOpen,faLayerGroup,faRegistered,faBookOpen,faGlasses,faRunning,faBroom,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//Context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let miProdSeleccionado=null //aqui copio Un registro para mandarlo a los modales de [ver 4 precios] o al [ver otras bodegas]. Primero se lo busca en php con async await. No confundirlo con rowRegistroClon
let opcionRegistro_imc='' //para saber si deseo incluir nuevo cliente, modificar o consultar
let codigoCandidato='' //codigo cuando deseo agregar un producto desde facturacion/presupuestos/nota de entrega, etc (desde tabela no se usa)
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let deseoBlockSN="S" //para saber si deseo traerme el campo BlockDeNotas del inventario (generalmente S)
let deseoOpSN="N" //para saber si deseo traerme el campo OpCrea del inventario (generalmente N)

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho={
	table: {
		style: {
			minHeight: '45vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'INVENTARIO DE LA BODEGA....'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      //marginLeft:'50px', //ok funcion
      paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok
      fontSize:'18px',   //ok
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',
    }
  },    
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',                 
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL       //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */
     },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada  
  {
    when: row => (row.IDr != filaClonada?.IDr ),
    style: row => ({ 
      backgroundColor:row.Adef_Activo=="No"  ? valueDC.sistema.coloresItemInactivo : 
      row.Descontable=="0" ? 'white' : 
      parseFloat(row.Adef_Stock)<=0 ? valueDC.sistema.coloresInventario0yNegativo : 
      parseFloat(row.Adef_Stock)<=parseFloat(row.Adef_StockMin) ? valueDC.sistema.coloresInventarioEntre1yMinimo : 'white',
      color: 'dimgray',
     }),    
  },
  //fila seleccionada  
  {
    //segun el SELECTOR
    when: (row) => (row.IDr == filaClonada?.IDr ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

const TabelaInventario=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [textoBuscarState,set_textoBuscarState]=useState('') //para el texto que va en el cuadrito de buscar  
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
  const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
  const [modalIncModRegistroState,set_modalIncModRegistroState]=useState(false) //para abrir el modal de incluir/modificar un registro        
  const [modalVer4PreciosState,set_modalVer4PreciosState]=useState(false)  
  const [modalVerOtrasBodegasState,set_modalVerOtrasBodegasState]=useState(false)  //para abrir el modal de ver los precios y stock de otras bodegas
  const [modalModificarStockState,set_modalModificarStockState]=useState(false)  

const miEstructura=[  
  {  
    name:'id',
    selector:row=>row.IDr, 
    sortable:true,
    center:true,
    grow:0.2,
    compact:true, //padding 0
    omit:true, //oculta (siempre) la celda
  },  
  {
    name:'PRINCIPAL',
    selector:row=>row.CodPrincipal, 
    sortable:true,
    compact:true,
    grow:1, //es una medida relativa (1 es mi referencia)
  },
  {
    name:'DESCRIPCION',
    selector:row=>row.Descripcion, 
    sortable:true,
    compact:true,
    grow:1.9,
  },      
  {
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1.6,  
    //style: {backgroundColor:'red'},

    cell: (row) => 
    <div style={{ display:'flex' }}>            
    {/* boton Editar y Eliminar */}
    <Button id="btnEditar" name="btnEditar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
    onClick={ async(e) => {    
      opcionRegistro_imc='m' //'m'odificar registro
      set_idMasState(0)
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_modalIncModRegistroState(true)
    }}>
      <FontAwesomeIcon size='lg' color="white" icon={faPencilAlt}/></Button>

    <Button id="btnEliminar" name="btnEliminar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      onClick={ async (e) => {
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        const miRegistroEnviar="Seguro desea eliminar al item: \n" + rowRegistroClon.Descripcion
        const miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miRegistroEnviar)
        if (miResp.isDismissed) return //alomejor se arrepintio

        let definitivo=await eliminarRegistroEnBDD() //En definitivo puedo recibir: null,-100,numero positivo (osea el mismo enviado) 
        if (definitivo==null) return
        if (definitivo<=0){
          await mostrarSwalBotonAceptar("warning","NO SE PUDO ELIMINAR","El producto tiene transacciones asociadas")
          return 
        }
        //pongo una notificacion abajo a la derecha
        mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
        eliminarJsonRegistro() //elimino del json y refresco la tabela
    }}>
      <FontAwesomeIcon size='lg' color="coral" icon={faTrashAlt} /></Button>
          
    {/* dropdown para el boton MAS... */}
    {/* al hacer clic por segunda vez consecutiva se debe apagar el desplegable */}
    <Dropdown id="dropMas" name="dropMas"  isOpen={ idMasState==row.IDr } direction="right"  
      toggle={()=>{
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.IDr : 0)
      }}>            
      <DropdownToggle color="info">
       <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
      </DropdownToggle>
        
      <DropdownMenu style={{backgroundColor:'pink',}} >
        <DropdownItem 
          onClick={async()=>{
          if (!hayFilaSeleccionada()) return
          opcionRegistro_imc='c' //'c'onsultar registro
          set_idMasState(0)
          set_modalIncModRegistroState(true)
        }}><FontAwesomeIcon style={{color:'dimGray'}} icon={faEye} /> Consultar
        </DropdownItem>        

        <DropdownItem onClick={ () => gestionarVer4Precios_y_OtrasBodegas_ModificarStock("4P") }>  <FontAwesomeIcon style={{color:'dimGray'}} icon={faListOl} /> Ver 4 precios y stock</DropdownItem>        
        <DropdownItem onClick={ () => gestionarVer4Precios_y_OtrasBodegas_ModificarStock("OB")}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faWarehouse} /> Ver en otras bodegas</DropdownItem>        
        <DropdownItem onClick={ () => alert('Copiar principal.. ' + row.IDr )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faBarcode} /> Copiar principal</DropdownItem>        
        <DropdownItem onClick={ () => alert('Clonar.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faClone} />  Clonar</DropdownItem>        
        <DropdownItem onClick={ () => alert('Duplicar.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faCamera} />  Fotos</DropdownItem>        

        <DropdownItem divider />
        <DropdownItem onClick={ () => alert('Ver record ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faRegistered}  /> Record</DropdownItem>        
        <DropdownItem onClick={ () => alert('Proveedores ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faTruck}  /> Proveedores</DropdownItem>        
        <DropdownItem onClick={ () => alert('Bloc.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faBookOpen} /> Bloc de notas</DropdownItem>        
        <DropdownItem divider />
        <DropdownItem><FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>

      </Dropdown>
    </div>,
  },    
  {
    name:'STOCK',
    selector:row=>row.Adef_Stock, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
  },    
  {
    name:'MINIMO',
    selector:row=>row.Adef_StockMin, 
    sortable:true,
    compact:true,
    center:true,
    //grow:0.8,
    //hide:'sm', //se oculta en pantallas pequenas por debajo de 600px de ancho
    omit:true,
  },    
  {
    name:'P1',
    selector:row=>row.Adef_P1, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    omit: !props.verPvpSinIva
  }, 
  {
    name:'P1iva',
    selector:row=>row.Adef_P1mi, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    omit: !props.verPvpConIva
  }, 
  //LOS DEMAS PRECIOS, no estan puestos en la tabela, pero siempre estan disponibles
  {
    name:'CLASE',
    selector:row=>row.Clase, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    hide:'sm',      
  },      
  {
    name:'UBICACION',
    selector:row=>row.Adef_Ubicacion, 
    sortable:true,
    compact:true,
    grow:0.8,
    hide:'sm',    
  }, 
  {
    name:'ACTIVO',
    selector:row=>row.Adef_Activo, 
    sortable:true,
    compact:true,
    center:true,
    omit:true, //activo, siempre la quiero invisible
  },    
]

const incluirModificarJsonInventario=(jsonRegistro)=>{
  //recibo los PVP sin iva y aqui debo calcular con iva (ojo debe quedar con 4 decimales y en modo texto)
  let miTasaIvaDef=(jsonRegistro.TipoIvaVenta=="0") ? "0" : valueDC.iva.Porcentaje
  miTasaIvaDef=parseFloat(miTasaIvaDef) //lo convierto en numero decimal

  let Adef_P1mi=(parseFloat(jsonRegistro.Adef_P1) + parseFloat(jsonRegistro.Adef_P1) * miTasaIvaDef/100).toFixed(4) //toFixed redondea y convierte a texto
  let Adef_P2mi=(parseFloat(jsonRegistro.Adef_P2) + parseFloat(jsonRegistro.Adef_P2) * miTasaIvaDef/100).toFixed(4)
  let Adef_P3mi=(parseFloat(jsonRegistro.Adef_P3) + parseFloat(jsonRegistro.Adef_P3) * miTasaIvaDef/100).toFixed(4)
  let Adef_P4mi=(parseFloat(jsonRegistro.Adef_P4) + parseFloat(jsonRegistro.Adef_P4) * miTasaIvaDef/100).toFixed(4)

  //NOTA: la fechaVence puede ser NULL o un texto de 10 caracteres yyyy-mm-dd
  let itemLoco={
  "IDr":jsonRegistro.IDr,
  "CodPrincipal":jsonRegistro.CodPrincipal,
  "Descripcion":jsonRegistro.Descripcion,
  "TipoIvaVenta":jsonRegistro.TipoIvaVenta,
  "CostoP":jsonRegistro.CostoP,
  "ParaCompra":jsonRegistro.ParaCompra,
  "ParaVenta":jsonRegistro.ParaVenta,
  "Clase":jsonRegistro.Clase,
  "VenderSinStock":jsonRegistro.VenderSinStock,
  "VenderPorDebajoCostoP":jsonRegistro.VenderPorDebajoCostoP,
  "Descontable":jsonRegistro.Descontable,
  "Adef_Stock":jsonRegistro.Adef_Stock,
  "Adef_StockMin":jsonRegistro.Adef_StockMin,
  "Adef_P1":jsonRegistro.Adef_P1,
  "Adef_P1mi":Adef_P1mi,
  "Adef_P2":jsonRegistro.Adef_P2,
  "Adef_P2mi":Adef_P2mi,
  "Adef_P3":jsonRegistro.Adef_P3,
  "Adef_P3mi":Adef_P3mi,  
  "Adef_P4":jsonRegistro.Adef_P4,
  "Adef_P4mi":Adef_P4mi,  
  "Adef_Ubicacion":jsonRegistro.Adef_Ubicacion, 
  "Adef_FechaVence":jsonRegistro.Adef_FechaVence,
  "Adef_Activo":jsonRegistro.Adef_Activo,
  "CodSecundario":jsonRegistro.CodSecundario,  
  "CodAuxiliar":jsonRegistro.CodAuxiliar,  
  "CodCat":jsonRegistro.CodCat,
  "CodMarca":jsonRegistro.CodMarca,
  "VisibleSN":opcionRegistro_imc=="i" ? 'S' : rowRegistroClon.VisibleSN,
  "BlockDeNotas":opcionRegistro_imc=="i" ? '' : rowRegistroClon.BlockDeNotas,    
  //"OpCrea":opcionRegistro_imc=="i" ? valueDC.usuario.LoginUsu : rowRegistroClon.OpCrea,    
  }

  //******  cuando es modificar *************
  if (opcionRegistro_imc=='m'){
    let datosFullCopiaMod=registrosFullState.map(item=>item.IDr==rowRegistroClon.IDr ? itemLoco : item )
    set_registrosFullState(datosFullCopiaMod)
  }
  //FALTA: ojo, aqui falta hacer algo para que se muestre el registro en cuestion, posiblemente quede en una tercera pagina y no se puede ver
  //FALTA: no se como hacer para que quede ordenado donde corresponde. Revisar esta parte de DONDE INSERTARLO
  //******  cuando es nuevo (version funciona OK )*************  
  if (opcionRegistro_imc=='i'){
    let datosFullMasInsert=[...registrosFullState,itemLoco]
    set_registrosFullState(datosFullMasInsert)   
  }

  //debo simular un clic sobre el registro afectado.
  set_rowRegistroState(itemLoco)  
  rowRegistroClon=itemLoco  
}

const eliminarJsonRegistro=()=>{
  let datosFullCopia=registrosFullState.filter(item=>item.IDr!=rowRegistroClon.IDr)
  //actualizo el estado para la tabela, y quito la franja azul
  set_registrosFullState(datosFullCopia)
  set_rowRegistroState(null)
  rowRegistroClon=null
}
  
const modificarStockEnTabela=(miCodRojo,miNuevoStock)=>{
  miNuevoStock=miNuevoStock.toFixed(2) //lo convierto en texto ya que todo el JSON es texto
  let datosFullCopia=registrosFullState.map(item=>{ 
    if (item.IDr==miCodRojo) 
      item.Adef_Stock=miNuevoStock  
    return item 
    })

  //actualizo el estado para la tabela
  set_registrosFullState(datosFullCopia)
}

const refrescarData=async()=>{   
  let data=new FormData()
  data.append('miSol','inv_buscarTodosPocasColumnasAlmacenDef')
  data.append('miAlmacen',valueDC.estable.Almacen)
  data.append('deseoBlockSN',deseoBlockSN)
  data.append('deseoOpSN',deseoOpSN) 
  data.append('orderBy','Descripcion')
  data.append('forma','Asc')

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullState(dataApi)   
}

const hayFilaSeleccionada=()=>{
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un Producto")          
    miDevolver=false
  }
  return miDevolver
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //=== blanqueo estas variables 
  rowRegistroClon=null 
  miProdSeleccionado=null
  codigoCandidato=''
  refrescarData()

  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    //variables normales
    rowRegistroClon=null 
    opcionRegistro_imc=''
    miProdSeleccionado=null
    codigoCandidato=''
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }    
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al llamador
useEffect(()=>{  
  if (registrosFullState==null){    
    props.activarMenu() 
  }
  },[registrosFullState]
)

const filtrarElementos=(cadenaLoca)=>{
  //ojo: obligatorio convertirla en minuscula para hacer la busqueda
  cadenaLoca=cadenaLoca.toLocaleLowerCase()

  let filtradosTmp=registrosFullState.map(item=>{
    if (
      item.CodPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Descripcion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Clase.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Adef_Ubicacion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.CodSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.CodAuxiliar.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.BlockDeNotas.toString().toLocaleLowerCase().includes(cadenaLoca)
      )  
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item
    })
  set_registrosFullState(filtradosTmp)
  set_idMasState(0)
  rowRegistroClon=null    
} 
 
const eliminarRegistroEnBDD=async()=>{
  let data=new FormData()
  data.append('miSol','inv_eliminar')
	data.append('IDr',rowRegistroClon.IDr)

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)  
  return dataApi //dominio: null,-100,numero positivo
}

const gestionarVer4Precios_y_OtrasBodegas_ModificarStock=async(queDeseo)=>{
  //queDeseo puede ser: 4P=>para ver los 4 precios de un producto. OB=>ver los precios y el stock del producto en otras bodegas. MS=>modificar stock
  let miResp=await buscarUnProductoPorCodRojoConSwal(rowRegistroClon.IDr,valueDC) //me tiene que devuelver un JSON
  if (!miResp.AgrModConExito) return //no muestro ningun mensaje ya que la funcion buscarUnProductoPorCodRojoConSwal lo muestra

  //reviso que realmente haya UN registro. Alomejor alguien lo ha borrado desde otra PC
  if (miResp.Data.length==0){
    await mostrarSwalBotonAceptar("danger","PRODUCTO NO EXISTE","El producto ya no existe")
    return
  }
  //copio en una variable, solamente el registro en cuestion, que viene desde la BDD
  miProdSeleccionado=miResp.Data[0]
  //Llamo al modal correspondiente
  if (queDeseo=="4P") {
    await sleepPepe(200) //para que no me aparezca la franja verde en el lado derecho
    set_modalVer4PreciosState(true)
  }
  if (queDeseo=="OB"){
    await sleepPepe(200) //para que no me aparezca la franja verde en el lado derecho
    set_modalVerOtrasBodegasState(true)
  }
  if (queDeseo=="MS") {
    await sleepPepe(200) //para que no me aparezca la franja verde en el lado derecho
    set_modalModificarStockState(true)
  }
}

const ComponentePrincipalBody=()=>{  
return(
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 
  <div id="divSubMaster" name="divSubMaster">
  {/******* Boton superior de OPCIONES (utilidades) ************/}
   <Row style={{margin:'0',paddingLeft:'0px'}} >     
    <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidadesState }  size="md"
      toggle= { ()=>set_dropUtilidadesState(!dropUtilidadesState)}>
      <DropdownToggle caret color="primary">
        Opciones
      </DropdownToggle>
  
      <DropdownMenu style={{backgroundColor:'cyan',}} >
        <DropdownItem onClick={ () => {
            alert(JSON.stringify(rowRegistroState,null,2))
            alert(JSON.stringify(rowRegistroClon,null,2))
          }}
          ><FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de la fila seleccionada
        </DropdownItem>  

        <DropdownItem onClick={ () => alert('prueba')}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faGlasses} /> Copiar codigo principal a secundario</DropdownItem>        
        <DropdownItem divider />
        <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Calcular PVP a partir del costo</DropdownItem>                
        <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Cambiar precios</DropdownItem>                
        <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Redondear precios</DropdownItem>                
        <DropdownItem divider />
        <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Reportes</DropdownItem>                
        <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Exportar</DropdownItem>                
        <DropdownItem divider />
        <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Cargos</DropdownItem>                
        <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Descargos</DropdownItem>                
        <DropdownItem divider />
        <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Kardex</DropdownItem>                
        <DropdownItem divider />
        <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>
    </Dropdown>
   </Row>

   {/******* Menu superior, para telefono vertical: solo 4 botones ************  */}
   <div id="divMenuSuperior" name="divMenuSuperior"> 
    <Row style={{margin:'0px',}} >
      <Col>
        <ButtonGroup> 
          <Button id="btnNuevo" name="btnNuevo" className="btnPpal btnBordebtnPpal"  
            onClick = { () => { 
              opcionRegistro_imc='i' //'i'ncluir nuevo registro
              set_idMasState(0)
              set_rowRegistroState(null)
              rowRegistroClon=null
              set_modalIncModRegistroState(true)
            }}>
            <span style ={{ fontSize:"35px"}}>
              <FontAwesomeIcon color="gainsboro" icon={faHandHoldingMedical} /> 
            </span>
            <span><br/>Nuevo</span>
          </Button>
          <Button id="btnRecord" name="btnRecord" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
            onClick = { () => { alert('record') } } >  
            <span style ={{ fontSize:"35px"}}>
              <FontAwesomeIcon color="gainsboro" icon={faRegistered} />
            </span>
            <span><br/>Record</span>
          </Button>
          <Button id="btnStock" name="btnStock" className="btnPpal btnBordebtnPpal"  
            onClick = { async() =>{ 
              if (!hayFilaSeleccionada()) return
              set_idMasState(0)
              gestionarVer4Precios_y_OtrasBodegas_ModificarStock("MS") //Modificar Stock
            }} >  
            <span style ={{ fontSize:"35px"}}>
              <FontAwesomeIcon color="gainsboro" icon={faLayerGroup} />
            </span>
            <span><br/>Stock</span>
          </Button>
          <Button id="btnProveedores" name="btnProveedores" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
            onClick = { () => { 
              //alert('ver proveedores') 
              //alert(JSON.stringify(this.state.datosFiltrados))            
              }} >  
            <span style ={{ fontSize:"35px"}}>
              <FontAwesomeIcon color="gainsboro" icon={faTruck} />
            </span>
            <span><br/>Prov.</span>
          </Button>
          <Button id="btnBloc" name="btnBloc" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
            onClick = { () => {
              //this.setState({estatusAbiertoModal_EncriptarEnJS : true} )
              }}>  
            <span style ={{ fontSize:"35px"}}>
              <FontAwesomeIcon color="gainsboro" icon={faBookOpen} />
            </span>
            <span><br/>Bloc</span>
          </Button>        
          <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
            onClick={async()=>{
              set_textoBuscarState('')
              //pongo a 0 los codigos del estado para que se quite la franja AZUL
              set_rowRegistroState(null)
              rowRegistroClon=null
              set_idMasState(0)
              refrescarData()              
            }} >  
            <span style ={{ fontSize:"35px"}}>
            <FontAwesomeIcon color="gainsboro" icon={faSyncAlt} />
            </span>
            <span><br/>Refresh</span>
          </Button>
          <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"  
            onClick = { () => { 
              props.activarMenu()
              }} >  
            <span style ={{ fontSize:"35px"}}>
            <FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /> 
            </span>
            <span><br/>Salir</span>          
          </Button>
        </ButtonGroup>          
      </Col>
    </Row>
   </div> {/* divMenuSuperior */}

   {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
   <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
        <Input
          className = "textoBusquedaClientes" 
          type="text" placeholder="Texto de búsqueda" id="inputBusqueda" name="inputBusqueda"
          value={textoBuscarState}
          onChange={()=>{
            set_textoBuscarState( document.getElementById("inputBusqueda").value )
            filtrarElementos( document.getElementById("inputBusqueda").value.trim() )
          }}/>
        <Button id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
          onClick={()=>{
            set_textoBuscarState('')
            set_rowRegistroState(null)
            rowRegistroClon=null
            set_idMasState(0)          
            filtrarElementos('') 
          }}> 
        <FontAwesomeIcon color="paleGreen" icon={faBroom}/> Limpiar
        </Button>        
   </div> { /* divBuscador1 */ }

  </div> { /* divSubMaster */ }

  { /* inicio del DIV para la tabela */ }
  <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable  
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title={'lista de inventario'}
    columns={miEstructura}
    data={registrosFullState?.filter(item=>item.VisibleSN=='S')}
    conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)}  
    keyField ='IDr' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
    onRowClicked={(row) => { 
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_idMasState(0)      
    }}
    //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
    highlightOnHover  
    //pointerOnHover='true'
    //el noDataComponent, puede ser un string o un componente
    //    <h1><span>Nada por aquí</span></h1>
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //onRowClicked={this.miOnRowClicked()} //callback to access the row, even on row click (no pude hacerle funcionar)
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras

    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    paginationPerPage={ valueDC.sistema.paginadoParaInventario } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
  </div> { /* divTabela */ }

    {/* ************ MODAL PARA AGREGAR/MODIFICAR/CONSULTAR INVENTARIO **********/}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalIncModRegistroState } >
      <IncModInventario 
         ocultarModal_Inv={(accion,jsonRegistro)=>{
          set_modalIncModRegistroState(false)
          //accion puede ser: close/save/notfound
          if (accion=='close') return
          //al regresar del modal, no es necesario refrescar leyendo de nuevo la BDD, lo que hago es modificar el JSON segun lo sucedido          
          if (accion=='save') incluirModificarJsonInventario(jsonRegistro)
          //el notfound, significa que NO se encontró al registro que se deseaba editar/consultar (por lo tanto debo eliminarlo de la tabela)
          if (accion=='notfound') eliminarJsonRegistro()
        }}
        opcionRegistro_imc = {opcionRegistro_imc}
        registroIDr = {(opcionRegistro_imc=='i') ? 0 : ((rowRegistroClon==null) ? 0: rowRegistroClon.IDr)} //es util solamente cuando deseo editar o consultar el registro (cuando es NUEVO se va con 0)
        codigoCandidato = {codigoCandidato} //solo tiene utilidad cuando se desea crear un producto desde FACTURACION, COMPRAS o similares
        registrosFull = {registrosFullState}
      />
    </Modal>

    {/* ************************* MODAL PARA VER 4 PRECIOS DE UN ALMACEN *************** */}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalVer4PreciosState } >
      <Ver4PreciosDeUnProducto
        cerrarModal={()=>set_modalVer4PreciosState(false)}
        miProd={miProdSeleccionado}
      />
    </Modal>

    {/* ************************* MODAL PARA MODIFICAR EL STOCK *************** */}
    <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalModificarStockState} >
      <ModificarStockInventario
         cerrarModal={(accion,miCodRojo,miNuevoStock)=>{
          set_modalModificarStockState(false)
          //accion puede ser: close/ok
          if (accion=='close') return
          //actualizo la tabela con el nuevo stock, y pongo una notificacion abajo a la derecha (OK)      
          modificarStockEnTabela(miCodRojo,miNuevoStock)
          mostrarSwalPos("OK",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)  
         }}
         valueDC={valueDC}
         miProd={miProdSeleccionado}
     />
    </Modal>

    {/* ************************* MODAL PARA VER 4 PRECIOS DE LOS 10 ALMACENES/OTRAS BODEGAS *************** */}
    <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={modalVerOtrasBodegasState} >
      <VerStockPreciosOtrosAlmacenes
        cerrarModal={()=> set_modalVerOtrasBodegasState(false)} 
        valueDC={valueDC}
        miProd={miProdSeleccionado}
      />
    </Modal>

</div> // divMaster
) //del return interno
}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} // del export

export default TabelaInventario