//me muestra una ventanita con los 4 precios de un producto. Pero en cada Almacen/Bodega. Maximo 10 bodegas
//se requiere como entrada en las props un JSON con todos los campos del producto. Siempre es UNO solo

import React from 'react'
import {Input, Label, Button, ButtonGroup, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose ,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
 
export default class VerStockPreciosOtrosAlmacenes extends React.Component {   
cantAlm=10 //Cantidad de almacenes  
miProd=null //aqui guardo el JSON del registro que viene en las props
numFloat=null //para varios usos, ej: guardar el precio CON iva de un producto, o el stock global
numTexto=null //numero tipo texto con , para decimal
miPorcIva=null //aqui guardo el porcentaje de IVA para este producto

cambiarPuntoPorComa=(miTexto)=>{
  miTexto=miTexto.replace(".",",")
  return miTexto
}

mostrarOcultarPrecios=()=>{
  let miCheck=document.getElementById("checkMostrarPrecios").checked
  let j=0 //para recorrer cada almacen (maximo 10)
  let i=0 //para recorrer los objetos  (10 objetos) dentro de cada almacen (max 10 almacenes)
  let misAlmacenesj=['btnAlm0','btnAlm1','btnAlm2','btnAlm3','btnAlm4','btnAlm5','btnAlm6','btnAlm7','btnAlm8','btnAlm9']  

  //muestro/oculto las cabeceras de PVP1,PVP2,PVP3,PVP4
  for (i=0;i<4;i++)
    document.getElementsByName("btnHeaderPX")[i].style.display= (miCheck) ? 'block' : 'none'
  
  //muestro/oculto los precios del Almacen 0 al 9. Por cada almacen hay diez objetos que se deben ocultar
  for (j=0;j<=9;j++) //de los almacenes
    for (i=0;i<10;i++) //de los  botones de cada almacen
      if ( (j+1) <=this.cantAlm)
        document.getElementsByName(misAlmacenesj[j])[i].style.display= (miCheck) ? 'block' : 'none'

  /*
  if (1 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm1")[i].style.display= (miCheck) ? 'block' : 'none'
  
  if (2 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm2")[i].style.display= (miCheck) ? 'block' : 'none'      

  if (3 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm3")[i].style.display= (miCheck) ? 'block' : 'none'          

  if (4 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm4")[i].style.display= (miCheck) ? 'block' : 'none'              

  if (5 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm5")[i].style.display= (miCheck) ? 'block' : 'none'              

  if (6 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm6")[i].style.display= (miCheck) ? 'block' : 'none'              

  if (7 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm7")[i].style.display= (miCheck) ? 'block' : 'none'              

  if (8 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm8")[i].style.display= (miCheck) ? 'block' : 'none'              

  if (9 <= this.cantAlm) for (i=0;i<10;i++)
    document.getElementsByName("btnAlm9")[i].style.display= (miCheck) ? 'block' : 'none'              
    */
}

ocultarFilas=()=>{
  //primero oculto todo
  document.getElementById("rowAlm1_fa").style.display='none'
  document.getElementById("rowAlm1_fb").style.display='none'
  document.getElementById("rowAlm2_fa").style.display='none'
  document.getElementById("rowAlm2_fb").style.display='none'
  document.getElementById("rowAlm3_fa").style.display='none'
  document.getElementById("rowAlm3_fb").style.display='none'
  document.getElementById("rowAlm4_fa").style.display='none'
  document.getElementById("rowAlm4_fb").style.display='none'
  document.getElementById("rowAlm5_fa").style.display='none'
  document.getElementById("rowAlm5_fb").style.display='none'
  document.getElementById("rowAlm6_fa").style.display='none'
  document.getElementById("rowAlm6_fb").style.display='none'
  document.getElementById("rowAlm7_fa").style.display='none'
  document.getElementById("rowAlm7_fb").style.display='none'
  document.getElementById("rowAlm8_fa").style.display='none'
  document.getElementById("rowAlm8_fb").style.display='none'
  document.getElementById("rowAlm9_fa").style.display='none'
  document.getElementById("rowAlm9_fb").style.display='none'  
}

calcularPrecioConIva=()=>{
  this.numFloat=this.numFloat + this.numFloat * this.miPorcIva/100
  this.numTexto=this.numFloat.toFixed(4)
  this.numTexto=this.cambiarPuntoPorComa(this.numTexto)
}

mostrarInfo=()=>{
//aqui realmente voy poniendo los datos en cada boton (no importa si el boton esta oculto)

//******** Almacen 0***************/
document.getElementById("btnAlm0_alias_fa").innerText='0 [Pincipal]' //nombre, alias del almacen
document.getElementById("btnAlm0_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A0_P1)
document.getElementById("btnAlm0_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A0_P2)
document.getElementById("btnAlm0_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A0_P3)
document.getElementById("btnAlm0_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A0_P4)
document.getElementById("btnAlm0_alias_fb").innerText= 'Stock ' + this.cambiarPuntoPorComa(this.miProd.A0_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A0_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm0_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A0_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm0_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A0_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm0_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A0_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm0_p4_fb").innerText=this.numTexto

//******** Almacen 1***************/
document.getElementById("btnAlm1_alias_fa").innerText='1 [Loja]' //nombre, alias del almacen
document.getElementById("btnAlm1_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A1_P1)
document.getElementById("btnAlm1_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A1_P2)
document.getElementById("btnAlm1_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A1_P3)
document.getElementById("btnAlm1_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A1_P4)
document.getElementById("btnAlm1_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A1_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A1_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm1_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A1_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm1_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A1_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm1_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A1_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm1_p4_fb").innerText=this.numTexto

//******** Almacen 2***************/
document.getElementById("btnAlm2_alias_fa").innerText='2 [Cuenca]' //nombre, alias del almacen
document.getElementById("btnAlm2_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A2_P1)
document.getElementById("btnAlm2_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A2_P2)
document.getElementById("btnAlm2_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A2_P3)
document.getElementById("btnAlm2_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A2_P4)
document.getElementById("btnAlm2_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A2_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A2_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm2_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A2_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm2_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A2_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm2_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A2_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm2_p4_fb").innerText=this.numTexto

//******** Almacen 3***************/
document.getElementById("btnAlm3_alias_fa").innerText='3 [Cariamangas]' //nombre, alias del almacen
document.getElementById("btnAlm3_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A3_P1)
document.getElementById("btnAlm3_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A3_P2)
document.getElementById("btnAlm3_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A3_P3)
document.getElementById("btnAlm3_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A3_P4)
document.getElementById("btnAlm3_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A3_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A3_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm3_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A3_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm3_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A3_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm3_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A3_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm3_p4_fb").innerText=this.numTexto

//******** Almacen 4***************/
document.getElementById("btnAlm4_alias_fa").innerText='4 [Quito]' //nombre, alias del almacen
document.getElementById("btnAlm4_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A4_P1)
document.getElementById("btnAlm4_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A4_P2)
document.getElementById("btnAlm4_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A4_P3)
document.getElementById("btnAlm4_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A4_P4)
document.getElementById("btnAlm4_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A4_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A4_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm4_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A4_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm4_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A4_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm4_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A4_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm4_p4_fb").innerText=this.numTexto

//******** Almacen 5***************/
document.getElementById("btnAlm5_alias_fa").innerText='5 [Esmeraldas..]' //nombre, alias del almacen
document.getElementById("btnAlm5_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A5_P1)
document.getElementById("btnAlm5_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A5_P2)
document.getElementById("btnAlm5_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A5_P3)
document.getElementById("btnAlm5_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A5_P4)
document.getElementById("btnAlm5_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A5_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A5_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm5_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A5_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm5_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A5_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm5_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A5_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm5_p4_fb").innerText=this.numTexto

//******** Almacen 6***************/
document.getElementById("btnAlm6_alias_fa").innerText='6 [Cajas]' //nombre, alias del almacen
document.getElementById("btnAlm6_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A6_P1)
document.getElementById("btnAlm6_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A6_P2)
document.getElementById("btnAlm6_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A6_P3)
document.getElementById("btnAlm6_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A6_P4)
document.getElementById("btnAlm6_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A6_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A6_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm6_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A6_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm6_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A6_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm6_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A6_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm6_p4_fb").innerText=this.numTexto

//******** Almacen 7***************/
document.getElementById("btnAlm7_alias_fa").innerText='7 [Zamora y Yantzatza]' //nombre, alias del almacen
document.getElementById("btnAlm7_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A7_P1)
document.getElementById("btnAlm7_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A7_P2)
document.getElementById("btnAlm7_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A7_P3)
document.getElementById("btnAlm7_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A7_P4)
document.getElementById("btnAlm7_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A7_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A7_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm7_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A7_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm7_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A7_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm7_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A7_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm7_p4_fb").innerText=this.numTexto

//******** Almacen 8***************/
document.getElementById("btnAlm8_alias_fa").innerText='8 [Carchi]' //nombre, alias del almacen
document.getElementById("btnAlm8_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A8_P1)
document.getElementById("btnAlm8_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A8_P2)
document.getElementById("btnAlm8_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A8_P3)
document.getElementById("btnAlm8_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A8_P4)
document.getElementById("btnAlm8_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A8_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A8_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm8_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A8_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm8_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A8_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm8_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A8_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm8_p4_fb").innerText=this.numTexto

//******** Almacen 9***************/
document.getElementById("btnAlm9_alias_fa").innerText='9 [Quicentro]' //nombre, alias del almacen
document.getElementById("btnAlm9_p1_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A9_P1)
document.getElementById("btnAlm9_p2_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A9_P2)
document.getElementById("btnAlm9_p3_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A9_P3)
document.getElementById("btnAlm9_p4_fa").innerText=this.cambiarPuntoPorComa(this.miProd.A9_P4)
document.getElementById("btnAlm9_alias_fb").innerText='Stock ' + this.cambiarPuntoPorComa(this.miProd.A9_Stock) //stock del almacen
this.numFloat=parseFloat(this.miProd.A9_P1)
this.calcularPrecioConIva()
document.getElementById("btnAlm9_p1_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A9_P2)
this.calcularPrecioConIva()
document.getElementById("btnAlm9_p2_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A9_P3)
this.calcularPrecioConIva()
document.getElementById("btnAlm9_p3_fb").innerText=this.numTexto
this.numFloat=parseFloat(this.miProd.A9_P4)
this.calcularPrecioConIva()
document.getElementById("btnAlm9_p4_fb").innerText=this.numTexto

/****************STOCK GLOBAL***************/
this.numFloat=parseFloat(this.miProd.A0_Stock) + parseFloat(this.miProd.A1_Stock) + parseFloat(this.miProd.A2_Stock) + parseFloat(this.miProd.A3_Stock) + parseFloat(this.miProd.A4_Stock) + parseFloat(this.miProd.A5_Stock) + parseFloat(this.miProd.A6_Stock) + parseFloat(this.miProd.A7_Stock) + parseFloat(this.miProd.A8_Stock) + parseFloat(this.miProd.A9_Stock)
this.numTexto=this.numFloat.toFixed(2)
document.getElementById("btnStockGlobal").innerText= "Stock Global "  + this.cambiarPuntoPorComa(this.numTexto)
}

componentDidMount(){  
  //prendo el check de ver precios
  document.getElementById("checkMostrarPrecios").setAttribute("checked","true")

  this.miProd=this.props.miProd //copio el JSON de producto en una variable de este componente
  this.miPorcIva=(this.miProd.TipoIvaVenta=='0') ? 0 : parseFloat(this.props.valueDC.iva.Porcentaje) //guardo el % de iva que le toca a este producto

  //alert(JSON.stringify(this.miProd))
  //primero oculto todo
  this.ocultarFilas()

  //empiezo a prender solo algunas filas (dependiendo del numero de bodegas)
  if (2 <= this.cantAlm) {
    document.getElementById("rowAlm1_fa").style.display='block'
    document.getElementById("rowAlm1_fb").style.display='block'
  }
  if (3 <= this.cantAlm) {
    document.getElementById("rowAlm2_fa").style.display='block'
    document.getElementById("rowAlm2_fb").style.display='block'
  }
  if (4 <= this.cantAlm) {
    document.getElementById("rowAlm3_fa").style.display='block'
    document.getElementById("rowAlm3_fb").style.display='block'
  }
  if (5 <= this.cantAlm) {
    document.getElementById("rowAlm4_fa").style.display='block'
    document.getElementById("rowAlm4_fb").style.display='block'
  }
  if (6 <= this.cantAlm) {
    document.getElementById("rowAlm5_fa").style.display='block'
    document.getElementById("rowAlm5_fb").style.display='block'
  }
  if (7 <= this.cantAlm) {
    document.getElementById("rowAlm6_fa").style.display='block'
    document.getElementById("rowAlm6_fb").style.display='block'
  }
  if (8 <= this.cantAlm) {
    document.getElementById("rowAlm7_fa").style.display='block'
    document.getElementById("rowAlm7_fb").style.display='block'
  }
  if (9 <= this.cantAlm) {
    document.getElementById("rowAlm8_fa").style.display='block'
    document.getElementById("rowAlm8_fb").style.display='block'
  }
  if (10 <= this.cantAlm) {
    document.getElementById("rowAlm9_fa").style.display='block'
    document.getElementById("rowAlm9_fb").style.display='block'
  }  
  this.mostrarInfo()
}

render(){    
let descripcion40Letras=this.props.miProd.Descripcion.substring(0,38)
let miColorHeader='success'
let miColorPar='info'
let miColorImpar='secondary'

return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    

{/* Nombre del producto + Boton Cerrar*/}
<Row style={{margin:'0',}}>
  <Col xs="10" >
    <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{descripcion40Letras}</Label>
  </Col>
  <Col xs="2" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ this.props.cerrarModal() }}>
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
  </Col>
</Row>            

{/* Check para mostrar/ocultar precios */}
<div style={{marginLeft:"3%",marginRight:"3%",marginTop:"0%",marginBottom:'0%',padding:'2%', color:'purple', fontSize:'18px'}}>    
  <Row>
    <Col xs="12">
      <Label for="checkMostrarPrecios">
      <Input id="checkMostrarPrecios" name="checkMostrarPrecios" type="checkbox"  
        onClick= { () =>{ this.mostrarOcultarPrecios() }}>
      </Input>
      { } Mostrar Precios
      </Label>
    </Col>
  </Row>
</div>

<div style={{marginLeft:"3%",marginRight:"3%",marginTop:"0%",marginBottom:'3%',padding:'2%',background:'purple', color:'pink'}}>    
{/* Cabecera de la aparente Tablita */}
  <Row> 
   <ButtonGroup>
    <Button style={{width:'32%', height:'40px',border:'white 1px solid',textAlign:'left'}} id="btnHeaderAlm" name="btnHeaderAlm" color={miColorHeader}>BODEGA</Button> 
    <Button style={{width:'17%', height:'40px',border:'white 1px solid'}} id="btnHeaderP1" name="btnHeaderPX" color={miColorHeader} >PVP1</Button> 
    <Button style={{width:'17%', height:'40px',border:'white 1px solid'}} id="btnHeaderP2" name="btnHeaderPX" color={miColorHeader} >PVP2</Button> 
    <Button style={{width:'17%', height:'40px',border:'white 1px solid'}} id="btnHeaderP3" name="btnHeaderPX" color={miColorHeader} >PVP3</Button> 
    <Button style={{width:'17%', height:'40px',border:'white 1px solid'}} id="btnHeaderP4" name="btnHeaderPX" color={miColorHeader} >PVP4</Button> 
   </ButtonGroup> 
  </Row>  
{/* Cada almacen, va a ocupar dos filas _fa y _fa */}
{/***************************Almacen 0  *****************************/}
<Row><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm0_alias_fa" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm0" color={miColorPar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm0_p1_fa" name="btnAlm0" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm0_p2_fa" name="btnAlm0" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm0_p3_fa" name="btnAlm0" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm0_p4_fa" name="btnAlm0" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
<Row><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm0_alias_fb" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm0" color={miColorPar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm0_p1_fb" name="btnAlm0" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm0_p2_fb" name="btnAlm0" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm0_p3_fb" name="btnAlm0" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm0_p4_fb" name="btnAlm0" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 1  *****************************/}
<Row id="rowAlm1_fa"><ButtonGroup> 
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm1_alias_fa" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm1" color={miColorImpar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm1_p1_fa" name="btnAlm1" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm1_p2_fa" name="btnAlm1" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm1_p3_fa" name="btnAlm1" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm1_p4_fa" name="btnAlm1" color={miColorImpar} ></Button> 
</ButtonGroup></Row>    
<Row id="rowAlm1_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm1_alias_fb" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm1" color={miColorImpar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm1_p1_fb" name="btnAlm1" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm1_p2_fb" name="btnAlm1" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm1_p3_fb" name="btnAlm1" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm1_p4_fb" name="btnAlm1" color={miColorImpar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 2  *****************************/}
<Row id="rowAlm2_fa"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm2_alias_fa" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm2" color={miColorPar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm2_p1_fa" name="btnAlm2" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm2_p2_fa" name="btnAlm2" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm2_p3_fa" name="btnAlm2" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm2_p4_fa" name="btnAlm2" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
<Row id="rowAlm2_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm2_alias_fb" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm2" color={miColorPar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm2_p1_fb" name="btnAlm2" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm2_p2_fb" name="btnAlm2" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm2_p3_fb" name="btnAlm2" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm2_p4_fb" name="btnAlm2" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 3  *****************************/}
<Row id="rowAlm3_fa"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm3_alias_fa" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm3" color={miColorImpar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm3_p1_fa" name="btnAlm3" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm3_p2_fa" name="btnAlm3" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm3_p3_fa" name="btnAlm3" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm3_p4_fa" name="btnAlm3" color={miColorImpar} ></Button> 
</ButtonGroup></Row>  
<Row id="rowAlm3_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm3_alias_fb" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm3" color={miColorImpar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm3_p1_fb" name="btnAlm3" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm3_p2_fb" name="btnAlm3" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm3_p3_fb" name="btnAlm3" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm3_p4_fb" name="btnAlm3" color={miColorImpar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 4  *****************************/}
<Row id="rowAlm4_fa"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm4_alias_fa" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm4" color={miColorPar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm4_p1_fa" name="btnAlm4" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm4_p2_fa" name="btnAlm4" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm4_p3_fa" name="btnAlm4" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm4_p4_fa" name="btnAlm4" color={miColorPar} ></Button> 
</ButtonGroup></Row>    
<Row id="rowAlm4_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm4_alias_fb" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm4" color={miColorPar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm4_p1_fb" name="btnAlm4" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm4_p2_fb" name="btnAlm4" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm4_p3_fb" name="btnAlm4" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm4_p4_fb" name="btnAlm4" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 5  *****************************/}
<Row id="rowAlm5_fa"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm5_alias_fa" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm5" color={miColorImpar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm5_p1_fa" name="btnAlm5" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm5_p2_fa" name="btnAlm5" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm5_p3_fa" name="btnAlm5" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm5_p4_fa" name="btnAlm5" color={miColorImpar} ></Button> 
</ButtonGroup></Row>  
<Row id="rowAlm5_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm5_alias_fb" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm5" color={miColorImpar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm5_p1_fb" name="btnAlm5" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm5_p2_fb" name="btnAlm5" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm5_p3_fb" name="btnAlm5" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm5_p4_fb" name="btnAlm5" color={miColorImpar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 6  *****************************/}
<Row id="rowAlm6_fa"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm6_alias_fa" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm6" color={miColorPar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm6_p1_fa" name="btnAlm6" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm6_p2_fa" name="btnAlm6" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm6_p3_fa" name="btnAlm6" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm6_p4_fa" name="btnAlm6" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
<Row id="rowAlm6_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm6_alias_fb" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm6" color={miColorPar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm6_p1_fb" name="btnAlm6" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm6_p2_fb" name="btnAlm6" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm6_p3_fb" name="btnAlm6" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm6_p4_fb" name="btnAlm6" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 7  *****************************/}
<Row id="rowAlm7_fa"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm7_alias_fa" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm7" color={miColorImpar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm7_p1_fa" name="btnAlm7" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm7_p2_fa" name="btnAlm7" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm7_p3_fa" name="btnAlm7" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm7_p4_fa" name="btnAlm7" color={miColorImpar} ></Button> 
</ButtonGroup></Row>    
<Row id="rowAlm7_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm7_alias_fb" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm7" color={miColorImpar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm7_p1_fb" name="btnAlm7" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm7_p2_fb" name="btnAlm7" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm7_p3_fb" name="btnAlm7" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm7_p4_fb" name="btnAlm7" color={miColorImpar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 8  *****************************/}
<Row id="rowAlm8_fa"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm8_alias_fa" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm8" color={miColorPar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm8_p1_fa" name="btnAlm8" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm8_p2_fa" name="btnAlm8" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm8_p3_fa" name="btnAlm8" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm8_p4_fa" name="btnAlm8" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
<Row id="rowAlm8_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm8_alias_fb" color={miColorPar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm8" color={miColorPar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm8_p1_fb" name="btnAlm8" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm8_p2_fb" name="btnAlm8" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm8_p3_fb" name="btnAlm8" color={miColorPar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm8_p4_fb" name="btnAlm8" color={miColorPar} ></Button> 
</ButtonGroup></Row>  
{/***************************Almacen 9  *****************************/}
<Row id="rowAlm9_fa"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm9_alias_fa" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm9" color={miColorImpar} >S/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm9_p1_fa" name="btnAlm9" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm9_p2_fa" name="btnAlm9" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm9_p3_fa" name="btnAlm9" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm9_p4_fa" name="btnAlm9" color={miColorImpar} ></Button> 
</ButtonGroup></Row>  
<Row id="rowAlm9_fb"><ButtonGroup>
  <Button disabled style={{width:'22%', height:'40px',textAlign:'left'}} id="btnAlm9_alias_fb" color={miColorImpar} ></Button>     
  <Button disabled style={{width:'10%', height:'40px',}} name="btnAlm9" color={miColorImpar} >C/Iva</Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm9_p1_fb" name="btnAlm9" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm9_p2_fb" name="btnAlm9" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm9_p3_fb" name="btnAlm9" color={miColorImpar} ></Button> 
  <Button disabled style={{width:'17%', height:'40px',}} id="btnAlm9_p4_fb" name="btnAlm9" color={miColorImpar} ></Button> 
</ButtonGroup></Row>  

{/***************************STOCK GLOBAL  *****************************/}
<br></br>
<Row><ButtonGroup>
  <Button style={{width:'30%', height:'40px',}} id="btnStockGlobal" color='success' ></Button>     
</ButtonGroup></Row>  

</div>  
</div> // div del return
)}
}