//NOTA: no siempre el estado se actualiza rapido. (por ello debo usar la variable rowRegistroClon y tener una copia del registro donde se hizo clic)
//voy a usar la palabra REGISTRO para no usar la palabra PROVEEDOR (la idea es copiarme el formulario para bancos,unidades,marcas .... y me funcione con pocos cambios)
//alert (JSON.stringify(itemLoco,null,2))

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,ButtonGroup, Input,} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, { sleepPepe,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal,ejecutarFetchGenericoConSwal } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet } from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
import IncModProveedor from './IncModProveedor'
import FormularioCorreo from './FormularioCorreo'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faGlasses,faRegistered,faRunning,faCamera,faDoorOpen,faBroom,faBookReader,faBookOpen, faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed, faSearchDollar } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let correoEnviadoCorrectamenteSN=null //para saber si se pudo enviar el correo
let opcionRegistro_imc='' //para saber si deseo incluir nuevo registro, modificar o consultar
let cedulaCandidata='' //numero de cedula cuando deseo agregar un proveedor desde compras (desde tabela no se usa)
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let deseoBlockSN="S" //para saber si deseo traerme el campo BlockDeNotas de proveedores (generalmente S)
let deseoOpSN="N" //para saber si deseo traerme el campo OpCrea de proveedores (generalmente N)

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada  
  {
    when: row => (row.IDr != filaClonada?.IDr ),
    style: row => ({ 
      backgroundColor:row.Activo=="Si"  ? 'white' : valueDC.sistema.coloresItemInactivo,
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (row.IDr == filaClonada?.IDr ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

const miEstilacho = {
  table: {
    style: {
      minHeight: '45vh',      
    },
  },    
  //el header, se refiere al titulo que dice 'LISTA DE PROVEEDORES'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
    },
  },  
  headCells: {
    style: {
      //paddingLeft: '50px', //estaba '50px', no me funciona, override the cell padding for head cells
      //paddingRight: '8px', //estaba '8px'
      background: 'hotpink', //ok hotpink
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    }, 
  },  
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',       
    }
  },    
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',            
    },
  },
}

const TabelaProveedores=(props)=>{
const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
const [textoBuscarState,set_textoBuscarState]=useState('') //para el texto que va en el cuadrito de buscar  
const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
const [modalIncModRegistroState,set_modalIncModRegistroState]=useState(false) //para abrir el modal de incluir/modificar un registro        
const [modalCorreoState,set_modalCorreoState]=useState(false) //para abrir el modal de enviar correo

const miEstructuraDataTable=[  
  {  
      name:'id',
      selector:row => row.IDr,
      sortable:true,
      center:true,
      grow:0.2,
      compact:true, //padding 0
      //width:'50%',
      omit:true, //oculta (siempre) la celda
  },
  {
      name:'PRINCIPAL',
      selector:row => row.IdPrincipal,
      sortable:true,
      compact:true,
      grow:1, //es una medida relativa (1 es mi referencia)
      //cell:(row,index,column) =>  <h2> {row.titlezz} </h2>
      //style: {backgroundColor:'cyan'},
  },
  {
      name:'NOMBRE',
      selector:row => row.RazonSocial,
      sortable:true,
      compact:true,
      grow:1.9,
  },       
  {
    //ACCIONES
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1.6, //originalmente 1.6    

    //esto funciona fino
    //cell: (row) => <div><div style={{ fontWeight: 'bold' }}>{row.IDr}</div>{row.IdPrincipal}</div>,
    //esto funciona fino
    //cell: (row) => <div style={{ fontWeight: 900 }}>{row.IDr}</div>,
    //<div style={{ display:'flex' }}>            
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton Editar y Eliminar */}
    <Button id="btnEditar" name="btnEditar" style={{ marginRight:'1px',}} raised = "false" primary='false' color="info" 
      onClick={ async (e) => { 
        if (await hayInternet()==false) return
        opcionRegistro_imc='m' //'m'odificar registro
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_modalIncModRegistroState(true)
      }}>
      <FontAwesomeIcon size='lg' color="white" icon={faPencilAlt}/>
    </Button>
    <Button id="btnEliminar" name="btnEliminar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      onClick={ async (e) => {              
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        const miRegistroEnviar="Seguro desea eliminar al proveedor: \n" + rowRegistroClon.RazonSocial
        const miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miRegistroEnviar)
        if (miResp.isDismissed) return //alomejor se arrepintio

        let definitivo=await eliminarRegistroEnBDD() //En definitivo puedo recibir: null,-100,numero positivo (osea el mismo enviado)
        if (definitivo==null) return
        if (definitivo<=0){
          await mostrarSwalBotonAceptar("warning","NO SE PUDO ELIMINAR","El proveedor tiene transacciones asociadas")
          return 
        }
        //pongo una notificacion abajo a la derecha
        mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
        eliminarJsonRegistro(rowRegistroClon.IDr) //elimino del json y refresco la tabela
      }}>
      <FontAwesomeIcon size='lg' color="coral" icon={faTrashAlt} />
    </Button>
          
    {/* dropdown para el boton MAS... */}
    {/* al hacer clic por segunda vez consecutiva se debe apagar el desplegable */}

    <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.IDr } direction="right"  
      toggle= { ()=> {
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.IDr : 0)
        }}>    
      <DropdownToggle color="info">
        <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
      </DropdownToggle>

      <DropdownMenu style={{backgroundColor:'pink',}} >
        <DropdownItem onClick={ async() => { 
          if (!hayFilaSeleccionada()) return
          if (await hayInternet()==false) return
          opcionRegistro_imc='c' //'c'onsultar registro
          set_idMasState(0)
          set_modalIncModRegistroState(true)
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faEye} /> Consultar</DropdownItem>        
        <DropdownItem onClick={ () => alert('Copiar.. ' + row.IDr )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Copiar principal</DropdownItem>        
        <DropdownItem onClick={ () => alert('fotos ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faCamera} />  Fotos</DropdownItem>              
        <DropdownItem divider />
        <DropdownItem onClick={ () => {
          if (!hayFilaSeleccionada()) return
          if (rowRegistroClon.Email.trim().length==0){
            mostrarSwalBotonAceptar("warning","NO SE PUEDE ENVIAR","El proveedor NO tiene dirección de correo")
            return
          }
          set_idMasState(0)
          set_modalCorreoState(true)
          }}>          
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faEnvelope} /> Enviar email</DropdownItem>        
        <DropdownItem onClick={ () => alert('Previa.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faCommentDollar} />  Previa</DropdownItem>        
        <DropdownItem onClick={ () => alert('Block de Notas.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faBookOpen} /> Bloc de notas</DropdownItem>                
        <DropdownItem divider />
        <DropdownItem><FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>

      </Dropdown>

    </div>,  
  },    
  {
    name:'COMERCIAL',
    selector:row => row.NombreComercial,
    sortable:true,
    compact:true,
    left:true,    
    grow:1.2,
  },          
  {
    name:'TELF',
    selector:row => row.TlfCel,
    sortable:true,
    compact:true,
    grow:0.9,
  },    
  {
    name:'CORREO',
    selector:row => row.Email,
    sortable:true,
    compact:true,
    grow:1.5,
  }, 
  {
    name:'CLASE',
    selector:row => row.Clase,
    sortable:true,
    compact:true,
    grow:1,
    hide:'sm',  
  },      
  {
    name:'CIUDAD',
    selector:row => row.Ciudad,
    sortable:true,
    compact:true,
    grow:1,
    hide:'md',    
  },  
]

const incluirModificarJsonProveedor=(jsonRegistro)=>{
  //Lo que hace es Incluir o Modificar un Item en el array JSON . (asi me ahorro la lectura de la BDD)
  let itemLoco={
    "IDr":jsonRegistro.IDr,
    "IdPrincipal":jsonRegistro.IdPrincipal,
    "IdSecundario":jsonRegistro.IdSecundario,
    "RazonSocial":jsonRegistro.RazonSocial,
    "NombreComercial":jsonRegistro.NombreComercial,
    "TlfCel":jsonRegistro.TlfCel,
    "Email":jsonRegistro.Email,
    "Clase":jsonRegistro.Clase,
    "Ciudad":jsonRegistro.Ciudad,
    "Activo":jsonRegistro.Activo,
    "VisibleSN":opcionRegistro_imc=="i" ? 'S' : rowRegistroClon.VisibleSN,
    "BlockDeNotas":opcionRegistro_imc=="i" ? '' : rowRegistroClon.BlockDeNotas,
    "OpCrea":opcionRegistro_imc=="i" ? valueDC.usuario.LoginUsu : rowRegistroClon.OpCrea,
  }
  
  //******  cuando es modificar *************
  if (opcionRegistro_imc=='m'){
    let datosFullCopia=null
    datosFullCopia=registrosFullState.map(item=>item.IDr==rowRegistroClon.IDr ? itemLoco : item )
    set_registrosFullState(datosFullCopia)
  }

  //******  cuando es nuevo *************
  //FALTA: ojo, aqui falta hacer algo para que se muestre el registro en cuestion, posiblemente quede en una tercera pagina y no se puede ver
  //FALTA: no se como hacer para que quede ordenado donde corresponde. Revisar esta parte de DONDE INSERTARLO
  if (opcionRegistro_imc=='i'){
    registrosFullState.push(itemLoco) 
  }
  
  //debo simular un clic sobre el registro afectado.
  set_rowRegistroState(itemLoco)  
  rowRegistroClon=itemLoco
}

const eliminarJsonRegistro=()=>{
  let datosFullCopia=registrosFullState.filter(item=>item.IDr!=rowRegistroClon.IDr)
  //actualizo el estado para la tabela, y quito la franja azul
  set_registrosFullState(datosFullCopia)
  set_rowRegistroState(null)
  rowRegistroClon=null
}
  
const refrescarData=async()=>{
  let data=new FormData()
  data.append('miSol','proveedor_buscarTodosPocasColumnas')
  data.append('deseoBlockSN',deseoBlockSN)
  data.append('deseoOpSN',deseoOpSN)   
  data.append('orderBy','RazonSocial')
  data.append('forma','Asc')

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_registrosFullState(dataApi)   
}  

const hayFilaSeleccionada=()=>{
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true  
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un Proveedor")          
    miDevolver=false
  }
  return miDevolver
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //=== blanqueo estas variables 
  rowRegistroClon=null 
  cedulaCandidata=''   
  refrescarData()
  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    //variables normales
    rowRegistroClon=null 
    opcionRegistro_imc=''
    cedulaCandidata=''
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }     
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al menu principal
useEffect(()=>{  
  if (registrosFullState==null){    
    props.activarMenu() 
  }
  },[registrosFullState]
)

const filtrarElementos=(cadenaLoca)=>{
  //ojo: obligatorio convertirla en minuscula para hacer la busqueda
  cadenaLoca=cadenaLoca.toLocaleLowerCase()

  let filtradosTmp=registrosFullState.map(item=>{
    if (  
    item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.IdSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.Clase.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.Ciudad.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.Email.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.BlockDeNotas.toString().toLocaleLowerCase().includes(cadenaLoca)
    ) 
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item
  })
  set_registrosFullState(filtradosTmp)
  set_idMasState(0)
  rowRegistroClon=null    
} 

const eliminarRegistroEnBDD=async()=>{
  let data=new FormData()
  data.append('miSol','proveedor_eliminar')
	data.append('IDr',rowRegistroClon.IDr)

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)  
  return dataApi //dominio: null,-100,numero positivo
}

const ComponentePrincipalBody=()=>{  
return(      
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 
  <div id="divSubMaster" name="divSubMaster">
  {/******* Boton superior de OPCIONES (utilidades) ************/}
  <Row style={{margin:'0',paddingLeft:'0px'}} >     
    <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidadesState }  size="md"
      toggle= {()=>set_dropUtilidadesState(!dropUtilidadesState)}>
        <DropdownToggle caret color="primary">
          Opciones
        </DropdownToggle>
        <DropdownMenu style={{backgroundColor:'cyan',}} >
          <DropdownItem onClick={ () => alert('Listado' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faGlasses} /> Listado</DropdownItem>        
          <DropdownItem onClick={ () => alert('Exportar' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> Exportar</DropdownItem>                  
          <DropdownItem onClick={ () => alert(JSON.stringify(registrosFullState,null,2) )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de datos full</DropdownItem>                
          <DropdownItem onClick={ () => {
            alert(JSON.stringify(rowRegistroState,null,2))
            alert(JSON.stringify(rowRegistroClon,null,2))
          }}
          >      <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileExcel} /> JSON de la fila seleccionada</DropdownItem>          
          <DropdownItem divider />
          <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
        </DropdownMenu>
    </Dropdown>
  </Row>

    {/******* Menu superior, para telefono vertical: solo 4 botones ************  */}
    <div id="divMenuSuperior" name="divMenuSuperior" > 
      <Row style={{margin:'0px',}} >
        <Col>
          <ButtonGroup>
            <Button id="btnNuevo" name="btnNuevo" className="btnPpal btnBordebtnPpal"             
              onClick = {()=>{                 
                opcionRegistro_imc='i' //'i'ncluir nuevo registro
                set_idMasState(0)
                set_rowRegistroState(null)
                rowRegistroClon=null
                set_modalIncModRegistroState(true)
              }}
              >
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faUserPlus} /> 
              </span>
              <span><br/>Nuevo</span>
            </Button>
            <Button id="btnRecord" name="btnRecord" className="btnPpal btnBordebtnPpal"  
              //onClick = { () => { alert('record') } }              
              >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faRegistered} />
              </span>
              <span><br/>Record</span>
            </Button>
            <Button id="btnEnviarEmail" name="btnEnviarEmail" className="btnPpal btnBordebtnPpal btnOcultoPeq"           
              onClick = { () => {
                if (!hayFilaSeleccionada()) return
                set_idMasState(0)
                if (rowRegistroClon.Email.trim().length==0){
                  mostrarSwalBotonAceptar("warning","NO SE PUEDE ENVIAR","El proveedor NO tiene dirección de correo")
                  return
                }
                set_modalCorreoState(true)
                }}               
                >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faEnvelope} />
              </span>
              <span><br/>Enviar</span>
            </Button>
            <Button id="btnSaldoInicial" name="btnSaldoInicial" className="btnPpal btnBordebtnPpal btnOcultoPeq" 
              //onClick = { () => { alert('previa') } }
              >  
              <span style ={{ fontSize:"35px"}}>
                  <FontAwesomeIcon color="gainsboro" icon={faCommentDollar}/> 
              </span>
              <span><br/>Previa</span>
            </Button>      
            <Button id="btnBloc" name="btnBloc" className="btnPpal btnBordebtnPpal btnOcultoPeq"   
              //onClick = { () => { alert('Bloc') } } 
              >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faBookOpen} />
              </span>
              <span><br/>Bloc</span>
            </Button>        
            <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"                
              onClick={async()=>{ 
                set_textoBuscarState('')
                //pongo a 0 los codigos del estado para que se quite la franja AZUL
                set_rowRegistroState(null)
                rowRegistroClon=null
                set_idMasState(0)
                refrescarData()                
              }}>  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /> 
              </span>
              <span><br/>Refresh</span>          
            </Button>
            <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"               
              onClick = { () => { 
                props.activarMenu()
              }}           
              >  
              <span style ={{ fontSize:"35px"}}>
                <FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /> 
              </span>
              <span><br/>Salir</span>          
            </Button>
          </ButtonGroup>
        </Col>    
      </Row>
    </div> {/* divMenuSuperior */}

    {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}    
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
      <Input
        className = "textoBusquedaClientes" 
        type="text" placeholder="Texto de búsqueda" id="inputBusqueda" name="inputBusqueda"
        value={textoBuscarState}
        onChange={()=>{
          set_textoBuscarState( document.getElementById("inputBusqueda").value )
          filtrarElementos( document.getElementById("inputBusqueda").value.trim() )
        }}/>
      <Button id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}      
        onClick={()=>{
          set_textoBuscarState('')
          set_rowRegistroState(null)
          rowRegistroClon=null
          set_idMasState(0)          
          filtrarElementos('') 
        }}        
        > 
        <FontAwesomeIcon color="paleGreen" icon={faBroom}/>
      </Button>        
    </div> { /* divBuscador1 */ }

   </div> { /* divSubMaster */ }

    { /* inicio del DIV para la tabela divTabela */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title='Lista de Proveedores'
    columns={miEstructuraDataTable}
    data={registrosFullState?.filter(item=>item.VisibleSN=='S')}
    conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)}  
    keyField ='IDr' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
    onRowClicked={(row) => { 
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_idMasState(0)    
    }}
    //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""
    //    <h1><span>Nada por aquí</span></h1>
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //'60vh'  
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras

    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //falta: rvesiar esta parte, me parece interesante ya que debe arrancar ordenando por DESCRIPCION
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    //paginationPerPage={10} // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationPerPage={ valueDC.sistema.paginadoParaProveedores }  
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

    {/************************** MODAL PARA AGREGAR/MODIFICAR/CONSULTAR REGISTRO ****************/}  
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalIncModRegistroState } >
      <IncModProveedor
        cerrarModal={(accion,jsonRegistro)=> {
          set_modalIncModRegistroState(false)
          //accion puede ser: close/save/notfound
          if (accion=='close') return
          //al regresar del modal, no es necesario refrescar leyendo de nuevo la BDD, lo que hago es modificar el JSON segun lo sucedido          
          if (accion=='save') incluirModificarJsonProveedor(jsonRegistro)
          //el notfound, significa que NO se encontró al registro que se deseaba editar/consultar (por lo tanto debo eliminarlo de la tabela)
          if (accion=='notfound') eliminarJsonRegistro()
        }}
        opcionRegistro_imc={opcionRegistro_imc}        
        registroIDr={(opcionRegistro_imc=='i') ? 0 : ((rowRegistroClon==null) ? 0: rowRegistroClon.IDr)} //es util solamente cuando deseo editar o consultar el registro (cuando es NUEVO se va con 0)
        cedulaCandidata = {cedulaCandidata} //solo tiene utilidad cuando se desea crear un proveedor desde COMPRAS (desde este formulario no se usa nunca)
        registrosFull = {registrosFullState}            
      />
    </Modal>

    {/* ************************* MODAL PARA ENVIAR CORREO *************** */}   
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalCorreoState } >
      <FormularioCorreo 
        cerrarModalCorreo={async(accion,miAsunto,miMensaje)=>{
          set_modalCorreoState(false)
          //accion puede ser: close/send
          if (accion=='close') return
          if (accion=='send') {
            correoEnviadoCorrectamenteSN = await enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal('',rowRegistroClon.Email,'N','',valueDC,miAsunto,miMensaje)
          }
        }}
        destinoNombre={rowRegistroClon!=null ? rowRegistroClon.RazonSocial : ''}
        destinoEmail={rowRegistroClon!=null ? rowRegistroClon.Email : ''}
        asuntoDefault={""}
        mensajeDefault={""}
      />
    </Modal>
    
</div> //Div principal DivMaster
) //del return interno
}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} //del TabelaProveedores 

export default TabelaProveedores
