//Me permite ver los detalles (panel) de la compra (Factura/Nota/Liquidacion). Por ejemplo: fecha, proveedor, numero....
import React from 'react'
import { Input, Label, Button, Row, Col, } from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
 
export default class MostrarPanelDeLaCompra extends React.Component {
  valueDC=null //Aqui hago una copia de los valores del context

componentDidMount(){    
  document.getElementById("txtDocumentoFNL").value=this.props.row.Tipo //Factura, Nota de venta, Liquidacion
  document.getElementById("txtDocumentoNumero").value=this.props.row.Comprobante  
  document.getElementById("txtFechaComprobante").value=this.props.row.FechaComprobanteFormat
  document.getElementById("txtFechaIngreso").value=this.props.row.FechaIngresoFormat
  document.getElementById("txtProveedor").value=this.props.row.RazonSocial
  document.getElementById("txtNombreComercial").value=this.props.row.NombreComercial  
  document.getElementById("txtCorreo").value=this.props.row.EmailProveedor
  document.getElementById("txtCondicion").value=(this.props.row.Condicion=="CREDITO") ? "CREDITO (" + this.props.row.Plazo   + " dias)": "CONTADO"  
  document.getElementById("txtCancelada").value=(this.props.row.Cancelado=="0") ? "No" : "Si"

  //cuando no viene el campo Condicion, entonces lo oculto
  if (!this.props.row.Condicion)
    document.getElementById("rowCondicion").style.display= 'none' //pero Los botones visibles se reacomodan
  //cuando no viene el campo Cancelado, entonces lo oculto
  if (!this.props.row.Cancelado)
    document.getElementById("rowCancelada").style.display= 'none' //pero Los botones visibles se reacomodan

  //al ser CONTADO, oculto el campo txtCancelada
  if (this.props.row.Condicion && this.props.row.Condicion=="CONTADO")
    document.getElementById("rowCancelada").style.display= 'none' //pero Los botones visibles se reacomodan
  
  //********************* para el bloque 2 */
  document.getElementById("txtUsuCrea").value=this.props.row.OpCrea
}

copiarVariablesDesdeContext=(value)=>{
  this.valueDC=value
}

render(){   
return ( 
<AppContextConsumer>
{value =>{         
return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
{this.copiarVariablesDesdeContext(value)}

{/* Titulo + Boton Cerrar*/}
<Row style={{margin:'0',}}>
  <Col xs="10" >
    <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"PANEL DE LA COMPRA"}</Label>
  </Col>
  <Col xs="2" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ this.props.cerrarModal()  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
  </Col>
</Row> 

{/* Empiezo a mostrar la informacion RELEVANTE, color purple */}
<div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink', }}>
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Comprobante</Label>
    </Col>
    <Col xs="6">
      <Input disabled name="txtDocumentoFNL" id="txtDocumentoFNL" style={{paddingLeft:'3px', textAlign:'left',width:'80%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Numero</Label>
    </Col>
    <Col xs="6">
      <Input disabled name="txtDocumentoNumero" id="txtDocumentoNumero" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>F. Emisión</Label>
    </Col>
    <Col xs="6">
      <Input disabled name="txtFechaComprobante" id="txtFechaComprobante" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>
    </Col>    
  </Row>
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>F. Ingreso</Label>
    </Col>
    <Col xs="6">
      <Input disabled name="txtFechaIngreso" id="txtFechaIngreso" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>
    </Col>    
  </Row>  
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Proveedor</Label>
    </Col>
    <Col xs="8">
      <Input disabled name="txtProveedor" id="txtProveedor" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>N. Comercial</Label>
    </Col>
    <Col xs="8">
      <Input disabled name="txtNombreComercial" id="txtNombreComercial" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Correo</Label>
    </Col>
    <Col xs="8">
    <Input disabled name="txtCorreo" id="txtCorreo" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>  
  <Row name="rowCondicion" id="rowCondicion" style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Condición</Label>
    </Col>
    <Col xs="6">
      <Input disabled name="txtCondicion" id="txtCondicion" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black'}}/>            
    </Col>    
  </Row>  
  <Row id="rowCancelada" name="rowCancelada" style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Cancelada</Label>
    </Col>
    <Col xs="3">
      <Input disabled name="txtCancelada" id="txtCancelada" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'black' }}/>            
    </Col>    
  </Row>   
  </div> {/* (divBloque1) */}

  {/* OTRO BLOQUECITO, para mostrar informacion USUCREA, y posiblemente en el futuro otra informacion.... */}
  <div id="divBloque2" name="divBloque2" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'gray', color:'pink', }}>
  <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0'}}>
    <Col xs="4" style={{textAlign:'left'}}>
      <Label>Creada por</Label>
    </Col>
    <Col xs="5">
      <Input disabled name="txtUsuCrea" id="txtUsuCrea" style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px',background:'white',color:'dimgray' }}/>            
    </Col>    
  </Row>    
  </div> {/* (divBloque2) */}

</div> // (divMaster)
) //del return interno
} //del value
}
</AppContextConsumer>  

)//return externo
}//del render()
}//de la clase
