//import logo from './logo.svg';
//import './App.css';
//corona
import LoginCorona from './LoginCorona'

const AppCorona=()=> {
  return (
    <div>       
      <LoginCorona/>   
    </div>
  );
}

export default AppCorona
