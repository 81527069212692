//FALTA: el boton de RESOLVER tambien me genera el problema que cada vez la pantalla se pone mas pequena
import React, {useState,useEffect} from 'react'
import {Row,Col,Jumbotron,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, {buscarCarpeta_certificadofirma,mostrarSwalUnSegundo,sleepPepe,devolverMesTextoDesdeUnNumero,enviarCorreoSriPdfMasXmlConSwal,resolverComprobanteRojoSriConSwal,determinarFormatoImpresoraGrande,hayInternet} from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,generarDocumentoXmlConSwal,firmarDocumentoXmlConSwal,recepcionValidarSriConSwal,consultarAutorizacionSriConSwal,generarDocumentoPDF_SriConSwal,generarFacturaDeVentaSimple_ConSwal} from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSun, faBolt, faCalendarAlt ,faRunning,faVoteYea,faGlasses,faStickyNote,faCopy,faBan,faMoneyCheckAlt,faDoorOpen,faReceipt,faBroom,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed } from '@fortawesome/free-solid-svg-icons' 
import PedirMesYano_OK from './PedirMesYano_OK'
import MostrarConsultaAutorizacionSri from './MostrarConsultaAutorizacionSri'
import RecibosIngresoDeUnDocumento from './RecibosIngresoDeUnDocumento'
import VerPdfOk from './VerPdfOk'
import MostrarResultadosResolver from './MostrarResultadosResolver'
import MostrarPanelDeLaFactura from './MostrarPanelDeLaFactura'
import PedirClaveEspecial from './PedirClaveEspecial'
//Context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let intentos=1 //me sirve para hacer 3 intentos en cada solicitud/peticion. El tope maximo viene en el context
let permanecerEnForm=null //al tratar de refrescar y no poder, entonces posiblemente me tengo que devuelver al MENU PRINCIPAL
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let mesVer=null
let anoVer=null
let pepeJsonConsultaSri=null //aqui guardo todo el resultado (NO ESTOY SEGURO)
let resolverJson=null //resolverJson={}. aqui guardo el json de resolver un documento rojo (NO ESTOY SEGURO)

//estas 3 hay que eliminarlas luego. solo estan para que no me de error
let estab=''
let punto=''
let numFac=''

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho={
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE FACTURAS'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
		},
	}, 
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender
      fontSize:'18px',   //ok
      fontWeight: 'bold'
    },
  },    
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',
      //borderStyle:"solid",             
    }
  }, 
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
      //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */
    },
  },
}

export default function TabelaRetencionesElabLista(props){
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
  const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
  const [modalPedirMesYanoState,set_modalPedirMesYanoState]=useState(false) //para abrir el modal de Pedri mes y ano
  const [modalCorreoState,set_modalCorreoState]=useState(false) //para abrir el modal de enviar correo

const filaCondicional=[
  //fila NO seleccionada
  {
    when: row => (rowRegistroState==null || row.Retencion != rowRegistroState.Retencion ),
    style: row => ({ 
      backgroundColor:row.AutorizacionSri=="0"  ? valueDC.sistema.coloresSriNoAutorizado : (row.EnviadoAlReceptor == "0") ? valueDC.sistema.coloresSriNoCorreo : valueDC.sistema.coloresSriPerfecto,
      textDecoration: row.Anulado=="1" ? 'line-through' : 'none',
      color: 'dimgray', 
     }),    
  },
  //fila seleccionada
  { 
    //al tocar una fila se debe poner azul. Pero si la fila esta anulada se debe mantener el tachado
    when: row => (rowRegistroState!=null && row.Retencion == rowRegistroState.Retencion),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      textDecoration: row.Anulado=="1" ? 'line-through' : 'none',
      color: 'white', 
    }),
  },  
]

const miEstructura=[  
  {  
    name:'RETENCION', //lo que el usario ve. 17 caracteres
    selector:row => row.Retencion, //Tal como viene en el Json desde las Base de datos
    sortable:true,
    left:true,
    grow:1.5, //es una medida relativa (1 es mi referencia)
    compact:true, //sets cell padding to 0
    omit:false, //en algunos casos, se debe ocultar algunas columnas
    //style:{ background: 'gray' },
  },
  {
    name:'FECHA',
    selector:row => row.FechaEmisionFormat,
    sortable:true,
    left:true,
    compact:true,
    grow:0.7,  //originalmente 0.9
  }, 
  {
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1.3,  

    //esto funciona fino
    //cell: (row) => <div><div style={{ fontWeight: 'bold' }}>{row.factura17}</div>{row.IdPrincipal}</div>,
    //esto funciona fino
    //cell: (row) => <div style={{ fontWeight: 900 }}>{row.factura17}</div>,
    //<div style={{ display:'flex' }}>            
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton Anular y Enviar */}
    <Button id="btnAnular" name="btnAnular" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
     onClick={ (e) => {
      /*
      this.alimentarEstadoAlHacerClicEnUnaFila(row,"N") //actualizo el estado
      //truco para que de chance que se actualice en estado.        
      setTimeout(async()=>{          
        this.gestionarAnularFactura()
      },50)    
*/
      }
    }
      >
      <FontAwesomeIcon size='lg' color="coral" icon={faBan}/></Button>

    <Button id="btnEnviarEmail" name="btnEnviarEmail" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
     onClick={(e)=>{      
      //me interesa primeramente que se ponga fondo azul
      //this.alimentarEstadoAlHacerClicEnUnaFila(row,"N") //actualizo el estado
      if (row.Anulado=='1'){
        mostrarSwalBotonAceptar("error","ATENCION","El comprobante está anulado. No se puede enviar")                          
        return
      }      
      if (row.AutorizacionSri=='0'){
        mostrarSwalBotonAceptar("warning","ATENCION","El comprobante aún no está Autorizado")                          
        return
      }      
      if (row.EmailCliente.trim()==''){
        mostrarSwalBotonAceptar("warning","ATENCION","El cliente no tiene correo")                          
        return
      }      
      //truco para que de chance que se actualice en estado. En la funcion gestionarEnviarCorreoSriPdfMasXmlConSwal necesito variables del estado
      setTimeout(async()=>{ 
        //en caso que la fila sea amarilla, le envio un parametro para que se TILDE en la bdd
        let tildarSN=(row.EnviadoAlReceptor=="0") ? "S" : "N"
        //this.gestionarEnviarCorreoSriPdfMasXmlConSwal(tildarSN)
      },50)         
      }}>
      <FontAwesomeIcon size='lg' color="white" icon={faEnvelope} /></Button>          
    {/** dropdown para el boton MAS... */}
    <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.Retencion } direction="right"  
      toggle= { ()=> {      
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.IDr : 0)              
      }}>    
        <DropdownToggle color="info">
          <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
        </DropdownToggle>
      <DropdownMenu style={{backgroundColor:'pink',}} >            
      <DropdownItem onClick={ () => gestionarResolverRojo() 
        }><FontAwesomeIcon style={{color:'dimGray'}} icon={faBolt} />  Resolver SRI</DropdownItem>        
      <DropdownItem divider />
      <DropdownItem onClick={ () => gestionarVerFacturaPdfSri() }>
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faGlasses}  /> Ver pdf</DropdownItem>        
      <DropdownItem onClick={ () => gestionarVerPanelFactura() }> <FontAwesomeIcon style={{color:'dimGray'}} icon={faVoteYea}  /> Ver panel</DropdownItem>        
      <DropdownItem onClick={ async() => {
          if (await verificarSiEstaAnuladoSN()=="S") return          
          //primero debo buscar la fecha y monto de la nota, para poder mandarlo al modal
          //this.buscarCampos()
          if (await hayInternet()==false) return 
          //this.setState({estatusAbiertoModal_RecibosCaja : true})
          }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faMoneyCheckAlt}  /> Ver recibos de caja</DropdownItem>
      <DropdownItem divider />
      <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        

      </DropdownMenu>
      </Dropdown>
    </div>,
  },
  {
    name:'RAZON SOCIAL',
    selector:row => row.RazonSocial,
    sortable:true,
    left:true,
    compact:true,
    grow:1.5,
  },   
  {
    name:'IDENTIFIC.',
    selector:row => row.IdPrincipal,
    sortable:true,
    left:true,
    compact:true,
    grow:0.9,  
  },
  {
    name:'RETENIDO',
    selector:row => row.TotalRetenido,
    sortable:true,
    right:true,
    compact:true,
    grow:0.7, 
  },           
  {
    name:'CREA',
    selector:row => row.OpCreaRet,
    sortable:true,
    center:true,
    compact:true,
    grow:0.25,
    hide:'sm', //se oculta en pantallas pequenas por debajo de 600px de ancho
  }, 
]

const tildarAnuladaEnElJson=()=>{ 
  /*
  //luego de anular en la BDD, debo tildar en el JSON. Asi me ahorro tener que volver a leer de la BDD
  let copiaFull=this.state.datosFull.map(item=>{
    if (item.Estab==this.state.estab && item.Punto==this.state.punto && item.NumFac==this.state.numFac) {
       item.Anulado="1"
       item.OpAnula=this.valueDC.usuario.LoginUsu
    }
    return item      
  })    
 this.setState({datosFull:copiaFull })   
 */ 
} 

const activarLista=()=>{
  /*
  this.setState({nombreComponenteParaVer:'lista' }) 
  */
}

const anularFacturaEnBDD=async()=>{
  if (await hayInternet()==false) return 
  intentos=1
  let seguir=true
  mostrarSwalEspera()
  while (seguir){ 
    //Comienza el proceso para anular
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','factura_Anular')
    data.append('miEstab',estab)
    data.append('miPunto',punto)
    data.append('miNumFac',numFac)
    data.append('opAnula',valueDC.usuario.LoginUsu)

    try{
      const mi_response=await fetch(miPhpFile, {method:"POST", body:data} )
      const pepeJson=await mi_response.json()  
  
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
  
      //*******por aqui, curso ideal    
      apagarSwal()
      seguir=false
      if (pepeJson.Data==-100){
        mostrarSwalBotonAceptar("warning","NO SE PUEDE ANULAR DOS VECES","la factura ya había sido anulada previamente")
        //marco en el JSON que la factura esta anulada
        tildarAnuladaEnElJson()                                     
      }     
      if (pepeJson.Data==-110){
        mostrarSwalBotonAceptar("warning","NO SE PUEDE ANULAR","la factura fué cancelada en grupo. Se recomienda que primero elimine los recibos de caja asociados")
      }     
      if (pepeJson.Data<0 && (pepeJson.Data !=-100 && pepeJson.Data !=-110) ){
        await mostrarSwalBotonAceptar("info","NO SE PUDO ANULAR","no se pudo anular la Factura")
      }
      if (pepeJson.Data>0){
        //marco en el JSON que la factura esta anulada
        tildarAnuladaEnElJson()                    
        //le informo al usuario que todo OK
        mostrarSwalBotonAceptar("success","OK","La Factura se anuló correctamente. Usted también deberá anular la Factura desde la Página del SRI")
      }
    }
    catch(e){
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
      }
      else{
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        //ha pulsado salir en el swal
        if (miRespSwal.isDismissed) {        
          // No hago nada 
          seguir=false
        }
      } //del else
    } //del catch
  } //del while
}

const hayFilaSeleccionada=()=>{
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Retención")          
    miDevolver=false
  }
  return miDevolver
}

const gestionarAnularFactura=()=>{    
  /*
  if (this.state.factura17==''){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Factura")          
    return
  }
  //reviso si ya esta anulada previamente en el grid (es decir si ya estaba tachada)
  if (this.rowFactura.Anulado==1){
    mostrarSwalBotonAceptar("warning","ATENCION","Esta Factura ya estaba anulada")          
    return
  }

  //llamo al modal de pedir clave
  this.setState({estatusAbiertoModal_PedirClaveEspecial : true} )
  */
}

const generarFacturaPDF=async()=>{
intentos=1
let seguir=true  
mostrarSwalEspera()
while (seguir){
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','generarPDFfactura')   
  data.append('Estab',estab) 
  data.append('Punto',punto)  
  data.append('NumFac',numFac)

  try{
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeJson=await response.json() 

   //analizo la cabecera del JSON de la respuesta
    let hayError=false
    let miE=null
    if (!pepeJson.ConexionBUC){
      hayError=true
      miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
    } 
    if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
      hayError=true
      miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
    }     
    if (hayError){
      throw miE      
    } 
    //rumbo normal*********
    apagarSwal()
    seguir=false
    mostrarSwalBotonAceptar("success","OK","PDF creado correctamente")
  } //del try
  catch (e) {    
    intentos++
    if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
      await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
    }
    else{  
      //llamo al gestor para que me aparezca la pantalla de REINTENTAR
      apagarSwal()
      let miRespSwal = await gestionarCatch(e)
      //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
      if (miRespSwal.isDenied) {
        mostrarSwalEspera()
        intentos=1
      }
      else{
        //ha pulsado salir en el swal
        seguir=false
      }
    }//del else    
  }  //del catch    
}//del while
}

const gestionarResolverRojo=async()=>{
  /*
  if (await verificarSiEstaAnuladoSN()=="S") return
  if (await hayInternet()==false) return 
  
  //primero debo ver que la licencia, tenga permiso de usar firma
  if (valueDC.licencia.FirmaElectronicaUsar=="0"){
    mostrarSwalBotonAceptar("error","ERROR","Usted no tiene una firma asociada")
    return
  }
  let miFormato=determinarFormatoImpresoraGrande(valueDC,estab,'FormatoFacturaSri')
  if (miFormato==''){
    await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato")          
    return false
  }
  //mando a resolver, recibo la respuesta para mostrarla al usuario y para poder tildar el grid
  resolverJson=await resolverComprobanteRojoSriConSwal("FA",miFormato,estab,punto,numFac,valueDC,clienteNombre,clienteEmail)
  
  //voy a tildar el grid segun la respuesta
  if (resolverJson !=null && resolverJson.Autorizado){
    let miCorreo=(resolverJson.EnviadoPorCorreo) ? "1" : "0"
    let copiaFull=datosFull.map(item=>{
      if (item.Estab==estab && item.Punto==punto && item.NumFac==numFac) {
         item.AutorizacionSri="1"
         item.MensajeErrorAutorizacion=null
         item.EnviadoAlReceptor=miCorreo
      }
      return item      
    })    
   //this.setState({datosFull:copiaFull})    
  }
  
  //llamo a mi formulario para que el cliente vea el resumen  
  if (resolverJson !=null)
    //this.setState({estatusAbiertoModal_ResolverSri : true} )  
*/
}

const gestionarVerPanelFactura=()=>{
  //this.setState({estatusAbiertoModal_PanelFactura : true} )  
}

const gestionarFirmarXml=async()=>{
  //primero debo ver que la licencia, tenga permiso de usar firma
  if (valueDC.licencia.FirmaElectronicaUsar=="0"){
    mostrarSwalBotonAceptar("error","ERROR","Usted no tiene una firma asociada")
    return
  }
  if (await hayInternet()==false) return 
  //mando a firmar
  firmarDocumentoXmlConSwal("FA",estab,punto,numFac,valueDC)   
}

const gestionarEnviarValidarSri=async()=>{
  /*
  //NOTAS: si la firmo con otro RUC la recibe OK. pero en la consulta/autorizacion me da el error: FIRMA INVALIDA, El certificado utilizado, no es del tipo firma digital</informacionAdicional
  //El SRI solo permite intentar 3 veces. Luego al enviarle me da error # 90, mensaje: LIMITE DE INTENTOS EXCEDE
  //OJO: a veces no se por que causa, pero el primer intento no hace nada, luego el segundo me viene con html y el tercero lo hace bien. Solo a veces
  if (await hayInternet()==false) return 

  let digitoAmbiente12=rowRegistroClon.claveAcceso.substring(23,24) //1:pruebas, 2:produccion
  let archivoTmp="FA_FIR_" + rowRegistroClon.estab + "-" + rowRegistroClon.punto + "-" + rowRegistroClon.numRet + ".xml"
  //en caso de no haber conexion con el SRI o no se puedo convertir en JSON la respuesta, entonces recibo un NULL
  pepeJsonEnvioSri=await recepcionValidarSriConSwal(digitoAmbiente12,archivoTmp,valueDC) 

  //Al ser null, es que no se pudo codificar el JSON.
  if (pepeJsonEnvioSri==null) {
    //al ser nulo, no hubo conexion y seguramente ya se mostro la pantalla negra
    return
  }
  if (!pepeJsonEnvioSri.ConexionSRI) {
    //al ser false, seguramente ya dio la pantalla negra
    return
  }
  //al ser DEVUELTA 
  if (pepeJsonEnvioSri.Estado=="DEVUELTA"){
    let mensajeSri="Identificador: " + pepeJsonEnvioSri.Identificador + " Mensaje: " + pepeJsonEnvioSri.Mensaje
    mostrarSwalBotonAceptar("error","DEVUELTA",mensajeSri)
    return
  }

  //RECIBIDA: muestro el mensaje del SRI
  if (pepeJsonEnvioSri.Estado=="RECIBIDA"){
    mostrarSwalBotonAceptar("success","OK","RECIBIDA")
    return
  }
  
  //al estar todo ok----- lo idea---- entonces actualizo en la bdd (solo en caso de ser necesario)
  //falta: si estaba roja, entonces actualizo en la BDD y lo dejo actualizando en segudo plano  
  */
}

const gestionarConsultarAutorizacionSri=async()=>{
  /*
  //esta funcion consulta en el SRI, pero SI descarga el xml autorizado desde el SRI y lo guarda en disco. 
  //Se busca la autorizacion dependiendo del digito de AMBIENTE que se encuentra dentro de los 49 digitos
  //OJO: no hace ningun cambio en la BDD
  if (await hayInternet()==false) return 
  
  let claveAccesoTmp=this.state.claveAcceso
 
  //en caso de no haber conexion con el SRI o no se puedo convetir en JSON la respuesta, entonces recibo un NULL
  this.pepeJsonConsultaSri=await consultarAutorizacionSriConSwal(claveAccesoTmp,this.valueDC) 
  //Al recibIr un JSON bueno, con NumeroDeComprobantes==1 entonces muestro el formulario con la respuesta
  if (this.pepeJsonConsultaSri==null) {
    //al ser nulo, no hubo conexion y seguramente ya se mostro la pantalla negra
    return
  }
  if (!this.pepeJsonConsultaSri.ConexionSRI) {
    //al ser false, seguramente ya dio la pantalla negra
    return
  }
  if (this.pepeJsonConsultaSri.NumeroComprobantes==0) {
    //significa que hubo conexion, pero el comprobante NO EXISTE
    await mostrarSwalBotonAceptar("warning","ATENCION","El comprobante no existe")
    return
  }

  //llamo a mi formulario para que el cliente lo vea
  this.setState({estatusAbiertoModal_ConsultarSri : true} )
  */
}

const gestionarEnviarCorreoSriPdfMasXmlConSwal=async(tildarSN)=>{
  /*
  if (await hayInternet()==false) return 
  //*******llamo a una funcion que haga el envio. Y le mando un parametro para que se TILDE en la bdd. (pero el cambio de color solo se ve al darle refrescar o salir y volver a entrar)
  let correoEnviadoCorrectamenteSN = await enviarCorreoSriPdfMasXmlConSwal(
    this.state.clienteNombre,this.state.clienteEmail,this.valueDC,this.state.claveAcceso,
    "FA",this.state.estab,this.state.punto,this.state.numFac,tildarSN
  )

  if (correoEnviadoCorrectamenteSN=="N" || tildarSN=="N")
    return

  //al estar enviado, entonces lo tildo en el grid 
  let copiaFull=this.state.datosFull.map(item=>{
    if (item.Estab==this.state.estab && item.Punto==this.state.punto && item.NumFac==this.state.numFac) item.EnviadoAlReceptor="1"  
    return item
  })
  this.setState({datosFull:copiaFull})
  */
}

const refrescarData=async()=>{
intentos=1
let seguir=true  
mostrarSwalEspera()
while (seguir){
    let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','retela_buscarUnMesPocasColumnas')
    data.append('miMes',mesVer)
    data.append('miAno',anoVer)
    data.append('orderBy','FechaEmision')
    data.append('forma','Desc') 

    try{   
      let response = await fetch(miPhpFile, { method: 'POST',body:data })
      let pepeJson = await response.json()  
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
      //rumbo normal*********    
      set_registrosFullState(pepeJson.Data)
      apagarSwal()
      seguir=false
    } //del try
    catch (e) {   
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
      }
      else{  
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        else{
          //ha pulsado salir en el swal
          seguir=false
          if (!permanecerEnForm){
            props.activarMenu()
            desmontar() //de ultimo siempre
          }
        }
      }//del else
    }//del catch
} //del while
}

const determinarMesAndAnoActual=()=>{
  mesVer=(new Date().getMonth()) + 1 //OJO: enero es 0
  anoVer=(new Date().getFullYear())
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  determinarMesAndAnoActual()
  //=== blanqueo estas variables 
  rowRegistroClon=null 
  permanecerEnForm=false

  refrescarData()
  },[]
)

const desmontar=()=>{
  set_registrosFullState([])
  //hay que blanquear las variables que no son parte del estado. Obligatorio, ya que al volver a entrar nuevamente al formulario, entonces queda el valor de la sesion anterior  
  //Solo las mas sensibles
  rowRegistroClon=null 
}

const filtrarElementos=()=>{
  if (rowRegistroState != null) {
    set_rowRegistroState(null)                 
    rowRegistroClon=null
  }
  if (idMasState !=0) set_idMasState(0)
  let cadenaLoca=document.getElementById("inputBusqueda").value.trim().toLocaleLowerCase()  
  let filtradosTmp=registrosFullState.map(item=>{
    if (
      item.Retencion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.FechaEmisionFormat.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.TotalRetenido.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.ClaveAcceso.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.EmailProv.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.OpCreaRet.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.OpAnulaRet.toString().toLocaleLowerCase().includes(cadenaLoca)            
      )   
      item.VisibleSN='S'
    else
      item.VisibleSN='N'

    return item         
  })
  set_registrosFullState(filtradosTmp)
} 

const copiarVariablesDesdeContext=(value)=>{
  valueDC=value
}

const buscarCampos=()=>{
  /*
  //me permite buscar ciertos campos para poder enviarlos al modal de RECIBOS DE CAJA
  //por ahora me interesa FECHA FORMAT y MONTO TOTAL (FechaEmisionFormat, totalNota)
  this.state.datosFull.some(item=>{
    if (item.Factura==this.state.factura17){
      this.fechaFactura=item.FechaEmisionFormat
      this.montoFactura=item.TotalFactura
    }
    return (item.Factura == this.state.factura17) //para que se salga del ciclo
  })
  */
}

const alimentarEstadoAlHacerClicEnUnaFila=(row,botonMasSN)=>{
  /*
  //Me sirve para modificar el estado cuando se hace clic en una fila y/o en los 3 ICONOS de acciones 
  this.setState({
    factura17:row.Factura.trim(),
    claveAcceso:row.ClaveAcceso.trim(),
    clienteNombre:row.RazonSocial.trim(),
    clienteEmail:row.EmailCliente.trim(),
    estab:row.Estab.trim(),
    punto:row.Punto.trim(),
    numFac:row.NumFac.trim(),
  })
  this.rowFactura=row //guardo una copia del row, para mostrar el panel

  if (botonMasSN=="N")
    this.setState({idMas:0})
  if (botonMasSN=="S")
    this.setState({idMas:((this.state.idMas == 0) ? row.Factura : 0) })  
    */
}  

const verificarSiEstaAnuladoSN=async()=>{
  //reviso si el documento esta anulado
  let estaAnuladoSN=(rowRegistroClon.Anulado=="1") ? "S" : "N"
  if (estaAnuladoSN=="S"){
    await mostrarSwalBotonAceptar("warning","ATENCION","La Retención está anulada.... No se puede continuar")
  }

  return estaAnuladoSN
}

const gestionarVerFacturaPdfSri=async()=>{
  /*
  if (await this.verificarSiEstaAnuladoSN()=="S") return
  if (await hayInternet()==false) return 

  let miFormato=determinarFormatoImpresoraGrande(this.valueDC,this.state.estab,'FormatoFacturaSri')
  if (miFormato==''){
    await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato")          
    return false
  }

  //debo generar el PDF del sri y luego lo muestro  
  this.pdfCreadoCorrectamenteSN=await generarDocumentoPDF_SriConSwal("FA",miFormato,this.state.estab,this.state.punto,this.state.numFac,this.valueDC)   
  if (this.pdfCreadoCorrectamenteSN=="S"){
    this.setState( {nombreComponenteParaVer:'verPdfFacturaSri',} )
    }
    else{
       mostrarSwalBotonAceptar("error","ATENCION","No se pudo crear el PDF correctamente")
    }      
  */        
}

const gestionarVerFacturaSimple=async()=>{
  /*
  if (await this.verificarSiEstaAnuladoSN()=="S") return
  if (await hayInternet()==false) return 

  let miFormato=determinarFormatoImpresoraGrande(this.valueDC,this.state.estab,'FormatoFacturaSimple')
  if (miFormato==''){
    await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato")          
    return false
  }

  //debo generar el PDF simple de la factura y luego lo muestro  
  this.pdfCreadoCorrectamenteSN=await generarFacturaDeVentaSimple_ConSwal(miFormato,this.state.estab,this.state.punto,this.state.numFac,this.valueDC)
    
  if (this.pdfCreadoCorrectamenteSN=="S"){
    this.setState( {nombreComponenteParaVer:'verPdfFacturaSimple',} )
    }
    else{
       mostrarSwalBotonAceptar("error","ATENCION","No se pudo crear el PDF correctamente")
    }     
    */         
}

const Componente_ListaRegistros=()=>{  
return (  
<AppContextConsumer>
{value =>{  
return(  
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 

  {/* copio desde el context a una variable propia del componente  */}
  {copiarVariablesDesdeContext(value)}

  <div id="divSubMaster" name="divSubMaster">
  {/******* Boton superior de OPCIONES (utilidades) ************/}
    <Row style={{margin:'0',paddingLeft:'0px'}} >     
    <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidadesState }  size="md"
      toggle= {()=>set_dropUtilidadesState(!dropUtilidadesState)}>
      <DropdownToggle caret color="primary">
        Opciones
      </DropdownToggle>  
      <DropdownMenu style={{backgroundColor:'cyan',}} >        
        <DropdownItem onClick={ () => alert(JSON.stringify(rowRegistroClon,null,2) )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> JSON de la fila seleccionada</DropdownItem>        
        <DropdownItem onClick={ () => alert('Editar seriales.. ' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Editar Serie</DropdownItem>        
        <DropdownItem onClick={ () => alert('Editar observaciones.. ' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Editar Observaciones</DropdownItem>        
        <DropdownItem divider />
        <DropdownItem onClick={async()=>{
          if (!hayFilaSeleccionada()) return         
          if (verificarSiEstaAnuladoSN()=="S") return
          if (await hayInternet()==false) return

          //let generadoCorrectamenteSN= await generarDocumentoXmlConSwal("FA",this.state.estab,this.state.punto,this.state.numFac,this.valueDC) 
          alert('xml')
        }}>
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} /> Generar XML</DropdownItem>        
        <DropdownItem onClick={async()=>{ 
/*
          if (await this.hayFilaSeleccionada()==false ) return
          if (await this.verificarSiEstaAnuladoSN()=="S") return
          this.gestionarFirmarXml() 
*/
        }}> 
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} />  Firmar XML</DropdownItem>        
        <DropdownItem onClick={ async() => {
          /*
          if (await this.hayFilaSeleccionada() ) 
          if (await this.verificarSiEstaAnuladoSN()=="S") return
          this.gestionarEnviarValidarSri() 
          */
        }}>
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} /> Enviar al SRI</DropdownItem>        
        <DropdownItem onClick={ async() => { if (hayFilaSeleccionada() )        
          gestionarConsultarAutorizacionSri()
          } }>
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} /> Consultar autorizacion SRI</DropdownItem>
        <DropdownItem divider />
        <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>
    </Dropdown>
    </Row>

    {/******* Menu superior, para telefono vertical: solo 4 botones *************/}
    <div id="divMenuSuperior" name="divMenuSuperior"> 
    <Row style={{margin:'0px',}} >
      <Col>      
      <ButtonGroup> 
       <Button id="btnVerPdfSri" name="btnVerPdfSri" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
         onClick = { async() => { 
           if (hayFilaSeleccionada() ) 
            gestionarVerFacturaPdfSri() 
          }}>
         <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faGlasses}/></span>
         <span><br/>Ver pdf</span>
       </Button>
       <Button id="btnVerFacturaSimple" name="btnVerFacturaSimple" className="btnPpal btnBordebtnPpal"  
         onClick = { async() => { if (hayFilaSeleccionada() ) 
         gestionarVerFacturaSimple() 
         }}>
         <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faCopy}/></span>
         <span><br/>Simple</span>
       </Button>
       <Button id="btnTicket" name="btnTicket" className="btnPpal btnBordebtnPpal"  
         onClick = { async() => {         
          alert ('ticket')
         }} > 
         <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faReceipt}/></span>
         <span><br/>Ticket</span>
       </Button>
       <Button id="btnPanel" name="btnPanel" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
         onClick = { async() => { if (hayFilaSeleccionada() ) 
         gestionarVerPanelFactura() 
         }}>  
         <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faVoteYea} /></span>
         <span><br/>Panel</span>          
       </Button>
       
       <Button id="btnRecibos" name="btnRecibos" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
          onClick = { async () => { 
/*
            if (await this.hayFilaSeleccionada() ) {
              if (await this.verificarSiEstaAnuladoSN()=="S") return
              if (await hayInternet()==false) return 
              //primero debo buscar la fecha y monto de la nota, para poder mandarlo al modal
              this.buscarCampos()
              this.setState({estatusAbiertoModal_RecibosCaja : true} )            
            }
*/
          }}>  
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faMoneyCheckAlt}/></span>
          <span><br/>Recibos</span>          
       </Button>

       <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
         onClick = { async() => { 
            permanecerEnForm=true
            if (!hayInternet()) return 
            set_rowRegistroState(null)
            rowRegistroClon=null
            set_idMasState(0)
            document.getElementById('inputBusqueda').value=''
            determinarMesAndAnoActual()
            refrescarData()               
          }} >  
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /></span>
          <span><br/>Refresh</span>          
       </Button>
       <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"  
          onClick = { () => { 
            props.activarMenu()
            desmontar() //de ultimo siempre
          }} >  
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /></span>
          <span><br/>Salir</span>          
       </Button>
      </ButtonGroup>          
      </Col>
    </Row>
    </div> {/* divMenuSuperior */}

    {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
        <Input
          className = "textoBusquedaClientes"
          type="text" placeholder="Texto de búsqueda" name="inputBusqueda" id="inputBusqueda" 
          onChange={()=>filtrarElementos()}
        />
        <Button  
          id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
          onClick={()=>{
            document.getElementById("inputBusqueda").value=''
            set_rowRegistroState(null)
            rowRegistroClon=null
            set_idMasState(0)
            filtrarElementos()
          }}> 
          <FontAwesomeIcon color="paleGreen" icon={faBroom} />
        </Button>

        <Label id="miPeriodo" name="miPeriodo" style={{marginLeft:"10px",marginRight:"10px",marginTop:"5px",fontSize:'18px', fontWeight:'bold',color:"white" }}>
          {
            devolverMesTextoDesdeUnNumero(mesVer) + "-" + anoVer
          }
        </Label> 
        <Button size="md" id="btnPeriodo" name="btnPeriodo" color="info" solid="true" 
         onClick={()=>{
            set_modalPedirMesYanoState(true)
          }}>
        Cambiar
        </Button>        
    </div> { /* divBuscador1 */ }

  </div> { /* div SubMaster */ }

    { /* inicio del DIV para la tabela */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title='Lista de Retenciones'
    columns={miEstructura}
    data={registrosFullState.filter(item=>item.VisibleSN=='S')}
    conditionalRowStyles={filaCondicional}  
    keyField ='Retencion' /* Se puede poner 'id' (valor por omision), 'Factura' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */   
    onRowClicked={(row) => { 
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_idMasState(0)      
    }}    
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""  //Nada por aquí
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //ahora se usa dentro de estilacho
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //onRowClicked={this.miOnRowClicked()} //callback to access the row, even on row click (no pude hacerle funcionar)
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras
    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    paginationPerPage={ valueDC.sistema.paginadoParaFacturas } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //pepe. no poner fixedHeader
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

{/************ MODAL PARA PEDIR MES Y ANO *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalPedirMesYanoState } >
      <PedirMesYano_OK
        cerrarModal={async(accion,mesNuevo,anoNuevo)=>{
          set_modalPedirMesYanoState(false)
          //accion puede ser: close/select 
          if (accion=='close') return
          //rumbo ideal....          
          if (await hayInternet()==false) return 
          mesVer=mesNuevo
          anoVer=anoNuevo
          document.getElementById("inputBusqueda").value=''
          set_rowRegistroState(null)
          rowRegistroClon=null
          refrescarData()         
        }}
        mesActual={mesVer}
        anoActual={anoVer}
      />
    </Modal>      

{/************ MODAL PARA VER LA CONSULTA DEL SRI *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ false } >
      <MostrarConsultaAutorizacionSri
        cerrarModal={()=> {

          //this.setState( { estatusAbiertoModal_ConsultarSri : false,})
        }}
        pepeJson={pepeJsonConsultaSri}      
      />
    </Modal>   
  
{/************ MODAL PARA VER LOS RESULTADOS DE RESOLVER SRI *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ false } >
      <MostrarResultadosResolver
        cerrarModal={()=> {
          //this.setState( { estatusAbiertoModal_ResolverSri : false,})
        }}
        //documentoTipoYnum={"RETENCION: " + rowRegistroClon.Retencion }
        resolverJson={resolverJson}      
      />
    </Modal>    
    
{/************ MODAL PARA VER EL PANEL DE LA FACTURA *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ false } >
    <MostrarPanelDeLaFactura
        cerrarModal={()=> {
          //this.setState( { estatusAbiertoModal_PanelFactura : false,})
        }}
        row={rowRegistroClon}      
      />
  </Modal>  
  
{/************ MODAL PARA PEDIR LA CLAVE ESPECIAL y luego anular*************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ false } >
    <PedirClaveEspecial
        cerrarModal={(claveCorrecta)=> {
          //this.setState( { estatusAbiertoModal_PedirClaveEspecial : false,})
          {/* al ser la clave correcta, entonces ANULO */}          
          //if (claveCorrecta) anularFacturaEnBDD()        
        }}
        claveEnBDD={valueDC.licencia.ClaveOperacionesEspeciales}      
        //subtitulo={"anular la retención: " + rowRegistroClon.Retencion}
      />
  </Modal>  

</div> //{/*  divMaster */}
) //del return interno
}}
</AppContextConsumer>
)//Del return externo 
} //del componente

/*
render(){
if (this.state.nombreComponenteParaVer=='verPdfFacturaSri') {
  return (<VerPdfOk
    documentoTitulo={"FACTURA"}
    documentoArchivo={"FA_" + this.state.estab + "-" + this.state.punto + "-" + this.state.numFac }
    corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
    tipoSriSN={"S"} //es un documento del sri?
    activarMenu={ this.activarLista } 
    />)
}

if (this.state.nombreComponenteParaVer=='verPdfFacturaSimple') {
  return (<VerPdfOk
    documentoTitulo={"FACTURA"}
    documentoArchivo={"FS_" + this.state.estab + "-" + this.state.punto + "-" + this.state.numFac }
    corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
    tipoSriSN={"N"} //es un documento del sri?
    activarMenu={ this.activarLista } 
    />)
}

if (this.state.nombreComponenteParaVer=='lista') {
  return(<this.Componente_ListaFacturas/>)
}
  
} //del render
} //de la clase
*/

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return Componente_ListaRegistros()

} // del export 

