//FALTA: falta UN boton para editar inventario (solo esta para consultar)
//FALTA: faltra UN boton para crear nuevo item del inventario (solo esta para consultar)
//este form es solo para las compras con FACTURA
//para las compras a RISE/rimpe popular/nota de venta. Se debe hacer otro formulario
//FALTA: se debe ir guardando en el local storage, cada item (suponte que se vaya la luz o se salga por error)

import React, {useContext} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label, InputGroup, CardGroup, ListGroup, ListGroupItemHeading} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import DataTable from 'react-data-table-component'
import buscarPhpPath, { aceptarSoloEnteros,aceptarDecimales,verificarSiTextoEsPurosDigitos,generarPresupuestoPDFconSwal,generarNotaEntregaPDFconSwal,generarFacturaDeVentaSimple_ConSwal,determinarFormatoImpresoraGrande,hayInternet,procesarCompraEnBDD  } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalBotonAceptarBucle,mostrarSwalPos,mostrarSwalUnSegundo,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,devolverFechaHoyTextoYYYYMMDD,textoDelCombo,formatearFechaHoraDevuelveDDMMYY,sleepPepe } from './lib_basica'
//del context
import {AppContextConsumer} from './ContextBase'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDownload, faWindowClose,faClipboard ,faFlag,faParking,faFileAlt,faDoorClosed,faDoorOpen, faBroom,faBarcode, faArrowDown,faArrowCircleDown,faArrowAltCircleDown,faEye,faPager,faCaretDown,faChevronCircleDown,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faMoneyBillAlt, faCashRegister,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faEllipsisV,faSyncAlt, faArrowAltCircleLeft, faGrinTongueSquint } from '@fortawesome/free-solid-svg-icons' 
//Modales
import IncModProveedor from './IncModProveedor'
import IncModInventario from './IncModInventario'
import TabelaProveedoresBuscador from './TabelaProveedoresBuscador' 
import TabelaInventarioBuscador from './TabelaInventarioBuscador' 
import PedirTextoGenericoSerie from './PedirTextoGenerico' //Para pedir/cambiar la serie (le puse 100 caractares maximo)
import PedirCantidad from './PedirCantidad'
import PedirDescuentoP from './PedirDescuentoP'
import PedirPrecioEspecifico from './PedirPrecioEspecifico'
import PedirTextoComplementario from './PedirTextoComplementario' //Para pedir texto complementario de la Factura (maximo 300)
import PedirFormaDePago from './PedirFormaDePago'
import PedirDiasCredito from './PedirDiasCredito'

const miEstilacho={
	table: {
		style: {
			minHeight: '25vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'ITEMS SELECCIONADOS'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      background: 'pink', //ok hotpink
      color: 'gray',  //ok lavender
      fontSize:'16px',   //ok
      fontWeight: 'bold'
    },
  },
  rows: {    
    style: {
      minHeight: '40px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',
    }
  },  
  cells: {
    style: {
      fontSize:'16px',
      cursor: 'pointer',
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',                 
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL      
    },
  },
}

export default class ComprarConFactura extends React.Component {
state={         
        //miFilaAzulIndice es el INDICE en el que se encuentra en AZUL una fila
        miFilaAzulIndice:-1, //OJO: arranco con -1 importante
        datosInv:[],  //contiene todas las filas del inventario (pocas columnas)
        datosFullProv:[],  //contiene todas las filas de los proveedores (pocas columnas)
        opcionProveedor_imc:'', //para saber si deseo incluir nuevo proveedor, modificar o consultar
        opcionInv_imc:'c',//Solo lo uso para CONSULTAR un producto del inventario
        cedulaCandidata:'', //numero de cedula cuando deseo agregar un proveedor desde compras
        datosGrid:[], //datos para ir alimentando la Tabela de compra
        //Datos del proveedor ponchado
        proveedorPonchado:false,
        proveedorIDr:0,
        proveedorCedula:'',
        proveedorIdSecundario:'',
        proveedorNombre:'', //lo uso para hacer el envio de la factura x correo
        proveedorNombreComercial:'', //lo uso para actuaizar la latebla de presupuestos (solo cuando modifico un presupuesto o plan)
        proveedorEmail:'', //lo uso para hacer el envio de la factura x correo        
        proveedorActivo:null, //Si, No
        //Datos del inventario ponchado (al darle ENTER en el cuadrito negro)
        inventarioNegroPonchado:false,
        inventarioNegroIDr:0,
        inventarioNegroCodigoPrincipal:'', //se aplica cuando estoy en compras y deseo crear un producto
        inventarioNegroActivo:null, //Si, No                
        inventarioNegroParaCompra:null,
        //Variables para los modales
        estatusAbiertoModal_IncModProv:false, //para abrir el modal de incluir/modificar proveedor
        estatusAbiertoModal_IncModInv : false, //por ahora solo lo uso para CONSULTAR un producto (para el futuro se debe CREAR y EDITAR)
        estatusAbiertoModal_TabelaProveedoresBuscador:false,
        estatusAbiertoModal_TabelaInvBuscador:false,
        estatusAbiertoModal_Cantidad:false,          
        estatusAbiertoModal_Serie:false,                  
        estatusAbiertoModal_DescuentoP:false,
        estatusAbiertoModal_PrecioEspecifico:false,
        estatusAbiertoModal_FormaDePago:false,
        estatusAbiertoModal_TextoComplementario:false,  
        estatusAbiertoDropdownMenu_InvMas:false,
        estatusAbiertoDropdownMenu_FacturaMas:false,        
        estatusAbiertoModal_PedirDiasCredito:false,  
}     

almacenDef=null; //codigo del Almacen definitivo para alimentar el inventario    
//productoNegro, en este objeto ya tengo el Producto Listo en punta para ser ingresado al grid    
productoNegro=null //Va a ser un objeto JSON listo para meterlo al grid de venta
alPedirCantidadNuevoModificar="N" //al pedir la cantidad a vender, necesito saber si es de un producto Nuevo o estoy Modificando la cantidad en el grid
miFilaAutonumerico=1 //Me sirve para ir calculando la fila en el grid (a su vez es una clave para el grid) 
//otras variables
miFilaAzulJson=null //Variable tipo Json,para enviarla completa a los modales (pedir cantidad,descuento,precio1234,..... etc)
afectaItems1T=null //Para saber si una accion, me afecta al item azul o a todas las filas
deseoPvpSinCon=null //Sin.Con. Al llamar al modal de cambiar precio especifico
datosFormaPago=null //Para saber como ha pagado. (el modal de la forma de pago me devuelve un JSON)
valueDC=null //Aqui hago una copia de los valores del context
sePudoLeerInv=false //para saber si se pudo leer el Inventario
sePudoLeerProv=false //para saber si se pudo leer la tabla de proveedores
intentosInv=0 //me sirve para varios intentos para leer el inventario 
intentosProv=0 //me sirve para varios intentos para leer los proveedores
//esta variable tipo Objeto/Json, me sirve para mandar a PHP como Cabecera y Pie
//los datos posibles son: AlContado,Plazo,Cancelado,Observaciones,TextoComplementario,IDr(codigo del Proveedor),Estab,Punto,NumFactura.....
caPiePhp={}
//aqui van los sutotales de la factura (realmente son varios casilleros). (se envia a PHP). La funcion que llena esta JSON es calcularTotal()
subtotalFacturaPhp={}
//estas 8 variables, me sirven para saber exactamente que columnas de precio mostrar en el buscador de Inventario (fue la unica manera de resolverlo)
//se podria ver 0 1 o 2 columnas nada mas. (pero en compras, no amerita) (en ventas si)
pvp1InventarioSinIvaVerDefinitivo=null
pvp1InventarioConIvaVerDefinitivo=null
pvp2InventarioSinIvaVerDefinitivo=null
pvp2InventarioConIvaVerDefinitivo=null
pvp3InventarioSinIvaVerDefinitivo=null  
pvp3InventarioConIvaVerDefinitivo=null
pvp4InventarioSinIvaVerDefinitivo=null
pvp4InventarioConIvaVerDefinitivo=null
//***** fin de las 8 variable
//***** variables para las columnas opcionales de BlocK y OpCrea
prov_deseoBlockSN="S" //para saber si deseo traerme el campo BlockDeNotas de proveedores (generalmente S)
prov_deseoOpSN="N" //para saber si deseo traerme el campo OpCrea de proveedores (generalmente N)
inv_deseoBlockSN="S" //para saber si deseo traerme el campo BlockDeNotas del inventario (generalmente S)
inv_deseoOpSN="N" //para saber si deseo traerme el campo OpCrea del inventario (generalmente N)

//Estructura para el DataTable de la compra
miEstructuraDataTable=[  
  {  
      name:'FILA',
      selector:row => row.Fila, //Aqui debe ir un valor/orden/fila/Autonumerico que sirva de clave para el dataTable
      center:true,
      //grow:0.2,
      omit:true, //debe estar oculta.
  },
  {  
      name:'IDR',
      selector:row => row.IDr,
      center:true,
      //grow:0.2,
      omit:true, //debe estar oculta.
  },   
  {
      name:'PRINCIPAL',
      selector:row => row.CodPrincipal,
      sortable:false,
      left:true,
      compact:true,
      grow:0.5, //es una medida relativa (1 es mi referencia)
  },  
  {
      name:'DESCRIPCION',
      selector:row => row.DescripcionModificada,  //realmente nunca va a ser modificada (lo tengo asi para mantener la compatibilidad de otros MODALES)
      sortable:false,
      left:true,
      compact:true,
      grow:1.5,
      //width:'50%', //probando
  },         
  {  
      name:'CANT',
      selector:row => row.Cant,  //cant comprada
      sortable:false,
      center:true,
      grow:0.2,
      compact:true, //padding 0
  },      
  {  
      name:'COSTOunit',  //Nuevo costo real unitario sin iva
      selector:row => row.PvpSinIva, //Es el costo pero lo tengo como Pvp para mantener la compatibilidad con otros Modales
      sortable:false,
      center:true,
      grow:0.3,
      compact:true, //padding 0
      //omit: !this.props.verPvpSinIva,
  },  
  {  
      name:'COSTOunitConIva',   
      selector:row => row.PvpConIva,  
      sortable:false,
      center:true,
      grow:0.3,
      compact:true, //padding 0
      omit: true,
  },  
  {  
      name:'TOTAL', //Total renglon sin iva (restado el descuento)
      selector:row => row.Total,
      sortable:false,
      center:true,
      grow:0.3,
      compact:true, //padding 0
  },      
  {  
      name:'DCTO%unit',
      selector:row => row.DescP, //Descuento Unitario en porcentaje
      sortable:false,
      center:true,
      grow:0.2,
      compact:true, //padding 0
  }, 
  {  
      name:'DCTO$unit',
      selector:row => row.DescD, //Descuento unitario en dolares
      sortable:false,
      center:true,
      grow:0.2,
      compact:true, //padding 0
  },                  
  {  
      name:'GRAVA', //Si, No
      selector:row => row.GravaIva,
      sortable:false,
      center:true,
      grow:0.1,
      compact:true, //padding 0
      omit:false,   
      hide:'sm',  
  },   
  {  
      name:'COSTOP',  
      selector:row => row.CostoP,
      sortable:false,
      center:true,
      //grow:0.1,
      compact:true, //padding 0
      omit:true,  //debe estar oculta. (solo la tengo de referencia)
  },  
  {  
      name:'COSTOU',  
      selector:row => row.CostoU,
      sortable:false,
      center:true,
      //grow:0.1,
      compact:true, //padding 0
      omit:true,  //debe estar oculta. (solo la tengo de referencia)
  },  
  {
      name:'SERIE',
      selector:row => row.DescripcionAmpliada, //Para la serie (pero nunca se usan los 300 caractedes de la BDD, quiza 100)
      sortable:false,
      left:true,
      compact:true,
      grow:0.15,
  }, 
  {
      name:'SECUNDARIO',
      selector:row => row.CodSecundario,
      sortable:false,
      left:true,
      compact:true,
      grow:0.6,  
  }, 
  {
      name:'AUXILIAR',
      selector:row => row.CodAuxiliar,
      sortable:false,
      left:true,
      compact:true,
      grow:0.6,  
  }, 
];

filaCondicional = [
  //fila NO seleccionada
  {
    when: row => (row.Fila != this.state.miFilaAzulIndice ),
    style: row => ({ 
      backgroundColor: 'white' ,
      color: 'dimgray',
     }),    
  },  
  {
    //segun el SELECTOR
    when: (row) => (row.Fila == this.state.miFilaAzulIndice ),
    style: row => ({ 
      backgroundColor: this.valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

desmontar=()=>{
  //la idea es desmontar, limpiar variables 
  this.setState({datosInv:[],datosFullProv:[],datosGrid:[]})
}

salirPorBoton=()=>{
  //FALTA: al tratar de salir, se debe revisar si hay productos en el grid. tipo Xofie  
  this.props.activarMenu()
  this.desmontar()
}

ParteTituloMasCerrar=()=>{   
return (
// parte para los datos del cliente
<div id="divTitulo" name="divTitulo" style={{height:'60px',borderStyle:'solid', borderWidth:'3px',borderColor:'dimgray'}}>        
  <Row style={{margin:'0'}}>
      <Col xs="10" style={{paddingTop:'5px'}}>
        <Label id="labelTipoDoc" name="labelTipoDoc" style={{fontSize:'30px'}} ></Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'7px'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ this.salirPorBoton()  }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>  
  </Row>
</div>
)}

validarATS=()=>{
//aqui valido el tipo de compra (fisica/electronica)  , numero de autorizacion y sustento tributario
//puede ser que el contribuyente, no este obligado a esta parte
if (this.valueDC.sistema.atsComprasValidar=="No")
  return true

//Compra Fisica o Electronica
if (document.getElementById('radioFisica').checked==false && document.getElementById('radioElectronica').checked==false ){
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar si la factura de compra es Física o Electrónica")
  return false  
}  

//Autorizacion
let txtLoco=document.getElementById('txtAutorizacion').value.trim()
if (txtLoco.length==0 ){
  mostrarSwalBotonAceptar("error","ATENCION","El numero de Autorización no puede estar vacío")
  return false  
}  
if (!verificarSiTextoEsPurosDigitos(txtLoco)){
  mostrarSwalBotonAceptar("error","ATENCION","El numero de Autorización debe contener solo digitos")
  return false  
} 
if (document.getElementById('radioFisica').checked && txtLoco.length!=10){
  mostrarSwalBotonAceptar("error","ATENCION",`El numero de Autorización debe contener 10 digitos, solo hay ${txtLoco.length}`)
  return false  
} 
if (document.getElementById('radioElectronica').checked && txtLoco.length!=49){
  mostrarSwalBotonAceptar("error","ATENCION",`El numero de Autorización debe contener 49 digitos, solo hay ${txtLoco.length}`)
  return false  
} 
//Sustento (no hace falta validar ya que siempre hay un valor previamente pinchado)
return true
}

validarUltima=()=>{
  //esta debe ser la ultima validacion, se chequea que haya un proveedor valido, grid con filas y monto a pagar >0
  if (!this.state.proveedorPonchado){    
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un proveedor")
    return false  
  }
  if (this.state.proveedorPonchado && this.state.proveedorActivo=="No" ){    
    mostrarSwalBotonAceptar("error","ATENCION","El proveedor no se encuentra activo")
    return false  
  }
  //----------------------------------------------------------------------------------------------------------
  //------ NUMERO DE LA FACTURA (son 3 validaciones). (son 3 cuadritos) 001-002-123456789 --------------------
  //----------------------------------------------------------------------------------------------------------
  let txtLoco=null //me va a servir para cualquier cosa (estab, punto, numFac, y fecha)
  //*************** establecimiento
  txtLoco= document.getElementById('txtEstab').value.trim()
  if (txtLoco.length !=3 ){
    mostrarSwalBotonAceptar("error","ATENCION","El establecimiento del proveedor debe tener 3 digitos")
    return false  
  }  
  if (!verificarSiTextoEsPurosDigitos(txtLoco)){
    mostrarSwalBotonAceptar("error","ATENCION","El establecimiento del proveedor debe tener solo numeros")
    return false  
  }  
  //*************** punto
  txtLoco= document.getElementById('txtPunto').value.trim()
  if (txtLoco.length !=3 ){
    mostrarSwalBotonAceptar("error","ATENCION","El punto del proveedor debe tener 3 digitos")
    return false  
  }  
  if (!verificarSiTextoEsPurosDigitos(txtLoco)){
    mostrarSwalBotonAceptar("error","ATENCION","El punto del proveedor debe tener solo numeros")
    return false  
  }  
  //*************** num factura
  txtLoco= document.getElementById('txtNumFac').value.trim()
  if (txtLoco.length==0 ){
    mostrarSwalBotonAceptar("error","ATENCION","El numero de Factura  debe contener digitos")
    return false  
  }  
  if (!verificarSiTextoEsPurosDigitos(txtLoco)){
    mostrarSwalBotonAceptar("error","ATENCION","El numero de Factura debe contener solo digitos")
    return false  
  }  

  //Fecha de factura 
  if (!document.getElementById('txtFechaFactura').value){
    mostrarSwalBotonAceptar("error","ATENCION","La fecha de la Factura es inválida")
    return false  
  }
//------ FIN DE NUMERO DE FACTURA  --------------------

//validar ATS
if (!this.validarATS())
  return false

//----------------------------------------------------------------
//------ Grid vacío y total a pagar ------------------------------
//----------------------------------------------------------------
  if (this.state.datosGrid.length<=0){    
    mostrarSwalBotonAceptar("error","ATENCION","Aún no ha seleccionado ningún producto del inventario")
    return false  
  }
  if (this.subtotalFacturaPhp.total<=0){    
    mostrarSwalBotonAceptar("error","ATENCION","El monto total debe ser mayor que cero")
    return false  
  }

  return true
}
  
buscarProductoPorPrincipal=(txtPrincipal)=>{
//OJO: es sensible a minusculas y mayusculas (pero le mando ya en minuscula)
//Recorro el inventario hasta encontrar el producto escrito
for(let i=0; i< this.state.datosInv.length; i++ ){
  let CodPrincipalBDD=this.state.datosInv[i].CodPrincipal
  CodPrincipalBDD=CodPrincipalBDD.toLocaleLowerCase()
  if (CodPrincipalBDD==txtPrincipal){     
    this.productoNegro={      
        //por ahora va 0, luego al meter al grid se determina el valor correcto
        Fila: 0,
        IDr: this.state.datosInv[i].IDr,
        CodPrincipal: this.state.datosInv[i].CodPrincipal,
        DescripcionModificada:this.state.datosInv[i].Descripcion,        
        Cant:this.valueDC.sistema.cantidadPredeterminadaParaVender,
        //Estos dos campos realmente se refieren al COSTO (pero lo tengo como Pvp para mantener la compatibilidad con otros Modales)
        PvpSinIva:this.state.datosInv[i].CostoU, //este luego el usuario tiene que editarlo
        PvpConIva:"0.0000",  
        Total:"0.00", //se actualiza luego
        DescP:"0.00",
        DescD:"0.0000",
        Precio01234:"0", //siempre cero para que en el modal de cambiar costo, ya me aparezca el costo 
        GravaIva: this.state.datosInv[i].TipoIvaCompra==0 ? 'No' :'Si', 
        CostoP:this.state.datosInv[i].CostoP,      
        CostoU:this.state.datosInv[i].CostoU,      
        DescripcionAmpliada:'', //Realmente se refiere a la Serie
        CodSecundario: this.state.datosInv[i].CodSecundario,
        CodAuxiliar: this.state.datosInv[i].CodAuxiliar,
    }
    //preparo lo que el cliente va a mirar al seleccionar en poducto: Descripcion + Stock (no le puse el precio ya que puede crear confusion)
    let nombreProductoMostrar=this.productoNegro.DescripcionModificada + " // Stock Actual: " + this.state.datosInv[i].Adef_Stock
    document.getElementById("txtNombreProducto").value=nombreProductoMostrar
    document.getElementById("btnConsultarInv1").style.visibility='visible'
    document.getElementById("btnEnterInv").style.visibility='visible'
    this.setState({inventarioNegroPonchado:true,inventarioNegroIDr:this.productoNegro.IDr,inventarioNegroCodigoPrincipal:this.productoNegro.CodPrincipal,
      inventarioNegroActivo:this.state.datosInv[i].Adef_Activo,inventarioNegroParaCompra:this.state.datosInv[i].ParaCompra})
    break //rompo el ciclo for
  }  //del if del item encontrado
} //Del for
}

prepararEscenarioParaNuevoNegro=()=>{
  //preparo todo para un nuevo producto
  document.getElementById('txtNegro').value=''
  document.getElementById('txtNombreProducto').value=''
  document.getElementById("btnConsultarInv1").style.visibility='hidden'
  document.getElementById("btnEnterInv").style.visibility='hidden'

  this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:"0",inventarioNegroCodigoPrincipal:'',
  inventarioNegroActivo:null,inventarioNegroParaCompra:null,})  
}

meterProductoNegroEnGridDeVenta=async()=>{    
let gridLoco = [...this.state.datosGrid ]

//numero de fila
this.productoNegro.Fila=this.miFilaAutonumerico
   
//empujo el item que estoy comprando
gridLoco.push(this.productoNegro) 

//Al nuevo producto le pongo el numero de fila que le corresponde en el grid
await sleepPepe(50)
this.setState({miFilaAzulIndice: this.miFilaAutonumerico++ })
await sleepPepe(50)
this.setState({datosGrid:gridLoco})
await sleepPepe(50)

//preparo todo para un nuevo producto
this.prepararEscenarioParaNuevoNegro()

this.calcularTotal()
}
  
validarAzul=()=>{
  //solo me permite saber si alguna fila del carrito este seleccionada
  if (this.state.miFilaAzulIndice<=0){    
    mostrarSwalBotonAceptar("warning","ATENCION","Debe seleccionar un item de compra")
    return false  
  }

  //copio la fila azul en un Json para mandarla luego a cualquier Modal
  for(let i=0; i<this.state.datosGrid.length;i++)
  if (this.state.datosGrid[i].Fila==this.state.miFilaAzulIndice){
      this.miFilaAzulJson=this.state.datosGrid[i]
      break
   }

  return true
} 

gestionarComprarProductoNegro=()=>{
  //me permite meter UN nuevo item al grid de ventas
  let miCasilla=document.getElementById("txtNegro").value.trim().toLocaleLowerCase()
  if (miCasilla.length==0) return
  
  if (!this.state.inventarioNegroPonchado){
    mostrarSwalBotonAceptar("error","ATENCION","El producto no existe ")
    return
  }

  /*
  if (this.state.inventarioNegroActivo=="No"){
    mostrarSwalBotonAceptar("error","ATENCION","El producto no está activo")
    return
  }
  */

  //Reviso que realmente sea producto para COMPRAR
  if (this.state.inventarioNegroParaCompra=="0"){
    mostrarSwalBotonAceptar("error","ATENCION","Este producto no es para comprar")
    return
  }

  this.alPedirCantidadNuevoModificar="N"
  if (this.valueDC.sistema.ventanaParaPedirCantidadEnVentas=="Si"){
    this.setState({estatusAbiertoModal_Cantidad:true }) 
  }
  else{               
     this.meterProductoNegroEnGridDeVenta()
  }            
}

ParteProveedor=()=> {   
  //Cuadros de texto referente al proveedor: ruc, nombre, nombre
  return (
  <div id="divParteProveedor" name="divParteProveedor" style={{background:'white', marginLeft:'2%', marginRight:'2%',marginTop:'1%',paddingLeft:'10px',paddingRight:'10px',borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid',borderWidth:'2px',borderColor:'dimgray'}}>         
    <Row style={{margin:'0'}}>
      {/* columna 1: para ruc */}
      <Col xs="5" md="4" style={{padding:'0'}}>
        <label style={{color:'dimgray'}}>Ruc</label>
        <Input style={{paddingLeft:'2%',paddingRight:'0',paddingTop:'0',paddingBottom:'0' }} placeholder="Escriba el Ruc y pulse enter" maxLength="20" name="txtCedulaProveedor" id="txtCedulaProveedor"
          onChange={()=> {          
            let cadenaLoca=document.getElementById("txtCedulaProveedor").value
            cadenaLoca=cadenaLoca.trim().toLocaleLowerCase()
            this.filtrarPorCedula(cadenaLoca)
          }}
          onKeyPress = {async(e) => { 
            //esta parte me sirve cuando doy enter y el cliente NO existe. Me pegunta si deseo crearlo y llama al formulario para crear UN cliente nuevo
            let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
            let miCasilla=document.getElementById("txtCedulaProveedor").value.trim() 
            if ( miKey==13 && miCasilla.length >0 && !this.state.proveedorPonchado) {
              //pregunto si desea crear un proveedor nuevo
              let miMensaje="El proveedor no existe, Desea crearlo ?"
              let miResp = await mostrarSwalConfirmacionEliminarAnular('NUEVO',miMensaje)
                if (miResp.isDenied) {
                    if (await hayInternet()==false) return 
                    //cuando desea agregar un proveedor desde aqui, debo enviarle la cedulaCandidata
                    this.setState({ opcionProveedor_imc:"i",cedulaCandidata : miCasilla, estatusAbiertoModal_IncModProv:true})
                }                
            }            
          }}            
        />            
      </Col>
      {/* columna 2: nombre del proveedor */}
      <Col xs="7" md="8" style={{paddingLeft:"3%", paddingRight:'0',paddingTop:'0',paddingBottom:'0'}} >
        <label style={{color:'dimgray'}}>Proveedor</label>
        <Input style={{paddingLeft:'2%',paddingRight:'0',paddingTop:'0',paddingBottom:'0' }} disabled placeholder="Nombre del Proveedor"  name="txtNombreProveedor" id="txtNombreProveedor"/>
      </Col>  
    </Row>
  {/* aqui vienen los botones: BUSCAR, EDITAR, CONSULTA className="classVerOcultar"*/}
    <Row style={{marginTop:'5px',marginBottom:'0',marginLeft:'0',marginRight:'0'}}>  
      <Col style={{padding:'0'}}>      
        <Button id="btnBuscadorProveedor" name="btnBuscadorProveedor" className="btnCliFact" color="warning" 
          onClick={ (e) => { 
            this.setState({ estatusAbiertoModal_TabelaProveedoresBuscador : true })            
          }}>BUSCAR
        </Button>
        <Button id="btnModificarProveedor" name="btnModificarProveedor" className="btnCliFact"  color="warning" 
          onClick={ async(e) => { 
            if (await hayInternet()==false) return 
            this.setState( {opcionProveedor_imc:'m',estatusAbiertoModal_IncModProv : true} )
          }}>EDITAR
        </Button>
        <Button  id="btnConsultarProveedor" name="btnConsultarProveedor" className="btnCliFact"  color="warning" 
          onClick={ async(e) => {
            if (await hayInternet()==false) return 
            this.setState( {opcionProveedor_imc:'c',estatusAbiertoModal_IncModProv : true} )
          }}>CONSULTAR
        </Button>
      </Col>    
    </Row>  
  </div>
)}    
  
ParteEncabezadoFactura=()=> {   
//Al final del div le pongo un borde inferior para que SEPARE de la parte de la compra
return (
  <div id="Encabezado" style={{background:'white',marginLeft:'2%',marginRight:'2%',marginBottom:'5px',paddingLeft:'10px', paddingRight:'10px',paddingBottom:'5px',borderLeftStyle:'solid',borderRightStyle:'solid',borderBottomStyle:'solid',borderWidth:'2px',borderColor:'dimgray'}}>           
    <div id="divSubEncabezado1" >
      <Row style={{margin:'0',}}>
        {/* Columna para el numero de factura */}
        <Col xs="8" md="4" lg="3" style={{paddingLeft:'0',paddingRight:'0',paddingTop:'0',paddingBottom:'0',}}>
          <Label>Numero de Factura</Label> 
          <div style={{display:'flex'}}> 
            <Input maxLength="3" id="txtEstab" style={{width:'15%',padding:'0',textAlign:'center'}} onKeyPress = {(e) => { aceptarSoloEnteros(e) }}></Input>
            <Input disabled value="-" style={{width:'3%',padding:'0',textAlign:'center'}}></Input>
            <Input maxLength="3" id="txtPunto" style={{width:'15%',padding:'0',textAlign:'center'}} onKeyPress = {(e) => { aceptarSoloEnteros(e) }}></Input>
            <Input disabled value="-" style={{width:'3%',padding:'0',textAlign:'center'}}></Input>
            <Input maxLength="9" id="txtNumFac" style={{width:'43%',padding:'0'}} onKeyPress = {(e) => { aceptarSoloEnteros(e) }}></Input>
          </div>
        </Col>  
        {/* Columna para la fecha */}
        <Col xs="4" md="3" lg="2" style={{marginLeft:'0%',paddingLeft:'1%',paddingRight:'0',paddingTop:'0', paddingBottom:'0%',}}>
          <Label for="txtFechaFactura">Fecha de Factura</Label>  
          <div>
            <Input type="date" id="txtFechaFactura" style={{padding:'0'}}></Input>
          </div>
        </Col>  
      </Row>     
    </div >

    {/* Se puede mostrar u ocultar segun las preferencias del sistema */}
    <div id="divSubEncabezado2_OnOff" style={{marginTop:'4px'}}>
      <div id="divFE" >           
        <Row style={{margin:'0',}}>
          {/* Columna para el tipo de compra */}
          <Col xs="6" style={{paddingLeft:'1%',paddingRight:'2%',paddingTop:'0',paddingBottom:'0',borderTopStyle:'solid',borderBottomStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid',borderWidth:'1px',borderColor:'silver'}}>
            <Label>Tipo de compra</Label>
            <br/>
            <Row>
              <Col xs="5"> 
                <Label><Input type="radio" id="radioFisica" value="1" name="radioFE" style={{marginBottom:'0%'}}/> Física</Label>
              </Col>
              <Col xs="7" style={{textAlign:'right',}}> 
                <Label><Input type="radio" id="radioElectronica" value="0" name="radioFE" /> Electrónica</Label>
              </Col>
            </Row>  
          </Col>  
        {/* Columna de Autorizacion */}
          <Col xs="6" style={{marginLeft:'0%',paddingLeft:'2%',paddingRight:'2%',paddingTop:'0', paddingBottom:'0',borderTopStyle:'solid',borderBottomStyle:'solid',borderRightStyle:'solid',borderWidth:'1px',borderColor:'silver'}}>
            <Label for="txtAutorizacion">Autorización</Label>
            <br/>          
            <Input maxLength="49" id="txtAutorizacion" style={{padding:'0'}} onKeyPress = {(e) => { aceptarSoloEnteros(e) }}></Input>
          </Col>  
        </Row>     
      </div >

      <div id="divSustento" style={{marginTop:'4px'}}>           
        <Row style={{margin:'0',}}>
          <Col xs="12" md="8" lg="6" style={{padding:'0',}}>
            <ButtonGroup>
              <Label for ="comboSustento" style={{marginRight:'10px',}}>Sustento</Label>
              <Input type="select" name="comboSustento" id="comboSustento" style={{paddingLeft:'1%',paddingRight:'0',paddingTop:'0',paddingBottom:'0'}} >
                <option value="01">01 - Crédito Tributario para declaración de IVA (Servicios y Bienes distintos de Inventario y Activo Fijo)</option>
                <option value="02">02 - Costo o Gasto para declaración de Imp. a la Renta (Servicios y Bienes distintos de Inventario y Activo Fijo)</option>
                <option value="03">03 - Activo Fijo. Crédito Tributario para declaracion de IVA</option>
                <option value="04">04 - Activo Fijo. Costo o Gasto para declaración de Imp. a la Renta</option>
                <option value="06">06 - Inventario. Crédito Tributario para declaración de IVA</option>          
                <option value="07">07 - Inventario. Costo o Gasto para declaración de Imp. a la Renta)</option>
              </Input>
            </ButtonGroup>
          </Col>  
        </Row>     
      </div >
    </div>
  </div>
)}    
    
ParteBotoneraSuperior=()=>{   
return (
<div id="divParteBotoneraSuperior" name="divParteBotoneraSuperior" style={{background:'dimgray', marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%', borderStyle:'solid',borderColor:'black',borderWidth:'1px'}}>
  {/* Cuadrito negro,flechita hacia abajo, descripcion  */}
  <Row style={{margin:'0',padding:'4px'}}>
    <Col xs="4" md="3" style={{padding:'0',}} >
      <Input placeholder="Código y pulse enter" maxLength="30" name="txtNegro" id="txtNegro"
        onChange = {async(e) => { 
          //let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
          this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:0,inventarioNegroCodigoPrincipal:'',inventarioNegroActivo:null,})
          document.getElementById("txtNombreProducto").value=""
          document.getElementById("btnConsultarInv1").style.visibility='hidden'
          document.getElementById("btnEnterInv").style.visibility='hidden'
          this.productoNegro=null //pongo vacio en mi JSON del futuro producto que se va a meter al grid de venta                             
          let miCasilla=document.getElementById("txtNegro").value.trim().toLocaleLowerCase()
            if ( miCasilla.length >0){
                //hay que ponerle AWAIT para que de chance que se actualice el estado
                await this.buscarProductoPorPrincipal(document.getElementById("txtNegro").value.trim().toLowerCase()) 
              }            
        }}
        onKeyPress = {(e) => { 
          let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
          if (miKey==13 ){
            this.gestionarComprarProductoNegro()
              }            
        }}/>
    </Col>
    <Col xs="2" md ="1" style={{paddingLeft:'4px',paddingRight:'4px',textAlign:'center',}} >
        <Button style={{width:'100%',height:'38px',padding:'0'}} id="btnEnterInv" name="btnEnterInv" color="warning" 
          onClick={ () => { this.gestionarComprarProductoNegro() }}>
          <span style ={{ fontSize:"22px",}}>            
            <FontAwesomeIcon color="black" icon={faDownload }/>
            </span>
        </Button>    
    </Col>  
    <Col xs="6" md="8" style={{padding:'0',}} >
      <Input disabled name="txtNombreProducto" id="txtNombreProducto"/>
    </Col>
  </Row>
  <Row style={{margin:'0',paddingTop:'0',paddingBottom:'4px', paddingLeft:'4px',paddingRight:'4px'}}>
    <Col style={{padding:'0'}} >
      <Button id="btnBuscadorInv" name="btnBuscadorInv" className="btnGestorProductoFact"  
        onClick={ (e) => { 
          this.determinarPVPvisibleEnBuscadorDeInventario() //determino que columna de PVP se requiere ver. Ademas se determina si tambien quiere ver la culumna con iva            
          this.setState({ estatusAbiertoModal_TabelaInvBuscador : true })            
        }}>BUSCAR    
      </Button>
      <Button id="btnRefrescarInv" name="btnRefrescarInv" className="btnGestorProductoFact"  
        onClick={ () => { 
          this.intentosInv=0
          document.getElementById('txtNegro').value=''
          document.getElementById('txtNombreProducto').value=''
          document.getElementById("btnConsultarInv1").style.visibility='hidden'
          document.getElementById("btnEnterInv").style.visibility='hidden'
          this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:0,inventarioNegroCodigoPrincipal:'',
          inventarioNegroActivo:null,inventarioNegroParaCompra:null,miFilaAzulIndice: 0 })
          this.refrescarInventarioExclusivamente() 
        }}>REFRESH
      </Button>
      <Button id="btnConsultarInv1" name="btnConsultarInv1" className="btnGestorProductoFact" 
        onClick={ (e) => { 
          this.setState( {opcionInv_imc:'c',estatusAbiertoModal_IncModInv : true} )
        }}>CONSULTAR     
      </Button>      
    </Col>
  </Row>

  {/* Botones de cantidad,Modificar Descr,Descuento.... etc 8 botones */}
  <Row style={{margin:'0',paddingTop:'0',paddingBottom:'6px',paddingLeft:'6px',paddingRight:'6px'}}>
    <Col style={{padding:'0'}}>
      <ButtonGroup>
        <Button className="menuItemsVenta" id="btnInvCant" name="btnInvCant" color="info" 
          onClick = { () => {
            if (!this.validarAzul()) return
            this.alPedirCantidadNuevoModificar="M"
            this.setState({estatusAbiertoModal_Cantidad:true }) 
          }}>
          <FontAwesomeIcon color="#303030" icon={faLayerGroup}/>
        </Button>
        <Button className="menuItemsVenta" id="btnInvPorc" name="btnInvPorc" color="info"  
          onClick = { () => {
            if (!this.validarAzul()) return
            this.afectaItems1T='1'
            this.setState({estatusAbiertoModal_DescuentoP:true }) 
          }}>
          <FontAwesomeIcon color="#303030" icon={faPercent}/>
        </Button>
        <Button className="menuItemsVenta"  id="btnInvPvpSinIva" name="btnInvPvpSinIva" color="info" 
          onClick={ () => { 
            if (!this.validarAzul()) return
            this.deseoPvpSinCon='Sin'
            this.setState({estatusAbiertoModal_PrecioEspecifico:true})
          }}>
          <FontAwesomeIcon color="#303030" icon={faDollarSign}/>
        </Button>        
        <Button className="menuItemsVenta" id="btnInvPvpConIva" name="btnInvPvpConIva" color="info" 
          onClick={ () => {
            if (!this.validarAzul()) return
            this.deseoPvpSinCon='Con'
            this.setState({estatusAbiertoModal_PrecioEspecifico:true})
          }}>
          <FontAwesomeIcon color="#303030" icon={faCommentDollar}/>
        </Button>
        <Button className="menuItemsVenta" id="btnInvEliminar" name="btnInvEliminar" color="info" 
          onClick={ () => { 
            if (this.validarAzul()) this.eliminarProducto()
          }}>
            <FontAwesomeIcon color="#303030" icon={faTrashAlt}/>          
        </Button>
        <Dropdown className="menuItemsVenta" id="btnInvMas" name="btnInvMas" isOpen={ this.state.estatusAbiertoDropdownMenu_InvMas } direction="right"  
        toggle= { ()=> { this.setState( {estatusAbiertoDropdownMenu_InvMas:!this.state.estatusAbiertoDropdownMenu_InvMas} )}}>    
          <DropdownToggle className="menuItemsVenta" >
            <FontAwesomeIcon color="#303030" icon={faEllipsisH} />
          </DropdownToggle>
          <DropdownMenu style={{backgroundColor:'pink',}}>             
            <DropdownItem id="btnInvPorcTodos" name="btnInvPorcTodos" 
              onClick={ async() => {
                if (this.state.datosGrid.length<=0) return
                let miProductoEnviar="Seguro que desea poner el mismo % de descuento a todos los items"
                let miResp = await mostrarSwalConfirmacionEliminarAnular('ATENCION',miProductoEnviar)
                if (miResp.isDismissed) return //alomejor se arrepintio                    
                this.afectaItems1T='T'
                this.setState({estatusAbiertoModal_DescuentoP:true }) 
              }}>
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faPercent} /> Todos los items
            </DropdownItem>        
            <DropdownItem id="btnInvBorrarTodo" name="btnInvBorrarTodo" 
              onClick={async () =>{
                if (this.state.datosGrid.length<=0) return
                let miProductoEnviar="Seguro desea eliminar todos los items"
                let miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miProductoEnviar)
                if (miResp.isDismissed) return //alomejor se arrepintio     
                await sleepPepe(50)                  
                this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:"0",inventarioNegroCodigoPrincipal:'',
                  inventarioNegroActivo:null,inventarioNegroParaCompra:null,datosGrid:[],miFilaAzulIndice: -1})
                await sleepPepe(50)
                this.calcularTotal()
              }}>
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faBroom} /> Borrar todo
            </DropdownItem>                 
            <DropdownItem divider/>
            <DropdownItem id="btnInvSerie" name="btnInvSerie" 
              onClick={ () => {
                if (!this.validarAzul()) return
                this.setState({estatusAbiertoModal_Serie:true }) 
              }}> 
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faBarcode} /> Escribir Serie
            </DropdownItem>                
            <DropdownItem id="btnVerGrid" name="btnVerGrid" 
              onClick={ () => {
                alert(JSON.stringify(this.state.datosGrid,null,2))
              }}> 
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faBarcode} /> Ver JSON del grid
            </DropdownItem>                
            <DropdownItem divider/>             

            <DropdownItem > 
              <FontAwesomeIcon style={{color:'dimGray'}} icon={faDoorClosed} /> Cerrar Menú
            </DropdownItem>             

          </DropdownMenu>
        </Dropdown>    
      </ButtonGroup>
    </Col>    
  </Row>   {/* fin de Botones de cantidad,Modificar Descr,Descuento.... etc 8 botones */}

</div> //divParteBotoneraSuperior
)
} 

ParteGrid=()=>{
return(
<div id="divParteGrid" name="divParteGrid" style={{marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBotto:'0',width:'96%', borderStyle:'solid',borderColor:'black',borderWidth:'1px'}}>
  <DataTable
  //************ DATA TABLA PROPERTIES (basic) ***********/
  title='Items Seleccionados'
  columns={this.miEstructuraDataTable}
  data={this.state.datosGrid}
  conditionalRowStyles={this.filaCondicional}  
  keyField ='Fila' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
  //highlightOnHover  
  onRowClicked={(row) => { this.setState({miFilaAzulIndice:row.Fila})}}
  //  noDataComponent = "<h1><span>Carrito Vacío</span></h1>"
  noDataComponent = "Aún no has seleccionado productos..."

  //************ DATA TABLA PROPERTIES (row selection) ***********/
  //selectableRows //aparece el checkbox
  //selectableRowsVisibleOnly
  //selectableRowsHighlight
  //selectableRowsNoSelectAll

  //************ DATA TABLA PROPERTIES (row expander) ***********/
  //expandableRows //interesante

  //************ DATA TABLA PROPERTIES (sorting) ***********/
  //defaultSortField

  //************ DATA TABLA PROPERTIES (header) ***********/
  //actions //component or array of components

  //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
  
  //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
  //subHeader //me gustaria esta opcion pero no funciona

  //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
  customStyles={miEstilacho} /* redefino algun estilo */
  />    { /* del componente DataTable */ }
  </div>
  )  
}

ParteSubTotales=()=> {  
let miLabelLoca = "Subtotal " + this.valueDC.iva.Porcentaje + "%" 
return (
<div id="divParteSubTotales" name="divParteSubTotales" style={{padding:'9px',background:'dimgray', marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%', borderBottomStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid',borderColor:'black',borderWidth:'1px'}}>    
  <Row style={{background:'dimgray',margin:'0'}}>
    <Col xs="7" md="9" style={{padding:"0"}}>
      <Input disabled id="labelDcto" name="labelDcto" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="Descuento"/>  
      <Input disabled id="label0" name="label0" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="SubTotal 0%"/>     
      <Input disabled id="label12" name ="label12" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue={miLabelLoca}  />  
      <Input disabled id="labelIva" name="labelIva" style={{textAlign:'right',paddingTop:'0',paddingBottom:'0'}} defaultValue="Iva"/>  
      <Input disabled id="labelTotal" name="labelTotal" style={{textAlign:'right', fontWeight: 'bold',paddingTop:'0',paddingBottom:'0'}} defaultValue="TOTAL"/>  
    </Col>
    <Col xs="5" md="3" style={{padding:"0"}}>
      <Input disabled id="txtDcto" name="txtDcto" defaultValue="0.00" style={{paddingTop:'0',paddingBottom:'0' }}/>  
      <Input disabled id="txt0" name="txt0" defaultValue="0.00"style={{paddingTop:'0',paddingBottom:'0' }} />  
      <Input disabled id="txt12" name="txt12" defaultValue="0.00" style={{paddingTop:'0',paddingBottom:'0' }} />  
      <Input disabled id="txtIva" name="txtIva" defaultValue="0.00" style={{paddingTop:'0',paddingBottom:'0' }} />  
      <Input disabled id="txtTotal" name="txtTotal" defaultValue="0.00" style={{textAlign:'left', fontWeight: 'bold', paddingTop:'0',paddingBottom:'0' }} />  
    </Col>
  </Row>
</div>
)
} 

parteBotonMasOpciones=()=>{
  // partecita para el boton de mas Opciones y observaciones
  return(  
  <div id="divMasOpciones" name="divMasOpciones" style={{marginTop:'6px',marginBottom:'6px',marginLeft:'2%',marginRight:'2%',}}>
  <Row style={{margin:'0'}}> 
    <Col xs="3" md="2" lg="1" style={{padding:'0',}}> 
      <Dropdown style={{marginTop:'0',}}  id="btnFacturaMas" name="btnFacturaMas" isOpen={ this.state.estatusAbiertoDropdownMenu_FacturaMas } direction="up"  
          toggle= { ()=> { this.setState( {estatusAbiertoDropdownMenu_FacturaMas:!this.state.estatusAbiertoDropdownMenu_FacturaMas} ) }}> 
          <DropdownToggle style={{width:'100%',fontSize:'16px',fontWeight: 'bold',color:'white'}} color="info" >
            MAS { }<FontAwesomeIcon color="gainsbro" icon={faCaretDown} />
          </DropdownToggle>
        <DropdownMenu style={{backgroundColor:'pink',}}>        
          <DropdownItem id="btnTextoComp" name="btnTextoComp" 
            onClick={ async() =>{
              this.setState({estatusAbiertoModal_TextoComplementario:true})
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faFileAlt} /> Texto Complementario
          </DropdownItem>        
          <DropdownItem divider/>
          <DropdownItem ><FontAwesomeIcon style={{color:'dimGray'}} icon={faDoorClosed} /> Cerrar Menú
          </DropdownItem>        
        </DropdownMenu>
      </Dropdown>  
    </Col>
    <Col xs="9" md="10" lg="11" style={{padding:'0',}}> 
      <Input style={{background:'white',marginLeft:'2%', width:'98%'}} placeholder="Observaciones" maxLength="100" name="txtObservaciones" id="txtObservaciones"/>    
    </Col>
  </Row>
  </div>    
  )
}
  
ParteBotoneraInferiorA=()=>{   
//Botones de [formas de pago + Credito]
return (
<div id="divBotoneraInferiorA" name="divBotoneraInferiorA" style={{marginLeft:'2%',marginRight:'2%',}} >
  <Row style={{margin:'0',padding:'0'}}>
    <ButtonGroup style={{padding:"0",}} >
      <Button style={{height:"90px",fontSize:'60px',borderStyle:'solid',borderWidth:'1px',borderColor:'black'}} id="btnFormaPago" name="btnFormaPago" color="primary" 
        onClick = { async () => {
          //validar que se haya seleccionado algun proveedor y hayan elementos en el grid
          if (!this.validarUltima()) return
          this.setState({estatusAbiertoModal_FormaDePago:true })         
        }}>
        <FontAwesomeIcon color="black" icon={faCashRegister}/>
      </Button>
      <Button style={{height:"90px", fontSize:'16px',fontWeight: 'bold',borderStyle:'solid',borderWidth:'1px',borderColor:'black'}} id="btnCredito" name="btnCredito" color="secondary"
        onClick = {async()=>{             
          //validar que se haya seleccionado algun proveedor y hayan elementos en el grid
          if (!this.validarUltima()) return
          //Abro un MODAL para pedir los dias de credito. Al cerrar el modal entonces se manda a procesar la Factura
          this.setState({estatusAbiertoModal_PedirDiasCredito:true }) 
        }}>CREDITO
      </Button>
    </ButtonGroup>
  </Row>   
</div>
)
} 
    
refrescarInventarioExclusivamente=async()=>{
//solo la llamo desde el boton REFRESCAR INVENTARIO
this.intentosInv=1
let seguir=true
mostrarSwalEspera()
while (seguir){
  //OJO: hay que hacer 3 intentos, de no haber exito entonces se debe mostrar la ventana de REINTENTAR
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','inv_buscarTodosPocasColumnasAlmacenDefParaComprar')
  data.append('miAlmacen',this.almacenDef) //codigo del almacen
  data.append('deseoBlockSN',this.inv_deseoBlockSN)
  data.append('deseoOpSN',this.inv_deseoOpSN)  
  data.append('orderBy','Descripcion')
  data.append('forma','Asc')  
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeJson = await response.json() 

    //analizo la cabecera del JSON de la respuesta
    let hayError=false
    let miE=null
    if (!pepeJson.ConexionBUC){
      hayError=true
      miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
    } 
    if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
      hayError=true
      miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
    }     
    if (hayError){
      throw miE      
    } 
    //rumbo normal*********        
    this.setState({ datosInv:pepeJson.Data,}) 
    apagarSwal()
    seguir=false
  } //del try
  catch (e) {   
    this.intentosInv++
    if (this.intentosInv<=this.valueDC.sistema.numeroDeReintentosPhp){      
      await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
    }
    else{  
      //llamo al gestor para que me aparezca la pantalla de REINTENTAR
      apagarSwal()
      let miRespSwal = await gestionarCatch(e)
      //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
      if (miRespSwal.isDenied) {
        mostrarSwalEspera()
        this.intentosInv=1
      }
      else{
        //ha pulsado salir en el swal. No hago nada para poder seguir en Facturacion
        seguir=false
      }
    }//del else
  }//del catch
}//del while
}

refrescarInventario=async()=>{    
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','inv_buscarTodosPocasColumnasAlmacenDefParaComprar')
  data.append('miAlmacen',this.almacenDef)
  data.append('deseoBlockSN',this.inv_deseoBlockSN)
  data.append('deseoOpSN',this.inv_deseoOpSN)    
  data.append('orderBy','Descripcion')
  data.append('forma','Asc')   
      
  try{
   const response = await fetch(miPhpFile, { method: 'POST',body:data })
   const pepeJson=await response.json()
   this.setState({ datosInv:pepeJson.Data })        

   this.sePudoLeerInv = (pepeJson.ConexionBUC && pepeJson.AgrModConExito)  
  }
  catch { 
    this.sePudoLeerInv = false
  }
}       

refrescarProveedores=async()=>{ 
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','proveedor_buscarTodosPocasColumnas')
  data.append('deseoBlockSN',this.prov_deseoBlockSN)
  data.append('deseoOpSN',this.prov_deseoOpSN)   
  data.append('orderBy','RazonSocial')
  data.append('forma','Asc')
  
  try{
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeJson=await response.json() 

    this.setState({ datosFullProv:pepeJson.Data })        
    this.sePudoLeerProv = (pepeJson.ConexionBUC && pepeJson.AgrModConExito)  
  } //del try
  catch (e) {    
    this.sePudoLeerProv = false
  }   
}

calcularTotal=async()=>{
//Esta rutina, me recalcula varias casillas del grid (por ejemplo el precio unit con iva, el descuento en $ unitario, el total por renglon, etc....)
//tambien totaliza el documento

//**** estas dos variables solo se alimentan al regresar del Formulario de Descuento secundario de la Compra */
this.subtotalFacturaPhp.DescuentoSecundarioBase0=0
this.subtotalFacturaPhp.DescuentoSecundarioBase12=0
//Variables que se alimentan en esta rutina
this.subtotalFacturaPhp.propina=0 //Por ahora no lo uso
this.subtotalFacturaPhp.dcto=0 //sumatoria de los descuentos primarios
this.subtotalFacturaPhp.sub0=0 //esta informacion es relevante para mandarla a PHP (ya esta restados todos los posibles descuentos)
this.subtotalFacturaPhp.sub12=0 //esta informacion es relevante para mandarla a PHP (ya esta restados todos los posibles descuentos)
this.subtotalFacturaPhp.ivaD=0
this.subtotalFacturaPhp.total=0

//variables propias de esa rutina (j: de java)
let j_cant=0
let j_ivaP=0
let j_pvp=0
let j_descP=0
let j_dcto=0
let j_renglon=0
let j_qty=0

let vectorLoco=[...this.state.datosGrid ]

for (let i=0; i<vectorLoco.length;i++){
    j_cant=parseFloat(vectorLoco[i].Cant)
    j_qty += j_cant
    j_ivaP=(vectorLoco[i].GravaIva=='No') ? 0 : parseFloat(this.valueDC.iva.Porcentaje)
    j_pvp=parseFloat(vectorLoco[i].PvpSinIva)
    j_descP=parseFloat(vectorLoco[i].DescP)

    j_renglon=j_cant * j_pvp
    j_dcto=j_renglon * j_descP /100
    j_renglon=j_renglon - j_dcto

    vectorLoco[i].DescD=(j_pvp * j_descP/100).toFixed(4) //descuento en $ unitario
    vectorLoco[i].Total=j_renglon.toFixed(4)  //total renglon sin impuesto
    
    this.subtotalFacturaPhp.dcto += j_dcto
    if (j_ivaP==0){
      vectorLoco[i].PvpConIva=j_pvp.toFixed(4) //se hace referencia al precio unitario
      this.subtotalFacturaPhp.sub0 += j_renglon
    }
    else{
      vectorLoco[i].PvpConIva=(j_pvp + j_pvp * j_ivaP/100).toFixed(4) //se hace referencia al precio unitario
      this.subtotalFacturaPhp.sub12 += j_renglon
      //this.subtotalFacturaPhp.ivaD += (j_renglon * j_ivaP /100)
    }
  } //del for

  await sleepPepe(50)
  this.setState({ datosGrid:[]})     //Lo hago solamente para forzar el render
  await sleepPepe(50)
  this.setState({datosGrid:vectorLoco})
  await sleepPepe(50)


let numTexto=null
//*******hago los redondeos necesarios
numTexto=this.subtotalFacturaPhp.propina.toFixed(2) //la funcion toFixed redondea pero me devuelve un texto
this.subtotalFacturaPhp.propina=parseFloat(numTexto)
//base 0
numTexto=this.subtotalFacturaPhp.sub0.toFixed(2) 
this.subtotalFacturaPhp.sub0=parseFloat(numTexto)
//base 12
numTexto=this.subtotalFacturaPhp.sub12.toFixed(2) 
this.subtotalFacturaPhp.sub12=parseFloat(numTexto)
//base Iva Dolares
let porcentajeIvaTMP=parseFloat(this.valueDC.iva.Porcentaje)
let ivaD_sr=(this.subtotalFacturaPhp.sub12 * porcentajeIvaTMP/100) //iva dolares sin redondear
numTexto=ivaD_sr.toFixed(2)
this.subtotalFacturaPhp.ivaD=parseFloat(numTexto)

//total a pagar
numTexto=this.subtotalFacturaPhp.propina + this.subtotalFacturaPhp.sub0 + this.subtotalFacturaPhp.sub12 + ivaD_sr
numTexto=numTexto.toFixed(2)
this.subtotalFacturaPhp.total = parseFloat(numTexto)

//escribo en el documento
document.getElementById('txtFilas').innerText=(vectorLoco.length).toFixed(0)
document.getElementById('txtQty').innerText=j_qty.toFixed(2) 

document.getElementById('txtDcto').value=this.subtotalFacturaPhp.dcto.toFixed(2)
document.getElementById('txt0').value=this.subtotalFacturaPhp.sub0.toFixed(2)
document.getElementById('txt12').value=this.subtotalFacturaPhp.sub12.toFixed(2)
document.getElementById('txtIva').value=this.subtotalFacturaPhp.ivaD.toFixed(2)
document.getElementById('txtTotal').value=this.subtotalFacturaPhp.total.toFixed(2)
}

eliminarProducto=async()=> {
let miProductoEnviar="Seguro desea eliminar el producto: \n" + this.miFilaAzulJson.DescripcionModificada
let miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miProductoEnviar)
if (miResp.isDismissed) return //alomejor se arrepintio    

let gridLoco=this.state.datosGrid.filter(item=>item.Fila != this.state.miFilaAzulIndice)        

await sleepPepe(50)
this.setState( {datosGrid:gridLoco,miFilaAzulIndice:-1} ) 
await sleepPepe(50)

this.calcularTotal()  
}

ponerTituloDeDocumento=()=>{
  document.getElementById("labelTipoDoc").innerText= 'COMPRA CON FACTURA'      
  document.getElementById("divTitulo").style.background='purple'
  document.getElementById("divTitulo").style.color='white'
  //#d5b5d4 //morado medio
  //#eadae9 //morado bien claro
  document.getElementById("miFormularioFacturacion").style.background='#d5b5d4'      
  //etiqueta inferior
  document.getElementById("txtDocHaciendo").style.background='purple'      
  document.getElementById("txtDocHaciendo").innerText='COMPRA CON FACTURA'
  document.getElementById("txtDocHaciendo").style.color='white'
}

componentDidMount=async()=>{
  //apago el DIV del subEncabezado, cuando no se desea validar la comptra para ATS
  if (this.valueDC.sistema.atsComprasValidar=="No")
    document.getElementById("divSubEncabezado2_OnOff").style.display= 'none' //pero Los botones invisibles se reacomodan
   
  //muestro en el titulo, el tipo de documento que deseo hacer, y pongo los colores respectivos (en este caso hay un solo posible tipo de documento)
  this.ponerTituloDeDocumento()

  //apago los botones de consultar y editar proveedor
  this.ponerVisibleOculto_ClaseVerOcultar('hidden')

  //apago el boton de consultar inventario (detalles) y otras cosas basicas
  this.prepararEscenarioParaNuevoNegro()

  //Creo y limpio el objeto caPiePhp
  this.limpiar_caPiePhp()

  //esa hay que dajarla SIEMPRE, para que me cree el JSON de totales y escriba puros 0 en la parte inferior
  this.calcularTotal()

  //pongo el sustento tributario segun las preferencias del sistema (normalmente 06)
  document.getElementById("comboSustento").value=this.valueDC.sistema.atsComprasCodigoDeSustentoPredefinido
  await this.gestionarAlimentarInventarioMasProveedores()    
}

copiarVariablesDesdeContext=(value)=>{
  this.valueDC=value
  //FALTA: aprovecho de poner el Almacen Definitivo (esto es temporal, deberia haber una pantalla que pregunte el Almacen o Tambien ya venir desde las Props)
  this.almacenDef=value.estable.Almacen
}

limpiar_caPiePhp=()=>{
  //Nunca se guarda el descuento primario en $  (eso se debe reconstruir desde las tablas del detalle)
  //Creo y limpio este objeto para mandar a PHP  (cabecera y pie)

  //Datos del proveedor
  this.caPiePhp.IDr=null //luego se actualiza desde el estado (es el codigo rojo del proveedor)
  this.caPiePhp.TipoDocFNL=null //en este caso es Factura (hay que hacer otro formulario para Notas de venta)
  this.caPiePhp.Estab=null
  this.caPiePhp.Punto=null 
  this.caPiePhp.NumFac=null
  this.caPiePhp.FechaFactura_dia=null  
  this.caPiePhp.FechaFactura_mes=null  
  this.caPiePhp.FechaFactura_ano=null  
  //otra informacion relevante
  this.caPiePhp.EstabDestino=null // para saber a cual estab va la mercancia
  this.caPiePhp.Almacen=null //0..9 para saber a que almacen va la mercancia
  this.caPiePhp.AlContado=null //1,0
  this.caPiePhp.Plazo=null
  this.caPiePhp.Cancelado=null //1,0
  this.caPiePhp.CompraFE=null //"F" "E"
  this.caPiePhp.Autorizacion=null //si la compra es fisica son 10 digitos (49 para electronica)
  this.caPiePhp.CodigoSustento=null //Lo mas comun es 06
  this.caPiePhp.Flete=null //Monto del flete
  this.caPiePhp.FletePagaIva=null
  //el codigoIva y Porcentaje se va en el context y luego en PHP lo extraigo (igualmengte el usuario)
  this.caPiePhp.Observaciones=null
  this.caPiePhp.TextoComplementario=null
  //informacion para hacer el recibo de caja (el dinero tiene que salir de algun estab y punto especifico)
  this.caPiePhp.EstabReciboCaja=null
  this.caPiePhp.PuntoReciboCaja=null
}

prepararCaPiePhpCosasComunes=()=>{
  //informacion de proveedor
  this.caPiePhp.IDr=this.state.proveedorIDr
  this.caPiePhp.TipoDocFNL="F" //en este caso es Factura (hay que hacer otro formulario para mer notas de venta)
  this.caPiePhp.Estab=document.getElementById('txtEstab').value.trim()
  this.caPiePhp.Punto=document.getElementById('txtPunto').value.trim()
  this.caPiePhp.NumFac=document.getElementById('txtNumFac').value.trim()
  let fechaLocaTexto = document.getElementById('txtFechaFactura').value // siempre devuelve: yyyy-mm-dd (en windows y ipad y iphone)
  this.caPiePhp.FechaFactura_dia=fechaLocaTexto.substring(8,10)
  this.caPiePhp.FechaFactura_mes=fechaLocaTexto.substring(5,7)
  this.caPiePhp.FechaFactura_ano=fechaLocaTexto.substring(0,4)

  //otra informacion relevante
  this.caPiePhp.EstabDestino=this.valueDC.usuario.EstabUsu //en un futuro, puede ser que la compra se registre para otro estab distinto
  this.caPiePhp.Almacen=this.valueDC.almacen.CodAlm //por ahora es el almacen al que pertenece el estab del usuario logeado

  if (this.valueDC.sistema.atsComprasValidar=="No")
   this.caPiePhp.CompraFE="F" //puede ser F o E ya que el contribuyente no esta obligado a hacer ATS
  else
    this.caPiePhp.CompraFE= (document.getElementById('radioFisica').checked) ? "F" : "E"
  this.caPiePhp.Autorizacion=document.getElementById('txtAutorizacion').value.trim() //si la compra es fisica son 10 digitos (49 para electronica)

  this.caPiePhp.CodigoSustento=document.getElementById('comboSustento').value  
  this.caPiePhp.Flete=0 //Monto del flete
  this.caPiePhp.FletePagaIva=0  
  this.caPiePhp.Observaciones=document.getElementById("txtObservaciones").value.trim()
  this.caPiePhp.Observaciones=(this.caPiePhp.Observaciones.length==0) ? null : this.caPiePhp.Observaciones
  if (this.caPiePhp.TextoComplementario!=null)
  this.caPiePhp.TextoComplementario=(this.caPiePhp.TextoComplementario.length==0) ? null : this.caPiePhp.TextoComplementario //me interesa que se vaya con null o con texto    
  //informacion para hacer el recibo de caja
  //por ahora el estab y punto para el recibo de caja va a coincidir con el estab y punto de usuario logeado. (en el futuro puede ser que el usurio este comprandpo para otro establecimiento)
  this.caPiePhp.EstabReciboCaja=this.valueDC.usuario.EstabUsu
  this.caPiePhp.PuntoReciboCaja=this.valueDC.usuario.PuntoUsu
}

prepararDatosGridResumido=()=>{
let vectorLoco=[]
for(let i=0; i<this.state.datosGrid.length; i++ ){
      const x={
      IDr: this.state.datosGrid[i].IDr,
      Cant:this.state.datosGrid[i].Cant,
      DescripcionModificada: this.state.datosGrid[i].DescripcionModificada.trim(),
      DescripcionAmpliada:this.state.datosGrid[i].DescripcionAmpliada.trim(), 
      GravaIva: (this.state.datosGrid[i].GravaIva=='Si') ? "1":"0",
      PvpSinIva:this.state.datosGrid[i].PvpSinIva, //realmente se hace referencia al costo sin iva
      DescP:this.state.datosGrid[i].DescP,
    }
    vectorLoco.push(x) //empujo el item requerido por el cliente
  }
return vectorLoco   
} 
  
gestionarAlimentarInventarioMasProveedores=async()=>{  
this.intentosInv=0
this.sePudoLeerInv=false
//gestiono la lectura de Inventario
mostrarSwalEspera()
while (!this.sePudoLeerInv && this.intentosInv<this.valueDC.sistema.numeroDeReintentosPhp){      
  await this.refrescarInventario()
  this.intentosInv++
  if (!this.sePudoLeerInv && this.intentosInv<this.valueDC.sistema.numeroDeReintentosPhp){      
    await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
  }
}

//gestiono la lectura de Proveedores
this.intentosProv=0
this.sePudoLeerProv=false
while (!this.sePudoLeerProv && this.intentosProv<this.valueDC.sistema.numeroDeReintentosPhp){      
  this.intentosProv++
  await this.refrescarProveedores()
  if (!this.sePudoLeerProv && this.intentosProv<this.valueDC.sistema.numeroDeReintentosPhp){      
    await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
  }
}

apagarSwal()
if (!this.sePudoLeerInv || !this.sePudoLeerProv){
  //llamo al gestor para que me aparezca la pantalla de REINTENTAR
  let miRespSwal = await gestionarCatch("No se pudo ejecutar la solicitud")

  //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente N veces mas
  if (miRespSwal.isDenied) {
    this.intentosInv=0
    this.intentosProv=0
    await this.gestionarAlimentarInventarioMasProveedores()
  }
  else{
    //ha pulsado salir en el swal
    this.salirPorBoton()
  }
}//del else   
}  

ponerVisibleOculto_ClaseVerOcultar=(miEstatus)=>{
  //Los posible estatus son:  
  // 'hidden' //Lo botones visibles se quedan en su lugar original
  // 'visible'
  document.getElementById("btnModificarProveedor").style.visibility=miEstatus
  document.getElementById("btnConsultarProveedor").style.visibility=miEstatus
}

filtrarPorCedula=(cadenaLoca) =>{
  this.ponerVisibleOculto_ClaseVerOcultar('hidden')
  document.getElementById("txtNombreProveedor").value=''
  
  //guardo en el estado, que AUN NO se consiguio al proveedor
  this.setState({ proveedorPonchado:false, proveedorIDr:0,proveedorCedula:'',proveedorIdSecundario:'',proveedorActivo:null, proveedorNombre:'',proveedorNombreComercial:'',proveedorEmail:''})

  if (cadenaLoca.length==0)
    return

  this.state.datosFullProv.some( prov => {
      if (prov.IdPrincipal.trim().toLowerCase()==cadenaLoca) {            
        document.getElementById("txtNombreProveedor").value=prov.RazonSocial.trim()
        this.ponerVisibleOculto_ClaseVerOcultar('visible')
       //guardo en el estado, que ya se consiguio al proveedor
       this.setState({ proveedorPonchado:true, proveedorIDr:prov.IDr,proveedorCedula:prov.IdPrincipal,proveedorIdSecundario:prov.IdSecundario,proveedorActivo:prov.Activo, proveedorNombre:prov.RazonSocial,proveedorNombreComercial:prov.NombreComercial,proveedorEmail:prov.Email,})
       //en caso que sea INACTIVO le muestro una ALERTA 
       if (prov.Activo=="No"){
        mostrarSwalBotonAceptar("error","ATENCION","El proveedor no está activo")
       }  
        return true
      }
  })
} 

incluirModificarJsonProveedor=(jsonRegistro)=>{
    //Lo que hace es Incluir o Modificar un Item en el array JSON . (asi me ahorro la lectura de la BDD)
    //OJO: vuelvo a escribir la cedula en textBox, alomejor el usuario la modifico en el formulario: IncModProveedor
    document.getElementById("txtCedulaProveedor").value=jsonRegistro.IdPrincipal
    let itemLoco={
    "IDr":jsonRegistro.IDr,
    "IdPrincipal":jsonRegistro.IdPrincipal,
    "IdSecundario":jsonRegistro.IdSecundario,
    "RazonSocial":jsonRegistro.RazonSocial,
    "NombreComercial":jsonRegistro.NombreComercial,
    "TlfCel":jsonRegistro.TlfCel,
    "Email":jsonRegistro.Email,
    "Clase":jsonRegistro.Clase,
    "Ciudad":jsonRegistro.Ciudad,
    "Activo":jsonRegistro.Activo,
    "VisibleSN":'S',  //para este formulario siempre 'S' ya que los proveedores no requieren estan en ninguna Tabela
    "BlockDeNotas":'', //aqui no requiero usar el Block
    "OpCrea":'', //aqui no requiero usar el OpCrea
    }

  //***********  cuando es modificar *************
  if (this.state.opcionProveedor_imc=='m'){
    let datosFullCopia=this.state.datosFullProv.map(item=>{ return (item.IDr==jsonRegistro.IDr) ? itemLoco : item })
    this.setState( {datosFullProv:datosFullCopia} )       
    }
  
  //******  cuando es nuevo *************
  //en este modulo no importa si estan o no ordenados los datos
  if (this.state.opcionProveedor_imc=='i'){  
    let datosFullCopia=this.state.datosFullProv.filter(item=>{ return true })
    datosFullCopia.push(itemLoco) 
    this.setState({datosFullProv:datosFullCopia,}) 
    }

  //ahora, forzo la busqueda (estamos seguros que lo va a encontrar)
  let cadenaLoca=document.getElementById("txtCedulaProveedor").value
  cadenaLoca=cadenaLoca.trim().toLocaleLowerCase()
  this.filtrarPorCedula(cadenaLoca)
  }

eliminarJsonProveedor=(IDr)=>{
  //esta funcion elimina un proveedor del JSON del estado
  let datosFullCopia=this.state.datosFullProv.filter(item=>item.IDr != IDr)
  this.setState({datosFullProv:datosFullCopia,}) 

  //llamo a la funcion filtrarPorCedula, para que me ponga los valores predeterminados, apague proveedorPonchado....
  let cadenaLoca=document.getElementById("txtCedulaProveedor").value.trim().toLocaleLowerCase()
  this.filtrarPorCedula(cadenaLoca)    
}
 
eliminarJsonInventario=(IDr)=>{
  //esta funcion elimina un producto del JSON del estado/inventario
  let datosFullCopia=this.state.datosInv.filter(item=>item.IDr != IDr)
  this.setState({datosInv:datosFullCopia,}) 
  this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:0,inventarioNegroCodigoPrincipal:'',inventarioNegroActivo:null,})
  document.getElementById("txtNombreProducto").value=""
  document.getElementById("btnConsultarInv1").style.visibility='hidden'
  document.getElementById("btnEnterInv").style.visibility='hidden'
  this.productoNegro=null //pongo vacio en mi JSON del futuro producto que se va a meter al grid de venta                             
}

determinarPVPvisibleEnBuscadorDeInventario=()=>{
  //blanqueo las variables involucradas
  this.pvp1InventarioSinIvaVerDefinitivo=false
  this.pvp1InventarioConIvaVerDefinitivo=false
  this.pvp2InventarioSinIvaVerDefinitivo=false  
  this.pvp2InventarioConIvaVerDefinitivo=false 
  this.pvp3InventarioSinIvaVerDefinitivo=false  
  this.pvp3InventarioConIvaVerDefinitivo=false 
  this.pvp4InventarioSinIvaVerDefinitivo=false  
  this.pvp4InventarioConIvaVerDefinitivo=false 
} 

gestionarRegistrarCompra=async()=>{
  this.prepararCaPiePhpCosasComunes()
  let vectorDV=this.prepararDatosGridResumido() //la idea es generar un array, solo con las columnas necesarias (le elimino secundario, auxiliar,........)
  //Mando a registrar la compra
  let numeroDevuelto=await procesarCompraEnBDD(this.valueDC,this.caPiePhp,this.subtotalFacturaPhp,vectorDV,this.datosFormaPago) //me tiene que devolver el mismo numero de factura enviado, es decir un numero positivo (al ser negativo significa algun error)
  //muestro un mensaje con el resultado
  if (numeroDevuelto<0) {
    mostrarSwalBotonAceptar("error","ATENCION","No se pudo registrar la compra. Código de error # " + numeroDevuelto)
  }
  else{
    await mostrarSwalBotonAceptar("info","LISTO","Compra registrada correctamente")
    //desmontar y salir
    this.salirPorBoton() // en la funcion salirPorBoton() tambien desmonta
  }
}

render(){  
return (               
<AppContextConsumer>
{ (value) =>{ 
return (
<div id="miFormularioFacturacion" name="miFormularioFacturacion" style={{margin:'0',width:"100%",paddingBottom:'1%'}}>    
{this.copiarVariablesDesdeContext(value)}
  <this.ParteTituloMasCerrar/>
  <this.ParteProveedor/>
  <this.ParteEncabezadoFactura/>
  <this.ParteBotoneraSuperior/> 
  <this.ParteGrid/>

  <div id="divQty" name="divQty" style={{marginLeft:'2%',marginRight:'2%',marginTop:'0',marginBottom:'0',width:'96%',background:'dimgray', color:'white',borderTopStyle:'solid',borderLeftStyle:'solid',borderRightStyle:'solid', borderColor:'black',borderWidth:'1px'}}>
    <Label id="labelFilas" name="labelFilas" style={{marginLeft:'1%',marginRight:'1%'}}>Filas:</Label>
    <Label id="txtFilas" name="txtFilas" style={{marginRight:'5%'}}>.</Label>
    <Label id="labelQty" name="labelQty" style={{marginRight:'1%'}} >Qty:</Label>
    <Label id="txtQty" name="txtQty" >.</Label>
  </div>
  <this.ParteSubTotales/>
  <this.parteBotonMasOpciones/> {/* boton MAS OPCIONES y observaciones */}
  <div id="divDocHaciendo" name="divDocHaciendo" style={{marginLeft:'2%',marginRight:'2%',marginBottom:'0'}}>
    <Label name="txtDocHaciendo" id="txtDocHaciendo" style={{width:'100%',height:'28px',textAlign:'center'}}></Label>
  </div>
  <this.ParteBotoneraInferiorA/> {/* botones forma de pago */}

{/************************** MODAL PARA CANTIDAD ****************/}
<Modal size={'md'} isOpen={ this.state.estatusAbiertoModal_Cantidad } >
  <PedirCantidad  
    miTitulo={(this.alPedirCantidadNuevoModificar=="M") ? this.miFilaAzulJson.DescripcionModificada.substring(0,38) : (this.productoNegro && this.productoNegro.DescripcionModificada ) ? this.productoNegro.DescripcionModificada.substring(0,38) : "" } //viene siendo la descripción del producto    
    miSubTitulo={'Indíque la cantidad'}
    miValorTexto2D={(this.alPedirCantidadNuevoModificar=="M") ? this.miFilaAzulJson.Cant : (this.productoNegro && this.productoNegro.Cant) ? this.productoNegro.Cant : '0.00' } //se lo envio como texto pero con dos decimales  
    miAceptaCero={false} //para saber si se acepta 0
    cerrarModal={async (miStatus,miCantSolicitada) => { 
    // la variable miStatus es boolean, al estar en true quiere decir que si desea editar      
    //la variable miCantSolicitada ya viene como NUMBER y redondeada a 2 decimales
    //hay dos escenarios: Cuando estoy modificando => entonces modifico el grid en la linea respectiva
    //Cuando es nuevo item => entonces agrego un item al final

    this.setState({ estatusAbiertoModal_Cantidad : false })
    if (miStatus==false) return

    //caso 1: cuando es Modificar la cantidad
    if (this.alPedirCantidadNuevoModificar=="M"){  
      let gridLoco=[...this.state.datosGrid]
      for(let i=0; i<gridLoco.length;i++)
        if (gridLoco[i].Fila==this.state.miFilaAzulIndice){
          gridLoco[i].Cant=miCantSolicitada.toFixed(2) //aqui lo formateo siempre a dos decimales (y lo convierte a TEXTO)
       }

       await sleepPepe(150) //para que se refresque el grid
       this.setState({datosGrid:gridLoco})
       await sleepPepe(150)
       this.calcularTotal()
    }

    //caso 2: cuando es nuevo item 
    if (this.alPedirCantidadNuevoModificar=="N"){
      this.productoNegro.Cant=miCantSolicitada.toFixed(2) //aqui lo formateo siempre a dos decimales (y lo convierte a TEXTO)
      this.meterProductoNegroEnGridDeVenta()
    }
    }}/>
</Modal> {/* fin de modal de cantidad */}

{/************************** MODAL PARA CAMBIAR LA SERIE ****************/}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_Serie } >
  <PedirTextoGenericoSerie
    miTitulo={"Indíque la Serie"}
    miLongitudMaxima={100}
    textoViejo={ this.miFilaAzulJson==null ? "" : this.miFilaAzulJson.DescripcionAmpliada }
    cerrarModal={()=> this.setState({ estatusAbiertoModal_Serie:false })}
    regresarAlLlamador={async(textoNuevo) => { 
      //para la serie, se acepta vacio
      let gridLoco=[...this.state.datosGrid]
      for(let i=0; i<gridLoco.length;i++)
        if (gridLoco[i].Fila==this.state.miFilaAzulIndice){
          gridLoco[i].DescripcionAmpliada=textoNuevo
        }

       await sleepPepe(50)
       this.setState({datosGrid:gridLoco})
       await sleepPepe(50)
       this.calcularTotal() 
    }
    }/>
</Modal> {/* fin de modal de cambiar la serie del producto */}

{/* ************************* MODAL PARA PORCENTAJE DE DESCUENTO *************** */}
{/* ******************** SIRVE PARA ('1') ITEM O ('T') TODOS LOS ITEMS ********* */}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_DescuentoP } >
  <PedirDescuentoP 
    miTitulo={this.afectaItems1T=="1" ? this.miFilaAzulJson.DescripcionModificada.substring(0,38) : "Descuento % para todos"}
    miSubTitulo={"% de descuento (Máx 2 decimales)"}
    miValorOriginal={this.afectaItems1T=="1" ? parseFloat(this.miFilaAzulJson.DescP): 0 } //se le debo mandar como float
    mi234Decimales={2}    
    miColorFondo={this.afectaItems1T=="1" ? "purple" : "red"}    
    miAceptaCero={true}
    cerrarModal={async(miStatus,miValorNuevo)=>{      
      //miStatus es boolean y debe venir como true/false para saber si realmente hizo el cambio
      //ojo: miValorNuevo debe venir como number y ya redondeado a 2 decimales
      this.setState({ estatusAbiertoModal_DescuentoP : false })  
      if (miStatus==false) return

      //rumbo normal
      let gridLoco=[...this.state.datosGrid ]
      for(let i=0; i<gridLoco.length;i++){
        if (this.afectaItems1T=='1' && gridLoco[i].Fila==this.state.miFilaAzulIndice)               
            gridLoco[i].DescP=miValorNuevo.toFixed(2)
        if (this.afectaItems1T=='T')
            gridLoco[i].DescP=miValorNuevo.toFixed(2)
        }

        await sleepPepe(50)
        this.setState({datosGrid:gridLoco})
        await sleepPepe(50)
        this.calcularTotal()
}}
/>
</Modal> {/* fin de modal de descuento % */}

{/* ********************** MODAL PARA COSTO ESPECIFICO tanto con iva como sin iva*************** */}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ this.state.estatusAbiertoModal_PrecioEspecifico } >
  <PedirPrecioEspecifico       
    etiquetaPrecioCosto="Costo"
    deseoPvpSinCon={this.deseoPvpSinCon}
    miFilaAzulJson={this.miFilaAzulJson}
    miIvaDef= { ( this.miFilaAzulJson != null && this.miFilaAzulJson.GravaIva=='Si') ? this.valueDC.iva.Porcentaje : 0 }
    cerrarModal={()=> this.setState({ estatusAbiertoModal_PrecioEspecifico : false })}
    cambiarPrecioEspecifico = { async (miPvpSin) => { 
    //al haber un % de descuento, se procedera a limpiarlo y dar el mensaje
    //el parametro viene como number
    let gridLoco=[...this.state.datosGrid ]

    let habiaDescuento=false
    let i=0
    for(i=0; i<gridLoco.length;i++)
      if (gridLoco[i].Fila==this.state.miFilaAzulIndice){
          gridLoco[i].PvpSinIva=miPvpSin.toFixed(4)
          gridLoco[i].Precio01234="0"
          break //ojo, esta linea es obligatoria para mantener el apuntador i 
      }
    habiaDescuento=(gridLoco[i].DescP>0) ? true : false   
    gridLoco[i].DescP="0.00"

    if (habiaDescuento)
      await mostrarSwalBotonAceptar("warning","ATENCION","El descuento se perderá")

    await sleepPepe(50)
    this.setState({datosGrid:gridLoco})
    await sleepPepe(50)
    this.calcularTotal()
    }}/>
</Modal> {/* fin de modal de precio especifico */}

{/************************** MODAL PARA LA FORMA DE PAGO ****************/}
<Modal style={{ background:'blue',}} size={'lg'} isOpen={ this.state.estatusAbiertoModal_FormaDePago } >
  <PedirFormaDePago        
        totalFull= {this.subtotalFacturaPhp.total} //total a cobrar realmente (2 decimales)        
        mostrarRetSN= {'S'} //Mostrar retencion en la forma de pago        
        todoAbonoTA={'T'} //Para saber si debe pagar Todo, o puede hacer Abono
        recibirPago = { async(miFaltanteFloat,miJsonTmp,seleccionOk) => { 
          this.setState({ estatusAbiertoModal_FormaDePago : false })
          if (!seleccionOk) return
          //aqui paso del temporal, al json real de la forma de pago
          this.datosFormaPago=miJsonTmp   
          //reviso el internet
          if (await hayInternet()==false) return 

          //alimento una parte de caPiePhp
          this.caPiePhp.AlContado="1"
          this.caPiePhp.Plazo="0"
          this.caPiePhp.Cancelado="1"
          this.gestionarRegistrarCompra()
        }}
  />
</Modal> {/* fin de modal FORMA DE PAGO */}

{/**** MODAL PARA BUSCADOR DE PROVEEDORES ******** size={'md'} size={'lg'} size={'xl'} NO GARRA xl, lo toma como lg */}
<Modal style={{ background:'blue',}} size={'xl'}  isOpen={ this.state.estatusAbiertoModal_TabelaProveedoresBuscador} >
  <TabelaProveedoresBuscador 
    cerrarModal={(accion,IdPrincipal_Select)=>{
       this.setState({ estatusAbiertoModal_TabelaProveedoresBuscador : false })
       if (accion=='close') return
       //aqui viene la parte de mostrar al proveedor seleccionado
       document.getElementById("txtCedulaProveedor").value = IdPrincipal_Select    
       let cadenaLoca=IdPrincipal_Select.trim().toLocaleLowerCase() //ya que podria tener texto
       this.filtrarPorCedula(cadenaLoca)
    }}
    registrosFull={this.state.datosFullProv}
/>
</Modal> {/* fin de modal de Buscador de proveedores */}

{/* ************************* MODAL PARA AGREGAR/MODIFICAR/CONSULTAR PROVEEDORES *************** */}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ this.state.estatusAbiertoModal_IncModProv } >
  <IncModProveedor 
    cerrarModal={(accion,jsonRegistro)=>{
      //accion puede ser: close/save/notfound
      this.setState( { estatusAbiertoModal_IncModProv : false, })
      if (accion=='close') return
      //al regresar del modal, no es necesario refrescar leyendo de nuevo la BDD, lo que hago es modificar el JSON segun lo sucedido          
      if (accion=='save') this.incluirModificarJsonProveedor(jsonRegistro)
      //el notfound, significa que NO se encontró al registro que se deseaba editar/consultar (por lo tanto debo eliminarlo del estado)
      if (accion=='notfound') this.eliminarJsonProveedor(this.state.proveedorIDr)      
    }}     
    opcionRegistro_imc={this.state.opcionProveedor_imc}        
    registroIDr={this.state.opcionRegistro_imc=='i' ? 0 : this.state.proveedorIDr} //es util solamente cuando deseo editar o consultar el registro (cuando es NUEVO se va con 0)
    cedulaCandidata = {this.state.cedulaCandidata} //solo tiene utilidad cuando se desea crear un proveedor desde COMPRAS (desde este formulario no se usa nunca)
    registrosFull = {this.state.datosFullProv}    
  />
</Modal>

{/**** MODAL PARA BUSCADOR DE INVENTARIO ******** size={'md'} size={'lg'} size={'xl'} NO GARRA xl, lo toma como lg */}
<Modal style={{ background:'blue',}} size={'xl'}  isOpen={ this.state.estatusAbiertoModal_TabelaInvBuscador} >
  <TabelaInventarioBuscador 
    datosInv={this.state.datosInv} //todas las filas del inventario, pero pocas columnas
    pvp1InventarioSinIvaVerDefinitivo={this.pvp1InventarioSinIvaVerDefinitivo}
    pvp1InventarioConIvaVerDefinitivo={this.pvp1InventarioConIvaVerDefinitivo}
    pvp2InventarioSinIvaVerDefinitivo={this.pvp2InventarioSinIvaVerDefinitivo}
    pvp2InventarioConIvaVerDefinitivo={this.pvp2InventarioConIvaVerDefinitivo}
    pvp3InventarioSinIvaVerDefinitivo={this.pvp3InventarioSinIvaVerDefinitivo}
    pvp3InventarioConIvaVerDefinitivo={this.pvp3InventarioConIvaVerDefinitivo}
    pvp4InventarioSinIvaVerDefinitivo={this.pvp4InventarioSinIvaVerDefinitivo}
    pvp4InventarioConIvaVerDefinitivo={this.pvp4InventarioConIvaVerDefinitivo}
    ParaCompraParaVenta="ParaCompra"
    cerrarModal = { async(huboSeleccion,codigoOriginal) => {
      //huboSeleccion es boolean. Me indica si realmente se ha seleccionado un item en el buscador
      //codigoOriginal, me trae el IDprincipal del producto seleccionado
      this.setState({ estatusAbiertoModal_TabelaInvBuscador : false })                 
      if (!huboSeleccion) return
  
      this.setState({inventarioNegroPonchado:false,inventarioNegroIDr:0,inventarioNegroCodigoPrincipal:'',inventarioNegroActivo:null,})        
      await sleepPepe(100) //ok
      document.getElementById("txtNegro").value = codigoOriginal     
      document.getElementById("txtNombreProducto").value=""
      document.getElementById("btnConsultarInv1").style.visibility='hidden'
      this.productoNegro=null //pongo vacio en mi JSON del futuro producto que se va a meter al grid de venta                           
      await sleepPepe(100) //ok
      //hay que poner AWAIT en buscarProductoPorPrincipal, para forzar la actualizacion del estado (es extrano pero solo asi funciona)
      await this.buscarProductoPorPrincipal(codigoOriginal.trim().toLowerCase()) 
      await sleepPepe(100) //ok
      this.gestionarComprarProductoNegro() 
}}    

/>
</Modal> {/* fin de modal de Buscador de inventario */}

{/* ************ MODAL PARA CONSULTAR INVENTARIO **********/}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ this.state.estatusAbiertoModal_IncModInv } >
  <IncModInventario 
    ocultarModal_Inv={(accion,jsonRegistro)=>{
      this.setState( { estatusAbiertoModal_IncModInv : false,})  
      //accion puede ser: close/save/notfound
      if (accion=='close') return
      //al regresar del modal, no es necesario refrescar leyendo de nuevo la BDD, lo que hago es modificar el JSON segun lo sucedido          
      //if (accion=='save') incluirModificarJsonInventario(jsonRegistro)
      //el notfound, significa que NO se encontró al registro que se deseaba editar/consultar (por lo tanto debo eliminarlo del JSON)
      if (accion=='notfound') this.eliminarJsonInventario(this.state.inventarioNegroIDr)
    }} 
    opcionRegistro_imc={this.state.opcionInv_imc} // por ahora solo estoy consultando 
    registroIDr={this.state.inventarioNegroIDr} //codigo rojo del producto que deseo consultar 
    codigoCandidato = {''} //solo tiene utilidad cuando se desea crear un producto desde FACTURACION, COMPRAS o similares (pero desde este form NO lo voy a usar todavia)
    registrosFull={this.state.datosInv} //todas las filas del inventario, pero pocas columnas
  />
</Modal>

{/************************** MODAL PARA CAMBIAR TEXTO COMPLEMENTARIO ****************/}
<Modal centered={true} size={'md'} isOpen={ this.state.estatusAbiertoModal_TextoComplementario } >
  <PedirTextoComplementario
    miTitulo={"Texto Complementario"}
    miLongitudMaxima={300}
    textoViejo={ this.caPiePhp.TextoComplementario}
    cerrarModal={()=> this.setState({ estatusAbiertoModal_TextoComplementario:false })}
    regresarAlLlamador={(textoNuevo) => { 
      //se acepta vacio
      this.caPiePhp.TextoComplementario=textoNuevo
    }
    }/>
</Modal> {/* fin de modal Texto Complementario */}


{/************************** MODAL PARA PEDIR LOS DIAS DE CREDITO *************** */}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ this.state.estatusAbiertoModal_PedirDiasCredito } >
  <PedirDiasCredito 
    cerrarModal={async(estatus,misDias)=>{
      this.setState( { estatusAbiertoModal_PedirDiasCredito : false, })
      //estatus: true/false
      //misDias: entero (maximo 999)      
      if (estatus==false) return

      //******* rumbo ideal *********
      //alimento una parte de caPiePhp
      this.caPiePhp.AlContado="0"
      this.caPiePhp.Plazo=misDias
      this.caPiePhp.Cancelado="0"
      //reviso el internet
      if (await hayInternet()==false) return 

      this.gestionarRegistrarCompra()
    }}     
    miTitulo = { "Dias de crédito" }    
    valorPorDefecto={30} //le mando null para obligarlo a escribir
    topeMaximo={999}
  />
</Modal>

</div> // div principal (miFormularioFacturacion)
) //del return interno
}
}
</AppContextConsumer>
) //del return externo
} //del render
}
