//este formulario, me permite seleccionar UN proveedor
//ya desde el modulo llamador, se le envia un JSON con todas las filas necesarias (pocas columnas). 
//Desde el llamador vienen proveedores Activos + Inactivos. Por lo tanto primero hago un filtro
//se corre el riesgo que no se muestren los proveedores recientemente ingresados/actualizados desde otra computadora
//FALTA: se debe configurar en la tabela que se ordene automaticamente por la RAzonSocial, sin importar como venga el JSON

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import DataTable  from 'react-data-table-component'
import {hayInternet } from './lib_basica'
import IncModProveedor from './IncModProveedor'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let opcionRegistro_imc='' //para saber si deseo incluir nuevo registro, modificar o consultar (pero en este form solo se va a poder llamar a CONSULTAR)
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho={
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE CLIENTES'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
		},
	},   
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
        minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },    
    cells: {
      style: {
        fontSize:'16px', //16px
        //el borde solo lo quiero ABAJO
        // top | right | bottom | left 
        //border-style: none solid dotted dashed;      
        borderStyle:"none none solid none", 
        borderColor:'silver',
        borderWidth:'thin',           
        //marginLeft:'20px', //funciona
        //paddingLeft: '80px', // no me funciona override the cell padding for data cells
        //paddingRight: '8px',
        //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
        //backgroundColor:'yellow', //
        //fontWeight:'bold',
        //height:'10px', /* bacan */
        //paddingTop:'0', /* no funciona */
        //paddingBottom:'0', /* no funciona */
        //margin:0, /* no me funciona */
      },
    },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: row => (row.IDr != filaClonada?.IDr ),
    style: row => ({ 
      backgroundColor:row.Activo=="Si"  ? 'white' : valueDC.sistema.coloresItemInactivo,
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (row.IDr == filaClonada?.IDr ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaSecundaria,
      color: 'black',
    }),
  },
]

const TabelaProveedoresBuscador=(props)=>{
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
  const [textoBuscarState,set_textoBuscarState]=useState('') //para el texto que va en el cuadrito de buscar  
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
  const [modalIncModRegistroState,set_modalIncModRegistroState]=useState(false) //para abrir el modal de incluir/modificar un registro        

const miEstructuraDataTable=[  
  {  
    name:'id',
    selector:row => row.IDr,
    sortable:true,
    center:true,
    grow:0.2,
    compact:true, //padding 0
    omit:true, //oculta (siempre) la celda
  },
  {
    name:'PRINCIPAL',
    selector:row => row.IdPrincipal,
    sortable:true,
    compact:true,
    grow:1, //es una medida relativa (1 es mi referencia)
  },
  {
    name:'NOMBRE',
    selector:row => row.RazonSocial,
    sortable:true,
    compact:true,
    grow:1.5,
  },       
  {
    name:'ACCIONES',
    sortable:false,
    center:true,
    compact:true,
    grow:1.6, //originalmente 1.6
    //width:'20%',
        
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton Listo  */}
    <Button id="btnSeleccionar" name="btnSeleccionar" style={{ marginRight:'1px',color:'white',fontWeight: 'bold' }} raised = "true" primary='false' color="info" 
      onClick={ () => { 
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        props.cerrarModal('select',rowRegistroClon.IdPrincipal.trim())
      }}>Listo
    </Button>
          
    {/* dropdown para el boton MAS... */}
    <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.IDr } direction="right"  
      toggle={()=>{
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.IDr : 0)
      }}>    
      <DropdownToggle color="info">
        <FontAwesomeIcon color="white" icon={faEllipsisH} />
      </DropdownToggle>
      <DropdownMenu style={{backgroundColor:'pink',}} >
        <DropdownItem onClick={async() => {
          if (await hayInternet()==false) return
          opcionRegistro_imc='c' //'c'onsultar registro
          set_idMasState(0)
          set_modalIncModRegistroState(true)          
        }}>
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faEye} /> Consultar</DropdownItem>        
        <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faBookReader} /> Cerrar</DropdownItem>        
      </DropdownMenu>

      </Dropdown>
    </div>,
  },    
  {
    name:'COMERCIAL',
    selector:row => row.NombreComercial,
    sortable:true,
    compact:true,
    left:true,
    grow:1,
  },    
  {
    name:'TELF',
    selector:row => row.TlfCel,
    sortable:true,
    compact:true,
    grow:0.9,
  },    
  {
    name:'CORREO',
    selector:row => row.Email,
    sortable:true,
    compact:true,
    grow:1.5, 
    omit:true, //oculta (siempre) la celda
  }, 
  {
    name:'CLASE',
    selector:row => row.Clase,
    sortable:true,
    compact:true,
    grow:1,
    hide:'sm',  
  },      
  {
    name:'CIUDAD',
    selector:row => row.Ciudad,
    sortable:true,
    compact:true,
    grow:1,
    hide:'md',    
  },  
  {
    name:'ACTIVO',
    selector:row => row.Activo,
    sortable:true,
    compact:true,
    center:true,
    omit:true, //oculta (siempre) la celda
  },  
]

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //hago un filtro, solo me interesan los activos
  let activos=props.registrosFull.filter(item=>{
    if (item.Activo=="Si") {
      item.VisibleSN="S"
      return item
    }}
  )
  set_registrosFullState(activos)
  //blanqueo variables sensibles, que no sean del estado
  opcionRegistro_imc=''  
  rowRegistroClon=null  

  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    set_registrosFullState([])
    set_rowRegistroState(null) 
    rowRegistroClon=null
    opcionRegistro_imc='' 
  }      
  },[]
)

const filtrarElementos=(cadenaLoca)=>{
  //ojo: obligatorio convertirla en minuscula para hacer la busqueda
  cadenaLoca=cadenaLoca.toLocaleLowerCase()

  let resultado=registrosFullState.filter(item=>{
    if (
      item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Clase.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Ciudad.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Email.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.BlockDeNotas.toString().toLocaleLowerCase().includes(cadenaLoca)
      )  
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item      
    })

  set_registrosFullState(resultado)
  set_idMasState(0)
  rowRegistroClon=null    
} 

const ComponentePrincipalBody=()=>{   
return(      
<div id="divMaster" name="divMaster" style={{width:'100%',minHeight:'80vh',background:'gray'}}>
    {/**** barra de titulo  */}
    <div id="divBarraTitulo" name="divBarraTitulo"> 
      <Row style={{margin:'0'}}> 
        <Col xs="10">       
          <Label style={{marginTop:'5px',fontWeight: 'bold',color:"pink"}}>{"Proveedores Activos"}</Label>
        </Col>
        <Col xs="2" style={{textAlign:'right',padding:'0'}}>
          <Button style={{width:'40px', height:'40px', }} id="btnCerrar" name="btnCerrar" color="danger" 
            onClick= { () =>{ 
              props.cerrarModal('close')  
            }}>
            <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
          </Button>
        </Col>
      </Row>
    </div> { /* divBarraTitulo */ }  

    {/******* para input de busqueda y refrescar  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', marginLeft:'10px', }} > 
      <Input 
        className = "textoBusquedaClientes" type="text" placeholder="Texto de búsqueda" id="inputBusqueda" name="inputBusqueda"                
        value={textoBuscarState}
        onChange={()=>{
          set_textoBuscarState( document.getElementById("inputBusqueda").value )
          filtrarElementos( document.getElementById("inputBusqueda").value.trim() )
        }}/>
      <Button  
        id="btnLimpiar" name="btnLimpiar" color="secondary"  style = { {marginLeft:'5px', marginRight:'5px' }}
        onClick={()=>{
          set_textoBuscarState('')
          set_rowRegistroState(null)
          rowRegistroClon=null
          set_idMasState(0)          
          filtrarElementos('')           
        }}> 
        <FontAwesomeIcon color="paleGreen" icon={faBroom} style = { {marginRight:'5px' }} />
        Limpiar
      </Button>        
    </div> {/* divBuscador1 */}

    { /* ***** TABELA ******/ }
    <div id="divTabela" name="divTabela" style={{marginTop:'2%',marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
      <DataTable
        //************ DATA TABLA PROPERTIES (basic) ***********/
        title='Lista de Proveedores Activos'
        columns={miEstructuraDataTable}
        data={registrosFullState.filter(item=>item.VisibleSN=='S')}
        conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)}  
        keyField ='IDr' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
        //este onRowClicked, me sirve solamente para que al tocar/clickear un fila, se ponga de fondo morado u otro color
        onRowClicked={(row) => { 
          set_rowRegistroState(row)
          rowRegistroClon=row
          set_idMasState(0)
        }}
        //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
        highlightOnHover  
        //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
        noDataComponent = ""
        //************ DATA TABLA PROPERTIES (pagination) ***********/
        pagination
        //paginationServer //change de default pagination to work with server pagination
        paginationPerPage={ valueDC.sistema.paginadoParaBuscadorProveedores } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
        paginationComponentOptions={paginacionOpciones}

        //************ DATA TABLA PROPERTIES (header) ***********/
        //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body

        //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
        customStyles={miEstilacho} /* redefino algun estilo */
      />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

    {/* ************************* MODAL PARA CONSULTAR PROVEEDOR *************** */}   
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalIncModRegistroState } >
      <IncModProveedor 
        cerrarModal={(accion,jsonRegistro)=> {
          set_modalIncModRegistroState(false)
          //accion puede ser: close/save/notfound [pero en este caso solo tiene sentido: close,notfound]
          if (accion=='close') return
          //el notfound, significa que NO se encontró al registro que se deseaba consultar (por simplicidad NO lo elimino de la tabela. lo sigo dejando ahi)
          if (accion=='notfound') return
        }}
        opcionRegistro_imc={opcionRegistro_imc} //obviamente 'c' 
        registroIDr={(opcionRegistro_imc=='i') ? 0 : ((rowRegistroClon==null) ? 0: rowRegistroClon.IDr)} //es util solamente cuando deseo editar o consultar el registro (cuando es NUEVO se va con 0)
        cedulaCandidata = {''} //solo tiene utilidad cuando se desea crear un proveedor desde COMPRAS (desde este formulario no se usa nunca)
        registrosFull = {registrosFullState} //[se usa para el censo]. en el componente IncModProveedor, si va a leer fisicamente desde la BDD      
      />
    </Modal>  

</div> //Div principal
) // del return interno
}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} // del expport

export default TabelaProveedoresBuscador