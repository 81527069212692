//me muestra un modal con una lista de todos los recibos con que se ha pagado una compra o gasto
//OJO: solo para las compras a credito se le puede activar el boton ELIMINAR RECIBO.
//Solo para las compras, se le pone la etiqueta COMPRA/CREDITO o COMPRA/CONTADO. (la idea es que el usuario esté claro de que tipo de compra se muestran los recibos)
//cuando sea un GASTO, no se puede eliminar el recibo
import React from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import {Modal, Input, Label, Button, Row, Col} from 'reactstrap'
//del context
import {AppContextConsumer} from './ContextBase'
import buscarPhpPath,{sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,generarReciboEgresoPDFconSwal,eliminarPagoDeCxPconSwal,hayInternet } from './lib_basica'
//componentes necesarios
import VerPdfOk from './VerPdfOk'
import PedirClaveEspecial from './PedirClaveEspecial'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faGlasses, faAngleDoubleDown, faWindowClose ,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 

const miEstilacho={
	table: {
		style: {
			minHeight: '30vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'Recibos de caja'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'dimgray',
		},
	},    
  headCells: {
    style: {
      background: 'dimgray', //ok hotpink
      color: 'lavender',  //ok lavender
      fontSize:'16px',   //ok
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },  
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',            //thin
    },
  },
}

export default class RecibosEgresoDeUnDocumento extends React.Component {   
  state = {
    nombreComponenteParaVer: 'lista', //lista de recibos (vista por default) Tambien podria ser "VerPdf" 
    filaState: null, //17 numeros del recibo. Para que se ponga azul
    estabRecibo: null,
    puntoRecibo: null,
    numRecibo: null,
    datosFull: [],  //aqui esta la lista de todos los recibos correspondientes al generador, todas las columnas
    estatusAbiertoModal_PedirClaveEspecial: false, //para pedir la clave especial
  }  

valueDC=null //Aqui hago una copia de los valores del context
intentos=1 //me sirve para hacer 3 intentos en cada solicitud/peticion. El tope maximo viene en el context
permanecerEnForm=null //al tratar de refrescar y no poder, entonces posiblemente me tengo que devuelver  
pdfCreadoCorrectamenteSN=null //para saber si se pudo crear el PDF  

filaCondicional=[
  //fila NO seleccionada
  {
    when: (row) => (row.Recibo != this.state.filaState ),
    style: row => ({ 
      backgroundColor:'white',
      color: 'dimgray',
     }),    
  },  
  //fila NO seleccionada  
  {
    when: (row) => (row.Recibo == this.state.filaState ),
    style: row=> ({    
      backgroundColor: this.valueDC.sistema.coloresFilaSeleccionadaSecundaria,
      color: 'black', 
      //backgroundColor: 'red'
    }),
  },
]

miEstructuraDataTable=[  
  {  
    name:'RECIBO',
    selector:row => row.Recibo, //Tal como viene en el Json desde las Base de datos
    sortable:false,
    center:true,
    //compact:true,
    grow:1,
  },  
  {
    name:'MONTO',
    selector:row => row.MontoDelRecibo, 
    sortable:false,
    right:true,
    grow:0.4,
  }, 
{
  //ACCIONES
  name:'',
  sortable:false,
  center:true,
  //grow:0.4,  
  cell: (row) => 
  <div style={{ display:'flex',}}>            
    {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
    {/* tambien da error al poner raised. se debe poner raised="true"  */}
    {/* o se puede poner raised= {miVariable.toString() }  */}

  {/* boton eliminar, Solo aplica cuando el documento sea tipo CREDITO */}
  <Button id="btnEliminar" name="btnEliminar" style={{marginRight:'1px',display: this.props.condicion=='CONTADO' ? 'none' : 'block' }} raised = "true" primary='false' color="danger" 
    onClick={ () => {
      this.setState({filaState:row.Recibo.trim(),estabRecibo:row.Estab.trim(),puntoRecibo:row.Punto.trim(),numRecibo:row.NumRecibo.trim(),})  
      setTimeout(async()=>{
        this.setState({estatusAbiertoModal_PedirClaveEspecial : true} )
      },50)  
    }}>
    <span style ={{fontSize:"20px"}}><FontAwesomeIcon color="pink" icon={faTrashAlt} /></span>
  </Button>

  {/* boton ver recibo */}
  <Button id="btnVer" name="btnVer" style={{ marginRight:'1px' }} raised = "true" primary='false' color="primary" 
    onClick={ () => {
      this.setState({filaState:row.Recibo.trim(),estabRecibo:row.Estab.trim(),puntoRecibo:row.Punto.trim(),numRecibo:row.NumRecibo.trim(),})  
      setTimeout(async()=>{
        if (await hayInternet()==false) return 
        this.pdfCreadoCorrectamenteSN=await generarReciboEgresoPDFconSwal(this.state.estabRecibo,this.state.puntoRecibo,this.state.numRecibo,'A4v',this.valueDC)
        if (this.pdfCreadoCorrectamenteSN=="S") this.setState( {nombreComponenteParaVer:'verPdf',} )        
        else mostrarSwalBotonAceptar("error","ATENCION","No se pudo crear el PDF correctamente")              
      },50)    
    }}>
    <span style ={{fontSize:"20px"}}><FontAwesomeIcon color="pink" icon={faGlasses} /></span>
  </Button>
 </div>,
},
  {
    name:'FECHA',
    selector:row => row.FechaReciboFormat, 
    sortable:false,
    center:true,
    grow:0.7,   
  },   
]

copiarVariablesDesdeContext=(value)=>{
  this.valueDC=value
}

sumarizarAbonosYsalir=()=>{
//Hago una sumatoria de los abonos (la idea es actualizar la tabela del llamador ya que alomejor aqui se eliminaron recibos)
//OJO: en un futuro, se debe mejorar esta parte (al haber pagado varias facturas en un mismo recibo, entonces la sumatoria estaria incorrecta)
//el campo de referencia es MontoDelRecibo
let sumaDeAbonos=0

for (let value of this.state.datosFull) 
  sumaDeAbonos += parseFloat(value.MontoDelRecibo)

this.setState({datosFull:[],}) //limpio la memoria
let todoOKsn="s"
this.props.cerrarModal(todoOKsn,sumaDeAbonos)
}

refrescarData=async()=>{ 
  this.intentos=1
  let seguir=true  
  mostrarSwalEspera()
  while (seguir){
      let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
      let data=new FormData()
  
      data.append('miSol','reciboegreso_buscarRecibosGeneradosPorUnDocumento')
      data.append('IDr',this.props.IDr) //codigo rojo del proveedor
      data.append('EstabDG',this.props.estabDG)
      data.append('PuntoDG',this.props.puntoDG)
      data.append('NumDG',this.props.numDG)
      let miPrefijo=this.props.prefijoDG
      data.append('PrefijoDG',(miPrefijo=="F" ? "FA" : miPrefijo=="N" ? "NV" : miPrefijo=="L" ? "LIQ" : miPrefijo ))

      try{   
        let response = await fetch(miPhpFile, { method: 'POST',body:data })
        let pepeJson = await response.json()  
        //analizo la cabecera del JSON de la respuesta
        let hayError=false
        let miE=null
        if (!pepeJson.ConexionBUC){
          hayError=true
          miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
        } 
        if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
          hayError=true
          miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
        }     
        if (hayError){
          throw miE      
        } 
        //rumbo normal*********    
        this.setState({ datosFull:pepeJson.Data,}) 
        apagarSwal()
        seguir=false
      } //del try
      catch (e) {   
        this.intentos++
        if (this.intentos<=this.valueDC.sistema.numeroDeReintentosPhp){      
          await sleepPepe(this.valueDC.sistema.milisegundosParaNuevoReintentoPhp)        
        }
        else{  
          //llamo al gestor para que me aparezca la pantalla de REINTENTAR
          apagarSwal()
          let miRespSwal = await gestionarCatch(e)
          //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
          if (miRespSwal.isDenied) {
            mostrarSwalEspera()
            this.intentos=1
          }
          else{
            //ha pulsado salir en el swal
            seguir=false
            if (!this.permanecerEnForm){
              this.props.cerrarModal("n",null) //no se pudo leer los recibos. En sumatoria de abonos le mando 0
            }
          }
        }//del else
      }//del catch
  } //del while
  }
  
ponerCabecera=()=>{
  //titulo
  document.getElementById("miTitulo").innerText="Recibos de egreso de " + this.props.proveedorNombre
  //Tipo de documento de compra [FACTURA/NOTA DE VENTA/LIQUIDACION]. O quizá GASTO
  let miTipoDoc=null
  switch (this.props.prefijoDG) {
    case 'F':
      miTipoDoc='FACTURA / ' + this.props.condicion
      break;
    case 'N':
      miTipoDoc='NOTA DE VENTA / ' + this.props.condicion
      break
    case 'L':
      miTipoDoc='LIQUIDACION / ' + this.props.condicion
      break
  }
  document.getElementById("txtTipoDoc").value= miTipoDoc
  //Numero
  document.getElementById("txtNumDoc").value= this.props.doc17DG
  //Fecha
  document.getElementById("txtFecha").value= this.props.fechaDG
  //Monto total del documento generador
  document.getElementById("txtMonto").value= this.props.montoDG
}

componentDidMount(){  
  this.ponerCabecera()
  this.permanecerEnForm=false
  this.refrescarData()//busco todos los recibos del generador (al haber algun error, se sale del formulario y regreso al llamador)
}

ParteGrid=()=>{
  return(
  <div id="divTabela" name="divTabela" style={{borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'gray', width:'98%', marginLeft:'1%', marginRight:'1%', marginBottom:'20px',}} > 
    <DataTable
      //************ DATA TABLA PROPERTIES (basic) ***********/
      title='Recibos de egreso de caja'
      columns={this.miEstructuraDataTable}
      data={this.state.datosFull}
      conditionalRowStyles={this.filaCondicional}  
      keyField ='Recibo' /* Se puede poner 'id' (valor por omision), 'Fila' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
      onRowClicked={(row) => { 
          this.setState({filaState:row.Recibo.trim(),estabRecibo:row.Estab.trim(),puntoRecibo:row.Punto.trim(),numRecibo:row.NumRecibo.trim(),})  
        }}
      noDataComponent={null}
      //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
      customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
  </div>
  )  
}

activarLista=()=>{
  this.setState({nombreComponenteParaVer:'lista' }) 
  setTimeout(()=>{  
    this.ponerCabecera() //vuelvo a mostrar el encabezado
  },50)
}

gestionarEliminarRecibo=()=>{    
  //este pedazo de validaciones, queda para el futuro
  //se deberia validar si se ha pagado en grupo. Para dar una advertencia

  /*
  if (this.state.factura17==''){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Factura")          
    return
  }
  //reviso si ya esta anulada previamente en el grid (es decir si ya estaba tachada)
  if (this.rowFactura.Anulado==1){
    mostrarSwalBotonAceptar("warning","ATENCION","Esta Factura ya estaba anulada")          
    return
  }
*/

  this.eliminarReciboEnBDD()
}

eliminarReciboEnBDD=async()=>{
  if (await hayInternet()==false) return 
  //cuando estoy tratando de eliminar un recibo de una compra al contado, entonces sql me devuelve -110 (este caso es muy dificil que ocurra)
  let numeroDevuelto=await eliminarPagoDeCxPconSwal(this.props.IDr,this.state.estabRecibo,this.state.puntoRecibo,this.state.numRecibo,this.valueDC)

  numeroDevuelto=parseInt(numeroDevuelto)
  if (numeroDevuelto==-110)
    mostrarSwalBotonAceptar("error","ATENCION","A las Compras tipo Contado no es posible eliminar los recibos")              

  //todo OK
  if (numeroDevuelto>0)
  {
    //elimino de la tabela, el recibo eliminado
    let copiaFull=this.state.datosFull.filter(item=> (item.Recibo!=this.state.filaState) )

    this.setState({datosFull:[], })    
    this.setState({filaState:'',estabRecibo:'',puntoRecibo:'',numRecibo:''})  
    this.setState({datosFull:copiaFull})   
    
    mostrarSwalBotonAceptar("success","OK","Recibo eliminado correctamente")              
  }
}

render(){ 
return (  
  <AppContextConsumer>
  { (value) =>{

if (this.state.nombreComponenteParaVer=='verPdf') {
  return (<VerPdfOk
    documentoTitulo={"RECIBO DE EGRESO"}
    documentoArchivo={"REC_" + this.state.estabRecibo + "-" + this.state.puntoRecibo + "-" + this.state.numRecibo }
    corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
    tipoSriSN={"N"} //es un documento del sri?
    activarMenu={ this.activarLista }   
    />)
}

if (this.state.nombreComponenteParaVer=='lista') {
return (       
  <div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {this.copiarVariablesDesdeContext(value)}

{/* encabezado y boton X */}
<Row style={{margin:'0',}}>
  <Col xs="10" >
    <Label id="miTitulo" name="miTitulo" style={{marginTop:'5px',fontWeight: 'bold'}}>Recibos de caja</Label>
  </Col>
  <Col xs="2" style={{textAlign:'right',padding:'0'}}>
  <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
    onClick= { () =>{ this.sumarizarAbonosYsalir() }
      } >
  <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
  </Button>
  </Col>
</Row>             

{/* Datos del documento generador: numero, fecha, total */}
<div id="divBloque1" name="divBloque1"  style={{width:'98%',marginLeft:'1%',marginRight:'1%',marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink'}}>    
<Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0',}}>
  <Col xs="6" style={{display:"flex",}}>
    <Label style={{width:"30%" }} >Documento</Label>
    <Input name="txtTipoDoc" id="txtTipoDoc" disabled style={{marginLeft:"3%",width:"67%",textAlign:'center'}}/>           
  </Col>
  <Col xs="6" style={{display:"flex",}}>
    <Label style={{width:"30%" }}>Número</Label>
    <Input id="txtNumDoc" name="txtNumDoc" disabled style={{marginLeft:"3%",width:"67%",textAlign:'center'}}/>           
  </Col>  
</Row>
<Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0',}}>
  <Col xs="6" style={{display:"flex",}}>
    <Label style={{width:"30%" }} >Fecha Ingreso</Label>
    <Input name="txtFecha" id="txtFecha" disabled style={{marginLeft:"3%",width:"67%",textAlign:'center'}}/>           
  </Col>
  <Col xs="6" style={{display:"flex",}}>
    <Label style={{width:"30%" }} >Total</Label>
    <Input id="txtMonto" name="txtMonto" disabled style={{marginLeft:"3%",width:"67%",textAlign:'center'}}/>           
  </Col>  
</Row>
</div> {/* divBloque1 */}

 {/* lista de los recibos de pago */}
  <this.ParteGrid/>

{/************ MODAL PARA PEDIR LA CLAVE ESPECIAL y luego eliminar*************/}
<Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ this.state.estatusAbiertoModal_PedirClaveEspecial } >
    <PedirClaveEspecial
        cerrarModal={(claveCorrecta)=> {
          this.setState( { estatusAbiertoModal_PedirClaveEspecial : false,})
          if (claveCorrecta) this.eliminarReciboEnBDD()
        }}
        claveEnBDD={this.valueDC.licencia.ClaveOperacionesEspeciales}      
        subtitulo={"eliminar el recibo: " + this.state.filaState}
    />
</Modal> 

  </div> // divMaster
  )
} //del if de ver LISTA

}}
</AppContextConsumer>
) //Del return externo

} // del render principal
} // de la clase
