//alert(JSON.stringify(rowRegistroState,null,2))
//ERROR AL COBRAR 22.40=> si pongo 10 efectivo y 12.40 en cheque ME SALE MONTO MUY ALTO (solucionado)
//lo unico nuevo en este form, es que hay que recibir por las props una variable que indica si deseo revisar si la referencia ya fue usada anteriormente
//luego copiarlo en CORONA, 
import React, {useState,useEffect} from 'react'
import DataTable from 'react-data-table-component'
import {Input, Label, Button, Row, Col} from 'reactstrap'
import {limpiarCombo,aceptarDecimales2023,textoDelCombo,aceptarSoloEnteros,ejecutarFetchGenericoConSwal,mostrarSwalBotonAceptar,mostrarSwalConfirmacionEliminarAnular, reemplazarComaPorPunto,reemplazarPuntoPorComa} from './lib_basica'
//del context
import {AppContextConsumer} from './ContextBase'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleDown, faWindowClose ,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//ariables globales (y que no son del estado)
let rowRegistroStatePagoClon=null //es una copia del estado de la variable rowRegistroStatePago. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let miFilaAutonumerico=1 //Guarda el numero de fila automumerico en el grid(este valor no se recicla)
let mostrarRetSN=null //para saber si se debe mostrar la palabra retencion en la forma de pago
let totalFull=0 //Guardo el monto total a cobrar
let totalRec=0 //Total recibido
let totalSaldo=0 //saldo
let todoAbonoTA=null //para saber si se debe cobrar Todo o puede ser un Abono
let miJsonTmp=null //Es el JSON con las formas de pago elegidas por el usuario. que devuelvo al llamador
let valueDC=null //Aqui hago una copia de los valores del context
let dataApi=null //Aqui guardo la respuesta, para saber si un numero de referencia ya fué usada
let buscarReferenciaAutomaticamenteSN="N" //La gente de Quito (instituto Americano quiere que siempre se busque)

const miEstilacho={
	table: {
		style: {
			minHeight: '20vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'Formas seleccionadas'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'dimgray',
		},
	},    
  headCells: {
    style: {
      background: 'dimgray', //ok hotpink
      color: 'lavender',  //ok lavender
      fontSize:'16px',   //ok
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
      //color:'yellow', //ok funciona bien
      //background:'blue', //bacan
      //marginTop:'4px',
      //marginBottom:'10px',
    }
  },    
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',            //thin
      //color: 'dimgray', //el color de la celda lo maneja el filacondicional
      //cursor: 'pointer',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: (row) => (row.Fila != filaClonada?.Fila ),
    style: row => ({ 
      backgroundColor:'white',
      color: 'dimgray',
     }),    
  },  
  //fila NO seleccionada  
  {
    when: (row) => (row.Fila == filaClonada?.Fila ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaSecundaria,
      color: 'black', 
      //backgroundColor: 'red'
    }),
  },
]

const PedirFormaDePago=(props)=>{   
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')  
  const [rowRegistroStatePago,set_rowRegistroStatePago]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [registrosFullStatePago,set_registrosFullStatePago]=useState([]) //lo que contiene la tabela

const miEstructuraDataTable=[  
  {  
    name:'FILA',
    selector:row=>row.Fila, 
    sortable:false,
    center:true,
    grow:0.2,
    omit:true, //omitir siempre  
  },  
  {  
    name: 'IDFORMA',
    selector: row => row.IdForma,
    sortable: false,
    center: true,
    grow: 0.2,
    omit: true, //omitir siempre  
  },
  {  
    name: 'FORMA',
    selector: row => row.Forma,
    sortable: false,
    left: true,
    grow: 1,
    omit: false,  
  },
  // =========================
  // ===== acciones =========
  // =========================
  {
    name:'',
    sortable:false,
    center:true,
    //compact:true,
    grow:0.2,  
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton Eliminar      */}
    <Button id="btnEliminar" name="btnEliminar" style={{ marginRight:'0px' }} raised = "true" primary='false' color="primary" 
      onClick={ () => {          
        eliminarUnaFormaDePago(row.Fila)
      }}>
      <span style ={{fontSize:"20px"}}><FontAwesomeIcon color="pink" icon={faTrashAlt} /></span>
    </Button>
   </div>,
  },
  //====== fin de acciones
  {  
    name:'IdBancoOrigen',
    selector:row=>row.IdBancoOrigen, 
    sortable:false,
    center:true,
    grow:0.2,
    omit:true,  
  },     
  {  
    name:'MONTO',
    selector:row=>row.Monto, 
    sortable:false,
    right:true,
    grow:0.7,
    omit:false,  
  }, 
  {  
    name:'BANCO',
    selector:row=>row.Banco, 
    sortable:false,
    left:true,
    grow:0.7,
    omit:false,  
  },   
  {  
    name:'IdCuentaBancaria',
    selector:row=>row.IdCuentaBancaria, 
    sortable:false,
    center:true,
    grow:0.2,
    omit:true,  
  }, 
  {  
    name:'REFERENCIA',
    selector:row=>row.Referencia, 
    sortable:false,
    left:true,
    grow:0.7,
    omit:false,  
  }, 
]

const eliminarUnaFormaDePago=(FilaEliminar)=>{
  let miGridCopia=registrosFullStatePago.filter(item=>item.Fila!=FilaEliminar)
  set_registrosFullStatePago(miGridCopia)
}

const alimentarComboBancos=()=>{
  let miSelectB= document.getElementById("comboBanco")

  //limpio el combo de una StrictMode o de una corrida anterior
  limpiarCombo(miSelectB)

  valueDC.bancos.forEach( (item) => {
    let miOption=document.createElement("option")
    miOption.value= item.CodBanco
    miOption.text=item.Banco
    miSelectB.appendChild(miOption)
  })  
}

const validarBancoYreferencia=async()=>{
  //solamente me interesa validar que haya un banco seleccionado y algo escrito en la referencia
  //en algunas licencias hay que revisar si esa referencia/cheque/deposito ya fue usado anteriormente
  if (document.getElementById("comboBanco").selectedIndex < 0 ){
    await mostrarSwalBotonAceptar("warning","ATENCION","Debe seleccionar un Banco")
    return false
}

if ((document.getElementById("txtRef").value.trim()).length== 0 ){
  await mostrarSwalBotonAceptar("warning","ATENCION","Debe indicar la referencia")
  return false
}

//De forma opcional hay que revisar si alguna transferencia, cheque ya fue utilizado
if (buscarReferenciaAutomaticamenteSN=="S"){
  let puedoSeguirSN=await buscarReferenciaAutomaticamente() //puedo recibir S/N
  if (puedoSeguirSN=="N") return false
}

return true
}

const validarEfectivoRepetido=()=>{
  let repe=false //asumo que no esta repetido

  registrosFullStatePago.map(item=>{ 
    if (item.Forma.includes("EFECTIVO"))
      repe=true
    })

  return repe
}

const validarUnaFila=async()=>{  
//valida cuando se trata de meter UNA forma de pago segun sea EFECTIVO, CHEQUE......

//primero chequeo que haya seleccionado una FORMA
if (document.getElementById("comboForma").selectedIndex < 0 ){
  await mostrarSwalBotonAceptar("warning","ATENCION","Debe seleccionar una Forma de Pago")
  return false
}

//guardo la forma que desea
let miFormaTmp=document.getElementById("comboForma").value
miFormaTmp=textoDelCombo(document.getElementById("comboForma"))

//cuando sea efectivo, debo revisar que NO meta dos veces EFECTIVO
if (miFormaTmp.includes("EFECTIVO") && validarEfectivoRepetido()){
  await mostrarSwalBotonAceptar("warning","ATENCION","No se puede poner efectivo dos veces")
  return false
}

let montoTMP=document.getElementById("txtMonto").value.trim()
montoTMP=reemplazarComaPorPunto(montoTMP)
if (montoTMP.length==0 || isNaN(montoTMP)){
  await mostrarSwalBotonAceptar("warning","ATENCION","Debe indicar un monto válido")
  return false
}

montoTMP=parseFloat(montoTMP)
montoTMP=montoTMP.toFixed(2) //solo cojo dos decimales, pero el JS lo convierte en texto
montoTMP=parseFloat(montoTMP)

if (montoTMP <= 0 ){
  await mostrarSwalBotonAceptar("warning","ATENCION","Debe indicar un monto mayor que cero")
  return false
}

if (montoTMP > totalSaldo){
  await mostrarSwalBotonAceptar("warning","ATENCION","El Monto " + montoTMP.toFixed(2) + " es muy alto")
  return false
}

//cuando sea cheque, transferencia, tarjeta.... se debe elegir un banco y referencia
if (miFormaTmp.includes('TRANSFERENCIA') || miFormaTmp.includes('DEPOSITO') || miFormaTmp.includes('TARJETA') || miFormaTmp.includes('CHEQUE') || miFormaTmp.includes('DINERO ELECTR') ){
  if (await validarBancoYreferencia()==false) return false
}

return true
}

const validarFinal=async()=>{  
  //valido que realmente se haga recibido algun dinero
  //ademas, cuando es TODO obviamente que sea todo. En algunos casos se acepta ABONO

  if (registrosFullStatePago.length==0){
    await mostrarSwalBotonAceptar("warning","ATENCION","Aún no ha seleccionado ninguna forma de pago")
    return false
  }

  if (todoAbonoTA=="T" && totalSaldo!=0) {
    await mostrarSwalBotonAceptar("warning","ATENCION","El monto recibido no coincide")
    return false
  }

  return true
}

const calcularTotal=()=>{
  totalRec=0
  totalSaldo=totalFull

  //recorro el json del grid
  registrosFullStatePago.forEach(item=>{ totalRec += parseFloat(item.Monto) })
  totalSaldo=totalFull - totalRec

  //hago un redondeo todo loco (muy importante)
  totalSaldo=totalSaldo.toFixed(2)
  totalSaldo=parseFloat(totalSaldo)

  //pinto en los cuadros de texto
  document.getElementById("txtRecibido").value= totalRec.toFixed(2)
  document.getElementById("txtFaltante").value= totalSaldo.toFixed(2)
}

const generarJsonFormaDePago=()=>{
  //tomo el grid y lo convierto en JSON para devolverlo al llamador
  miJsonTmp=[]
  registrosFullStatePago.forEach(item=>{
    let formaTmp={}
    formaTmp.IdForma=item.IdForma 
    formaTmp.IdBancoOrigen=item.IdBancoOrigen
    formaTmp.IdCuentaBancaria=0
    formaTmp.Referencia=item.Referencia.trim()
    formaTmp.Monto=parseFloat(item.Monto)
    miJsonTmp.push(formaTmp)
  })
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  mostrarRetSN=props.mostrarRetSN
  totalFull=props.totalFull //desde el llamador, viene en float
  totalSaldo=props.totalFull 
  todoAbonoTA=props.todoAbonoTA

  let totalFullEnTexto=totalFull.toFixed(2) //lo convierto en texto 3.14
  totalFullEnTexto=reemplazarPuntoPorComa(totalFullEnTexto) //3,14

  //edito el titulo
  document.getElementById("miTitulo").innerText="Total a pagar: " + totalFullEnTexto
  //pongo de una vez el monto a pagar (solo cuando se pago Total. Cuando sea Abono se deja vacío)
  if (props.todoAbonoTA=="T") document.getElementById("txtMonto").value=totalFullEnTexto
  
  calcularTotal()
  
  //Elimino la opcion de RETENCION,
  if (mostrarRetSN=='N') document.getElementById("comboForma").remove(11)
  
  //Cargo los bancos (y arranca desactivado)
  alimentarComboBancos()
  document.getElementById("comboBanco").setAttribute("disabled","")                                      
  
  //No debe estar PRE-SELECCIONADA ninguna forma de pago.  
  document.getElementById("comboForma").selectedIndex=-1
  //No debe estar PRE-SELECCIONADO ningun banco
  document.getElementById("comboBanco").selectedIndex=-1

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //variables normales
    rowRegistroStatePagoClon=null 

    //variables de estado
    set_registrosFullStatePago([])
    set_rowRegistroStatePago([])
  } 
  },[]
)
useEffect(()=>{  
    calcularTotal()
    generarJsonFormaDePago()
  },[registrosFullStatePago]  
)

const ParteGrid=()=>{
return(
  <div id="divTabela" name="divTabela" style={{borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'gray', width:'98%', marginLeft:'1%', marginRight:'1%', }} > 
    <DataTable
      //************ DATA TABLA PROPERTIES (basic) ***********/
      title='Formas seleccionadas'
      columns={miEstructuraDataTable}
      data={registrosFullStatePago ?? []}
      conditionalRowStyles={filaCondicionalExterna(rowRegistroStatePagoClon)}  
      keyField ='Fila' /* Se puede poner 'id' (valor por omision), 'Fila' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
      onRowClicked={(row) => { 
        set_rowRegistroStatePago(row)
        rowRegistroStatePagoClon=row
      }}
      noDataComponent={null}
      //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
      customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
  </div> //divTabela
  )  
}

const incluirEnCesta=()=>{
//fila temporal para luego meterla al grid
let filaTmp={}
filaTmp.Fila=miFilaAutonumerico
filaTmp.IdForma=document.getElementById("comboForma").value
filaTmp.Forma=textoDelCombo(document.getElementById("comboForma")) 
filaTmp.IdBancoOrigen=(document.getElementById("comboBanco").selectedIndex==-1) ? 0 : document.getElementById("comboBanco").value
filaTmp.Banco=(textoDelCombo(document.getElementById("comboBanco"))==null) ? '' : textoDelCombo(document.getElementById("comboBanco"))
filaTmp.IdCuentaBancaria=0
filaTmp.Referencia=document.getElementById("txtRef").value.trim()
let montoTmp=document.getElementById("txtMonto").value
montoTmp=reemplazarComaPorPunto(montoTmp)
montoTmp=parseFloat(montoTmp)
montoTmp=montoTmp.toFixed(2)
filaTmp.Monto=montoTmp
//Aumento miFilaAutonumerico. Ese numero nunca se recicla
miFilaAutonumerico++

//Actualizo la tabela
set_registrosFullStatePago([...registrosFullStatePago,filaTmp])

//limpio
document.getElementById("comboForma").selectedIndex=-1
document.getElementById("comboBanco").selectedIndex=-1
document.getElementById("txtMonto") .value=''  
document.getElementById("txtRef").value=''  
}

const onChangeComboForma=()=>{
  document.getElementById("comboBanco").selectedIndex=-1
  document.getElementById("txtRef").value=''  

  //dependiendo de la forma de pago se activa/desactiva el combo de bancos
  let miFormaTmp=document.getElementById("comboForma").value
  miFormaTmp=textoDelCombo(document.getElementById("comboForma"))
  if (miFormaTmp.includes('TRANSFERENCIA') || miFormaTmp.includes('DEPOSITO') || miFormaTmp.includes('TARJETA') || miFormaTmp.includes('CHEQUE') || miFormaTmp.includes('DINERO ELECTR') ){
    document.getElementById("comboBanco").removeAttribute("disabled")
  }
  else{
    document.getElementById("comboBanco").setAttribute("disabled","")                                      
  }
}    

const buscarReferenciaEnBDD=async()=>{
  //Busca en la BDD si la referencia ya fué usada. El json que devuelve la BDD está en dataApi
  //dataApi, puede ser null o vacío (al tener datos, es porque ya fue usada y me trae los documentos donde se usó)
  //realmente la busqueda la hace sobre la vista: v_recibocajaingresos_imprimir

  let data=new FormData()
  data.append('miSol','reciboingreso_buscarReferencia')
  data.append('miRef',document.getElementById("txtRef").value.trim() )
  dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
}

const buscarReferenciaPorLink=async()=>{
  //Me sirve para buscar una referencia, pero usando el link azul (para el boton amarillo es por otro lado)
  let miRef=document.getElementById("txtRef").value.trim() 
  if (miRef.length==0){
    await mostrarSwalBotonAceptar("error","ATENCION","Debe escribir algún numero en la referencia")
    return
  }
  await buscarReferenciaEnBDD() //busco la referencia y el resultado queda guardado en dataApi
  if (dataApi==null) return 
  if (dataApi.length==0) await mostrarSwalBotonAceptar("info","TODO BIEN","Referencia libre")              
  if (dataApi.length>=1) await mostrarSwalBotonAceptar("error","ALERTA","Referencia usada")              
}

const buscarReferenciaAutomaticamente=async()=>{
  //Me sirve para buscar una referencia cuando el usurio hace clic en el boton Amarillo (el de incluir en la cesta) 
  //en caso de estar repitiendo una referencia, entonces se le pregunta al usuario si aún así desea avanzar
  let puedoSeguirSN="N" //para saber si puedo seguir avanzado o ya de plano el usurio NO quiere avanzar
  let miRef=document.getElementById("txtRef").value.trim() 

  await buscarReferenciaEnBDD() //busco la referencia y el resultado queda guardado en dataApi
  if (dataApi==null) puedoSeguirSN="N" //No puedo seguir porque no habia internet, o no hubo respuesta
  if (dataApi.length==0) puedoSeguirSN="S" //es lo ideal
  if (dataApi.length>=1) {
    let miResp = await mostrarSwalConfirmacionEliminarAnular(`REFERENCIA ${miRef} USADA `,"Desea avanzar de todos modos ?")
    if (miResp.isDenied) puedoSeguirSN="S"
    if (miResp.isDismissed) puedoSeguirSN="N"
  }

  return puedoSeguirSN
}

const ComponentePrincipalBody=()=>{  
return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* encabezado y boton X */}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label id="miTitulo" name="miTitulo" style={{marginTop:'5px',fontWeight: 'bold'}}>Total a pagar: </Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ props.recibirPago(null,null,false) }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row>             
  {/* Incluir un item en la cesta */}
  <div id="divBloque1" name="divBloque1"  style={{width:'98%',marginLeft:'1%',marginRight:'1%',marginTop:"2%",marginBottom:'2%',paddingTop:'1%',paddingBottom:'1%',background:'purple', color:'pink'}}>    
    {/*  fila para forma de pago y monto, boton incluir*/}
    <Row style={{marginBottom:"0%",marginLeft:'0',marginRight:'0',}}>
      <Col xs="6">
        <Label for ="comboForma" style={{marginLeft:"2%"}} >Forma</Label>
        <Input type="select" name="comboForma" id="comboForma" style={{width:"100%"}}
        onChange={()=> {     
          onChangeComboForma()     
        }}
        >
          <option value="5">EFECTIVO</option>
          <option value="50">TRANSFERENCIA</option>                 
          <option value="45">DEPOSITO</option>    
          <option value="20">TARJETA DE DEBITO</option>    
          <option value="35">TARJETA DE CREDITO</option>    
          <option value="40">CHEQUE</option>    
          <option value="55">DESCUENTO/BONIFICACION</option>    
          <option value="15">COMPENSACION DE DEUDAS</option>    
          <option value="25">DINERO ELECTRONICO</option>    
          <option value="30">TARJETA PREPAGO</option>    
          <option value="60">ENDOSO DE TITULO</option>    
          <option value="10">RETENCION</option>                
          <option value="65">OTRO</option>                
        </Input>    
      </Col>
      <Col xs="4" > 
        <Label for ="txtMonto" >Monto</Label>
        <Input style={{width:'95%'}} type="text" id="txtMonto" name="txtMonto" autocomplete="off"
            onKeyDown = {(e) => { aceptarDecimales2023(e) } }  />
      </Col>  
      <Col xs="2" style={{padding:'0',}}> 
        <Button id="btnIncluir" name="btnIncluir" color="warning"  style={{marginTop:'12px',width:"60px",height:"60px", fontSize:'30px' }} 
          onClick = { async() => {
            if (await validarUnaFila()==false) return
            incluirEnCesta()
          }}>
          <FontAwesomeIcon color="black" icon={faAngleDoubleDown}/>
        </Button>
      </Col>  
    </Row>  
    {/* fila para banco y referencia */}  
    <Row style={{marginBottom:"0%",marginLeft:'0',marginRight:'0',}}>
      <Col xs="6">
        <Label for ="comboBanco" style={{marginLeft:"2%"}} >Banco</Label>
        <Input type="select" name="comboBanco" id="comboBanco" style={{width:"100%"}} />
      </Col>
      <Col xs="6" > 
        <Label for ="txtRef" >Referencia</Label>
        <Input style={{width:'95%'}} type="text" id="txtRef" name="txtRef" maxLength="25" autocomplete="off" />   
        <a className="btn btn-link" style={{color:"cyan",}}
          onClick={ async()=>{    
            await buscarReferenciaPorLink()
          }}>Revisar la referencia
          </a>  
      </Col>  
    </Row>  
  </div> {/* divBloque1 */} 
  {/* Grid con las formas usadas por el cliente */}
  {ParteGrid()}

  {/* Pie: aqui va los montos: total, recibido, faltante y boton FIN */}
  <div id="divPie" name="divPie" style={{width:'98%',marginLeft:'1%',marginRight:'1%',marginTop:"2%",marginBottom:'1%',paddingTop:'2%',paddingBottom:'1%',background:'purple', color:'pink'}}>    
  <Row style={{margin:'0',}}>
    <Col xs="4">
      <Label for ="txtRecibido" >Recibido</Label>
      <Input style={{width:'95%'}} type="text" id="txtRecibido" name="txtRecibido" disabled />   
    </Col>
    <Col xs="4">
      <Label for ="txtFaltante" >Faltante</Label>
      <Input style={{width:'95%'}} type="text" id="txtFaltante" name="txtFaltante" disabled />   
    </Col>
    <Col xs="4" style={{textAlign:'right'}}>
      <Button style={{marginTop:'10px',width:"90px",height:"60px", fontSize:'25px', color:'white'}} id="btnImprimir" name="btnImprimir" color="success"
          onClick = { async() => {
          if (await validarFinal()==false ) return
          let miFaltanteFloat=document.getElementById("txtFaltante").value.trim()

          miFaltanteFloat=parseFloat(miFaltanteFloat)
          props.recibirPago(miFaltanteFloat,miJsonTmp,true)
      }}>LISTO
      </Button>    
      </Col>  
    </Row>  
  </div> {/* div del pie */} 
</div> // divMaster
) //del return
}

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

  //*******************************************************************
  // ***************** Programa principal *****************************
  //*******************************************************************
  if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} //de PedirFormaDePago

export default PedirFormaDePago