//Me permite hacer retenciones: de iva
//FALTA revisar: al tener una base de 78$ y hacer una retencion de 1,75% segun js me da 1,36 pero deberia dar 1,37
import React, {useState,useEffect} from 'react'
import { Input,Label,Button,Row,Col,Modal} from 'reactstrap'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleDown,faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,redondearA2D} from './lib_basica'
//modales
import PedirCantidad from './PedirCantidad' //para cambiar la base imponible
import TabelaRetencionCodigosIvaBuscador from './TabelaRetencionCodigosIvaBuscador'
import PedirDescuentoP from './PedirDescuentoP'
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let retCodClon='' 
let retDescripcionClon=''
let retBaseModificadaClon=0 
let retPorcentajeClon=0 
let retValorRetenidoClon=0
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)\

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho = {
table: {
  style: {
    minHeight: '25vh',      
  },
},    
//el header, se refiere al titulo que dice 'LISTA DE CLIENTES'
header: {
  style: {
    color: 'black',
    backgroundColor: 'hotpink',
    //fontSize:'22px',   
    //fontWeight: 'bold',
  },
},  
headCells: {
  style: {
    //paddingLeft: '50px', //estaba '50px', no me funciona, override the cell padding for head cells
    //paddingRight: '8px', //estaba '8px'
    background: 'hotpink', //ok hotpink
    color: 'lavender',  //ok lavender.
    fontSize:'18px',   //ok 18
    fontWeight: 'bold'
  }, 
},  
rows: {
  style: {
    minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    //color:'yellow', //ok funciona bien
    //background:'blue', //bacan
    //marginTop:'4px',
    //marginBottom:'10px',       
  }
},    
cells: {
  style: {
    fontSize:'16px', //16px
    //el borde solo lo quiero ABAJO
    // top | right | bottom | left 
    //border-style: none solid dotted dashed;      
    borderStyle:"none none solid none", 
    borderColor:'silver',
    borderWidth:'thin',           
    //marginLeft:'20px', //funciona
    //paddingLeft: '80px', // no me funciona override the cell padding for data cells
    //paddingRight: '8px',
    //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL
    //backgroundColor:'yellow', //
    //fontWeight:'bold',
    //height:'10px', /* bacan */
    //paddingTop:'0', /* no funciona */
    //paddingBottom:'0', /* no funciona */
    //margin:0, /* no me funciona */      
  },
},
}

export default function RetenerIva(props){
const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('retenerIva') //por default: 'retener'. Alomejor luego un componente para ver otras cosas
const [retCodState,set_retCodState]=useState('')  //codigo de la retencion, muy comun es 312
const [retDescripcionState,set_retDescripcionState]=useState('')  //descripcion de la retencion
const [retBaseModificadaState,set_retBaseModificadaState]=useState(0)  //alomejor en este formulario es necesario modificar la base imponible
const [retPorcentajeState,set_retPorcentajeState]=useState(0)  
const [retValorRetenidoState,set_retValorRetenidoState]=useState(0)  
const [retGridState,set_retGridState]=useState([]) //para guardar el detalle
const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada del grid
const [modalCambiarBase,set_modalCambiarBase]=useState(false)
const [modalCambiarPorcentaje,set_modalCambiarPorcentaje]=useState(false)
const [modalBuscadorIva,set_modalBuscadorIva]=useState(false)

const filaCondicional=[
  //fila NO seleccionada
  {
    when: row => (rowRegistroState==null || row.CodigoRet != rowRegistroState.CodigoRet ),
    style: row => ({ 
      backgroundColor:'white',
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (rowRegistroState!=null && row.CodigoRet == rowRegistroState.CodigoRet),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white',
    }),
  },
]

const miEstructura=[  
  {  
    name:'CODIGO',
    selector:row => row.CodigoRet,      
    sortable:false,
    center:true,
    grow:0.2,
    compact:true, //padding 0
    //width:'50%',
    omit:false, 
  },
  {
    name:'DESCRIPCION',
    selector:row => row.Descripcion,      
    sortable:false,
    compact:true,
    grow:0.8, //es una medida relativa (1 es mi referencia)
    //width:'13%',
    //style: {backgroundColor:'cyan'},
  },     
  {
    name:'BASE',
    selector:row => row.Base,
    sortable:false,
    compact:true,
    right:true,
    grow:0.3,
  },      
  {
    name:'% RET.',
    selector:row => row.Porcentaje,
    sortable:false,
    compact:true,
    center:true,
    grow:0.3,
  },      
  {
    name:'VALOR RET.',
    selector:row => row.ValorRetenido,
    sortable:false,
    compact:true,
    grow:0.3,
  },      
  {
    //ACCIONES
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:0.3, 
    //width:'20%',

    //esto funciona fino
    //cell: (row) => <div><div style={{ fontWeight: 'bold' }}>{row.IDr}</div>{row.IdPrincipal}</div>,
    //esto funciona fino
    //cell: (row) => <div style={{ fontWeight: 900 }}>{row.IDr}</div>,
    //<div style={{ display:'flex' }}>            
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* Eliminar */}
    <Button id="btnEliminar" name="btnEliminar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      onClick={ async (e) => {      
        set_rowRegistroState(row)
        rowRegistroClon=row
        eliminarRegistroEnGrid()   
      }}>
      <FontAwesomeIcon size='lg' color="coral" icon={faTrashAlt} />
    </Button>    
    </div>, 
  },    
]

const salirConDatos=async()=>{
  //Valído que el grid esté con datos. Pero también es posible que el usuario NO necesite retener nada en iva
  let salirRealmente=false

  if (retGridState.length==0){
    const miResp = await mostrarSwalConfirmacionEliminarAnular('ATENCION',"Está seguro que no desea retener IVA ?")
    if (miResp.isDismissed) return //alomejor se arrepintio, NO quiere salir aún
    salirRealmente=true
  }
  else{
    salirRealmente=true
  }
//rumbo normal  
if (salirRealmente) props.cerrarModal_RetenerIva(true,retGridState)  
}

const validarCasillasLlenas=()=>{
 //Valido que este llena la casilla: txtCodigo (no hace falta revisar muchas casillas)
 let miCasilla1=document.getElementById("txtCodigo").value.trim()

 if (miCasilla1.length==0){
  mostrarSwalBotonAceptar("warning","PRECAUCION","Se requiere el código del impuesto")
  return false
 }

return true
}

const validarCodigoDuplicado=()=>{
let miRepe=false
retGridState.map(item=>{
  if (item.CodigoRet==retCodClon) miRepe=true
})
if (miRepe)
  mostrarSwalBotonAceptar("warning","PRECAUCION",`El código ${retCodClon} no puede estar repetido`)

return miRepe
}

const eliminarRegistroEnGrid=()=>{
  let miArrayTemporal=retGridState.filter(item=>(item.CodigoRet!=rowRegistroClon.CodigoRet))
  set_retGridState(miArrayTemporal) 
  set_rowRegistroState(null)
  rowRegistroClon=null
}

const apagarBotones=()=>{
  //el boton de "editar % Ret." y "incluir" arrancan invisibles
  document.getElementById("btnModificarPorc").style.visibility= 'hidden' //pero Lo botones invisibles se quedan en su lugar original
  document.getElementById("btnIncluir").style.visibility= 'hidden' //pero Lo botones invisibles se quedan en su lugar original  
}

const blanquearVariables=()=>{
//limpio los casilleros y pongo a cero algunos valores
set_retCodState('')
retCodClon=''
set_retDescripcionState('')
retDescripcionClon=''
set_retPorcentajeState(0)
retPorcentajeClon=0
set_retValorRetenidoState(0)
retValorRetenidoClon=0
//apago la seleccion azul
set_rowRegistroState(null)
rowRegistroClon=null  
}

const alimentarGrid=()=>{
//primero valído que los casilleros estén con datos
if (validarCasillasLlenas()==false) return

//valído que no esté un codigo duplicado
if (validarCodigoDuplicado()==true) return

//Paso del estado a un array temporal
let miArrayTemporal=retGridState.filter(item=>true)
//creo un registro temporal
let miRegistroTemporal={}  
miRegistroTemporal.CodigoRet=retCodClon
miRegistroTemporal.Descripcion=retDescripcionClon
miRegistroTemporal.Base=retBaseModificadaClon.toFixed(2)
miRegistroTemporal.Porcentaje=retPorcentajeClon.toFixed(4)
miRegistroTemporal.ValorRetenido=retValorRetenidoClon.toFixed(2)
miArrayTemporal.push(miRegistroTemporal) 
set_retGridState(miArrayTemporal) 
//apago los botones que no son necesarios
apagarBotones()
blanquearVariables()
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  set_retBaseModificadaState(props.baseImponibleIva)
  retBaseModificadaClon=props.baseImponibleIva
  //alimento el grid, alomejor el usuario desea editar el grid (obviamente la primera vez viene vacío)
  set_retGridState(props.jsonGridIva)
  //el boton de "editar % Ret." y "incluir" arrancan invisibles
  apagarBotones()
  //blanqueo algunas variables
  blanquearVariables()
},[]
)

const copiarVariablesDesdeContext=(value)=>{
  valueDC=value
}

const Componente_Formulario=()=>{  
return( 
<AppContextConsumer>
{value =>{         
return(               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}> 
  {/* copio desde el context a una variable propia del componente  */}
  { copiarVariablesDesdeContext(value) } 

  {/* Titulo + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10">
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{"Retener Iva"}</Label>
      <Label style={{marginLeft:'0%',}}></Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" solid="true" 
        onClick= { () =>{ 
          props.cerrarModal_RetenerIva(false,null)
        }} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 

  <div id="divCabecera" name="divCabecera" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'gray', color:'pink'}}>
    <Row style={{margin:'0',}}>
      {/* este div va a contener, Codigo y descripcion del impuesto, base imponible, %retencion, valor retenido.. */}
      <Col xs="2">     
        <Label>Código</Label>   
      </Col>
      <Col xs="10" >
        <Label>Descripción del impuesto</Label>   
      </Col>
    </Row>      
    <Row style={{margin:'0',}}>
      <Col xs="2">        
        <Input disabled name="txtCodigo" id="txtCodigo" value={retCodState} style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray' }}/>            
      </Col>
      <Col xs="8">
        {/* <Input disabled name="txtDescrImpuesto" id="txtDescrImpuesto" value={retDescripcionState} style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px',color:'dimgray' }}/>  */}
         <textarea disabled rows="2" name="txtDescrImpuesto" id="txtDescrImpuesto" value={retDescripcionState} style={{textAlign:'left',width:'100%',fontSize:'18px' }}/> 
      </Col>
      <Col xs="2">
        <Button id="btnBuscadorRenta" color="primary" solid="true"  
          onClick={ (e) => { 
              set_modalBuscadorIva(true)
          }}>Buscar
        </Button>          
      </Col>
    </Row>      

    <Row style={{margin:'0',marginTop:'10px'}}>
      <Col xs="3">     
        <Label>Base imponible</Label>   
      </Col>
      <Col xs="3">     
        <Label>% Ret.</Label>   
      </Col>
      <Col xs="3">
        <Label>Valor retenido</Label>   
      </Col>
      <Col xs="3">      
      </Col>
    </Row>  
    <Row style={{margin:'0',}}>
      <Col xs="3">        
        <Input disabled name="txtBase" id="txtBase" value ={retBaseModificadaState.toFixed(2)} style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray' }}/>            
      </Col>
      <Col xs="3">        
        <Input disabled name="txtPorcRet" id="txtPorcRet" value ={retPorcentajeState.toFixed(4)} style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray', color:'dimgray'}}/>            
      </Col>
      <Col xs="3">
        <Input disabled name="txtvalorRetenido" id="txtvalorRetenido" value ={retValorRetenidoState.toFixed(2)} style={{paddingLeft:'3px', textAlign:'left',width:'100%',height:'30px', fontSize:'18px', color:'dimgray'}}/>            
      </Col>
      <Col xs="3">      
      <Button id="btnIncluir" name="btnIncluir" color="link"  style={{padding:'0'}}
          onClick = { () => {
            alimentarGrid()
          }}>Incluir
        </Button>      
      </Col>
    </Row> 

    <Row style={{margin:'0',}}>
      <Col xs="3">      
      <Button id="btnModificarBase" name="btnModificarBase" color="link"  solid="false" style={{padding:'0'}}
          onClick={()=>{
            set_modalCambiarBase(true)
          }}>Editar base
        </Button>      
      </Col>
      <Col xs="9">      
      <Button id="btnModificarPorc" name="btnModificarPorc" color="link"  solid="false" style={{padding:'0'}}
          onClick={()=>{
            set_modalCambiarPorcentaje(true)
          }}>Editar % Ret.
        </Button>      
      </Col>
    </Row> 

  </div> {/* divCabecera */}

  { /* inicio del DIV para la tabela */ }
    <div id="divTabela" name="divTabela" style={{marginTop:'0',marginBottom:'0',marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    //title='Lista de Clientes'
    columns={miEstructura}
    data={retGridState}
    conditionalRowStyles={filaCondicional}  
    keyField ='CodigoRet' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
    onRowClicked={(row) => { 
      set_rowRegistroState(row)
      rowRegistroClon=row
    }}
    //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""
    //    <h1><span>Nada por aquí</span></h1>
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //'60vh'  
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras

    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //falta: rvesiar esta parte, me parece interesante ya que debe arrancar ordenando por DESCRIPCION
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    //pagination
    //paginationServer //change de default pagination to work with server pagination
    //paginationPerPage={ valueDC.sistema.paginadoParaClientes }  
    //paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

    <div id="divPie" name="divPie" style={{marginLeft:"0%",marginRight:"0%",marginTop:"0%",marginBottom:'0%',padding:'2%',background:'hotpink', color:'white'}}>
    {/* En este div va: el boton de Ok */}   
    <Row style={{marginTop:'0px',marginBottom:'0',marginLeft:'0',marginRight:'0',}}>
      <Col xs="12" style={{textAlign:'right'}}>        
        <Button size="lg" id="btnOk" name="btnOk" color="success" solid="true" 
          onClick={()=>{
            salirConDatos()
            }}>Listo
        </Button>
      </Col>
     </Row>     
  </div> {/* divPie */}

{/********************* MODAL PARA CAMBIAR BASE IMPONIBLE ****************/}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ modalCambiarBase } >
  <PedirCantidad  
    miTitulo={'Cambiar Base Imponible'}
    miSubTitulo={'Indíque la nueva base'}
    miValorTexto2D={retBaseModificadaClon} //realmente se lo envio como float (aunque en PedirCantidad espera un texto, pero no importa)
    miAceptaCero={false} //para saber si se acepta 0
    cerrarModal={async (miStatus,miNuevaBase) => { 
      // la variable miStatus es boolean, al estar en true quiere decir que si desea editar
      //la variable miNuevaBase ya viene como NUMBER y redondeada a 2 decimales

      set_modalCambiarBase(false)
      if (miStatus==false) return

      set_retBaseModificadaState(miNuevaBase)
      retBaseModificadaClon=miNuevaBase
      retValorRetenidoClon=(retBaseModificadaClon*retPorcentajeClon/100) //alomejor me queda con muchos decimales, solo requiero 2
      retValorRetenidoClon=redondearA2D(retValorRetenidoClon)
      set_retValorRetenidoState(retValorRetenidoClon)
    }}/>
</Modal>

{/********************* MODAL PARA BUSCAR CODIGO DE IVA ****************/}
<Modal style={{ background:'blue',}} size={'lg'} isOpen={ modalBuscadorIva } >
  <TabelaRetencionCodigosIvaBuscador  
    cerrarModal={async (miStatus,miRegistroJson) => { 
    //la variable miStatus es texto: [select/close] para saber como salió
    //la variable miRegistroJson trae un registro completo: 1 fila x varias columnas

    set_modalBuscadorIva(false)
    if (miStatus=='close') return

    //pongo visible el boton de "editar %" y "incluir"
    document.getElementById("btnModificarPorc").style.visibility= 'visible'  
    document.getElementById("btnIncluir").style.visibility= 'visible'      
    //alimento las variables locales, usando lo que viene del buscador
    set_retCodState(miRegistroJson.Codigo)
    retCodClon=miRegistroJson.Codigo
    set_retDescripcionState(miRegistroJson.Descripcion)
    retDescripcionClon=miRegistroJson.Descripcion
    set_retPorcentajeState(parseFloat(miRegistroJson.Porcentaje))
    retPorcentajeClon=parseFloat(miRegistroJson.Porcentaje)
    retValorRetenidoClon=(retBaseModificadaClon*retPorcentajeClon/100) //alomejor me queda con muchos decimales, solo requiero 2
    retValorRetenidoClon=redondearA2D(retValorRetenidoClon)
    set_retValorRetenidoState(retValorRetenidoClon)
    }}/>
</Modal>

{/* ******************** MODAL PARA CAMBIAR EL % DE RETENCION ********* */}
<Modal style={{ background:'blue',}} size={'md'} isOpen={ modalCambiarPorcentaje } >
  <PedirDescuentoP 
    miTitulo={"Cambiar % de retención"}
    miSubTitulo={"% (Máx 4 decimales)"}
    miValorOriginal={retPorcentajeClon} //se le debo mandar como float
    mi234Decimales={4} //para retencion se acepta hasta 4 decimales
    miColorFondo={"purple"}    
    miAceptaCero={true}
    cerrarModal={(miStatus,miValorNuevo)=>{
      //miStatus es boolean y debe venir como true/false para saber si realmente hizo el cambio
      //ojo: miValorNuevo debe venir como number y ya redondeado a 2 decimales
      set_modalCambiarPorcentaje(false)
      if (miStatus==false)  return

      //rumbo normal
      set_retPorcentajeState(miValorNuevo)
      retPorcentajeClon=miValorNuevo
      retValorRetenidoClon=(retBaseModificadaClon*retPorcentajeClon/100) //alomejor me queda con muchos decimales, solo requiero 2
      retValorRetenidoClon=redondearA2D(retValorRetenidoClon)
      set_retValorRetenidoState(retValorRetenidoClon)
}}
/>
</Modal>  

</div> //divMaster)
) //del return interno
} //del value
}
</AppContextConsumer>  
)}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='retenerIva') return Componente_Formulario()

} //del export
