import React from 'react'
import {Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose ,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
 
export default class PedirPrecioTodosItem1234 extends React.Component {   

seleccionarYsalir=()=>{
for (let i = 0; i < document.getElementsByName("radioPrecio").length; i++)  
  if (document.getElementsByName("radioPrecio")[i].checked)
    this.precio1234=document.getElementsByName("radioPrecio")[i].value

  //hago el cambio en el llamador. le mando this.precio1234 como texto
  this.props.cambiarPrecioTodosItem1234(this.precio1234)
  
  //Cierro (espero varios milisegundos, para que el usuario vea el radio seleccionado)    
  setTimeout(()=>{ 
    this.props.cerrarModal()
  },400)
}

componentDidMount(){  
}

render(){    
let descripcion40Letras="Cambiar a todos los items"
return (
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>
  {/* Mensaje + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10">
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{descripcion40Letras}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ this.props.cerrarModal() }}>
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row>             
  <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'red', color:'pink'}}>    
    {/* Precio 1 */}
    <Row style={{margin:'0'}}>
      <Col xs="12" style={{display:'flex'}}>
        <Label>
          <Input type="radio" id="radP1" value="1" name="radioPrecio" 
          onClick = {(e) => { this.seleccionarYsalir() }} /> PVP 1
        </Label>
      </Col> 
    </Row>  
    {/* Precio 2 */}
    <Row style={{margin:'0',paddingTop:'2%'}}>
      <Col xs="12" style={{display:'flex'}}>
        <Label>
          <Input type="radio" id="radP2" value="2" name="radioPrecio"  
          onClick = {(e) => { this.seleccionarYsalir() }} /> PVP 2
        </Label>
      </Col> 
    </Row>  
    {/* Precio 3 */}
    <Row style={{margin:'0',paddingTop:'2%'}}>
      <Col xs="12" style={{display:'flex'}}>
        <Label> 
          <Input type="radio" id="radP3" value="3" name="radioPrecio" 
          onClick = {(e) => { this.seleccionarYsalir() }} /> PVP 3
        </Label>
      </Col>
    </Row>  
    {/* Precio 4 */}
    <Row style={{margin:'0',paddingTop:'2%'}}>
      <Col xs="12" style={{display:'flex'}}>
        <Label> 
          <Input type="radio" id="radP4" value="4" name="radioPrecio" 
          onClick = {(e) => { this.seleccionarYsalir() }} /> PVP 4
        </Label>
      </Col>
    </Row>  
  </div>  {/* divBloque1 */}
</div> // divMaster
)}
}
