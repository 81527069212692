//alert(JSON.stringify(rowRegistroState,null,2))
//FALTA: revisar. Al mostrar un producto para editarlo, debe poner 2 decimales automaticamente en costos y 4 en PVP
//OJO mi referencia es: GRAVA IVA EN COMPRAS 
//la parte fija y la pestana BASICOS, esta asociada al estado
//LA PESTANA DE EXTRAS, no esta asociada al estado, no es necesario (se recomienda trabajar a la antigua) 
//Solo la Fecha, esta asociada al estado
import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import buscarPhpPath, { sleepPepe,aceptarSoloEnteros,aceptarDecimales,ejecutarFetchGenericoConSwal } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose,faSearch,faMinus,faPlusCircle,faPlus,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es'
registerLocale('es',es)

let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let censoRegistros=null //Aqui tengo un array/censo de registros, para validar repeticiones. Solo requiero: {IDr, IdPrincipal, }
let miRegistroEdit=null //aqui voy a tener al registro que deseo editar/modificar
let codigoRojoDevueltoPorSql=null //cuando es nuevo el sql me devuelve un autonumerico. [Cuando es editar, me devuelve el mismo rojo enviado]
let repetido=null //[true,false], para saber si se esta tratando de meter un ruc repetido
let jsonDevolver={} //este objeto lo devuelvo al llamador (bien sea para nuevo o editar)
//Cuando es incluir, se debe mostrar el formulario inmediatamente, caso contrario hay que esperar hasta buscar los datos en la tabla

const IncModInventario=(props)=>{    
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('inventario') //por default: 'inventario'. Alomejor luego se requieran otros componentes 
  const [tabActivoState,set_tabActivoState]=useState('1') //La pestana de datos basicos
  const [lecturaBDDsuperado,set_lecturaBDDsuperado]=useState(true) //Al arrancar el FORM, asumo que todo saldrá bien (al arrancar el FORM hay que leer en la BDD. Alomejor podría regrasar con null)
  //===para la parte fija
  const [txtPrincipalState,set_txtPrincipalState]=useState('')  
  const [txtSecundarioState,set_txtSecundarioState]=useState('')  
  const [txtDescripcionState,set_txtDescripcionState]=useState('')  
  const [txtMiStockState,set_txtMiStockState]=useState('')  
  const [txtMiMinimoState,set_txtMiMinimoState]=useState('')  
  //====para la pestana 1 (datos basicos)  
  const [comboIvaCompra,set_comboIvaCompra]=useState("0") //los valores posibles son "0" y "1"
  const [txtMiUbicacionState,set_txtMiUbicacionState]=useState('')  
  const [txtCostoUState,set_txtCostoUState]=useState('')  
  const [txtCostoPState,set_txtCostoPState]=useState('')  
  //Precios de venta (sin iva) 
  const [p1sinState,set_p1sinState]=useState('') 
  const [p2sinState,set_p2sinState]=useState('') 
  const [p3sinState,set_p3sinState]=useState('') 
  const [p4sinState,set_p4sinState]=useState('') 
  //Precios de venta (con iva) 
  const [p1conState,set_p1conState]=useState('') 
  const [p2conState,set_p2conState]=useState('') 
  const [p3conState,set_p3conState]=useState('') 
  const [p4conState,set_p4conState]=useState('') 
  //check: Vender por debajo del costo Ponderado y Vender sin stock
  const [checkVenderPDCPState,set_checkVenderPDCPState]=useState(true)   
  const [checkVenderSSState,set_checkVenderSSState]=useState(true)   
  //para la pestana 2 (datos extra).. Solo la fecha
  //const [fechaVenceState,set_fechaVenceState]=useState(new Date()) //arranca con la fecha actual
  const [fechaVenceState,set_fechaVenceState]=useState(null) //arranca con la fecha actual

const ParteFija=()=>{   
return(
  <div id="divParteFija" name="divParteFija">
    <Row style={{marginTop:'2%',marginBottom:'2%',marginLeft:'0',marginRight:'0',padding:'0',}}>
      <Col xs="6">
        <Label for="txtPrincipal">Código Principal* </Label>        
        <Input  maxLength="30" name="txtPrincipal" id="txtPrincipal" value={txtPrincipalState}
          onChange={(e)=> set_txtPrincipalState(e.target.value)}
          onKeyUp={(e)=> buscarRepetidoEnCenso()}             
        />
      </Col>
      <Col xs="6">
        <Label for="txtSecundario">Código Secundario</Label>        
        <Input maxLength="30" name="txtSecundario" id="txtSecundario" value={txtSecundarioState}
          onChange={(e)=>set_txtSecundarioState(e.target.value)}
        />
      </Col>
    </Row>
    <Row style={{margin:'0',}}>
      <Col xs="12">
        <Input maxLength="300" placeholder="Descripción*" name="txtDescripcion" id="txtDescripcion" value={txtDescripcionState}
          onChange={(e)=>set_txtDescripcionState(e.target.value)}
        />
      </Col>
    </Row>  
    {/* MiStock y MiMinimo */}
    <Row style={{marginTop:'2%',marginBottom:'2%',marginLeft:'0',marginRight:'0',}}>    
      <Col style={{display:'flex',marginLeft:'2%',}} >
        <Label for="txtMiStock">Stock*</Label>
        <Input style={{marginLeft:'3%', width:'62%'}} type="number" id="txtMiStock" name="txtMiStock" value={txtMiStockState}
          onChange={(e)=>set_txtMiStockState(e.target.value)}        
          onKeyPress={(e)=>aceptarDecimales(e)}    
        />
      </Col>
      <Col style={{display:'flex'}} >
        <Label for="txtMiMinimo">Mínimo*</Label>
        <Input style={{marginLeft:'3%', width:'62%'}} type="number" id="txtMiMinimo" name="txtMiMinimo" value={txtMiMinimoState}
          onChange={(e)=> set_txtMiMinimoState(e.target.value)}                
          onKeyPress={(e)=>aceptarDecimales(e)}      
        />
      </Col>
    </Row>
  </div>  //divParteFija
)}      

const Precios4=()=>{
return(
  //* pequena tablita para los 4 precios del producto
  <div id="div4Precios" name="div4Precios" style={{marginTop:'2%'}}>
    <h3 style={{marginLeft:'2%',}}>Precios de Venta*</h3> 
    <hr/>
    <Row style={{margin:'0',}}>
      <Col xs="2">
      </Col>    
      <Col xs="4">
        <Label>Sin Iva</Label>
      </Col>    
      <Col xs="4">
        <Label id="labelConIva" name="labelConIva">Con Iva</Label>
      </Col>    
      <Col xs="2">
      </Col>        
    </Row>  
    {/* Precio 1 */}
    <Row style={{marginTop:'0', marginBottom:'1%',marginLeft:'0',marginRight:'0',}}>
      <Col xs="2">
        <Label style={{color:'Blue'}} >Pvp1</Label>
      </Col>    
      <Col xs="4">
        <Input type="number" id="txtP1sin" name="txtP1sin" style={{textAlign:'right'}} value={p1sinState}
          onKeyPress={(e)=>aceptarDecimales(e)}  
          onChange={(e)=>{
            set_p1sinState(e.target.value)
            calcularPvpConIvaMiAlmacen() 
          }}    
        />
      </Col>    
      <Col xs="4">
        <Input disabled type="number" id="txtP1con" name="txtP1con" style={{textAlign:'right'}} value={p1conState}/>
      </Col>    
      <Col xs="1">
        <Button id="btnP1" name="btnP1" color="info" disabled>
          <FontAwesomeIcon color="lime" icon={faDollarSign}/>
        </Button>
      </Col>
    </Row>   
    {/* Precio 2 */}
    <Row style={{marginTop:'0', marginBottom:'1%',marginLeft:'0',marginRight:'0',}}>
      <Col xs="2">
        <Label style={{color:'Blue'}} >Pvp2</Label>
      </Col>    
      <Col xs="4">
        <Input type="number" id="txtP2sin" name="txtP2sin" style={{textAlign:'right'}} value={p2sinState}
          onKeyPress={(e)=>aceptarDecimales(e)}  
          onChange={(e)=>{
            set_p2sinState(e.target.value)
            calcularPvpConIvaMiAlmacen() 
          }}   
        />
      </Col>    
      <Col xs="4">
        <Input disabled type="number" id="txtP2con" name="txtP2con" style={{textAlign:'right'}} value={p2conState} /> 
      </Col>    
      <Col xs="1">
        <Button id="btnP2" name="btnP2"  color="info" disabled>
          <FontAwesomeIcon color="lime" icon={faDollarSign}/>
        </Button>
    </Col>
    </Row>  
    {/* Precio 3 */}
    <Row style={{marginTop:'0', marginBottom:'1%',marginLeft:'0',marginRight:'0',}}>
      <Col xs="2">
        <Label style={{color:'Blue'}} >Pvp3</Label>
      </Col>    
      <Col xs="4">
        <Input type="number" id="txtP3sin" name="txtP3sin" style={{textAlign:'right'}} value={p3sinState}
          onKeyPress={(e)=>aceptarDecimales(e)}  
          onChange={(e)=>{
            set_p3sinState(e.target.value)
            calcularPvpConIvaMiAlmacen() 
          }}   
        /> 
      </Col>    
      <Col xs="4">
        <Input disabled type="number" id="txtP3con" name="txtP3con" style={{textAlign:'right'}} value={p3conState}/>
      </Col>    
      <Col xs="1">
        <Button id="btnP3" name="btnP3" color="info" disabled>
        <FontAwesomeIcon color="lime" icon={faDollarSign}/>
      </Button>
      </Col>
    </Row>  
    {/* Precio 4 */}
    <Row style={{marginTop:'0', marginBottom:'1%',marginLeft:'0',marginRight:'0', }}>
      <Col xs="2">
        <Label style={{color:'Blue'}} >Pvp4</Label>
      </Col>    
      <Col xs="4">
        <Input type="number" id="txtP4sin" name="txtP4sin" style={{textAlign:'right'}} value={p4sinState}
          onKeyPress={(e)=>aceptarDecimales(e)}  
          onChange={(e)=>{
            set_p4sinState(e.target.value)
            calcularPvpConIvaMiAlmacen() 
          }}   
        />
      </Col>    
      <Col xs="4">
        <Input disabled type="number" id="txtP4con" name="txtP4con" style={{textAlign:'right'}} value={p4conState}/> 
      </Col>    
      <Col xs="1">
        <Button id="btnP4" name="btnP4"  color="info" disabled>
        <FontAwesomeIcon color="lime" icon={faDollarSign}/>
      </Button>
      </Col>
    </Row>
    <hr/>
  </div> //div4Precios
)  
}

const TabDatosBasicos=()=>{   
return (
<div id="divDatosBasicos" name="divDatosBasicos">
  {/* Iva y Ubicacion */}
  <Row style={{margin:'0',}}>
    <Col xs="5">
      <Label for="comboIvaCompra">IVA*</Label>
      <Input style={{width:'95%'}} type="select" name="comboIvaCompra" id="comboIvaCompra"
        onChange={(e)=>{
          set_comboIvaCompra(e.target.value)
          calcularPvpConIvaMiAlmacen() 
        }}>
        <option value="0" selected={(comboIvaCompra=="0") ? true : false}>0%</option>
        <option value="1" selected={(comboIvaCompra=="1") ? true : false}>{valueDC.iva.Porcentaje +'%'}</option>  
        <option value="2" disabled>No Objeto de Iva</option>
        <option value="3" disabled>Exento</option>
      </Input>
    </Col>
    <Col xs="7">
      <Label for="txtMiUbicacion">Ubicación</Label>
      <Input style={{width:'97%',}} type="text" id="txtMiUbicacion" name="txtMiUbicacion" value={txtMiUbicacionState}
        onChange={(e)=>set_txtMiUbicacionState(e.target.value)}      
      />
    </Col> 
  </Row>
  {/* Costo Ultimo y Costo Ponderado */}
  <Row style={{marginTop:'1%',marginBottom:'0',marginLeft:'0',marginRight:'0'}}>
    <Col>
      <Label for="txtCostoU">Costo Ultimo*</Label>
      <Input style={{width:'95%'}} type="number" id="txtCostoU" name="txtCostoU" value={txtCostoUState}
        onKeyPress={(e)=>aceptarDecimales(e)}  
        onChange={(e)=>{ 
          set_txtCostoUState(e.target.value)
          if (props.opcionRegistro_imc=='i') set_txtCostoPState(e.target.value)
        }}
      />
    </Col>
    <Col>
      <Label for="txtCostoP">Costo Ponderado*</Label>
      <Input style={{width:'95%'}} type="number" id="txtCostoP" name="txtCostoP" value={txtCostoPState}
        onKeyPress={(e)=>aceptarDecimales(e)}
        onChange={(e)=>set_txtCostoPState(e.target.value) }
      />
    </Col>
  </Row>
  {/* tablita con los 4 precios del producto */}
  { Precios4() }
  <div style={{marginLeft:'2%'}}>
    <Label for="checkVPDCP">
      <Input id="checkVPDCP" name="checkVPDCP" type="checkbox" checked={checkVenderPDCPState}
        onChange={(e)=>set_checkVenderPDCPState(e.target.checked)}      
      /> Vender por debajo del costo ponderado
    </Label>
  </div>
  <div style={{marginLeft:'2%',}}>
    <Label for="checkVSS">
      <Input id="checkVSS" name="checkVSS" type="checkbox" checked={checkVenderSSState}
        onChange={(e)=>set_checkVenderSSState(e.target.checked)}            
      /> Vender sin stock
    </Label>
  </div>
</div> //divDatosBasicos
)}

const TabDatosExtra=()=>{
return(
<div id="divDatosExtra" name="divDatosExtra">
  {/* Categoria, marca, unidad */}
  <FormGroup style={{display:'flex',marginTop:'2%',marginLeft:'2%'}}>
    <Label for="comboCat" style={{width:'20%'}}>Categoría</Label>
    <Input type="select" name="comboCat" id="comboCat" style={{width:'50%',marginRight:'1%'}}/>
    <Button id="btnEliminarCat" name="btnEliminarCat" style={{ marginRight:'6%', }} color="info" 
      onClick={ () => document.getElementById("comboCat").selectedIndex=-1 }>
      <FontAwesomeIcon color="yellow" icon={faTrashAlt}/>
    </Button>
  </FormGroup>
  <FormGroup style={{display:'flex',marginLeft:'2%'}}>
    <Label for="comboMarca" style={{width:'20%'}}>Marca</Label>
    <Input type="select" name="comboMarca" id="comboMarca" style={{width:'50%',marginRight:'1%'}} />
    <Button id="btnEliminarMarca" name="btnEliminarMarca" style={{ marginRight:'6%', }} color="info" 
      onClick={ () => document.getElementById("comboMarca").selectedIndex=-1 }>
      <FontAwesomeIcon color="yellow" icon={faTrashAlt}/>
    </Button>
  </FormGroup>
  <FormGroup style={{display:'flex',marginLeft:'2%'}}>
    <Label for="comboUnidad" style={{width:'20%'}}>Unidad</Label>
    <Input type="select" name="comboUnidad" id="comboUnidad" style={{width:'50%',marginRight:'1%'}} />
    <Button id="btnEliminarUnidad" name="btnEliminarUnidad" style={{ marginRight:'6%',}} color="info" 
      onClick={ () => document.getElementById("comboUnidad").selectedIndex=-1 }>
      <FontAwesomeIcon color="yellow" icon={faTrashAlt}/>
    </Button>
  </FormGroup>
  {/* clase */}  
  <div style={{display: 'flex' ,marginLeft:'2%'}}>
    <Label style={{marginRight:'2%'}} for="txtClase" >Clase</Label>
    <Input maxLength="40" style={{marginBottom:'2%',width:'85%'}} placeholder="Clase" name="txtClase" id="txtClase"/>
  </div>          
  {/* Producto o Servicio, Activo?, descontable ?, cod auxiliar */}
  <Row style={{marginTop:'0', marginBottom:'1%',marginLeft:'0',marginRight:'0',}}>
  <Col xs="8">
    <FormGroup style={{display:'flex',marginLeft:'2%'}}>
      <Label for="comboBS" style={{marginRight:'2%'}}>Bien o Servicio</Label>
        <Input type="select" name="comboBS" id="comboBS" style={{width:'50%'}}>
          <option value="B">Bien</option>
          <option value="S">Servicio</option>
        </Input>
    </FormGroup>      
  </Col>
  <Col xs="4">
    <Label for="checkActivo">
      <Input id="checkActivo" name="checkActivo" type="checkbox" /> Activo
    </Label>
  </Col>
  </Row>
  <Row style={{marginTop:'0', marginBottom:'1%',marginLeft:'0',marginRight:'0', }}>
    <Col xs="8">
      <Input maxLength="20" placeholder="Código Auxiliar" name="txtAuxiliar" id="txtAuxiliar" />
    </Col>
    <Col xs="4">
      <Label for="checkDescontable">
        <Input id="checkDescontable" name="checkDescontable" type="checkbox" /> Descontable
      </Label>
  </Col>
  </Row>
  {/* Producto para comprar y/o vender */}
  <Row style = {{marginTop:'3%', marginBottom:'0',marginLeft:'0',marginRight:'0',borderStyle: 'solid', borderWidth:'thin', borderColor:'gray',}}>
    <legend style = {{textAlign:'center'}}>Producto para Compra y Venta</legend>
    <Col style = {{marginLeft:'10%'}}>
      <Label for ="checkCompra"><Input id="checkCompra" name="checkCompra" type="checkbox" /> Para Compra</Label>
    </Col>
    <Col style = {{marginLeft:'10%'}}>
      <Label for="checkVenta"><Input id="checkVenta" name="checkVenta" type="checkbox" /> Para Venta</Label>
    </Col>
  </Row>
  {/* Fecha de vencimiento con boton QUITAR*/}
  <Row style={{marginTop:'2%',marginBottom:'0',marginLeft:'0',marginRight:'0',}}>
    <Col xs="8">
      <FormGroup style={{display:'flex', marginLeft:'0',marginTop:'2%'}}>
        <Label for="txtFechaVence" style={{marginRight:'2%'}}>Vencimiento</Label>
        <DatePicker className="anchoDatePickerGenerico" id="txtFechaVence" name="txtFechaVence" locale='es' dateFormat="dd/MM/yyyy"
          selected={ fechaVenceState }          
          onChange= {(datePar)=>set_fechaVenceState(datePar) }
        /> 
      </FormGroup>
    </Col>
    <Col xs="4">
      <Button size="md" color="info" solid="true"  
        onClick= { () =>  
          set_fechaVenceState(null)
        }>Quitar Fecha
      </Button>
    </Col>
  </Row>      
  {/* Observaciones */}
  <div style={{width:'96%',marginLeft:'2%',marginRight:'2%'}}>
    <Input maxLength="100" style={{marginBottom:'2%'}} placeholder="Observaciones" name="txtObservaciones" id="txtObservaciones"/>
  </div>          
</div> // divDatosExtra  
)
}

const calcularPvpConIvaMiAlmacen=()=>{
  let MiIvaTmp=null //Variable temporal 
  if (document.getElementById("comboIvaCompra").value=='1'){
    //aqui grava iva (12% segun context)     
    MiIvaTmp=valueDC.iva.Porcentaje
    document.getElementById("labelConIva").style.visibility= 'visible'  
    document.getElementById("txtP1con").style.visibility= 'visible'  
    document.getElementById("btnP1").style.visibility= 'visible'  
    document.getElementById("txtP2con").style.visibility= 'visible'        
    document.getElementById("btnP2").style.visibility= 'visible'  
    document.getElementById("txtP3con").style.visibility= 'visible'        
    document.getElementById("btnP3").style.visibility= 'visible'  
    document.getElementById("txtP4con").style.visibility= 'visible'        
    document.getElementById("btnP4").style.visibility= 'visible'  
  }
  else{      
    //aqui NO grava iva (0%)
    MiIvaTmp=0
    document.getElementById("labelConIva").style.visibility= 'hidden'  
    document.getElementById("txtP1con").style.visibility= 'hidden'  
    document.getElementById("btnP1").style.visibility= 'hidden'  
    document.getElementById("txtP2con").style.visibility= 'hidden'        
    document.getElementById("btnP2").style.visibility= 'hidden'  
    document.getElementById("txtP3con").style.visibility= 'hidden'        
    document.getElementById("btnP3").style.visibility= 'hidden'  
    document.getElementById("txtP4con").style.visibility= 'hidden'        
    document.getElementById("btnP4").style.visibility= 'hidden'  
  }    
  //cuando la celda con el precio sin iva este vacio o incorrecta, se asume cero
  let miBase=0.0 
    
  //P1
  miBase=document.getElementById("txtP1sin").value
  if (Number.isNaN(miBase)) miBase=0
  miBase=parseFloat(miBase)
  set_p1conState(miBase + miBase * MiIvaTmp/100)
  //P2
  miBase=document.getElementById("txtP2sin").value
  if (Number.isNaN(miBase)) miBase=0
  miBase=parseFloat(miBase)
  set_p2conState(miBase + miBase * MiIvaTmp/100)
  //P3
  miBase=document.getElementById("txtP3sin").value
  if (Number.isNaN(miBase)) miBase=0
  miBase=parseFloat(miBase)
  set_p3conState(miBase + miBase * MiIvaTmp/100)
  //P4
  miBase=document.getElementById("txtP4sin").value
  if (Number.isNaN(miBase)) miBase=0
  miBase=parseFloat(miBase)
  set_p4conState(miBase + miBase * MiIvaTmp/100)
}

const validarPantalla=()=>{
let tmpTxt
//arranco con el Cod Principal, no se acepta vacio
tmpTxt=document.getElementById("txtPrincipal").value.trim() 
if (tmpTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar el Código Principal")
  return false
}
//Descripcion
tmpTxt=document.getElementById("txtDescripcion").value.trim()     
if (tmpTxt.length ==0){
  mostrarSwalBotonAceptar("error","ATENCION","La Descripción, no puede estar vacía")
  return false
}
//verifico si el codigo esta repetido
if (repetido){
  mostrarSwalBotonAceptar("error","ATENCION","El código está repetido")
  return false
}

return true
}

const prepararJsonParaDevolver=()=>{
   //importante: la fecha debe irse bien sea NULL o bien sea un texto: yyyy-mm-dd
   let fechaDevolver_texto=null
   if (fechaVenceState!=null)
    fechaDevolver_texto= fechaVenceState.toISOString().slice(0,10); //return YYYY-MM-DD
  
  jsonDevolver={}
  jsonDevolver.IDr=codigoRojoDevueltoPorSql
  jsonDevolver.CodPrincipal=document.getElementById("txtPrincipal").value.trim()
  jsonDevolver.Descripcion=document.getElementById("txtDescripcion").value.trim()
  jsonDevolver.IdSecundario=document.getElementById("txtSecundario").value.trim()
  jsonDevolver.TipoIvaVenta=document.getElementById("comboIvaCompra").value.trim()
  jsonDevolver.CostoP=document.getElementById("txtCostoP").value.trim()
  jsonDevolver.ParaCompra=(document.getElementById("checkCompra").checked) ? "1" : "0"
  jsonDevolver.ParaVenta=(document.getElementById("checkVenta").checked) ? "1" : "0"
  jsonDevolver.Clase=document.getElementById("txtClase").value.trim()
  jsonDevolver.VenderSinStock=(document.getElementById("checkVSS").checked) ? "1" : "0"
  jsonDevolver.VenderPorDebajoCostoP=(document.getElementById("checkVPDCP").checked) ? "1" : "0"
  jsonDevolver.Descontable=(document.getElementById("checkDescontable").checked) ? "1" : "0"
  jsonDevolver.Adef_Stock=document.getElementById("txtMiStock").value.trim()
  jsonDevolver.Adef_StockMin=document.getElementById("txtMiMinimo").value.trim()
  jsonDevolver.Adef_P1=document.getElementById("txtP1sin").value.trim()
  jsonDevolver.Adef_P2=document.getElementById("txtP2sin").value.trim()
  jsonDevolver.Adef_P3=document.getElementById("txtP3sin").value.trim()
  jsonDevolver.Adef_P4=document.getElementById("txtP4sin").value.trim()
  jsonDevolver.Adef_Ubicacion=document.getElementById("txtMiUbicacion").value.trim()
  jsonDevolver.Adef_FechaVence=fechaDevolver_texto
  jsonDevolver.Adef_Activo=(document.getElementById("checkActivo").checked) ? "Si" : "No"
  jsonDevolver.CodSecundario=document.getElementById("txtSecundario").value.trim()
  jsonDevolver.CodAuxiliar=document.getElementById("txtAuxiliar").value.trim()
  jsonDevolver.CodCat=document.getElementById("comboCat").selectedIndex==-1 ? "0" : (document.getElementById("comboCat").value).trim()
  jsonDevolver.CodMarca=document.getElementById("comboMarca").selectedIndex==-1 ? "0" : (document.getElementById("comboMarca").value).trim()
}

const guardarRegistro=async()=>{
    codigoRojoDevueltoPorSql=null

    if (!validarPantalla()) return  

    //LE PASO EL FORMULARIO COMPLETO + MiSolicitud + MiAlmacen + fechaVence
    let data=new FormData(document.getElementById("miFormularioInventario"))
    data.append('miSol','inv_guardar')
    data.append('miAlm',valueDC.estable.Almacen)   
    data.append('txtUsuario',valueDC.usuario.LoginUsu) //solo tiene utilidad/sentido al momento de NUEVO producto
    data.append('txtID',(props.opcionRegistro_imc=="i") ? 0 : props.registroIDr) //codigo rojo del producto (cuando es NUEVO se va con cero)  
    data.append('txtOpcion_imc',props.opcionRegistro_imc)
  
    //Le paso la fecha en modo string (es la unica forma que pude resolver este problema)
    //La fecha la tengo en un componente, por eso PHP lo ve VACIO (hay que mandarlo aparte con append )
    data.append('fechaTMP',document.getElementById("txtFechaVence").value)    

    let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
    if (dataApi==null) return
    
    //guardo el rojo devuelto por mi api (tambien me puede llegar negativo si hay error como por ejemplo codigo duplicado)
    codigoRojoDevueltoPorSql=dataApi
}
 
const crearCensoRegistrosCuandoEsNuevo=(miTodosJson)=>{
//hago un censo. es valido solamente cuando deseo crear un NUEVO registro  
censoRegistros=null //lo limpio
censoRegistros=miTodosJson.map(registro=>{ 
  let tmp={
  "IDr": registro.IDr,
  "CodPrincipal": registro.CodPrincipal,
  }
  return tmp
  })
}

const buscarRepetidoEnCenso=()=>{
repetido=false
let claveRevisar=document.getElementById("txtPrincipal").value.trim().toLowerCase()
//reviso cuando es NUEVO 
if (props.opcionRegistro_imc=='i'){
  censoRegistros.forEach( (x) => {
    if (x.CodPrincipal.trim().toLowerCase()==claveRevisar) repetido = true
  })
}
//reviso cuando es EDITAR
if (props.opcionRegistro_imc=='m'){
  censoRegistros.forEach( (x) => {
    if (x.IDr != props.registroIDr && x.CodPrincipal.trim().toLowerCase()==claveRevisar) repetido = true
  })
}
//resultado  
if (repetido){
  document.getElementById("txtPrincipal").style.background='red'
  mostrarSwalBotonAceptar("error","ATENCION","El código principal está repetido")
}
else{
  document.getElementById("txtPrincipal").style.background='white'
}

return repetido
}

const gestionarBuscarRegistro=async()=>{
  //esta funcion solo se usa cuando sea Modificar o Consultar
  //a) cuando sea 'Modificar':
    //La idea es, traerme desde la BDD, todas las filas (pero solo con los campos IDr, IdPrincipal para el censo)
    //Ademas me traigo todos los campos pero SOLO del registro que deseo editar
  //b) cuando se 'Consultar':
    //Me traigo todos los campos pero SOLO del registro que deseo consultar

    let data=new FormData()
    data.append('miSol',props.opcionRegistro_imc=='m' ? 'inv_buscarProductoParaEditarMasCenso' : 'inv_buscarxid')
    data.append('IDr',props.registroIDr)
    
    let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
    //al venir NULL, => entonces el formulario debe cerrarse automaticamente
    if (dataApi==null) set_lecturaBDDsuperado(false)
    if (dataApi==null) return      

    //rumbo ideal. hubo conexion. Pero debo revisar quizá el producto ya no existe (quizá fué casualmente eliminado dede otra pc)
    miRegistroEdit=dataApi[0] //extraigo el producto a ver/editar

    if (miRegistroEdit==null){
      await mostrarSwalBotonAceptar("error","ATENCION","El producto ya no existe...")            
      set_lecturaBDDsuperado(false)
      return
    } 
    //elimino del JSON la posicion 0 y obtengo el censo [aplica solo para el caso a) osea en modificacion]
    if (miRegistroEdit!=null && props.opcionRegistro_imc=='m'){
      dataApi.splice(0,1)
      censoRegistros=dataApi //creo el censo, simplemente copiando desde DATA al censo  
    }   
    
    //muestro el registro
    mostrarRegistro()    
  }

const mostrarDatosSensiblesAlAlmacen=()=>{
//aqui muestro la informacion que es dependiente del Almacen en cuestion (el almacen realmente depende del establecimiento)  

let activoDef=false
let stockDef=0
let minimoDef=0
let ubicacionDef=''
let p1Def=0
let p2Def=0
let p3Def=0
let p4Def=0
let fechaDef=null // para la fecha de vencimiento

//busco los valores definitivos
switch (valueDC.estable.Almacen) {
  case '0':
    activoDef =(miRegistroEdit.A0_Activo=="1")
    stockDef=miRegistroEdit.A0_Stock
    minimoDef=miRegistroEdit.A0_StockMin
    ubicacionDef=miRegistroEdit.A0_Ubicacion
    p1Def=miRegistroEdit.A0_P1
    p2Def=miRegistroEdit.A0_P2
    p3Def=miRegistroEdit.A0_P3
    p4Def=miRegistroEdit.A0_P4
    fechaDef=miRegistroEdit.A0_FechaVence
    break
  case '1':
    activoDef =(miRegistroEdit.A1_Activo=="1")
    stockDef=miRegistroEdit.A1_Stock
    minimoDef=miRegistroEdit.A1_StockMin
    ubicacionDef=miRegistroEdit.A1_Ubicacion
    p1Def=miRegistroEdit.A1_P1
    p2Def=miRegistroEdit.A1_P2
    p3Def=miRegistroEdit.A1_P3
    p4Def=miRegistroEdit.A1_P4
    fechaDef=miRegistroEdit.A1_FechaVence
    break
  case '2':
    activoDef =(miRegistroEdit.A2_Activo=="1")
    stockDef=miRegistroEdit.A2_Stock
    minimoDef=miRegistroEdit.A2_StockMin
    ubicacionDef=miRegistroEdit.A2_Ubicacion
    p1Def=miRegistroEdit.A2_P1
    p2Def=miRegistroEdit.A2_P2
    p3Def=miRegistroEdit.A2_P3
    p4Def=miRegistroEdit.A2_P4    
    fechaDef=miRegistroEdit.A2_FechaVence    
    break
  case '3':
    activoDef =(miRegistroEdit.A3_Activo=="1")
    stockDef=miRegistroEdit.A3_Stock
    minimoDef=miRegistroEdit.A3_StockMin
    ubicacionDef=miRegistroEdit.A3_Ubicacion
    p1Def=miRegistroEdit.A3_P1
    p2Def=miRegistroEdit.A3_P2
    p3Def=miRegistroEdit.A3_P3
    p4Def=miRegistroEdit.A3_P4    
    fechaDef=miRegistroEdit.A3_FechaVence    
    break
  case '4':
    activoDef =(miRegistroEdit.A4_Activo=="1")
    stockDef=miRegistroEdit.A4_Stock
    minimoDef=miRegistroEdit.A4_StockMin
    ubicacionDef=miRegistroEdit.A4_Ubicacion
    p1Def=miRegistroEdit.A4_P1
    p2Def=miRegistroEdit.A4_P2
    p3Def=miRegistroEdit.A4_P3
    p4Def=miRegistroEdit.A4_P4    
    fechaDef=miRegistroEdit.A4_FechaVence    
    break
  case '5':
    activoDef =(miRegistroEdit.A5_Activo=="1")
    stockDef=miRegistroEdit.A5_Stock
    minimoDef=miRegistroEdit.A5_StockMin
    ubicacionDef=miRegistroEdit.A5_Ubicacion
    p1Def=miRegistroEdit.A5_P1
    p2Def=miRegistroEdit.A5_P2
    p3Def=miRegistroEdit.A5_P3
    p4Def=miRegistroEdit.A5_P4    
    fechaDef=miRegistroEdit.A5_FechaVence    
    break
  case '6':
    activoDef =(miRegistroEdit.A6_Activo=="1")
    stockDef=miRegistroEdit.A6_Stock
    minimoDef=miRegistroEdit.A6_StockMin
    ubicacionDef=miRegistroEdit.A6_Ubicacion
    p1Def=miRegistroEdit.A6_P1
    p2Def=miRegistroEdit.A6_P2
    p3Def=miRegistroEdit.A6_P3
    p4Def=miRegistroEdit.A6_P4    
    fechaDef=miRegistroEdit.A6_FechaVence    
    break
  case '7':
    activoDef =(miRegistroEdit.A7_Activo=="1")
    stockDef=miRegistroEdit.A7_Stock
    minimoDef=miRegistroEdit.A7_StockMin
    ubicacionDef=miRegistroEdit.A7_Ubicacion
    p1Def=miRegistroEdit.A7_P1
    p2Def=miRegistroEdit.A7_P2
    p3Def=miRegistroEdit.A7_P3
    p4Def=miRegistroEdit.A7_P4    
    fechaDef=miRegistroEdit.A7_FechaVence    
    break
  case '8':
    activoDef =(miRegistroEdit.A8_Activo=="1")
    stockDef=miRegistroEdit.A8_Stock
    minimoDef=miRegistroEdit.A8_StockMin
    ubicacionDef=miRegistroEdit.A8_Ubicacion
    p1Def=miRegistroEdit.A8_P1
    p2Def=miRegistroEdit.A8_P2
    p3Def=miRegistroEdit.A8_P3
    p4Def=miRegistroEdit.A8_P4    
    fechaDef=miRegistroEdit.A8_FechaVence    
    break
  case '9':
    activoDef =(miRegistroEdit.A9_Activo=="1")
    stockDef=miRegistroEdit.A9_Stock
    minimoDef=miRegistroEdit.A9_StockMin
    ubicacionDef=miRegistroEdit.A9_Ubicacion
    p1Def=miRegistroEdit.A9_P1
    p2Def=miRegistroEdit.A9_P2
    p3Def=miRegistroEdit.A9_P3
    p4Def=miRegistroEdit.A9_P4    
    fechaDef=miRegistroEdit.A9_FechaVence    
    break    
}

//stock, minimo, ubicacion
set_txtMiStockState(stockDef * 1)
set_txtMiMinimoState(minimoDef * 1)
set_txtMiUbicacionState(ubicacionDef)
document.getElementById("checkActivo").checked=activoDef

//le fecha viene asi desde MYSQL/JSON yyyy-mm-dd  siempre 10 caracteres
if (fechaDef != null){
  let miAno=fechaDef.substring(0,4)
  let miMes=fechaDef.substring(5,7)
  let miDia=fechaDef.substring(8)
  //OJO: Al usar el metodo DATE con 3 string, el mes de enero es 0. OJO
  set_fechaVenceState(new Date(miAno,miMes-1,miDia))
}
else{
  set_fechaVenceState(null)
}

//precios de venta sin iva  
set_p1sinState(p1Def * 1)
set_p2sinState(p2Def * 1)
set_p3sinState(p3Def * 1)
set_p4sinState(p4Def * 1)

//llamo a una funcion para que recalcule el precio con iva (pero solo de mi almacen)
calcularPvpConIvaMiAlmacen()
}

const mostrarRegistro=()=>{
//primero se gestiona la informacion que sea comun (luego se invoca a una funcion para mostrar los datos sensibles al almacen) 

//********************* Parte de arriba (fija) **********************
//Codigo Principal, Secundario, Descripcion
set_txtPrincipalState(miRegistroEdit.CodPrincipal)
set_txtSecundarioState(miRegistroEdit.CodSecundario)
set_txtDescripcionState(miRegistroEdit.Descripcion)

//**************** Para la pestana/tab Basicos **********************
set_comboIvaCompra(miRegistroEdit.TipoIvaCompra)
set_txtCostoUState(miRegistroEdit.CostoU * 1)
set_txtCostoPState(miRegistroEdit.CostoP * 1)
set_checkVenderPDCPState(miRegistroEdit.VenderPorDebajoCostoP=="1")
set_checkVenderSSState(miRegistroEdit.VenderSinStock=="1")

//********************* Pestana/Tab Extra **********************/
document.getElementById("comboCat").value= miRegistroEdit.CodCat
document.getElementById("comboMarca").value= miRegistroEdit.CodMarca
document.getElementById("comboUnidad").value= miRegistroEdit.CodUni

//Clase,Bien o Servicio,auxiliar,descontable
document.getElementById("txtClase").value=miRegistroEdit.Clase
document.getElementById("comboBS").selectedIndex=(miRegistroEdit.TipoBS=="B") ? 0 : 1
document.getElementById("txtAuxiliar").value=miRegistroEdit.CodAuxiliar
document.getElementById("checkDescontable").checked= (miRegistroEdit.Descontable=="1")

//Producto para compra, venta y obervaciones
document.getElementById("checkCompra").checked= (miRegistroEdit.ParaCompra=="1")
document.getElementById("checkVenta").checked= (miRegistroEdit.ParaVenta=="1")
document.getElementById("txtObservaciones").value=miRegistroEdit.Observaciones
//====Ahora muestro la informacion que depende exclusivamente del Almacen (Activo,Stock,Minino,Ubicacion,P1,P2,P3,P4,Fecha Vence)
mostrarDatosSensiblesAlAlmacen()
}

const prepararValoresPorDefaultParaRegistroNuevo=()=>{
//************configuraciones por default de Preferencias
//(0):Bien /  (1):Servicio 
document.getElementById("comboBS").selectedIndex=(valueDC.sistema.tipoDeProductoPredeterminado=="Bien") ? 0 : 1
//Grava iva en compras y ventas (compras es mi referencia) (esta configurado con ESTADO y en el combo tambien)
//tuve que redundar, para que aparezca la tablita de los precios con iva
document.getElementById("comboIvaCompra").selectedIndex=(valueDC.sistema.gravaIvaEnCompras=="Si" ? "1" : "0")
set_comboIvaCompra(valueDC.sistema.gravaIvaEnCompras=="Si" ? "1" : "0")
//descontable de inventario
document.getElementById("checkDescontable").checked=(valueDC.sistema.descontableDeInventario=="Si") ? true : false
//Producto para compra 
document.getElementById("checkCompra").checked=(valueDC.sistema.productoParaCompra=="Si") ? true : false
//Producto para venta 
document.getElementById("checkVenta").checked=(valueDC.sistema.productoParaVenta=="Si") ? true : false
//vender por debajo del costo ponderado (esta configurado con ESTADO)
set_checkVenderPDCPState(valueDC.sistema.venderPorDebajoDelCostoPonderado=="Si" ? true : false)
//Vender sin stock (esta configurado con ESTADO)
set_checkVenderSSState(valueDC.sistema.venderSinStock=="Si" ? true : false)
}

//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  alimentarComboCatMarcaUni()

  //caso 1: cuando se desea NUEVO registro
  if (props.opcionRegistro_imc=='i'){
    document.getElementById("tituloH1").innerText= 'NUEVO'  
    document.getElementById("checkActivo").checked=true
    prepararValoresPorDefaultParaRegistroNuevo()
    crearCensoRegistrosCuandoEsNuevo(props.registrosFull) //creo un censo con los datos que vienen de la tabela (posiblemente esté desactualizada si otro usuario está metiendo datos desde otro dispositivo)
    calcularPvpConIvaMiAlmacen()
    //En caso de tener Intencion de crear UN nuevo producto desde ventas o equivalentes
    if (props.codigoCandidato.trim().length>0) set_txtPrincipalState(props.codigoCandidato.trim())  
  }
  //caso 2: cuando se desea EDITAR/CONSULTAR el registro  
  if (props.opcionRegistro_imc=='m' || props.opcionRegistro_imc=='c' ){
    document.getElementById("txtMiStock").setAttribute("disabled","true")                                      
    //cuando sea CONSULTAR, no muestro el boton guardar
    if (props.opcionRegistro_imc=='c'){
      document.getElementById("btnGuardar").style.visibility= 'hidden'
      //FALTA: no me desactiva al consultar
      document.getElementById("divParteFija").disabled=true
    }
    document.getElementById("tituloH1").innerText= (props.opcionRegistro_imc=='m') ? 'EDITAR' : 'CONSULTAR'
    gestionarBuscarRegistro() //ahi se obtienen los datos del producto que deseo editar y una lista (actualizada) para el censo
  } 
  //al descargar, limpio algunas variables sensibles
  return () => {
    censoRegistros=null
    miRegistroEdit=null
    codigoRojoDevueltoPorSql=null  
    repetido=null  
  }     
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al llamador
useEffect(()=>{  
  if (lecturaBDDsuperado==false){    
    props.ocultarModal_Inv("close",null)
  }
  },[lecturaBDDsuperado]
)

const alimentarComboCatMarcaUni=()=>{
//comboCat
let miSelectCat= document.getElementById("comboCat")
valueDC.categorias.forEach( (item) => {
  let miOption=document.createElement("option")
  miOption.value= item.IDcat
  miOption.text=item.Categoria
  miSelectCat.appendChild(miOption)
})  

//comboMarca
let miSelectMarca= document.getElementById("comboMarca")
valueDC.marcas.forEach( (item) => {
  let miOption=document.createElement("option")
  miOption.value= item.IDmar
  miOption.text=item.Marca
  miSelectMarca.appendChild(miOption)
})  

//comboUnidad
let miSelectUnidad= document.getElementById("comboUnidad")
valueDC.unidades.forEach( (item) => {
  let miOption=document.createElement("option")
  miOption.value= item.IDuni
  miOption.text=item.Unidad
  miSelectUnidad.appendChild(miOption)
})  

//pongo los combos sin ningun valor apuntando
document.getElementById("comboCat").selectedIndex=-1 
document.getElementById("comboMarca").selectedIndex=-1 
document.getElementById("comboUnidad").selectedIndex=-1 
}

const ComponentePrincipalBody=()=>{
return( 
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>  
  <Form id="miFormularioInventario">        
    {/* DIV para el encabezado: botones de GUARDAR Y SALIR*/}
    <div id="divGuardarSalir" name="divGuardarSalir" style={{backgroundColor:'silver',paddingTop:'0', paddingBottom:"2%"}}>
      <Row style={{margin:'0',}}>
        <Col xs="4" style={{paddingTop:'2%'}}>
          <h3 id="tituloH1"></h3>
        </Col>
        <Col xs="5" style={{paddingTop:'2%',}}>
          <Button id="btnGuardar" name="btnGuardar" color="primary" solid="true" style={{width:"90%", color:'white'}}
            onClick={async()=>{
              await guardarRegistro()
              if (codigoRojoDevueltoPorSql==null) return //quiere decir que NO se pudo guardar el registro
              // al recibir negativo, es que hubo algun error (alomejor codigo repetido) 
              if (codigoRojoDevueltoPorSql<0){
               await mostrarSwalBotonAceptar("warning","NO SE PUDO GUARDAR","Parece que está repitiendo el código")
               return               
              }
              //todo cool
              prepararJsonParaDevolver() //genero un JSON vacan para devolver al llamador
              props.ocultarModal_Inv('save',jsonDevolver)                
          }}>GUARDAR
          </Button>
        </Col>
        <Col xs="3" style={{textAlign:'right',paddingTop:'0',paddingRight:'0'}}> 
          <Button style={{ width: '40px', height: '40px', }} id="btnSalir" name="btnSalir" color="danger"    
            onClick={() => {
              props.ocultarModal_Inv("close",null)
            }}>
            <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
          </Button>              
        </Col>
      </Row>             
    </div> {/* divGuardarSalir */}
    {ParteFija()}
    <ModalBody>        
      <Nav tabs >
        <NavItem >
          <NavLink style={{ backgroundColor:'hotpink',marginRight:'2%'}}
            active= {tabActivoState == '1'}
            onClick={() => { 
              if(tabActivoState=='1') return 
              set_tabActivoState('1')
              }}>
            BASICOS
          </NavLink>
        </NavItem>
        <NavItem >
          <NavLink style={{ backgroundColor:'hotpink',marginRight:'3%'}}
            active= {tabActivoState == '2'}
            onClick={() => { 
              if(tabActivoState=='2') return 
              set_tabActivoState('2')      
            }}>
            EXTRA
          </NavLink>
        </NavItem>
      </Nav>     

      <TabContent activeTab={tabActivoState} style={{ backgroundColor:'pink', borderStyle:'solid', borderColor:'silver',borderWidth:'medium'  }}> 
        <TabPane tabId="1">
          { TabDatosBasicos() }
        </TabPane>
        <TabPane tabId="2">
          { TabDatosExtra() }
        </TabPane>
      </TabContent>
    </ModalBody>
  </Form>
</div> //divMaster
)} 

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='inventario') return ComponentePrincipal()

} //del IncModInventario

export default IncModInventario