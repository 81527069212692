//Solo pide Fecha Inicial y Final, para cualquier reporte que lo requiera
//luego me devuelvo al llamador, y desde ahi llamo realmente al componente del reporte
import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import { convierteObjetoFechaDeJSenTexto,textoDelCombo } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faBalanceScaleRight } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio
//variables que no son del estado
let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let miCurrentTime = new Date()
let miAno = miCurrentTime.getFullYear()
let miMes = miCurrentTime.getMonth() //OJO: al hacer esto: DICIEMBRE ES 11 
let miDia = miCurrentTime.getDate() //devuelve el dia del mes [1..31]
let jsonNegra={} //aqui guardo las opciones seleccionadas por el usuario

const PantNegraPedirFechaIniFechaFin=(props)=>{   
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('principal')
  const [fechaIni,setfechaIni]=useState(new Date(miAno,miMes,1))
  const [fechaFin,setfechaFin]=useState(new Date(miAno,miMes,miDia))

//************ simulo el componentDidMount */
useEffect( ()=>{
  //El boton de Excel, podria no estar visible en algunos casos
  if (props.botonExcelSN=="N")
    document.getElementById("btnExcel").style.visibility= 'hidden' //pero Lo botones visibles se quedan en su lugar original
  },[]
)

const salir=(botonEP)=>{
  //valido que la fecha fin sea mayor/igual que fecha ini
  let fechasOK=(fechaFin>=fechaIni)
  if (!fechasOK){
    mostrarSwalBotonAceptar("error","ATENCION","La fecha final debe ser mayor que la inicial")
    return false
  }

  //guardo las opciones del usuario 
  jsonNegra.fechaIni=convierteObjetoFechaDeJSenTexto(fechaIni) 
  jsonNegra.fechaFin=convierteObjetoFechaDeJSenTexto(fechaFin) 

  //cierro y regreso al llamador
  props.cerrarModal(true,jsonNegra,botonEP)  
}

const ComponentePrincipalBody=()=>{  
return(
<div id="divMaster" name="divMaster" className='FondoCasiNegro'>    
  {/* Titulo + Boton Cerrar */}
  <Row style={{margin:'0',}}>
      <Col xs="10" >
        <Label style={{marginTop:'5px',color:'dimGray',fontWeight: 'bold'}}>{props.miTitulo}</Label>
      </Col>
      <Col xs="2" style={{textAlign:'right',padding:'0'}}>
        <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
          onClick= { () =>{ 
            props.cerrarModal(false,null,null)  //no quiero seguir, no hay jsonNegra, no hay boton
            }} >
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
        </Button>
      </Col>
  </Row> 

  {/* Rango de fechas */}
    <div id="divBloque1" name="divBloque1" style={{margin:'3%',padding:'2%',background:'black', color:'Fuchsia', borderStyle:'solid', borderColor:'pink'}}>
      {/* Fechas */}
      {/* <Label style={{color:'pink'}}>Fechas</Label> */}
      <Row style={{margin:'0',}}>
        <Col>
          <Label>Desde</Label>
          <DatePicker id="txtDesde" name="txtDesde" className="anchoDatePickerGenerico" locale='es' dateFormat="dd/MM/yyyy"
            selected={ fechaIni }          
            onChange= { (datePar)  => setfechaIni(datePar) }
          />    
        </Col>
        <Col style={{textAlign:'right'}}>
          <Label>Hasta</Label>
          <DatePicker id="txtHasta" name="txtHasta" className="anchoDatePickerGenerico" locale='es' dateFormat="dd/MM/yyyy"
            selected={ fechaFin }          
            onChange= { (datePar)  => setfechaFin(datePar) }
          />
        </Col>
      </Row>

    </div> {/* divBloque1 */}

    {/* Boton Aceptar style={{marginRight:'10%'}} */}
    <Row style={{marginTop:'1%',marginBottom:'2%',marginLeft:'0',marginRight:'0',}} >
      <Col >    
        <Button size="md" id="btnExcel" name="btnExcel" color="info" solid="true" 
        onClick={()=>{ salir("E") }}>
        EXCEL</Button>
      </Col>         
      <Col style={{textAlign:'right',}}>    
        <Button size="md" id="btnListo" name="btnListo" color="info" solid="true" 
        onClick={()=>{ salir("P") }}>
        SIGUIENTE</Button>
      </Col>         
    </Row>    
</div> //divMaster
)}  

const ComponentePrincipal = () => {
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='principal') return ComponentePrincipal()

} // de PantNegraPedirFechaIniFechaFin

export default PantNegraPedirFechaIniFechaFin
