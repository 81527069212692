//alert(JSON.stringify(jsonNegra,null,2))
//para mostrar en JS la fecha actual, con let f=new Date() me devuelve: Tue Nov 02 2021 20:32:22 GMT-0500 (hora de Ecuador)
//NOTA IMPORTANTE: todos los Row, deben tener ajuro margin:'0' listo ok
import React, {useState,useEffect} from 'react'
import {Label,Row,Col,Button,Input,Dropdown,DropdownItem, DropdownMenu,DropdownToggle,Modal, Container} from 'reactstrap'
import buscarPhpPath, {devolverFechaHoyTextoYYYYMMDD,buscarHostnameSegunJs,consultarDatosBasicosDeLaFirmaElectronicaConSwal,mostrarSwalBotonAceptar,generarReporteLibroVentasPDFconSwal,generarReporteFacturasAnuladasPDFconSwal,generarReporteLibroVentasNotasEntregaPDFconSwal,sleepPepe } from './lib_basica'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFilePowerpoint, faBook, faObjectGroup,faBox, faTh, faFileInvoiceDollar,faObjectUngroup, faMap,faBoxes,faCartPlus,faFileSignature, faNewspaper,faFileInvoice ,faPowerOff, faRunning,faUsers,faUserFriends,faUserAlt,faUser,faUserPlus,faUserAltSlash,faLightbulb,faCartArrowDown, faShoppingCart, faCubes,faBookReader,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faTools, faStepBackward } from '@fortawesome/free-solid-svg-icons' 
//componentes a llamar desde el menu principal
import TabelaClientes from './TabelaClientes'
import TabelaFacturasLista from './TabelaFacturasLista'
import TabelaInventario from './TabelaInventario'
import Facturacion from './Facturacion'
import TabelaProveedores from './TabelaProveedores'
import TabelaPresupuestosLista from './TabelaPresupuestosLista'
import TabelaCxC from './TabelaCxC'
import TabelaCxP from './TabelaCxP'
import TabelaNotasLista from './TabelaNotasLista'
import TabelaComprasLista from './TabelaComprasLista'
import MostrarDatosBasicosFirmaElectronica from './MostrarDatosBasicosFirmaElectronica'
import EncriptarTextoEnJS from './EncriptarTextoEnJS'
import EncriptarTextoEnPHP from './EncriptarTextoEnPHP'
import VerContext from './VerContext'
import VerPdfOk from './VerPdfOk'
import ComprarConFactura from './ComprarConFactura'
import TabelaRetencionesElabLista from './TabelaRetencionesElabLista'
//*****pantallas negra para reportes***
import PantNegraLibroVentas from './PantNegraLibroVentas'
import PantNegraPedirFechaIniFechaFin from './PantNegraPedirFechaIniFechaFin'
import PantNegraLibroVentasNotasEntrega from './PantNegraLibroVentasNotasEntrega'
//del context
import {AppContextConsumer} from './ContextBase'
//variable normales
let valueDC=null //Aqui hago una copia de los valores del context  
let pepeJsonConsultarFirma=null //aqui guardo los datos de la firma
let pdfCreadoCorrectamenteSN=null //para generar algun pdf

const MenuPrincipalCorona=(props)=>{
  const [nombreComponenteParaVer,setNombreComponenteParaVer]=useState('menu') //Nombre del componente que deseo ver. Arranca obviamente con el menu principal
  const [modalDatosFirma,set_modalDatosFirma]=useState(false)   
  const [modalEncriptarEnJS,set_modalEncriptarEnJS]=useState(false)     
  const [modalEncriptarEnPHP,set_modalEncriptarEnPHP]=useState(false)       
  const [modalVerContext,set_modalVerContext]=useState(false)         
  const [modalLibroVentasNegra,set_modalLibroVentasNegra]=useState(false)         
  const [modalLibroVentasPDF,set_modalLibroVentasPDF]=useState(false)           
  const [modalFacturasAnuladasNegra,set_modalFacturasAnuladasNegra]=useState(false) 
  const [modalFacturasAnuladasPDF,set_modalFacturasAnuladasPDF]=useState(false)   
  const [modalNotasEntregaNegra,set_modalNotasEntregaNegra]=useState(false) 
  const [modalNotasEntregaPDF,set_modalNotasEntregaPDF]=useState(false)   
  const [dropUtilidades,set_dropUtilidades]=useState(false)    
  //para las credenciales superiores de mi cliente. Frontera >=880
  const [licenciadoLargo,set_licenciadoLargo]=useState('')   
  const [licenciadoCorto,set_licenciadoCorto]=useState('')
  const [estabMasPuntoLargo,set_estabMasPuntoLargo]=useState('') //Estab:001 - Punto:999
  const [estabMasPuntoCorto,set_estabMasPuntoCorto]=useState('') //001-999 

const gestionarConsultarDatosBasicosDeLaFirmaElectronica=async()=>{
  //primero reviso que tenga activada la opcion de usar la firma
  if(valueDC.licencia.FirmaElectronicaUsar=="0" || !valueDC.licencia.FirmaElectronicaUsar ){
    await mostrarSwalBotonAceptar("error","ERROR","No está habilitada la opción de Firma Electrónica")
    return
  }

  pepeJsonConsultarFirma=await consultarDatosBasicosDeLaFirmaElectronicaConSwal(valueDC) 
  //primero hay que revisar que pepeJsonConsultarFirma.AgrModConExito este true. 
  if (pepeJsonConsultarFirma.AgrModConExito==false) return

  //runmo ideal
  await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
  set_modalDatosFirma(true)
}

const despedirseSonido=()=>{
   let texto="Hasta Luego"
   speechSynthesis.speak(new SpeechSynthesisUtterance(texto))
}

const buscarHostnameSegunPhp=async()=>{
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','host_buscarHostnameSegunPhp')
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeText = await response.text() 

    alert(pepeText)
  }
  catch{
  }
}

//detectar el nombre de la BDD en produccion (la idea es que sea el mismo del subdominio)
const detectarNombreDeBDD=async()=>{
  let miPhpFile=  buscarPhpPath() + 'Contr_Generico.php'
  let data=new FormData()
  data.append('miSol','host_detectarNombreDeBDD')
  try{   
    let response = await fetch(miPhpFile, { method: 'POST',body:data })
    let pepeText = await response.text() 

    alert(pepeText)
  }
  catch{
  }
}

const buscarPathDeLaAplicacion=async()=>{
  //document.location.hostname //me devuelve (localhost para local) o (www.sistemaspaladino.com para remoto) **** OK ***
  alert("document.location.hostname=> " + document.location.hostname)
  alert("document.domain=> " + document.domain) //devuelve localhost
  alert("document.URL=> " + document.URL)   //devuelve http://localhost:3000/
}

const verAmbiente=async()=>{
  if (valueDC.licencia.AmbienteActualPRUPRO=="PRU")
    alert("Prueba")
  if (valueDC.licencia.AmbienteActualPRUPRO=="PRO")
    alert("Produccion")      
}

const activarMenu=() => {
  setNombreComponenteParaVer('menu') 
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //Activo un escucha para la ventana del navegador
  // window.addEventListener('resize',determinarCredenciales) //no usar
  //Licencia del man
  set_licenciadoLargo("Licencia: " + valueDC.licencia.Ruc + " / " + valueDC.licencia.RazonSocial)   
  set_licenciadoCorto("Licencia: " + valueDC.licencia.Ruc)   
  //estab y punto del usuario
  set_estabMasPuntoLargo("Estab: " + valueDC.usuario.EstabUsu + "- Punto: " + valueDC.usuario.PuntoUsu )
  set_estabMasPuntoCorto(valueDC.usuario.EstabUsu + "-" + valueDC.usuario.PuntoUsu)

  //al desmontar, debo quitar el escuchaDOR
  return()=>{
    //por ahora, nada
    //window.removeEventListener('resize',determinarCredenciales) //no usar
  }
},[]
)

const mostrarCredencialesSup=()=>{
return(
<div style={{width:'100%', display:'flex'}} >
  <div id="divAncho" name="divAncho" style={{width: 'calc(100% - 50px)', height:'44px',backgroundColor:'black'}} >
    <div id="divVersion" name="divVersion" style={{height:'23px',backgroundColor:'purple'}}>        
      <Row style={{margin:'0'}}>
          <Col xs="8" style={{paddingTop:'0px',margin:'0px'}}>
            <Label id="labelVersion" name="labelVersion" style={{color:'pink',fontSize:'13px'}} >Paladino-Corona V.1.32</Label>
          </Col>
          <Col xs="4" style={{paddingTop:'0px',margin:'0px',textAlign:'right'}}>
            <Label id="labelUsuario" name="labelUsuario" style={{color:'pink',fontSize:'13px',}} >{valueDC.usuario.LoginUsu}</Label>
          </Col>  
      </Row>  
    </div>
    {/*peachpuff, 'papayawhip' */}
    <div id="divLicencia" name="divLicencia" style={{height:'23px',backgroundColor:'hotpink'}}>        
      <Row style={{margin:'0'}}>
          <Col xs="7" md="9" style={{paddingTop:'0px',margin:'0px',textAlign:'left',}}>
            <Label id="licenciadoLargo" name="licenciadoLargo" className='classLicenciadoLargo' style={{color:'purple',fontSize:'13px',fontWeight: 'regular'}} >
              {licenciadoLargo}
            </Label>
            <Label id="licenciadoCorto" name="licenciadoCorto" className='classLicenciadoCorto' style={{color:'purple',fontSize:'13px',fontWeight: 'regular'}} >
              {licenciadoCorto}
            </Label>
          </Col>
          <Col xs="5" md="3" style={{paddingTop:'0px',margin:'0px',textAlign:'right',}}>
            <Label id="estabMasPuntoLargo" name="estabMasPuntoLargo" className='classLicenciadoLargo' style={{color:'purple',fontSize:'13px',}} >
              {estabMasPuntoLargo}
            </Label>
            <Label id="estabMasPuntoCorto" name="estabMasPuntoCorto" className='classLicenciadoCorto' style={{color:'purple',fontSize:'13px',}} >
              {estabMasPuntoCorto}
            </Label>            
          </Col>  
      </Row>  
    </div>  
  </div>
  {/* className="BotonMenuPpalSalir" */}
  <div id="divBoton" style={{width:'50px', height:'46px',backgroundColor:'gray',textAlign:'center',lineHeight:'42px'}} >
    <Button id="btnSalir" name="btnSalir" style={{width:'40px',height:'40px',backgroundColor:'black'}}
      onClick = { () => { 
        gestionarSalir()
      }}>  
      <span>
        <FontAwesomeIcon color="white" icon={faPowerOff} /> 
      </span>      
    </Button>
  </div>
</div>  
)
}

//en las credenciales inferiores, solo pongo el nombre comercial
// slateblue,aqua,Moccasin
const mostrarCredencialesInf=()=>{
return(
  <div id="divFooter" name="divFooter" className="classFooterMenuPpal classFooterMenuPpalVisibilidad" style={{background:'purple'}}>
    <Row style={{margin:'0'}}>
      <Col xs="12" >
        <Label id="labelComercial" name="labelComercial" style={{color:'white',fontSize:'12px', fontFamily:'fantasy,monospace,arial,', fontWeight: 'regular',}} >
          {valueDC.estable.NombreComercial}
        </Label>
      </Col>
    </Row>  
  </div>
)}

const gestionarSalir=()=>{
  //borro la sessionStore
  sessionStorage.removeItem("usuario")
  //mando un sonido que diga hasta luego o algo parecido.
  despedirseSonido()
  props.regresarAlLogin()  
}

const ComponentePrincipalBody=()=> {  
return(
  <div id="divMaster" name="divMaster" className="classContainerMenuPrincipal">
    {mostrarCredencialesSup()}

      <div id="divSubMaster" name="divSubMaster"> 
      {/******* Boton superior de OPCIONES (utilidades) ************/}
      <Row style={{margin:'0',paddingLeft:'10px'}} >     
        <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }} id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidades }  size="md"
        toggle= { ()=> {
          set_dropUtilidades(!dropUtilidades)
        }}>

        <DropdownToggle caret color="primary">
          Opciones
        </DropdownToggle>
        <DropdownMenu style={{backgroundColor:'cyan',}} >
          <DropdownItem onClick={ () => set_modalLibroVentasNegra(true) }>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Libro de ventas
          </DropdownItem>         
          <DropdownItem onClick={ () => set_modalFacturasAnuladasNegra(true) }>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Facturas anuladas</DropdownItem>      
          <DropdownItem 
            onClick={async()=>{
              setNombreComponenteParaVer('cxc')
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Cuentas por cobrar
          </DropdownItem>
          <DropdownItem divider />  
          <DropdownItem onClick={ () => 
            set_modalNotasEntregaNegra(true)
          }>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Listado de Notas de Entrega
          </DropdownItem>  
          <DropdownItem divider />  
          <DropdownItem onClick={ async() => {
            setNombreComponenteParaVer('proveedores')
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Proveedores</DropdownItem>

          <DropdownItem onClick={ async() => {
            setNombreComponenteParaVer('comprar_factura')  
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Nueva compra con Factura (c)</DropdownItem>                 
          <DropdownItem 
            onClick={async()=>{
              setNombreComponenteParaVer('lista de compras')  
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Compras
          </DropdownItem>  
          <DropdownItem 
            onClick={async()=>{
              setNombreComponenteParaVer('cxp')  
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Cuentas por pagar
          </DropdownItem>
          <DropdownItem divider />        

          <DropdownItem 
            onClick={async()=>{
              setNombreComponenteParaVer('retElab')  
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Retenciones elaboradas
          </DropdownItem>

{/*
          <DropdownItem onClick={ () => alert( buscarHostnameSegunJs() )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> HostName segun JS</DropdownItem>        

          <DropdownItem onClick={ async() => { 
            buscarHostnameSegunPhp() 
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> HostName segun PHP</DropdownItem>         
          <DropdownItem onClick={ () => buscarPathDeLaAplicacion() }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> PATH de la aplicacion</DropdownItem>                
          <DropdownItem onClick={ async() => {
            detectarNombreDeBDD() 
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Nombre de la BDD en hosting</DropdownItem>         
          */}            
            <DropdownItem onClick={ () => set_modalVerContext(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Ver Context</DropdownItem>                    
          <DropdownItem onClick={ async() => {
            gestionarConsultarDatosBasicosDeLaFirmaElectronica() 
            }}>
            <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Consultar datos de la firma electrónica</DropdownItem>
          <DropdownItem onClick={ () => set_modalEncriptarEnJS(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Encriptar en JS</DropdownItem>        
          <DropdownItem onClick={ () => set_modalEncriptarEnPHP(true) }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Encriptar en PHP</DropdownItem>                  
          <DropdownItem onClick={ () => verAmbiente() }>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Ver Ambiente</DropdownItem>
          <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
        </DropdownMenu>
        </Dropdown>
      </Row>

      <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
        <Col>
          <Button id="btnClientes" name="btnClientes" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
            onClick = { async() => {
              setNombreComponenteParaVer('clientes')
            }}>  
            <span className="TamanoIconoMenuPpal2024">
              <FontAwesomeIcon color="paleGreen" icon={faUsers}/> 
            </span>
            <span><br/>CLIENTES</span>
          </Button>
        </Col>
        <Col style={{textAlign:'right'}}>
          <Button id="btnInventario" name="btnInventario" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
            onClick = { async() => { 
              //verifico si el usuario es limitado. (entonces me devuelvo)
              if (valueDC.usuario.CategoriaUsu=="L") return
              //rumbo normal
              setNombreComponenteParaVer('inventario')
              }}>  
            <span className="TamanoIconoMenuPpal2024">
              <FontAwesomeIcon color="paleGreen" icon={faLightbulb} />
            </span>
            <span><br/>INVENTARIO</span>
          </Button>
        </Col>      
      </Row>
      <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
        <Col>
          <Button id="btnFactura" name="btnFactura"  className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
            onClick = { async() => { 
              setNombreComponenteParaVer('facturar')
            }}>  
            <span className="TamanoIconoMenuPpal2024">
              <FontAwesomeIcon color="paleGreen" icon={faCartPlus}/> 
            </span>
            <span><br/>+FACTURA</span>
          </Button>
        </Col>
        <Col style={{textAlign:'right'}}>
          <Button id="btnFacturas" name="btnFacturas" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
            onClick = { async() => {
              setNombreComponenteParaVer('lista de facturas')
            }}>  
            <span className="TamanoIconoMenuPpal2024">
              <FontAwesomeIcon color="paleGreen" icon={faMap} />
            </span>
            <span><br/>FACTURAS</span>
          </Button>
        </Col>      
      </Row>
      <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnProforma" name="btnProforma" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
          onClick = { async() => { 
            setNombreComponenteParaVer('cotizar')
          }}>  
          <span className="TamanoIconoMenuPpal2024">
            <FontAwesomeIcon color="paleGreen" icon={faFilePowerpoint}/> 
          </span>
          <span><br/>+PROFORMA</span>
        </Button>
        </Col>      
        <Col style={{textAlign:'right'}}>
          <Button id="btnPresupuestos" name="btnPresupuestos" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
            onClick = { async() => { 
              setNombreComponenteParaVer('lista de presupuestos')
              }}>  
            <span className="TamanoIconoMenuPpal2024">
              <FontAwesomeIcon color="paleGreen" icon={faBook}/> 
            </span>
            <span><br/>PROFORMAS</span>
          </Button>
        </Col>
      </Row>
      <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'1%' }} >
      <Col>
        <Button id="btnNotaCon" name="btnNotaCon" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
          onClick = { async() => { 
            setNombreComponenteParaVer('notacon')
          }}>  
          <span className="TamanoIconoMenuPpal2024">
            <FontAwesomeIcon color="paleGreen" icon={faFileSignature}/> 
          </span>
          <span><br/>+NOTA E</span>
        </Button>
        </Col>      
        <Col style={{textAlign:'right'}}>
          <Button id="btnNotasCon" name="btnNotasCon" className="BotonMenuPpal2024fijo BotonMenuPpal2024variable"
            onClick = { async() => { 
              setNombreComponenteParaVer('lista de notas')
            }}>  
            <span className="TamanoIconoMenuPpal2024">
              <FontAwesomeIcon color="paleGreen" icon={faMap}/> 
            </span>
            <span><br/>NOTAS E</span>
          </Button>
        </Col>
      </Row>
      {/*
      <Row style={{marginLeft:'0',marginRight:'0',marginTop:'0',marginBottom:'0%' }} >
        <Col>
          <Button id="btnLogin" name="btnLogin" className="BotonMenuPpalSalir"
            onClick = { () => { 
              gestionarSalir()
            }}>  
            <span style ={{ fontSize:"40px"}}>
            <FontAwesomeIcon color="paleGreen" icon={faPowerOff} /> 
            </span>
            <span><br/>Salir</span>          
          </Button>
        </Col>      
        <Col style={{textAlign:'right'}}>
        <Button id="btnConfig" name="btnConfig" className="BotonMenuPpal"  
          onClick = { async() => {  
            if (await hayInternet()==false) return
            // setState({nombreComponenteParaVer:'retElab'})          
          }}>  
          <span style ={{ fontSize:"40px"}}>
            <FontAwesomeIcon color="paleGreen" icon={faTools}/> 
          </span>
          <span><br/>AJUSTES</span>
        </Button>      
        </Col>      
      </Row>
        */}

    </div>  {/* divSubMaster */}

    {/************ MODAL PARA VER LOS DATOS DE LA FIRMA ELECTRONICA *************/}
    <Modal centered={true} size={'md'}  isOpen={ modalDatosFirma } >
        <MostrarDatosBasicosFirmaElectronica
          cerrarModal={()=> {
            set_modalDatosFirma(false)
          }}
          pepeJson={pepeJsonConsultarFirma}      
        />
    </Modal>   

    {/************ MODAL PARA ENCRIPTAR EN JS *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalEncriptarEnJS } >
        <EncriptarTextoEnJS
          cerrarModal={()=> {
            set_modalEncriptarEnJS(false)
          }}
        />
    </Modal>   
    {/************ MODAL PARA ENCRIPTAR EN PHP *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalEncriptarEnPHP } >
        <EncriptarTextoEnPHP
          cerrarModal={()=> {
            set_modalEncriptarEnPHP(false)
          }}
        />
    </Modal>     
    {/************ MODAL PARA VER EL CONTEXT    *************/}
    <Modal centered={true} size={'lg'} isOpen={ modalVerContext } >
        <VerContext
          cerrarModal={()=> {
            set_modalVerContext(false)
          }}
        />
    </Modal>     

    {/************ MODAL PARA PEDIR PARAMETROS PARA LIBRO DE VENTAS DE FACTURAS *************/}
    <Modal centered={true} size={'md'}  isOpen={ modalLibroVentasNegra } >
        <PantNegraLibroVentas
          miTitulo={"Libro de ventas"}
          botonExcelSN={"N"}
          cerrarModal={async(seguir,jsonNegra,botonEP)=> {
            set_modalLibroVentasNegra(false)
            if (!seguir) return
            if (botonEP=="P"){
              //debo generar el PDF y luego lo muestro  
              pdfCreadoCorrectamenteSN=await generarReporteLibroVentasPDFconSwal(valueDC,jsonNegra)
              if (pdfCreadoCorrectamenteSN=="S"){
                set_modalLibroVentasPDF(true)
              }
            }        
          }}
        />
    </Modal>     

    {/************ MODAL PARA PEDIR PARAMETROS PARA FACTURAS ANULADAS *************/}
    <Modal centered={true} size={'md'} isOpen={ modalFacturasAnuladasNegra } >
      <PantNegraPedirFechaIniFechaFin
        miTitulo={"Facturas Anuladas"}
        botonExcelSN={"N"}
        cerrarModal={async(seguir,jsonNegra,botonEP)=> {
          set_modalFacturasAnuladasNegra(false)
          if (!seguir) return
          if (botonEP=="P"){
            //debo generar el PDF y luego lo muestro  
            pdfCreadoCorrectamenteSN=await generarReporteFacturasAnuladasPDFconSwal(valueDC,jsonNegra)
            if (pdfCreadoCorrectamenteSN=="S"){
              set_modalFacturasAnuladasPDF(true)
            }                   
          }         
        }}
      />
    </Modal>     

    {/* ******** MODAL PARA PEDIR PARAMETROS PARA LIBRO DE VENTAS DE NOTAS DE ENTREGA ********* */}
    <Modal centered={true} size={'md'}  isOpen={ modalNotasEntregaNegra } >
        <PantNegraLibroVentasNotasEntrega
          miTitulo={"Listado de Notas de Entrega"}
          botonExcelSN={"N"}
          cerrarModal={async(seguir,jsonNegra,botonEP)=> {
            set_modalNotasEntregaNegra(false)
            if (!seguir) return

            if (botonEP=="P"){
              //debo generar el PDF y luego lo muestro  
              pdfCreadoCorrectamenteSN=await generarReporteLibroVentasNotasEntregaPDFconSwal(valueDC,jsonNegra)
              if (pdfCreadoCorrectamenteSN=="S"){
                set_modalNotasEntregaPDF(true)
              }
            }           
          }}
        />
    </Modal>   

    {/* *********** MODAL PARA VER EL LIBRO DE VENTAS ************ */}
    <Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ modalLibroVentasPDF } >
      <VerPdfOk
        documentoTitulo={"Libro de ventas"}
        documentoArchivo={"LIBROventas_" + valueDC.usuario.LoginUsu }
        corchetesSN={"N"} //NO quiero ver entre corchetes el nombre del archivo
        tipoSriSN={"N"} //es un documento del sri?
        estiloPantallaPG={"P"} //Para modal le mando P
        activarMenu={ ()=> set_modalLibroVentasPDF(false) } 
      />
    </Modal>   

    {/* *********** MODAL PARA VER LAS FACTURAS ANULADAS ************ */}
    <Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ modalFacturasAnuladasPDF } >
      <VerPdfOk
        documentoTitulo={"Facturas Anuladas"}
        documentoArchivo={"FACTURASanuladas_" + valueDC.usuario.LoginUsu }
        corchetesSN={"N"} //NO quiero ver entre corchetes el nombre del archivo
        tipoSriSN={"N"} //es un documento del sri?
        estiloPantallaPG={"P"} //Para modal le mando P
        activarMenu={ ()=> set_modalFacturasAnuladasPDF(false) } 
      />
    </Modal>   

    {/* *********** MODAL PARA VER LAS NOTAS DE ENTREGA ************ */}
    <Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ modalNotasEntregaPDF } >
      <VerPdfOk
        documentoTitulo={"Listado de Notas de Entrega"}
        documentoArchivo={"LISTAnotas_" + valueDC.usuario.LoginUsu }
        corchetesSN={"N"} //NO quiero ver entre corchetes el nombre del archivo
        tipoSriSN={"N"} //es un documento del sri?
        estiloPantallaPG={"P"} //Para modal le mando P
        activarMenu={ ()=> set_modalNotasEntregaPDF(false) } 
      />
    </Modal>  
    {mostrarCredencialesInf()}
  </div> 
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVer==='menu') return ComponentePrincipal()
if (nombreComponenteParaVer==='clientes') return (<TabelaClientes activarMenu={ activarMenu } />)
if (nombreComponenteParaVer==='inventario')
  return (<TabelaInventario 
    activarMenu={ activarMenu } 
    verPvpSinIva={(valueDC.sistema.mallaDeInventarioVerPreciosSinIva=="Si") ? true : false}
    verPvpConIva={(valueDC.sistema.mallaDeInventarioVerPreciosConIva=="Si") ? true : false}
  />)

  if (nombreComponenteParaVer==='lista de facturas')
    return (<TabelaFacturasLista activarMenu={ activarMenu } />)
  if (nombreComponenteParaVer==='cxc')
    return (<TabelaCxC activarMenu={ activarMenu } />)    

  if (nombreComponenteParaVer==='proveedores') 
    return (<TabelaProveedores activarMenu={ activarMenu } />)

  if (nombreComponenteParaVer==='facturar') 
    return (<Facturacion 
      docElab="FA"
      activarMenu={ activarMenu } 
      verPvpSinIva={(valueDC.sistema.mallaDeVentasVerPreciosSinIva=="Si") ? true : false}
      verPvpConIva={(valueDC.sistema.mallaDeVentasVerPreciosConIva=="Si") ? true : false}      
      />)    
  if (nombreComponenteParaVer==='cotizar')
    return (<Facturacion 
      docElab="PR"
      activarMenu={ activarMenu } 
      verPvpSinIva={(valueDC.sistema.mallaDeVentasVerPreciosSinIva=="Si") ? true : false}
      verPvpConIva={(valueDC.sistema.mallaDeVentasVerPreciosConIva=="Si") ? true : false}      
      />)    
      
  if (nombreComponenteParaVer==='lista de presupuestos')
    return (<TabelaPresupuestosLista     
      activarMenu={ activarMenu } 
      />)          

  if (nombreComponenteParaVer==='notacon')
    return (<Facturacion 
      docElab="NECONIVA"
      activarMenu={ activarMenu } 
      verPvpSinIva={(valueDC.sistema.mallaDeVentasVerPreciosSinIva=="Si") ? true : false}
      verPvpConIva={(valueDC.sistema.mallaDeVentasVerPreciosConIva=="Si") ? true : false}        
      />)    

  if (nombreComponenteParaVer==='lista de notas')
    return (<TabelaNotasLista     
      activarMenu={ activarMenu } 
      />)            

  if (nombreComponenteParaVer==='comprar_factura') 
    return (<ComprarConFactura 
      activarMenu={ activarMenu } 
      verPvpSinIva={(valueDC.sistema.mallaDeVentasVerPreciosSinIva=="Si") ? true : false}
      verPvpConIva={(valueDC.sistema.mallaDeVentasVerPreciosConIva=="Si") ? true : false}      
      />)  
        
  if (nombreComponenteParaVer==='lista de compras')
    return (<TabelaComprasLista activarMenu={ activarMenu } />)
   
  if (nombreComponenteParaVer==='cxp')
    return (<TabelaCxP activarMenu={ activarMenu } />)    

  if (nombreComponenteParaVer==='retElab')
    return (<TabelaRetencionesElabLista activarMenu={ activarMenu } />)    
    
} // const MenuPrincipalCorona

export default MenuPrincipalCorona
