//este formulario, me permite seleccionar UN producto activo (se requiere un parametro para saber si quiere PRODUCTOS DE VENTA o PRODUCTOS DE COMPRA).
//ya desde el modulo llamador, se le envia un JSON con todas las filas de la tabla inventario (pocas columnas). 
//Desde el llamador vienen Activos + Inactivos. Por lo tanto primero hago una seleccion de solo activos
//se corre el riesgo que no se muestren los productos ingresados/actualizados desde otra computadora
//FALTA: se debe configurar en la tabela que se ordene automaticamente por la Descripcion, sin importar como venga el JSON
import React, {useState,useEffect} from 'react'
import {Row,Col,Spinner, Jumbotron,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,buscarUnProductoPorCodRojoConSwal,hayInternet } from './lib_basica'
import DataTable from 'react-data-table-component'
//modales
import IncModInventario from './IncModInventario'
import Ver4PreciosDeUnProducto from './Ver4PreciosDeUnProducto'
import VerStockPreciosOtrosAlmacenes from './VerStockPreciosOtrosAlmacenes'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWarehouse,faListOl,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//Context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let miProdSeleccionado=null //aqui copio Un registro para mandarlo a los modales de [ver 4 precios] o al [ver otras bodegas]. Primero se lo busca en php con async await. No confundirlo con rowRegistroClon
let opcionRegistro_imc='' //para saber si deseo incluir nuevo cliente, modificar o consultar (pero desde este formulario solo 'c')
let codigoCandidato='' //codigo cuando deseo agregar un producto desde facturacion/presupuestos/nota de entrega, etc (desde este formulartio no se usa)
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho={
	table: {
		style: {
			minHeight: '45vh',      
		},
	},    
  //el header, se refiere al titulo que dice 'INVENTARIO DE LA BODEGA....'
  header: {
    style: {
      color: 'black',
      backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
    },
  },  
  headCells: {
    style: {
      //marginLeft:'50px', //ok funcion
      paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok
      fontSize:'18px',   //ok
      fontWeight: 'bold'
    },
  },  
  rows: {
    style: {
       minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },    
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',                 
      },
    },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada  
  {
    when: row => (row.IDr != filaClonada?.IDr ),
    style: row => ({ 
      backgroundColor:row.Adef_Activo=="No"  ? valueDC.sistema.coloresItemInactivo : 
      row.Descontable=="0" ? 'white' : 
      parseFloat(row.Adef_Stock)<=0 ? valueDC.sistema.coloresInventario0yNegativo : 
      parseFloat(row.Adef_Stock)<=parseFloat(row.Adef_StockMin) ? valueDC.sistema.coloresInventarioEntre1yMinimo : 'white',
      color: 'dimgray',
     }),    
  },
  //fila seleccionada  
  {
    //segun el SELECTOR
    when: (row) => (row.IDr == filaClonada?.IDr ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaSecundaria,
      color: 'black',
    }),
  },
]

const TabelaInventarioBuscador=(props)=>{   
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
  const [textoBuscarState,set_textoBuscarState]=useState('') //para el texto que va en el cuadrito de buscar  
  const [modalIncModRegistroState,set_modalIncModRegistroState]=useState(false) //para abrir el modal de incluir/modificar un registro        
  const [modalVer4PreciosState,set_modalVer4PreciosState]=useState(false)  
  const [modalVerOtrasBodegasState,set_modalVerOtrasBodegasState]=useState(false)  //para abrir el modal de ver los precios y stock de otras bodegas
  
//estructura de columnas para DataTable (la constante MiEstructura, se ha declarado dentro de la clase
//para que funcione el this de cell:
const miEstructura=[  
  {
    name:'PRINCIPAL',
    selector:row=>row.CodPrincipal, 
    sortable:true,
    left:true,
    compact:true, //al estar FALSE, me deja un padding
    grow:1, //es una medida relativa (1 es mi referencia)
  },
  {
    name:'DESCRIPCION',
    selector:row=>row.Descripcion, 
    left:true,
    sortable:true,
    compact:true,
    grow:1.7,
  },      
  {
    //acciones
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1.1,  //estaba 1.6

    cell: (row) => 
    <div style={{ display:'flex' }}>            
    {/* boton Listo  */}
    <Button id="btnSeleccionar" name="btnSeleccionar" style={{ marginRight:'1px',color:'white',fontWeight: 'bold' }} raised = "true" primary='false' color="info" 
      onClick={async()=>{ 
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        props.cerrarModal(true,row.CodPrincipal) 
    } }>Listo
    </Button>
          
    {/* dropdown para el boton MAS... */}
    {/* al hacer clic por segunda vez consecutiva se debe apagar el desplegable */}
    <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.IDr } direction="right"  
      toggle={()=>{
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.IDr : 0)
      }}>            
      <DropdownToggle color="info">
        <FontAwesomeIcon color="white" icon={faEllipsisH} />
      </DropdownToggle>
        
      <DropdownMenu style={{backgroundColor:'pink',}} >
        <DropdownItem 
          onClick={async()=>{
            if (!hayFilaSeleccionada()) return
            opcionRegistro_imc='c' //'c'onsultar registro
            set_idMasState(0)
            set_modalIncModRegistroState(true)          
          }}
        >
          <FontAwesomeIcon style={{color:'dimGray'}} icon={faEye} /> Consultar
        </DropdownItem>        
        <DropdownItem onClick={ () => gestionarVer4Precios_y_OtrasBodegas("4P") }>  <FontAwesomeIcon style={{color:'dimGray'}} icon={faListOl} /> Ver 4 precios y stock</DropdownItem>        
        <DropdownItem onClick={ () => gestionarVer4Precios_y_OtrasBodegas("OB")}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faWarehouse} /> Ver en otras bodegas</DropdownItem>                
        <DropdownItem onClick={ () => alert('Copiar.. ' + row.IDr )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Copiar cédula</DropdownItem>        
        <DropdownItem onClick={ () => alert('Deuda vieja.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faCommentDollar} />  Saldo Inicial</DropdownItem>        
        <DropdownItem onClick={ () => alert('Deuda vieja.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faEnvelope} /> Enviar email</DropdownItem>        
        <DropdownItem onClick={ () => alert('Deuda vieja.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faUserAlt}  /> Ver record</DropdownItem>        
        <DropdownItem onClick={ () => alert('Deuda vieja.. ' + row.IDr )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faBookReader} /> Bloc de notas</DropdownItem>        
        <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faBookReader} /> Cerrar</DropdownItem>        
      </DropdownMenu>

      </Dropdown>
    </div>,
  },    
  {
    name:'STOCK',
    selector:row=>row.Adef_Stock, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.6,
  },    
  {
    name:'MINIMO',
    selector:row=>row.Adef_StockMin, 
    sortable:true,
    compact:true,
    center:true,
    //hide:'sm', //se oculta en pantallas pequenas por debajo de 600px de ancho
    omit:true,
  },    
  {
    name:'P1',
    selector:row=>row.Adef_P1, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    omit: !(props.pvp1InventarioSinIvaVerDefinitivo)
  }, 
  {
    name:'P1iva',
    selector:row=>row.Adef_P1mi, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    omit: !(props.pvp1InventarioConIvaVerDefinitivo)
  },   
  {
    name:'P2',
    selector:row=>row.Adef_P2, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    //hide:'md',
    omit: !(props.pvp2InventarioSinIvaVerDefinitivo)
  }, 
  {
    name:'P2iva',
    selector:row=>row.Adef_P2mi, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    //hide:'md',
    omit: !(props.pvp2InventarioConIvaVerDefinitivo)
  },     
  {
    name:'P3',
    selector:row=>row.Adef_P3, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    //hide:'md',
    omit: !(props.pvp3InventarioSinIvaVerDefinitivo)
  }, 
  {
    name:'P3iva',
    selector:row=>row.Adef_P3mi, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    //hide:'md',
    omit: !(props.pvp3InventarioConIvaVerDefinitivo)
  },     
  {
    name:'P4',
    selector:row=>row.Adef_P4, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    //hide:'md',
    omit: !(props.pvp4InventarioSinIvaVerDefinitivo)
  }, 
  {
    name:'P4iva',
    selector:row=>row.Adef_P4mi, 
    sortable:true,
    compact:true,
    center:true,
    grow:0.8,
    //hide:'md',
    omit: !(props.pvp4InventarioConIvaVerDefinitivo)
  },     
  {
    name:'CLASE',
    selector:row=>row.Clase, 
    sortable:true,
    compact:true,
    center:true,
    grow:1.2,
    hide:'sm',  
  },      
  {
    name:'UBICACION',
    selector:row=>row.Adef_Ubicacion, 
    sortable:true,
    compact:true,
    grow:1,
    hide:'sm',    
  }, 
  {  
    name:'id',
    selector:row=>row.IDr, 
    sortable:true,
    center:true,
    //grow:0.2,
    compact:true, //padding 0
    omit:true, //oculta (siempre) la celda
  },  
]

const hayFilaSeleccionada=()=>{
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar un Producto")          
    miDevolver=false
  }
  return miDevolver
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //hago una seleccion, solo me interesan los activos y al mismo tiempo, si es solo para VENTA o COMPRA
  //En las props viene un parametro que puede tener el valor "ParaCompra" o "ParaVenta"  
  //desde el llamador viene la lista del INVENTARIO. no hace falta leer desde la BDD
  //=== blanqueo estas variables 
  rowRegistroClon=null 
  miProdSeleccionado=null
  codigoCandidato=''

  let meInteresa=props.datosInv.filter(item=>{
    if (item.Adef_Activo=="Si") {
      item.VisibleSN="S"
      if (props.ParaCompraParaVenta=="ParaCompra")        
        return ( item.ParaCompra=="1")
      else
        return ( item.ParaVenta=="1")  
    }
  })      
  set_registrosFullState(meInteresa)

  //Al desmontar el componente, entonces limpio las variables sensibles
  return () => {
    //variables normales
    rowRegistroClon=null     
    miProdSeleccionado=null
    codigoCandidato=''      
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }      
  },[]
)

const filtrarElementos=(cadenaLoca)=>{
  //ojo: obligatorio convertirla en minuscula para hacer la busqueda
  cadenaLoca=cadenaLoca.toLocaleLowerCase()

  let filtradosTmp=registrosFullState.filter(item=>{
    if (
      item.CodPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Descripcion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Clase.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Adef_Ubicacion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.CodSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.CodAuxiliar.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.BlockDeNotas.toString().toLocaleLowerCase().includes(cadenaLoca)
      )  
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item   
    })
    
  set_registrosFullState(filtradosTmp)
  set_idMasState(0)
  rowRegistroClon=null  
} 
  
const gestionarVer4Precios_y_OtrasBodegas=async(queDeseo)=>{
  if (await hayInternet()==false) return

  //queDeseo puede ser: 4P=>para ver los 4 precios de un producto. OB=>ver los precios y el stock del producto en otras bodegas. MS=>modificar stock
  let miResp=await buscarUnProductoPorCodRojoConSwal(rowRegistroClon.IDr,valueDC) //me tiene que devuelver un JSON
  if (!miResp.AgrModConExito) return //no muestro ningun mensaje ya que la funcion buscarUnProductoPorCodRojoConSwal lo muestra

  //reviso que realmente haya UN registro. Alomejor alguien lo ha borrado desde otra PC
  if (miResp.Data.length==0){
    mostrarSwalBotonAceptar("danger","PRODUCTO NO EXISTE","El producto ya no existe")
    return
  }
  //copio en una variable, solamente el registro en cuestion, que viene desde la BDD
  miProdSeleccionado=miResp.Data[0]
  //Llamo al modal correspondiente
  if (queDeseo=="4P") set_modalVer4PreciosState(true)
  if (queDeseo=="OB") set_modalVerOtrasBodegasState(true)
}

const ComponentePrincipalBody=()=>{   
return (  
  <div id="divMaster" name="divMaster" style={{width:'100%',minHeight:'80vh',background:'gray'}}>   
    {/**** barra de titulo  */}
    <div id="divBarraTitulo" name="divBarraTitulo"> 
      <Row style={{margin:'0'}}> 
        <Col xs="10">       
        <Label style={{marginTop:'5px',fontWeight: 'bold',color:"pink"}}>{"Productos Activos"}</Label>
        </Col>
        <Col xs="2" style={{textAlign:'right',padding:'0'}}>
          <Button className="botonCerrarTabelaBusqueda" style={{width:'40px', height:'40px', }} id="btnCerrar" name="btnCerrar" color="danger" 
            onClick={()=>{ 
              props.cerrarModal(false,null)  
            }} 
          >
            <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
          </Button>
        </Col>
      </Row>
    </div> {/* divBarraTitulo */}

    {/******* para input de busqueda y refrescar  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', marginLeft:'10px', }} >
      <Input 
        className = "textoBusquedaClientes" type="text" placeholder="Texto de búsqueda" name="inputBusqueda"  id="inputBusqueda" 
        value={textoBuscarState}
        onChange={()=>{
          set_textoBuscarState( document.getElementById("inputBusqueda").value )
          filtrarElementos( document.getElementById("inputBusqueda").value.trim() )
        }}/>
      <Button id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px',marginRight:'5px' }}
        onClick={()=>{
          set_textoBuscarState('')
          set_rowRegistroState(null)
          rowRegistroClon=null
          set_idMasState(0)          
          filtrarElementos('')           
        }}> 
        <FontAwesomeIcon color="paleGreen" icon={faBroom} style = { {marginRight:'5px' }} />
        Limpiar
      </Button>        
    </div> {/* divBuscador1 */}
    
    { /* ***** TABELA ******/ }
    <div id="divTabela" name="divTabela" style={{marginTop:'2%',marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} >
      <DataTable
        //************ DATA TABLA PROPERTIES (basic) ***********/
        title={' Inventario Activo de la Bodega ' + valueDC.estable.Almacen + ' [' + valueDC.almacen.AliasAlm + ']'}
        columns={miEstructura}
        data={registrosFullState.filter(item=>item.VisibleSN=='S')}
        conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)}  
        keyField ='IDr' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
        onRowClicked={(row)=>{
          set_rowRegistroState(row)
          rowRegistroClon=row
          set_idMasState(0)      
          }}
        highlightOnHover  
        //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
        noDataComponent = ""   
        //************ DATA TABLA PROPERTIES (pagination) ***********/
        pagination
        //paginationServer //change de default pagination to work with server pagination
        paginationPerPage={ valueDC.sistema.paginadoParaBuscadorInventario } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
        paginationComponentOptions={paginacionOpciones}

        //************ DATA TABLA PROPERTIES (header) ***********/
        //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body

        //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
        customStyles={miEstilacho} /* redefino algun estilo */
      />    { /* del componente DataTable */ }
    </div> { /* del divTabela */ }

    {/* ************ MODAL PARA CONSULTAR INVENTARIO **********/}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalIncModRegistroState } >
      <IncModInventario 
        ocultarModal_Inv={(accion,jsonRegistro)=>{
          set_modalIncModRegistroState(false)
          //accion puede ser: close/save/notfound
          if (accion=='close') return
        }} 
        opcionRegistro_imc = {opcionRegistro_imc} //por ahora solo 'c' de consultar
        registroIDr = {(opcionRegistro_imc=='i') ? 0 : ((rowRegistroClon==null) ? 0: rowRegistroClon.IDr)} //es util solamente cuando deseo editar o consultar el registro (cuando es NUEVO se va con 0)
        codigoCandidato = {codigoCandidato} //solo tiene utilidad cuando se desea crear un producto desde FACTURACION, COMPRAS o similares
        registrosFull = {registrosFullState}
      />
    </Modal>

    {/* ************************* MODAL PARA VER 4 PRECIOS DE UN ALMACEN *************** */}
    <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalVer4PreciosState } >
      <Ver4PreciosDeUnProducto
        cerrarModal={()=>set_modalVer4PreciosState(false)}
        miProd={miProdSeleccionado}
      />
    </Modal>

    {/* ************************* MODAL PARA VER 4 PRECIOS DE LOS 10 ALMACENES *************** */}
    <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalVerOtrasBodegasState} >
      <VerStockPreciosOtrosAlmacenes
        cerrarModal={()=>set_modalVerOtrasBodegasState(false)} 
        valueDC={valueDC}
        miProd={miProdSeleccionado}
      />
    </Modal>

    </div> //{/*  del divMaster */}
  ) //del return interno
}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()

} //del export

export default TabelaInventarioBuscador
