//alert(JSON.stringify(rowRegistroState,null,2))
//***************************** importante *****************/
//Este form, me sirve para Incluir/Modificar/Consultar UN cliente  
//Para nuevo/modificar=> se busca una lista actualizada de TODOS de los clientes para el censo (IDr e IdPrincipal) 
//el censo viene en Data.Censo (solo cuando sea Nuevo/Modificar)
//Cuando es Modificar/consultar, entonces en Data.RegistroBuscado vienen los datos del Cliente en cuestión
//Cuando es Modificar/consultar si Data.RegistroBuscado es NULL, significa que NO se encontró el Registro (y entonces doy una alerta y me regreso al llamador)
//Cuando es Nuevo, entonces en Data.RegistroBuscado viene null
//El censo me sirve solamente para dar una ALERTA cuando estoy escribiendo uan cedula repetida
//cuando es cliente de credito, se le pone siempre 30 dias de plazo y en limite se va a guardar 0.
//en la base de datos la columna [TipoVenta], puede ser [0:Solo Contado] [1:Contado y Credito]

import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import {ejecutarFetchGenericoConSwal } from './lib_basica'
import buscarPhpPath,{sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,verificarSiTextoEsPurosDigitos, aceptarSoloEnteros } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio

let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let censo=null //Aqui tengo un array/censo de registros, para validar repeticiones. Solo requiero: {IDr, IdPrincipal, }
let registroBuscado=null //aqui voy a tener al registro que deseo editar/modificar
let codigoRojoDevueltoPorSql=null //cuando es nuevo el sql me devuelve un autonumerico. [Cuando es editar, me devuelve el mismo rojo enviado]
let repetido=null //[true,false], para saber si se esta tratando de meter un ruc repetido
let jsonDevolver={} //este objeto lo devuelvo al llamador (bien sea para nuevo o editar)

const IncModCliente=(props)=>{           
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('cliente') //por default: 'cliente'. Alomejor luego un componente para ver un listado de las posibles ciudades/provincias . etc
  const [tabActivoState,set_tabActivoState]=useState('1') //La pestana de datos basicos
  const [lecturaBDDsuperado,set_lecturaBDDsuperado]=useState(true) //Al arrancar el FORM, asumo que todo saldrá bien (al arrancar el FORM hay que leer en la BDD. ALomejor podría regrasar con null)
  //para la parte fija
  const [comboTipoId,set_comboTipoId]=useState('0405')
  const [txtCedulaState,set_txtCedulaState]=useState('')  
  const [txtNombreState,set_txtNombreState]=useState('')  
  //para la pestana 1 (datos basicos)
  const [txtNombreComState,set_txtNombreComState]=useState('')  
  const [txtClaseState,set_txtClaseState]=useState('')  
  const [txtEmailState,set_txtEmailState]=useState('')  
  const [txtDireccionState,set_txtDireccionState]=useState('')  
  const [txtCiudadState,set_txtCiudadState]=useState('')  
  const [txtTlfFijoState,set_txtTlfFijoState]=useState('')  
  const [txtTlfCelState,set_txtTlfCelState]=useState('')  
  const [txtTlfWhatState,set_txtTlfWhatState]=useState('')  
  //radios: se requieren dos grupos a) [Tipo de precio]. b) [Forma de pago]
  const [p0State,set_p0State]=useState(false) //para el Tipo de precio
  const [p1State,set_p1State]=useState(false) 
  const [p2State,set_p2State]=useState(false) 
  const [p3State,set_p3State]=useState(false) 
  const [p4State,set_p4State]=useState(false) 
  const [concreState,set_concreState]=useState(false) //contado y credito
  const [consolState,set_consolState]=useState(false) //contado solamente
  //para la pestana 2 (datos extra)
  const [txtSecundarioState,set_txtSecundarioState]=useState('')  
  const [comboActivoState,set_comboActivoState]=useState(1)  //[0:false]  [1:true]
  const [comboPRState,set_comboPRState]=useState(0)  //[0:false]  [1:true]
  const [comboRetIvaState,set_comboRetIvaState]=useState(0)  //[0:false]  [1:true]
  const [comboRetRentaState,set_comboRetRentaState]=useState(0)  //[0:false]  [1:true]
  const [fechaEdoState,set_fechaEdoState]=useState(new Date()) //arranca con la fecha actual
  const [txtDireccionEnvioState,set_txtDireccionEnvioState]=useState('')  
  const [txtCiudadEnvioState,set_txtCiudadEnvioState]=useState('')  
  const [txtPersonaContactoState,set_txtPersonaContactoState]=useState('')  
  const [txtObservacionesState,set_txtObservacionesState]=useState('')  

const ParteFija=()=>{   
return (
  <div id="divParteFija" name="divParteFija">
    <Row style={{marginTop:'2%',marginBottom:'1%',marginLeft:'0',marginRight:'0',padding:'0'}}>
      <Col xs="5">
        <Input type="select" name="comboNacionalidad" id="comboNacionalidad" onChange={(e)=>set_comboTipoId(e.target.value)}>
          <option value="0405" selected={(comboTipoId=="0405") ? true : false}>Cédula/Ruc</option>
          <option value="06" selected={(comboTipoId=="06") ? true : false}>Pasaporte</option>
          <option value="07" disabled selected={(comboTipoId=="07") ? true : false}>Consumidor Final</option>
          <option value="08-1" selected={(comboTipoId=="08-1") ? true : false}>Id. del exterior (Persona)</option>
          <option value="08-2" selected={(comboTipoId=="08-2") ? true : false}>Id. del exterior (Sociedad)</option>
        </Input>
      </Col>
      <Col xs="7">        
        <Input  maxLength="20" placeholder="Cedula/Ruc/Pasaporte*"  name="txtCedula" id="txtCedula" value={txtCedulaState}
          onChange={(e)=> set_txtCedulaState(e.target.value)}
          onKeyUp={(e)=> buscarRepetidoEnCenso()}             
        />  
      </Col>
    </Row>    
    <Row style={{margin:'0',}}>
      <Col xs="12">
        <Input maxLength="100" placeholder="Nombre del Cliente*" name="txtNombre" id="txtNombre" value={txtNombreState}
            onChange={(e)=>set_txtNombreState(e.target.value) }                   
        />
      </Col>
    </Row>
  </div> //divParteFija
)}    
  
const  TabDatosBasicos=()=>{   
return (
  <div id="divDatosBasicos" name="divDatosBasicos">
    <div style={{width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
        <Label for="txtNombreCom">Nombre Comercial</Label>
        <Input maxLength="100" placeholder="Nombre Comercial" name="txtNombreCom" id="txtNombreCom" value={txtNombreComState} 
          onChange={(e)=>set_txtNombreComState(e.target.value)}               
        />
    </div>      
    {/* aqui hago una fila de dos columnas, para TIPO DE PRECIO y FORMA DE PAGO */}
    <Row style={{margin:'0'}}>
      <Col>
        { Precio1234() }
      </Col>
      <Col>
        { FormaDePago() }
        <div style={{marginTop:'5%',}}>
          <Input maxLength="40" style={{width:'100%',marginRight:'5%'}} placeholder="Clase" name="txtClase" id="txtClase" value={txtClaseState}  
            onChange={(e)=>set_txtClaseState(e.target.value)}          
          />
        </div>          
      </Col>
    </Row>
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="150" placeholder="Correo" name="txtEmail" id="txtEmail" value={txtEmailState} 
        onChange={(e)=>set_txtEmailState(e.target.value)}
      /> 
    </div>              
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="300" placeholder="Dirección" name="txtDireccion" id="txtDireccion" value={txtDireccionState} 
        onChange={(e)=>set_txtDireccionState(e.target.value)}
      />
    </div>          
    <div style={{display:'flex',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="50" style={{width:'40%',marginRight:'5%'}} placeholder="Ciudad" name="txtCiudad" id="txtCiudad" value={txtCiudadState} 
        onChange={(e)=>set_txtCiudadState(e.target.value)}
      />
      <Input maxLength="30" style={{width:'55%',}} placeholder="Tlf Fijo" name="txtTlfFijo" id="txtTlfFijo" value={txtTlfFijoState} 
        onChange={(e)=>set_txtTlfFijoState(e.target.value)}
      />
    </div>          
    <div style={{display:'flex',marginTop:'2%',marginLeft:'2%',marginRight:'2%',paddingBottom:'1%'}}>
      <Input maxLength="30" style={{width:'49%',marginRight:'2%'}} placeholder="Tlf Celular" name="txtTlfCel" id="txtTlfCel" value={txtTlfCelState} 
        onChange={(e)=>set_txtTlfCelState(e.target.value)}
      />
      <Input maxLength="30" style={{width:'49%'}} placeholder="Tlf WhatsApp" name="txtTlfWhat" id="txtTlfWhat" value={txtTlfWhatState} 
        onChange={(e)=>set_txtTlfWhatState(e.target.value)}  
      />
    </div>      
  </div>
)}

const prenderRadioTipoPrecio=(e)=>{
//Primero apago todos
set_p0State(false)
set_p1State(false)
set_p2State(false)
set_p3State(false)
set_p4State(false)

//prendo el que me interese
let miRadio=e.target.id
if (miRadio=="p0") set_p0State(true)
if (miRadio=="p1") set_p1State(true)
if (miRadio=="p2") set_p2State(true)
if (miRadio=="p3") set_p3State(true)
if (miRadio=="p4") set_p4State(true)
}

const prenderRadioFormaDePago=(e)=>{
  //Forma de pago/Tipo de venta
  //Primero apago todos
  set_consolState(false) 
  set_concreState(false)
  
  //prendo el que me interese
  let miRadio=e.target.id
  if (miRadio=="consol") set_consolState(true) //0 es contado solamente
  if (miRadio=="concre") set_concreState(true) //1 es contado y credito
}
  
const Precio1234=()=>{   
return (
  <div style={{paddingLeft:'2%',borderStyle:'solid', borderWidth:'thin', borderColor:'gray'}}>
    <legend>Tipo de precio</legend>
    <div>   
      <Label><Input type="radio" id="p0" value="0" name="radioPrecio" checked={p0State} 
        onClick={(e)=>prenderRadioTipoPrecio(e)}/> Precio común</Label><br/>
      <Label><Input type="radio" id="p1" value="1" name="radioPrecio" checked={p1State} 
        onClick={(e)=>prenderRadioTipoPrecio(e)}/> Precio 1</Label><br/>
      <Label><Input type="radio" id="p2" value="2" name="radioPrecio" checked={p2State} 
        onClick={(e)=>prenderRadioTipoPrecio(e)}/> Precio 2</Label><br/>
      <Label><Input type="radio" id="p3" value="3" name="radioPrecio" checked={p3State} 
        onClick={(e)=>prenderRadioTipoPrecio(e)}/> Precio 3</Label><br/>
      <Label><Input type="radio" id="p4" value="4" name="radioPrecio" checked={p4State} 
        onClick={(e)=>prenderRadioTipoPrecio(e)}/> Precio 4</Label>
    </div>            
  </div>
)}
    
const FormaDePago=()=>{   
return (
  <div style={{paddingLeft:'2%',borderStyle:'solid', borderWidth:'thin', borderColor:'gray'}}>
    <legend>Forma de pago</legend>
    <br/>
    <label><Input type="radio" id="concre" value="1" name="radioConCre" checked={concreState} style={{marginBottom:'6%'}}
      onClick={(e)=>prenderRadioFormaDePago(e)}    
    /> Contado y Crédito</label><br/>
    <label><Input type="radio" id="consol" value="0" name="radioConCre" checked={consolState} 
      onClick={(e)=>prenderRadioFormaDePago(e)}
    /> Solo Contado</label>
    <br/>       
    <br/>
  </div>
)}       
     
const TabDatosExtra=()=>{
return(
  <div id="divDatosExtra" name="divDatosExtra">
    <div style={{marginLeft:'2%',marginTop:'2%',marginBottom:'2%',width:'55%'}}>
      <Label for="txtSecundario">Secundario</Label>
      <Input maxLength="20" placeholder="Secundario" name="txtSecundario" id="txtSecundario" value={txtSecundarioState}
        onChange={(e)=>set_txtSecundarioState(e.target.value)}
      /> 
    </div>
    <center> 
      <Row style={{margin:'0%',marginBottom:'2%',}}>
        <Col xs="5">
          <Label for="comboActivo" style={{marginRight:'3%'}}>Activo</Label>
          <select name="comboActivo" id="comboActivo" disabled={(props.opcionRegistro_imc=="i") ? true : false} onChange={(e)=>set_comboActivoState(e.target.value)}>
            <option value="0" selected={(comboActivoState==0) ? true : false}>No</option>
            <option value="1" selected={(comboActivoState==1) ? true : false}>Si</option>             
          </select>
        </Col>
        <Col xs="7">
          <Label for="comboPr" style={{marginRight:'3%'}}>Parte Relacionada</Label>
          <select name="comboPr" id="comboPr" onChange={(e)=>set_comboPRState(e.target.value)}>
            <option value="0" selected={(comboPRState==0) ? true : false}>No</option>
            <option value="1" selected={(comboPRState==1) ? true : false}>Si</option>             
          </select>    
        </Col>
      </Row>
    </center> 
    <Row style = {{marginLeft:'2%',marginRight:'2%',marginBottom:'2%',paddingTop:'1%',paddingBottom:'3%',borderStyle: 'solid', borderWidth:'thin', borderColor:'gray',}}>
      <legend style = {{textAlign:'center'}}> Agente de Retención</legend>
      <Col style = {{textAlign:'center'}}>
        <Label for="comboRetIva" style={{marginRight:'3%'}}>IVA</Label>
        <select name="comboRetIva" id="comboRetIva" onChange={(e)=> set_comboRetIvaState(e.target.value)}>
          <option value="0" selected={(comboRetIvaState==0) ? true : false}>No</option>
          <option value="1" selected={(comboRetIvaState==1) ? true : false}>Si</option>
        </select>
      </Col>  
      <Col style = {{textAlign:'center'}}>
        <Label for="comboRetIslr" style={{marginRight:'3%'}}>RENTA</Label>
        <select name="comboRetIslr" id="comboRetIslr" onChange={(e)=> set_comboRetRentaState(e.target.value)}>
          <option value="0" selected={(comboRetRentaState==0) ? true : false}>No</option>
          <option value="1" selected={(comboRetRentaState==1) ? true : false}>Si</option> 
        </select>
      </Col>
    </Row>
    <Row style={{margin:"0%",}}>
      <Col style={{textAlign:'right',}}>
        <Label for="txtClienteDesde">Cliente desde</Label>
      </Col>      
      <Col>
        <DatePicker id="txtClienteDesde" name="txtClienteDesde" className="anchoDatePickerGenerico" locale='es' dateFormat="dd/MM/yyyy"
          selected={ fechaEdoState }          
          onChange= {(datePar)=>set_fechaEdoState(datePar) }
        />    
      </Col>
    </Row>
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="300" placeholder="Dirección de envio" name="txtDireccionEnvio" id="txtDireccionEnvio" value={txtDireccionEnvioState} 
        onChange={(e)=>set_txtDireccionEnvioState(e.target.value)}         
      />
    </div>           
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="50" placeholder="Ciudad de envio" name="txtCiudadEnvio" id="txtCiudadEnvio" value={txtCiudadEnvioState} 
        onChange={(e)=>set_txtCiudadEnvioState(e.target.value)}         
      /> 
    </div>              
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="40" placeholder="Persona Contacto" name="txtPersonaContacto" id="txtPersonaContacto" value={txtPersonaContactoState} 
        onChange={(e)=>set_txtPersonaContactoState(e.target.value)}         
      />
    </div>            
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Input maxLength="100" style={{marginBottom:'2%'}} placeholder="Observaciones" name="txtObservaciones" id="txtObservaciones" value={txtObservacionesState} 
        onChange={(e)=>set_txtObservacionesState(e.target.value)}
      /> 
    </div>          
  </div>
)}

const validarPantallaComun=async()=>{
//arranco con la cedula, no se acepta vacio
let miTxt=document.getElementById("txtCedula").value.trim() 
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar la Identificación: Cédula/Ruc, Pasaporte...")
  return false
}
//Cuando sea Cedula/Ruc solo puede tener 10 o 13 numeros (solo digitos)
if (document.getElementById("comboNacionalidad").selectedIndex==0){
  //reviso que solo hayan numeros
  if (verificarSiTextoEsPurosDigitos(miTxt)==false){
    mostrarSwalBotonAceptar("error","ATENCION","La identificación solo debe contener numeros")
    return false
  }
  if (miTxt.length != 10 && miTxt.length != 13 ){
    mostrarSwalBotonAceptar("error","ATENCION","La identificación debe tener 10 o 13 digitos. \n Ahora tiene " + miTxt.length)
    return false
  }
  //al haber 13 digitos, reviso que termine en 001
  if (miTxt.length == 13 && miTxt.substring(10) != '001' ){
    mostrarSwalBotonAceptar("error","ATENCION","El Ruc debe terminar en 001")
    return false
  }
}
//alerto cuando el ruc este repetido
if (repetido==true){
  mostrarSwalBotonAceptar("error","ATENCION","La identificación del proveedor está repetida")
  return false
}
//Nombre (razon social)
miTxt=document.getElementById("txtNombre").value.trim()     
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","El Nombre del cliente, no puede estar vacío")
  return false
}
//FechaDesde
miTxt=document.getElementById("txtClienteDesde").value.trim()     
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","El campo Cliente desde, no puede estar vacío")
  return false
}
return true
}

const validarPantallaParaClienteNuevo=()=>{
  //se valida solamente cuando el cliente es Nuevo (por ejemplo cuando la direccion sea obligatoria, la ciudad, correo, telefonos) 
  let txtLoco=null
  //reviso si el correo es obligatorio
  txtLoco=document.getElementById("txtEmail").value.trim()
  if (valueDC.sistema.paraClientesNuevosElCorreoEsObligatorio=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","El Correo no puede estar vacío")
    return false
  }
  //reviso si la direccion es obligatoria
  txtLoco=document.getElementById("txtDireccion").value.trim()
  if (valueDC.sistema.paraClientesNuevosLaDireccionEsObligatoria=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","La Dirección no puede estar vacía")
    return false
  }  
  //reviso si la ciudad es obligatoria
  txtLoco=document.getElementById("txtCiudad").value.trim()
  if (valueDC.sistema.paraClientesNuevosLaCiudadEsObligatoria=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","La Ciudad no puede estar vacía")
    return false
  }  
  //reviso si el telefono fijo, es obligatorio.
  txtLoco=document.getElementById("txtTlfFijo").value.trim()
  if (valueDC.sistema.paraClientesNuevosElTelefonoFijoEsObligatorio=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","El teléfono fijo no puede estar vacío")
    return false
  }  
  //reviso si el telefono celular, es obligatorio.
  txtLoco=document.getElementById("txtTlfCel").value.trim()
  if (valueDC.sistema.paraClientesNuevosElTelefonoCelularEsObligatorio=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","El teléfono celular no puede estar vacío")
    return false
  }    
  //reviso si el telefono whatsApp, es obligatorio.
  txtLoco=document.getElementById("txtTlfWhat").value.trim()
  if (valueDC.sistema.paraClientesNuevosElTelefonoWhatsAppEsObligatorio=="Si" && txtLoco.length==0){  
    mostrarSwalBotonAceptar("error","ATENCION","El teléfono whatsApp no puede estar vacío")
    return false
  }    

return true
}

const prepararJsonParaDevolver=()=>{
  //despues del numero, es OBLIGATORIO dejar un espacio
  let tipoPrecio
  if (document.getElementById("p0").checked) tipoPrecio="COMUN"
  if (document.getElementById("p1").checked) tipoPrecio="1 "
  if (document.getElementById("p2").checked) tipoPrecio="2 " 
  if (document.getElementById("p3").checked) tipoPrecio="3 "              
  if (document.getElementById("p4").checked) tipoPrecio="4 "        

  jsonDevolver={}
  jsonDevolver.IDr=codigoRojoDevueltoPorSql
  jsonDevolver.IdPrincipal=document.getElementById("txtCedula").value.trim()
  jsonDevolver.IdSecundario=document.getElementById("txtSecundario").value.trim()
  jsonDevolver.RazonSocial=document.getElementById("txtNombre").value.trim()
  jsonDevolver.NombreComercial=document.getElementById("txtNombreCom").value.trim()
  jsonDevolver.PrecioUsualDef=tipoPrecio //"comun",1,2,3,4
  jsonDevolver.TipoVenta=(document.getElementById("consol").checked) ? "0" : "1" //0=>contado solamente, 1=>contado y credito
  jsonDevolver.TlfCel=document.getElementById("txtTlfCel").value.trim()
  jsonDevolver.Email=document.getElementById("txtEmail").value.trim()
  jsonDevolver.Clase=document.getElementById("txtClase").value.trim()
  jsonDevolver.Ciudad=document.getElementById("txtCiudad").value.trim()
  jsonDevolver.Activo=document.getElementById("comboActivo").value=="0" ? "No" : "Si"     
}

const guardarRegistro=async()=>{ 
  codigoRojoDevueltoPorSql=null

  if ( await validarPantallaComun()==false) return  
  if ( props.opcionRegistro_imc=='i' && validarPantallaParaClienteNuevo()==false ) return   
   
  let data=new FormData(document.getElementById("miFormularioRegistro"))
  data.append('miSol','cli_guardar')
  //Le paso la fecha en modo string (es la unica forma que pude resolver este problema)
  data.append('fechaTMP',document.getElementById("txtClienteDesde").value)
  data.append('usuario',valueDC.usuario.LoginUsu) //solo tiene utilidad/sentido al momento de NUEVO cliente
  data.append('txtID',(props.opcionRegistro_imc=="i") ? 0 : props.registroIDr) //codigo rojo del registro a editar (cuando es NUEVO se va con cero)  
  data.append('txtOpcion_imc',props.opcionRegistro_imc)
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  if (dataApi==null) return
  
  //guardo el rojo devuelto por mi api (tambien me puede llegar negativo si hay error como por ejemplo cedula duplicada)
  codigoRojoDevueltoPorSql=dataApi
}        

const buscarRepetidoEnCenso=()=>{
repetido=false
let cedRuc=document.getElementById("txtCedula").value.trim().toLowerCase()
//reviso cuando es NUEVO 
if (props.opcionRegistro_imc=='i'){
  censo.forEach( (x) => {
    if (x.IdPrincipal.trim().toLowerCase()==cedRuc) repetido = true
  })
}
//reviso cuando es EDITAR
if (props.opcionRegistro_imc=='m'){
  censo.forEach( (x) => {
    if (x.IDr != props.registroIDr && x.IdPrincipal.trim().toLowerCase()==cedRuc) repetido = true
  })
}
//resultado  
if (repetido){
  document.getElementById("txtCedula").style.background='red'
  mostrarSwalBotonAceptar("error","ATENCION","La Identificación está repetida")
}
else{
  document.getElementById("txtCedula").style.background='white'
}

return repetido
}

const leerDesdeBDD_paso1=async()=>{
  //esta funcion se usa para todos los casos Incluir/Modificar/Consultar
  //a) cuando sea 'Modificar'o Consultar:
    //En Data.RegistroBuscado me trae los datos del man
    //si en Data.RegistroBuscado viene NULL significa que no se encontro al MAN (alomejor alguien la borró desde otra PC)
  //b) cuando sea 'Incluir':
    //En Data.RegistroBuscado obviamente viene null ya que no estamos buscando a nadie
  //c) Censo
    //Cuando sea Incluir/Modificar entonces en Data.Censo viene un array de IDr y IDprincipal para revisar repeticiones
    //Cuando sea consultar entonces Data.Censo viene vacío []. Nunca null

    let data=new FormData()
    data.append('miSol','cli_buscarClienteMasCensoOpcional')    
    data.append('IDr',props.opcionRegistro_imc=='i'? 0 : props.registroIDr) //Cuando es incluir, pués no necesito buscar ningun cliente. Pero en Consultar/modificar si.
    data.append('generarCensoSN',props.opcionRegistro_imc=='c' ? "N" : "S" ) //cuando es consultar, no hace falta el censo. Pero en incluir y modificar si es necesario el censo

    let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
    //al venir NULL, => entonces el formulario debe cerrarse automaticamente
    if (dataApi==null) set_lecturaBDDsuperado(false)
    if (dataApi==null) return   

    registroBuscado=dataApi.RegistroBuscado[0] //cuando es Incluir viene null. Cuando es Modificar o Consultar si viene null es porque el cliente buscado ya no existe (alomejor lo borraron desde otra pc)
    censo=[...dataApi.Censo] //cuando es Consultar viene vacío

    //Ahora reviso que realmente venga el cliente que deseo Modificar o Consultar. Alomejor alguien lo borro desde otra computadora    
    if ( (props.opcionRegistro_imc=="m" || props.opcionRegistro_imc=="c") && registroBuscado==null ){ 
      await mostrarSwalBotonAceptar("error","ATENCION","El cliente ya no existe...")            
      set_lecturaBDDsuperado(false)
      return
    }

    //Rumbo normal.... Al llegar aquí es porque todo va bien
    if ( (props.opcionRegistro_imc=="m" || props.opcionRegistro_imc=="c") && registroBuscado!=null )  
      mostrarRegistro()       
}

const mostrarRegistro=()=>{
//tipos de identificacion: 04ruc, 05cedula,06pasaporte,07cons final,08 id del exterior

  // ======= Tipo de Identificacion, identificacion, razon social  ================
    // caso especial, cuando sea Id del exterior (08), se subdivide en 2 opciones. 08-1 Persona / 08-2 Sociedad
    if (registroBuscado.TipoIdentificacion=="08") set_comboTipoId("08-" + registroBuscado.TipoDeExtranjero)
    else set_comboTipoId(registroBuscado.TipoIdentificacion)
  set_txtCedulaState(registroBuscado.IdPrincipal)
  set_txtNombreState(registroBuscado.RazonSocial) 
  // al ser consumidor final, no se puede modificar la IDENTIFICACION, NI NOMBRE
  if (registroBuscado.TipoIdentificacion=="07"){          
    document.getElementById("comboNacionalidad").disabled = true
    document.getElementById("txtCedula").disabled = true
    document.getElementById("txtNombre").disabled = true
  }
  // ======================== Para la pestana BASICOS ========================
  set_txtNombreComState(registroBuscado.NombreComercial)
  set_txtClaseState(registroBuscado.Clase)
  set_txtEmailState(registroBuscado.Email)
  set_txtDireccionState(registroBuscado.Direccion)
  set_txtCiudadState(registroBuscado.Ciudad)
  set_txtTlfFijoState(registroBuscado.TlfFijo) 
  set_txtTlfCelState(registroBuscado.TlfCel)
  set_txtTlfWhatState(registroBuscado.TlfWhat)   
  // ============= Tipo de precio
  if (registroBuscado.PrecioUsual==0) set_p0State(true)
  if (registroBuscado.PrecioUsual==1) set_p1State(true)
  if (registroBuscado.PrecioUsual==2) set_p2State(true)
  if (registroBuscado.PrecioUsual==3) set_p3State(true)
  if (registroBuscado.PrecioUsual==4) set_p4State(true)
  // ============= Forma de pago
  if (registroBuscado.TipoVenta==0) set_consolState(true)
  if (registroBuscado.TipoVenta==1) set_concreState(true)
  //======================== Pestana EXTRAS ========================
  set_txtSecundarioState(registroBuscado.IdSecundario)
  set_comboActivoState(registroBuscado.Activo)
  set_comboPRState(registroBuscado.ParteRelacionadaSN=="S" ? "1" : "0")
  set_comboRetIvaState(registroBuscado.AgenteRetIVA)
  set_comboRetRentaState(registroBuscado.AgenteRetISLR)
  set_txtDireccionEnvioState(registroBuscado.DireccionEnvio)
  set_txtCiudadEnvioState(registroBuscado.CiudadEnvio)
  set_txtPersonaContactoState(registroBuscado.PersonaContacto)
  set_txtObservacionesState(registroBuscado.Observaciones)
  //======================== FECHA ========================
  //la fecha viene asi desde MYSQL/JSON yyyy-mm-dd siempre 10 caracteres
  let miAno=registroBuscado.FechaDesde.substring(0,4)
  let miMes=registroBuscado.FechaDesde.substring(5,7)
  let miDia=registroBuscado.FechaDesde.substring(8)    
  //OJO: Al usar el metodo DATE con 3 string, el mes de enero es 0. OJO
  set_fechaEdoState(new Date(miAno,miMes-1,miDia))
}

const prepararValoresPorDefaultParaRegistroNuevo=()=>{
// ====================== valores por default, desde preferencias de valueDC

// a) Tipo de precio 0,1,2,3,4
if (valueDC.sistema.tipoDePrecioParaClientesNuevos.includes("Com")) set_p0State(true)
if (valueDC.sistema.tipoDePrecioParaClientesNuevos.includes("1")) set_p1State(true)
if (valueDC.sistema.tipoDePrecioParaClientesNuevos.includes("2")) set_p2State(true)
if (valueDC.sistema.tipoDePrecioParaClientesNuevos.includes("3")) set_p3State(true)
if (valueDC.sistema.tipoDePrecioParaClientesNuevos.includes("4")) set_p4State(true)
// b) Tipo de venta. 0 solo contado, 1 contado y credito
if (valueDC.sistema.formaDePagoParaClientesNuevos.includes("Solo Contado")) set_consolState(true)
if (valueDC.sistema.formaDePagoParaClientesNuevos.includes("Contado y Cr")) set_concreState(true)
// c) Direccion y ciudad
set_txtDireccionState(valueDC.sistema.direccionPorDefectoParaClientesNuevos)
set_txtCiudadState(valueDC.sistema.ciudadPorDefectoParaClientesNuevos)
}                

//=========== simulo el componentDidMount
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  //caso 1: cuando se desea NUEVO registro
  if (props.opcionRegistro_imc=='i'){
    document.getElementById("tituloH1").innerText= 'NUEVO'  
    //En caso de tener Intencion de crear UN nuevo cliente desde ventas o equivalentes
    if (props.cedulaCandidata.trim().length>0) set_txtCedulaState(props.cedulaCandidata)
    prepararValoresPorDefaultParaRegistroNuevo()
  }

  //caso 2: cuando sea modificar (se oculta el div de Plan $)
  if (props.opcionRegistro_imc=='m'){
    document.getElementById("tituloH1").innerText= 'EDITAR'
  }     

  //caso 3: cuando se desea CONSULTAR. no muestro el boton guardar ni el div de Plan $
  if (props.opcionRegistro_imc=='c'){
    document.getElementById("tituloH1").innerText= 'CONSULTAR'
    document.getElementById("btnGuardar").style.visibility= 'hidden'
    //FALTA: no me desactiva al consultar
    document.getElementById("divParteFija").disabled=true
  }
  
  //Para todos los casos, hay que leer en la BDD
  leerDesdeBDD_paso1() //Si todo sale bien entonces la variable de estado lecturaBDDsuperado true/false

  //al descargar, limpio algunas variables sensibles
  return () => {
    censo=null
    registroBuscado=null
    codigoRojoDevueltoPorSql=null  
    repetido=null  
  }    
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al llamador
useEffect(()=>{  
  if (lecturaBDDsuperado==false){    
    props.ocultarModal_Cli('close',null) 
  }
  },[lecturaBDDsuperado]
)

const ComponentePrincipalBody=()=>{
return ( 
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
  <Form id="miFormularioRegistro">
  {/* DIV para el encabezado: botones de GUARDAR Y SALIR*/}
  <div id="divGuardarSalir" name="divGuardarSalir" style={{backgroundColor:'silver',paddingTop:'0', paddingBottom:"2%"}}>
    <Row style={{margin:'0',}}>
      <Col xs="4" style={{paddingTop:'2%'}}>
        <h3 id="tituloH1"></h3>
      </Col>
      <Col xs="5" style={{paddingTop:'2%',}}>
        <Button id="btnGuardar" name="btnGuardar" color="primary" solid="true" style={{width:"90%", color:'white'}}
          onClick={async()=>{          
          await guardarRegistro() 
          if (codigoRojoDevueltoPorSql==null) return //quiere decir que NO se pudo guardar el registro
          // al recibir negativo, es que hubo algun error (alomejor cedula repetida) 
          if (codigoRojoDevueltoPorSql<0){
           await mostrarSwalBotonAceptar("warning","NO SE PUDO GUARDAR","Parece que está repitiendo una cédula/ruc")
           return 
          }
          //todo cool
          prepararJsonParaDevolver() //genero un JSON vacan para devolver al llamador
          props.ocultarModal_Cli('save',jsonDevolver)                        
          }}>GUARDAR
        </Button>  
      </Col>     
      <Col xs="3" style={{textAlign:'right',paddingTop:'0',paddingRight:'0'}}> 
        <Button style={{ width: '40px', height: '40px', }} id="btnSalir" name="btnSalir" color="danger"    
          onClick={() => {
            props.ocultarModal_Cli('close',null)
          }}>
          <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
        </Button>              
      </Col>
    </Row>             
  </div> {/* divGuardarSalir */}
  { ParteFija() }
  {/* aqui creo un tab de 2 pestanas */}   
  <ModalBody>
  <Nav tabs >
    <NavItem >
      <NavLink style={{ backgroundColor:'hotpink'}}
        active= {tabActivoState == '1'}
        onClick={() => { 
          if(tabActivoState=='1') return 
          set_tabActivoState('1')
          }}>
        BASICOS
      </NavLink>
    </NavItem>
    <NavItem >
      <NavLink style={{ backgroundColor:'hotpink'}}
        active= {tabActivoState == '2'}
        onClick={() => { 
          if(tabActivoState=='2') return 
          set_tabActivoState('2')
          }}>
        EXTRA
      </NavLink>
    </NavItem>
  </Nav>

  <TabContent activeTab={tabActivoState} style={{ backgroundColor:'pink', borderStyle:'solid', borderColor:'silver',borderWidth:'medium'  }}> 
    <TabPane tabId="1">
      { TabDatosBasicos() }
    </TabPane>
    <TabPane tabId="2">
      { TabDatosExtra() }
    </TabPane>
  </TabContent>
  </ModalBody>
  </Form> {/* miFormulario */}
</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='cliente') return ComponentePrincipal()

} // del IncModCliente

export default IncModCliente
