//Desde la PHP vienen 2 columnas muy importantes: Comprobante,CONCAT(Comprobante,'_',IDr) AS ComprobanteConIDr
//La columna Comprobante solamente trae los 17 digitos
import React, {useState,useEffect} from 'react'
import {Row,Col,Jumbotron,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, {buscarCarpeta_certificadofirma,mostrarSwalUnSegundo,sleepPepe,devolverMesTextoDesdeUnNumero,enviarCorreoSriPdfMasXmlConSwal,resolverComprobanteRojoSriConSwal,hayInternet} from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,generarDocumentoXmlConSwal,firmarDocumentoXmlConSwal,recepcionValidarSriConSwal,consultarAutorizacionSriConSwal,generarDocumentoPDF_SriConSwal,generarCompraPDFconSwal} from './lib_basica'
import DataTable, { Alignment, createTheme } from 'react-data-table-component'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch,faPencilAlt,faSun, faBolt, faCalendarAlt ,faRunning,faVoteYea,faGlasses,faStickyNote,faCopy,faBan,faMoneyCheckAlt,faDoorOpen,faReceipt,faBroom,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt, faDoorClosed } from '@fortawesome/free-solid-svg-icons' 
import PedirMesYano_OK from './PedirMesYano_OK'
import RecibosEgresoDeUnDocumento from './RecibosEgresoDeUnDocumento'
import VerPdfOk from './VerPdfOk'
import MostrarPanelDeLaCompra from './MostrarPanelDeLaCompra'
import PedirClaveEspecial from './PedirClaveEspecial'
import RetenerFormulario from './RetenerFormulario' 
import RetencionesAsociadasAunaCompra from './RetencionesAsociadasAunaCompra'
//Context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let intentos=1 //me sirve para hacer 3 intentos en cada solicitud/peticion. El tope maximo viene en el context
let permanecerEnForm=null //al tratar de refrescar y no poder, entonces posiblemente me tengo que devuelver al MENU PRINCIPAL
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let mesVer=null 
let anoVer=null  
let deseoBlockSN="N" //para saber si deseo traerme el campo BlockDeNotas de proveedores (generalmente N)
let deseoOpSN="S" //para saber si deseo traerme el campo OpCrea de proveedores (generalmente S)

const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho={
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE COMPRAS'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
		},
	}, 
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender
      fontSize:'18px',   //ok
      fontWeight: 'bold'
    },
  },    
  rows: {
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  }, 
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
      //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */
    },
  },
}

export default function TabelaComprasLista(props){
const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa
const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
const [modalPedirMesAnoState,set_modalPedirMesAnoState]=useState(false)
const [modalRecibosCajaState,set_modalRecibosCajaState]=useState(false)
const [modalRetenerState,set_modalRetenerState]=useState(false)
const [modalPanelFacturaState,set_modalPanelFacturaState]=useState(false)
const [modalListarRetencionesState,set_modalListarRetencionesState]=useState(false)
const [modalPedirClaveEspecialState,set_modalPedirClaveEspecialState]=useState(false)

const filaCondicional=[
  //fila NO seleccionada
  {
    when: row => (rowRegistroState==null || row.ComprobanteConIDr != rowRegistroState.ComprobanteConIDr),
    style: row => ({ 
      backgroundColor:'White',
      color: 'dimgray', 
     }),    
  },
  //fila seleccionada    
  { 
    //al tocar una fila se debe poner azul.  
    when: row => (rowRegistroState!=null && row.ComprobanteConIDr == rowRegistroState.ComprobanteConIDr ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white', 
    }),
  },
]

const miEstructuraDataTable=[  
  {  
    name:'COMPROBANTE', //lo que el usario ve. 17 caracteres
    selector:row => row.Comprobante,
    sortable:true,
    left:true,
    grow:1.5, //es una medida relativa (1 es mi referencia)
    compact:true, //sets cell padding to 0
    omit:false, //en algunos casos, se debe ocultar algunas columnas
  },
  {  
    name:'ComprobanteConIDr',
    selector:row => row.ComprobanteConIDr, //17 caracteres mas IDr del proveedor row.ComprobanteConIDr
    sortable:false,
    left:true,
    grow:1.5, //es una medida relativa (1 es mi referencia)
    compact:true, //sets cell padding to 0
    style: {backgroundColor:'cyan'},
    omit:true,  
  },  
  {
    name:'RAZON SOCIAL',
    selector:row => row.RazonSocial,
    sortable:true,
    left:true,
    compact:true,
    grow:1.5,
  },      
  //============= BOTONES DE ACCIONES ==================
  {
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1.3,  
    cell: (row) => 
    <div style={{ display:'flex' }}>            

    {/* boton Modificar y Eliminar */}
    <Button id="btnEditar" name="btnEditar" style={{ marginRight:'1px' }} raised = "false" primary='false' color="info" 
     onClick={(e)=>{    
        alert('editar')
      }}>
      <FontAwesomeIcon size='lg' color="white" icon={faPencilAlt} />
    </Button>
    <Button id="btnEliminar" name="btnEliminar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
      onClick={()=>{
        set_idMasState(0)
        set_rowRegistroState(row)
        rowRegistroClon=row
        set_modalPedirClaveEspecialState(true)
      }}>
      <FontAwesomeIcon size='lg' color="coral" icon={faTrashAlt}/>
    </Button>              
    {/** dropdown para el boton MAS... */}
    <Dropdown id="dropMas" name="dropMas" isOpen={ row.ComprobanteConIDr==idMasState } direction="right"  
      toggle={()=>{      
        set_rowRegistroState(row) //obligatorio
        rowRegistroClon=row
        set_idMasState((idMasState == 0) ? row.ComprobanteConIDr : 0)
      }}>    
        <DropdownToggle color="info">
          <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
        </DropdownToggle>
      <DropdownMenu style={{backgroundColor:'pink',}} >            
      <DropdownItem onClick={ () => {
          if (!hayFilaSeleccionada()) return
          set_modalRetenerState(true)
      }}>
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faSun} />  Retener</DropdownItem>        
      <DropdownItem onClick={ () => gestionarVerRetencionAsociada() }><FontAwesomeIcon style={{color:'dimGray'}} icon={faBolt} />  Retencion asociada</DropdownItem>        
      <DropdownItem divider />
      <DropdownItem onClick={ () => alert('buscar texto' ) }>
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faSearch}  /> Buscar texto</DropdownItem>          
      <DropdownItem 
        onClick={()=>{
          if (!hayFilaSeleccionada()) return
          set_modalRecibosCajaState(true)
        }}>
        <FontAwesomeIcon style={{color:'dimGray'}} icon={faMoneyCheckAlt}  /> Ver recibos de caja</DropdownItem>
      <DropdownItem divider />
      <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        

      </DropdownMenu>
      </Dropdown>
    </div>,
  },
  {
    name:'FECHA',
    selector:row => row.FechaComprobanteFormat,
    sortable:true,
    left:true,
    compact:true,
    grow:0.7,  //originalmente 0.9
  },   
  {
    name:'IDENTIFIC.',
    selector:row => row.IdPrincipal,
    sortable:true,
    left:true,
    compact:true,
    grow:0.9,  
  },
  {
    name:'TOTAL',
    selector:row => row.TotalCompra,
    sortable:true,
    right:true,
    compact:true,
    grow:0.7, 
  },
  {  
    name:'TIPO',  
    selector:row => row.Tipo, //FACTURA,NOTA,LIQ
    sortable:true,
    center:true,
    grow:1, //es una medida relativa (1 es mi referencia)
    compact:true, //sets cell padding to 0
    hide:'md', 
  },      
  {
    name:'CREA',
    selector:row => row.OpCrea,
    sortable:true,
    center:true,
    compact:true,
    grow:0.25,
    hide:'sm', //se oculta en pantallas pequenas por debajo de 600px de ancho
  }, 
]

const eliminarJsonRegistro=()=>{
  let datosFullCopia=registrosFullState.filter(item=>item.ComprobanteConIDr != rowRegistroClon.ComprobanteConIDr)

  //actualizo el estado para la tabela, y quito la franja azul
  set_registrosFullState(datosFullCopia)
  set_rowRegistroState(null)
  rowRegistroClon=null
}

const refrescarData=async()=>{ 
  intentos = 1
  let seguir = true
  mostrarSwalEspera()
  while (seguir){
    let miPhpFile = buscarPhpPath() + 'Contr_Generico.php'
    let data = new FormData()
    data.append('miSol', 'compras_buscarUnMesPocasColumnas')
    data.append('deseoBlockSN',deseoBlockSN)
    data.append('deseoOpSN',deseoOpSN)   
    data.append('miMes', mesVer)
    data.append('miAno', anoVer)
    data.append('orderBy', 'FechaComprobante')
    data.append('forma', 'Desc')
    try {
      let response = await fetch(miPhpFile, { method: 'POST', body: data })
      let pepeJson = await response.json()
      //analizo la cabecera del JSON de la respuesta
      let hayError = false
      let miE = null
      if (!pepeJson.ConexionBUC) {
        hayError = true
        miE = "Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      }
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito) {
        hayError = true
        miE = "No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }
      if (hayError) {
        throw miE
      }
      //rumbo normal*********    
      set_registrosFullState(pepeJson.Data)
      set_idMasState(0)
      set_rowRegistroState(null)
      rowRegistroClon=null      
      apagarSwal()
      seguir = false
      //alert(JSON.stringify(pepeJson.Data,null,2))
    } //del try
    catch (e) {
      intentos++
      if (intentos <= valueDC.sistema.numeroDeReintentosPhp) {
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)
      }
      else {
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar, y reseteo el contador. Para intentar automaticamente 3 veces
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos = 1
        }
        else {
          //ha pulsado salir en el swal
          seguir = false
          if (!permanecerEnForm) {            
            props.activarMenu()
            desmontar()
          }
        }
      }//del else
    }//del catch
  } //del while
}
    
const hayFilaSeleccionada=()=>{ 
  //Solo valida que realmente haya UNA fila seleccionada
  let miDevolver=true  
  if (rowRegistroClon==null){
    mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Compra")          
    miDevolver=false
  }
  return miDevolver    
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //=== blanqueo estas variables 
  rowRegistroClon=null 
  permanecerEnForm=false
  //pongo el mes y ano actual
  mesVer=(new Date().getMonth()) +1 //OJO: enero es 0
  anoVer=(new Date().getFullYear()) 
  ponerMesYano()
  refrescarData()
  },[]
)

const desmontar=()=>{
  set_registrosFullState([])
  //hay que blanquear las variables que no son parte del estado. Obligatorio, ya que al volver a entrar nuevamente al formulario, entonces queda el valor de la sesion anterior  
  //Solo las mas sensibles
  rowRegistroClon=null 
}

const filtrarElementos=()=>{
  if (rowRegistroState != null) {
    set_rowRegistroState(null)                 
    rowRegistroClon=null
  }
  if (idMasState !=0) set_idMasState(0)
  let cadenaLoca=document.getElementById("inputBusqueda").value.trim().toLocaleLowerCase()  
  let filtradosTmp=registrosFullState.map(item=>{
  if (
    item.Comprobante.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.IdSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.TotalCompra.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.Condicion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.EmailProveedor.toString().toLocaleLowerCase().includes(cadenaLoca) ||
    item.OpCrea.toString().toLocaleLowerCase().includes(cadenaLoca)
    )  
    item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item    
  })
  set_registrosFullState(filtradosTmp)
} 

const eliminarRegistroEnBDD=async()=>{
  intentos=1
  let seguir=true
  mostrarSwalEspera()
  while (seguir){ 
    //Comienza el proceso para eliminar
    let miPhpFile= buscarPhpPath() + 'Contr_Generico.php'
    let data=new FormData()
    data.append('miSol','compra_Eliminar')
    data.append('miIDr',rowRegistroClon.IDr)
    data.append('miTipoDocFNL',rowRegistroClon.Tipo.substring(0,1))
    data.append('miEstab',rowRegistroClon.Estab)
    data.append('miPunto',rowRegistroClon.Punto)
    data.append('miNumFac',rowRegistroClon.NumFac)
    try{
      const mi_response=await fetch(miPhpFile, {method:"POST", body:data} )
      const pepeJson=await mi_response.json()  
  
      //analizo la cabecera del JSON de la respuesta
      let hayError=false
      let miE=null
      if (!pepeJson.ConexionBUC){
        hayError=true
        miE="Base de Datos no responde"  //no cambiar este mensaje para que coincida con otras librerias: "Base de Datos no responde"
      } 
      if (pepeJson.ConexionBUC && !pepeJson.AgrModConExito){
        hayError=true
        miE="No se pudo ejecutar la solicitud" //no cambiar este mensaje para que coincida con otras librerias: "No se pudo ejecutar la solicitud"
      }     
      if (hayError){
        throw miE      
      } 
  
      //*******por aqui, curso ideal    
      apagarSwal()
      seguir=false
      if (pepeJson.Data==-100){
        await mostrarSwalBotonAceptar("warning","NO SE PUEDE ELIMINAR","La compra fué cancelada en grupo. Se recomienda que primero elimine los recibos de caja asociados")
      }     
      if (pepeJson.Data<0 && (pepeJson.Data !=-100) ){
        await mostrarSwalBotonAceptar("info","NO SE PUDO ELIMINAR","No se pudo eliminar la Compra")
      }
      if (pepeJson.Data>0){  
        //mando a eliminar del JSON de memoria RAM
        eliminarJsonRegistro()
        //le informo al usuario que todo OK
        await mostrarSwalBotonAceptar("success","OK","La Compra se eliminó correctamente")
      }
    }
    catch(e){
      intentos++
      if (intentos<=valueDC.sistema.numeroDeReintentosPhp){      
        await sleepPepe(valueDC.sistema.milisegundosParaNuevoReintentoPhp)     
      }
      else{
        //llamo al gestor para que me aparezca la pantalla de REINTENTAR
        apagarSwal()
        let miRespSwal = await gestionarCatch(e)
        //ha pulsado reintentar
        if (miRespSwal.isDenied) {
          mostrarSwalEspera()
          intentos=1
        }
        //ha pulsado salir en el swal
        if (miRespSwal.isDismissed) {        
          // No hago nada 
          seguir=false
        }
      } //del else
    } //del catch
  } //del while
}

const copiarVariablesDesdeContext=(value)=>{
  valueDC=value
}

const gestionarVerRetencionAsociada=async()=>{
  set_modalListarRetencionesState(true)
}

const ponerMesYano=()=>{
  let miPeriodo=devolverMesTextoDesdeUnNumero(mesVer) + "-" + anoVer
  document.getElementById("miPeriodo").innerText=miPeriodo
}

const gestionarVerCompraPdf=async()=>{
  if (await hayInternet()==false) return 
  //debo generar el PDF y luego lo muestro  
  let pdfCreadoCorrectamenteSN=await generarCompraPDFconSwal(rowRegistroClon.IDr,rowRegistroClon.TipoDocFNL.substring(0,1),rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumFac,valueDC)   
  if (pdfCreadoCorrectamenteSN=="S")
    set_nombreComponenteParaVerState('verPdf')
  else
    mostrarSwalBotonAceptar("error","ATENCION","No se pudo crear el PDF correctamente")
}

const actualizarTabelaDespuesDeEliminarRecibo=(sumaDeAbonos)=>{
//esta funcion, actualiza la columna [Cancelado], solo se usa esta funcion cuando la compra es a CREDITO
//el valor sumaDeAbonos, viene como float
   
  let copiaFull=null
  copiaFull=registrosFullState.map(item=> { 
    if (item.ComprobanteConIDr==rowRegistroClon.ComprobanteConIDr) {
      let tf=parseFloat(item.TotalCompra) //total de la factura o compra
      let s= tf - sumaDeAbonos
      if (s>0){
        item.Cancelado="0"
      }
    }
    return item
    })

//refresco la tabela con los cambios    
set_registrosFullState(copiaFull)  
}

const Componente_ListaRegistros=()=>{  
return (  
<AppContextConsumer>
{value =>{  
return(  
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 

  {/* copio desde el context a una variable propia del componente  */}
  {copiarVariablesDesdeContext(value)}

  <div id="divSubMaster" name="divSubMaster">
  {/******* Boton superior de OPCIONES (utilidades) ************/}
    <Row style={{margin:'0',paddingLeft:'0px'}} >     
    <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidadesState }  size="md"
      toggle= {()=>set_dropUtilidadesState(!dropUtilidadesState)}>
      <DropdownToggle caret color="primary">
        Opciones
      </DropdownToggle>  
      <DropdownMenu style={{backgroundColor:'cyan',}} >        
        <DropdownItem onClick={()=>{
            alert(JSON.stringify(rowRegistroState,null,2))
            alert(JSON.stringify(rowRegistroClon,null,2))
        }}
        >      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> JSON de la fila seleccionada</DropdownItem>        
        <DropdownItem onClick={ () => alert('Editar seriales.. ' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Editar Serie</DropdownItem>        
        <DropdownItem onClick={ () => alert('Editar observaciones.. ' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Editar Observaciones</DropdownItem>        
        <DropdownItem divider />

      <DropdownItem divider />
      <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>
    </Dropdown>
    </Row>

    {/******* Menu superior, para telefono vertical: solo 4 botones *************/}
    <div id="divMenuSuperior" name="divMenuSuperior"> 
    <Row style={{margin:'0px',}} >
      <Col>      
      <ButtonGroup> 
       <Button id="btnVerPdf" name="btnVerPdf" className="btnPpal btnBordebtnPpal"  
         onClick={()=>{ 
          if (!hayFilaSeleccionada()) return
          gestionarVerCompraPdf()
          }}>
         <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faGlasses}/></span>
         <span><br/>Ver</span>
       </Button>
       <Button id="btnBuscarTexto" name="btnBuscarTexto" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
         onClick = { async() => { 
           //if (await hayFilaSeleccionada() ) gestionarVerFacturaSimple() 
           alert("buscar texto-contenido en compras")
           }}>
         <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faSearch}/></span>
         <span><br/>Buscar</span>
       </Button>

       <Button id="btnRetener" name="btnRetener" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
         onClick = {()=>{ 
          if (!hayFilaSeleccionada()) return
          set_modalRetenerState(true)        
         }} > 
         <span style ={{ fontSize:"35px"}}>
         <FontAwesomeIcon color="gainsboro" icon={faSun}/>
        </span>
         <span><br/>Retener</span>
       </Button>

       <Button id="btnPanel" name="btnPanel" className="btnPpal btnBordebtnPpal"  
         onClick={()=>{ 
           if (!hayFilaSeleccionada()) return
           set_modalPanelFacturaState(true)
          }}>  
         <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faVoteYea} /></span>
         <span><br/>Panel</span>          
       </Button>
       <Button id="btnRecibos" name="btnRecibos" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
          onClick={()=>{ 
            if (!hayFilaSeleccionada()) return
            set_modalRecibosCajaState(true)
          }}>  
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faMoneyCheckAlt}/></span>
          <span><br/>Recibos</span>          
       </Button>
       <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
        onClick={async()=>{ 
          permanecerEnForm=true
          if (await hayInternet()==false) return
          document.getElementById("inputBusqueda").value=''
          set_rowRegistroState(null)
          set_idMasState(0)
          rowRegistroClon=null
          mesVer=(new Date().getMonth()) +1 //OJO: enero es 0
          anoVer=(new Date().getFullYear()) 
          ponerMesYano()        
          refrescarData() 
        }}>  
        <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /></span>
        <span><br/>Refresh</span>          
       </Button>
       <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"  
          onClick = { () => { 
            props.activarMenu()
            desmontar()
          }} >  
          <span style ={{ fontSize:"35px"}}><FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /></span>
          <span><br/>Salir</span>          
       </Button>
      </ButtonGroup>          
      </Col>
    </Row>
    </div> {/* divMenuSuperior */}

    {/******* para input de busqueda y refrescar  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
        <Input
          className = "textoBusquedaClientes"
          type="text" placeholder="Texto de búsqueda" name="inputBusqueda" id="inputBusqueda" 
          onChange={()=>filtrarElementos()}
        />
        <Button  
        id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
        onClick={() => {
          set_rowRegistroState(null)
          rowRegistroClon=null
          set_idMasState(0)
          document.getElementById("inputBusqueda").value=''
          filtrarElementos()
        }}> 
        <FontAwesomeIcon color="paleGreen" icon={faBroom} />
        </Button>
        <Label id="miPeriodo" name="miPeriodo" style={{marginLeft:"10px",marginRight:"10px",marginTop:"5px",fontSize:'18px', fontWeight:'bold',color:"white" }} />
        <Button size="md" id="btnPeriodo" name="btnPeriodo" color="info" solid="true" 
         onClick={()=>set_modalPedirMesAnoState(true)}
        >Cambiar
        </Button>        
    </div> { /* divBuscador1 */ }

  </div> { /* div SubMaster */ }

  { /* inicio del DIV para la tabela */ }
  <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
    <DataTable
    //************ DATA TABLA PROPERTIES (basic) ***********/
    title='Lista de Compras'
    columns={miEstructuraDataTable}
    data={registrosFullState.filter(item=>item.VisibleSN=='S')}
    conditionalRowStyles={filaCondicional}  
    keyField ='ComprobanteConIDr' /* Se puede poner 'id' (valor por omision), 'Factura' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */   
    onRowClicked={(row) =>{
      set_rowRegistroState(row)
      rowRegistroClon=row
      set_idMasState(0)
    }}
    highlightOnHover  
    //pointerOnHover='true'
    //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
    noDataComponent = ""  //Nada por aquí
    //className='string' //override the className on the Table wrapper
    //style= object overrade the className on the Table wrapper
    //style= {{minHeight:'60vh'}} //ahora se usa dentro de estilacho
    //responsive='true' //true es el valor por defecto
    //disabled='false' //por defecto false
    //onRowClicked={miOnRowClicked()} //callback to access the row, even on row click (no pude hacerle funcionar)
    //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
    //noTableHead //oculta las cabeceras
    //************ DATA TABLA PROPERTIES (row selection) ***********/
    //selectableRows //aparece el checkbox
    //selectableRowsVisibleOnly
    //selectableRowsHighlight
    //selectableRowsNoSelectAll

    //************ DATA TABLA PROPERTIES (row expander) ***********/
    //expandableRows //interesante

    //************ DATA TABLA PROPERTIES (sorting) ***********/
    //defaultSortField

    //************ DATA TABLA PROPERTIES (pagination) ***********/
    pagination
    //paginationServer //change de default pagination to work with server pagination
    paginationPerPage={ valueDC.sistema.paginadoParaCompras } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
    paginationComponentOptions={paginacionOpciones}

    //************ DATA TABLA PROPERTIES (header) ***********/
    //actions //component or array of components
    //pepe. no poner fixedHeader
    //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
    //fixedHeaderScrollHeight = "400px" //valor por defecto 100vh 
    //subHeader //me gustaria esta opcion pero no funciona

    //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
    //theme='dark' //interesante, ya dark existe
    //theme="solarized" //muy interesante (no implementado)
    customStyles={miEstilacho} /* redefino algun estilo */
    />    { /* del componente DataTable */ }
  </div> { /* divTabela */ }

    {/************ MODAL PARA PEDIR MES Y ANO *************/}
    <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalPedirMesAnoState } >
      <PedirMesYano_OK
        cerrarModal={async(accion,mesNuevo,anoNuevo)=>{
          set_modalPedirMesAnoState(false)
          //accion puede ser: close/select 
          if (accion=='close') return
          //rumbo ideal....
          if (await hayInternet()==false) return 
          permanecerEnForm=false
          document.getElementById("inputBusqueda").value=''
          mesVer=mesNuevo
          anoVer=anoNuevo
          ponerMesYano()
          refrescarData() 
        }}
        mesActual={mesVer}
        anoActual={anoVer}
      />
    </Modal>   

    {/* ******* MODAL PARA VER UNA LISTA DE LOS RECIBOS DE CAJA ********/}
    <Modal style={{ backgroundColor:'blue',}} size={'lg'}  isOpen={ modalRecibosCajaState } >
      <RecibosEgresoDeUnDocumento
        cerrarModal={(todoOKsn,sumaDeAbonos)=> {
          //todoOKsn, es una variable para saber si cargó exitosamente la lista de recibos 
          //sumaDeAbonos: contiene la sumatoria de los abonos
          //actualizo las columnas de Cancelado (alomejor se eliminaron recibos, y se debe cambiar el estatus de Cancelado)
          if (todoOKsn=="s" && rowRegistroClon.Condicion=="CREDITO"){
            actualizarTabelaDespuesDeEliminarRecibo(sumaDeAbonos) //se eliminen o no los recibos, siempre hay que actualizar la tabela siempre y cuando la compra sea a credito
          }
          set_modalRecibosCajaState(false)
          set_idMasState(0)
        }}
        IDr={rowRegistroClon==null ? '' : rowRegistroClon.IDr}
        proveedorNombre={rowRegistroClon==null ? '' : rowRegistroClon.RazonSocial}
        doc17DG={rowRegistroClon==null ? '' : rowRegistroClon.Comprobante}      
        estabDG={rowRegistroClon==null ? '' : rowRegistroClon.Estab}
        puntoDG={rowRegistroClon==null ? '' : rowRegistroClon.Punto}
        numDG={rowRegistroClon==null ? '' : rowRegistroClon.NumFac}
        fechaDG={rowRegistroClon==null ? '' : rowRegistroClon.FechaIngresoFormat}
        montoDG={rowRegistroClon==null ? '' : rowRegistroClon.TotalCompra}
        //desde este formulario, solo pueden haber documentos del tipo FACTURA,NOTA DE VENTA,LIQUIDACION
        prefijoDG={rowRegistroClon==null ? '' : rowRegistroClon.TipoDocFNL}
        condicion={rowRegistroClon==null ? '' : rowRegistroClon.Condicion }
      />
    </Modal>
    
{/************ MODAL PARA VER EL PANEL DE LA COMPRA *************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalPanelFacturaState } >
    <MostrarPanelDeLaCompra
      cerrarModal={()=> {
        set_modalPanelFacturaState(false)
        set_idMasState(0)
      }}
      row={rowRegistroClon}      
    />
  </Modal>  
  
{/************ MODAL PARA PEDIR LA CLAVE ESPECIAL y luego eliminar la compra*************/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalPedirClaveEspecialState } >
    <PedirClaveEspecial
      claveEnBDD={valueDC.licencia.ClaveOperacionesEspeciales}      
      subtitulo={"eliminar la compra: " + (rowRegistroClon ? rowRegistroClon.Comprobante : '')}
      cerrarModal={async(claveCorrecta)=> {
        set_modalPedirClaveEspecialState(false)
        //valores posibles de claveCorrecta [true/false]
        if (!claveCorrecta) return

        if (await hayInternet()==false) return
        eliminarRegistroEnBDD()                            
      }}
    />
  </Modal>  

{/* ******* MODAL PARA HACER UNA RETENCION ********/}
  <Modal style={{ backgroundColor:'blue',}} size={'xl'}  isOpen={ modalRetenerState } >
    <RetenerFormulario
      cerrarModal_Retener={(statusCabecera,statusRetencion)=> {
        //en statusCabecera viene un boolean para saber si se pudo leer el encabezado
        //en statusRetencion viene un boolean para saber si se pudo generar la retencion
        set_modalRetenerState(false)
        set_idMasState(0)
      }}
      IDr={rowRegistroClon==null ? '' : rowRegistroClon.IDr}
      IdPrincipal={rowRegistroClon==null ? '' : rowRegistroClon.IdPrincipal}
      RazonSocial={rowRegistroClon==null ? '' : rowRegistroClon.RazonSocial}
      doc17DG={rowRegistroClon==null ? '' : rowRegistroClon.Comprobante}    
      estabDG={rowRegistroClon==null ? '' : rowRegistroClon.Estab}
      puntoDG={rowRegistroClon==null ? '' : rowRegistroClon.Punto}
      numDG={rowRegistroClon==null ? '' : rowRegistroClon.NumFac}
      fechaDGformat={rowRegistroClon==null ? '' : rowRegistroClon.FechaComprobanteFormat} //lo mando con el formatro dd-mm-aaaa
      fechaDGamd={rowRegistroClon==null ? '' : rowRegistroClon.FechaComprobante} //lo mando con el formato aaaa-mm-dd
      montoDG={rowRegistroClon==null ? '' : rowRegistroClon.TotalCompra}
      //desde este formulario, solo pueden hacer retenciones a Factura,Liquidacion (para el caso de hacerle una retencion a una nota de Debito deberia hacerse otro formulario)
      TipoDocFNL={rowRegistroClon==null ? '' : rowRegistroClon.TipoDocFNL}      
    />
  </Modal>  

{/* ******* MODAL PARA LISTAR LAS RETENCIONES ASOCIADAS********/}
  <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalListarRetencionesState } >
    <RetencionesAsociadasAunaCompra
      cerrarModal={(statusListar)=> {
        //en statusListar viene un boolean para saber si se pudo leer el listado desde la BDD
        set_modalListarRetencionesState(false)
        set_idMasState(0)
      }}
      IDr={rowRegistroClon==null ? '' : rowRegistroClon.IDr}
      IdPrincipal={rowRegistroClon==null ? '' : rowRegistroClon.IdPrincipal}
      RazonSocial={rowRegistroClon==null ? '' : rowRegistroClon.RazonSocial}
      doc17DG={rowRegistroClon==null ? '' : rowRegistroClon.Comprobante}    
      estabDG={rowRegistroClon==null ? '' : rowRegistroClon.Estab}
      puntoDG={rowRegistroClon==null ? '' : rowRegistroClon.Punto}
      numDG={rowRegistroClon==null ? '' : rowRegistroClon.NumFac}
      fechaDGformat={rowRegistroClon==null ? '' : rowRegistroClon.FechaComprobanteFormat} //lo mando con el formato dd-mm-aaaa (fecha segun proveedor)
      fechaDGamd={rowRegistroClon==null ? '' : rowRegistroClon.FechaComprobante} //lo mando con el formato aaaa-mm-dd (fecha segun proveedor)
      TipoDocFNL={rowRegistroClon==null ? '' : rowRegistroClon.TipoDocFNL}      
    />
  </Modal>  

</div> //{/*  divMaster */}
) //del return interno
}}
</AppContextConsumer>
)
} // del Componente_ListaRegistros

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return Componente_ListaRegistros()
if (nombreComponenteParaVerState=='verPdf')
  return(<VerPdfOk
    documentoTitulo={"COMPRA"}
    documentoArchivo={"COMPRA_" + rowRegistroClon.Estab + "-" + rowRegistroClon.Punto + "-" + rowRegistroClon.NumFac }
    corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
    tipoSriSN={"N"} //es un documento del sri?
    activarMenu={()=>{
      set_nombreComponenteParaVerState('lista') 
      set_idMasState(0)
    }}
  />)

} //del export
