//alert(JSON.stringify(rowRegistroState,null,2))
//***************************** importante *****************/
//Para nuevo proveedor: viene desde TabelaProveedores.js (en las props) una lista del 100% de los proveedores registrados (pero podria estar desactualizada). Los campos que me interesan son: {IDr, IdPrincipal, }
//Para editar/consultar: en ese caso se busca una lista actualizada del 100% de los proveedores para el censo (IDr e IdPrincipal) y todos los campos del registro que deseo editar (todo esto viene en DATA)

import React, {useState,useEffect} from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
//Para las pestanas TAB y otros
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap'
import buscarPhpPath,{sleepPepe,gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar,mostrarSwalConfirmacionEliminarAnular,verificarSiTextoEsPurosDigitos,ejecutarFetchGenericoConSwal } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPencilAlt,faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio

let valueDC=null //Esta variable me sirve para tener una copia de los valores del context  
let censoRegistros=null //Aqui tengo un array/censo de registros, para validar repeticiones. Solo requiero: {IDr, IdPrincipal, }
let miRegistroEdit=null //aqui voy a tener al registro que deseo editar/modificar
let codigoRojoDevueltoPorSql=null //cuando es nuevo el sql me devuelve un autonumerico. [Cuando es editar, me devuelve el mismo rojo enviado]
let repetido=null //[true,false], para saber si se esta tratando de meter un ruc repetido
let jsonDevolver={} //este objeto lo devuelvo al llamador (bien sea para nuevo o editar)
//Cuando es incluir , se debe mostrar el formulario inmediatamente, caso contrario hay que esperar hasta buscar los datos en la tabla

const IncModProveedor=(props)=>{       
const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('proveedor') //por default: 'proveedor'. Alomejor luego un componente para ver un listado de las posibles ciudades/provincias . etc
const [tabActivoState,set_tabActivoState]=useState('1') //La pestana de datos basicos
const [lecturaBDDsuperado,set_lecturaBDDsuperado]=useState(true) //Al arrancar el FORM, asumo que todo saldrá bien (al arrancar el FORM hay que leer en la BDD. Alomejor podría regrasar con null)
//para la parte fija
const [txtCedulaState,set_txtCedulaState]=useState('')  
const [txtNombreState,set_txtNombreState]=useState('')  
//para la pestana 1 (datos basicos)
const [txtNombreComState,set_txtNombreComState]=useState('')  
const [txtClaseState,set_txtClaseState]=useState('')  
const [txtEmailState,set_txtEmailState]=useState('')  
const [txtDireccionState,set_txtDireccionState]=useState('')  
const [txtCiudadState,set_txtCiudadState]=useState('')  
const [txtTlfFijoState,set_txtTlfFijoState]=useState('')  
const [txtTlfCelState,set_txtTlfCelState]=useState('')  
const [txtTlfWhatState,set_txtTlfWhatState]=useState('')  
//para la pestana 2 (datos extra)
const [txtSecundarioState,set_txtSecundarioState]=useState('')  
const [comboActivoState,set_comboActivoState]=useState(1)  //[0:false]  [1:true]
const [comboPRState,set_comboPRState]=useState(0)  //[0:false]  [1:true]
const [fechaEdoState,set_fechaEdoState]=useState(new Date()) //arranca con la fecha actual
const [txtPersonaContactoState,set_txtPersonaContactoState]=useState('')  
const [txtObservacionesState,set_txtObservacionesState]=useState('')  
 
const ParteFija=()=>{   
return (
  <div id="divParteFija" name="divParteFija" >
    <Row style={{marginTop:'2%',marginBottom:'1%',marginLeft:'0',marginRight:'0',padding:'0'}}>
      <Col xs="5">
        <Input type="select" name="comboNacionalidad" id="comboNacionalidad" disabled={true}>
          <option value="0405">Cédula/Ruc</option>          
        </Input>
      </Col>
      <Col xs="7">        
        <Input type="text" maxLength="20" placeholder="Cedula/Ruc*"  name="txtCedula" id="txtCedula" value={txtCedulaState} 
          onChange={(e)=> set_txtCedulaState(e.target.value)}
          onKeyUp={(e)=> buscarRepetidoEnCenso()}     
        />   
      </Col>
      </Row>    
      <Row style={{margin:'0',}}>
        <Col xs="12">
          <Input type="text" maxLength="100" placeholder="Nombre del Proveedor*" name="txtNombre" id="txtNombre" value={txtNombreState} 
            onChange={(e)=>set_txtNombreState(e.target.value) }           
        />
        </Col>
      </Row>
  </div> 
)}    

const TabDatosBasicos=()=>{   
return (
 <div id="divDatosBasicos" name="divDatosBasicos">
    <div style={{width:'96%',marginTop:'2%',marginBottom:'2%',marginLeft:'2%',marginRight:'2%'}}>
        <Label for="txtNombreCom">Nombre Comercial</Label>
        <Input maxLength="100" placeholder="Nombre Comercial" name="txtNombreCom" id="txtNombreCom" value={txtNombreComState} 
          onChange={(e)=>set_txtNombreComState(e.target.value)}               
        />
    </div>
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
        <Input maxLength="40" placeholder="Clase" name="txtClase" id="txtClase" value={txtClaseState} 
          onChange={(e)=>set_txtClaseState(e.target.value)}
        />
    </div>            
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
        <Input maxLength="150" placeholder="Correo" name="txtEmail" id="txtEmail" value={txtEmailState} 
          onChange={(e)=>set_txtEmailState(e.target.value)}
        /> 
    </div>              
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
        <Input maxLength="300" placeholder="Dirección" name="txtDireccion" id="txtDireccion" value={txtDireccionState} 
          onChange={(e)=>set_txtDireccionState(e.target.value)}
        />
    </div>          
    <div style={{display:'flex',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
        <Input maxLength="50" style={{width:'40%',marginRight:'5%'}} placeholder="Ciudad" name="txtCiudad" id="txtCiudad" value={txtCiudadState} 
          onChange={(e)=>set_txtCiudadState(e.target.value)}
        />
        <Input maxLength="30" style={{width:'55%',}} placeholder="Tlf Fijo" name="txtTlfFijo" id="txtTlfFijo" value={txtTlfFijoState} 
          onChange={(e)=>set_txtTlfFijoState(e.target.value)}
        />
    </div>          
    <div style={{display:'flex',marginTop:'2%',marginLeft:'2%',marginRight:'2%',paddingBottom:'1%'}}>
        <Input maxLength="30" style={{width:'49%',marginRight:'2%'}} placeholder="Tlf Celular" name="txtTlfCel" id="txtTlfCel" value={txtTlfCelState} 
          onChange={(e)=>set_txtTlfCelState(e.target.value)}
        />
        <Input maxLength="30" style={{width:'49%'}} placeholder="Tlf WhatsApp" name="txtTlfWhat" id="txtTlfWhat" value={txtTlfWhatState} 
          onChange={(e)=>set_txtTlfWhatState(e.target.value)}  
        />
    </div>
  </div>
)}

const TabDatosExtra=()=>{
return(
  <div id="divDatosExtra" name="divDatosExtra">
    <div style={{marginLeft:'2%',marginTop:'2%',marginBottom:'2%',width:'55%'}}>
        <Label for="txtSecundario">Secundario</Label>
        <Input maxLength="20" placeholder="Secundario" name="txtSecundario" id="txtSecundario" value={txtSecundarioState}
          onChange={(e)=>set_txtSecundarioState(e.target.value)}
        /> 
    </div>
    <center> 
      <Row style={{margin:'0%',marginBottom:'2%',}}>
        <Col xs="5">
          <Label for="comboActivo" style={{marginRight:'3%'}}>Activo</Label>
            <select name="comboActivo" id="comboActivo" disabled={(props.opcionRegistro_imc=="i") ? true : false} onChange={(e)=> set_comboActivoState(e.target.value)}>
              <option value="0" selected={(comboActivoState==0) ? true : false}>No</option>
              <option value="1" selected={(comboActivoState==1) ? true : false}>Si</option>             
            </select>
        </Col>
        <Col xs="7">
          <Label for="comboPr" style={{marginRight:'3%'}}>Parte Relacionada</Label>
            <select name="comboPr" id="comboPr" onChange={(e)=> set_comboPRState(e.target.value)}>
              <option value="0" selected={(comboPRState==0) ? true : false}>No</option>
              <option value="1" selected={(comboPRState==1) ? true : false}>Si</option>             
            </select>    
        </Col>
      </Row>
    </center> 
    <Row style={{margin:"0%",}}>
      <Col style={{textAlign:'right',}}>
          <Label for="txtProveedorDesde">Proveedor desde</Label>
      </Col>      
      <Col>
        <DatePicker id="txtProveedorDesde" name="txtProveedorDesde" className="anchoDatePickerGenerico" locale='es' dateFormat="dd/MM/yyyy"
          selected={ fechaEdoState }          
          onChange= {(datePar)=>set_fechaEdoState(datePar) }
        />    
      </Col>
    </Row>
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Label for="txtPersonaContacto">Persona Contacto</Label>
      <Input maxLength="40" placeholder="Persona Contacto" name="txtPersonaContacto" id="txtPersonaContacto" value={txtPersonaContactoState} 
        onChange={(e)=>set_txtPersonaContactoState(e.target.value)}         
      />
    </div>            
    <div style={{width:'96%',marginTop:'2%',marginLeft:'2%',marginRight:'2%'}}>
      <Label for="txtObservaciones">Observaciones</Label>
      <Input maxLength="100" style={{marginBottom:'2%'}} placeholder="Observaciones" name="txtObservaciones" id="txtObservaciones" value={txtObservacionesState} 
        onChange={(e)=>set_txtObservacionesState(e.target.value)}
      />
    </div>                
  </div>  
)
}

const validarPantallaComun=async()=>{
//arranco con la cedula, no se acepta vacio
let miTxt=document.getElementById("txtCedula").value.trim() 
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar la Cédula o Ruc")
  return false
}
//reviso que solo hayan digitos en la cedula (no se acepta decimales)
if (verificarSiTextoEsPurosDigitos(miTxt)==false){
  mostrarSwalBotonAceptar("error","ATENCION","La identificación solo debe contener numeros")
  return false
}
//al no tener 10 o 13 numeros, debo preguntar si desea avanzar 
if (miTxt.length != 10 && miTxt.length != 13 ){
  let textoSeguir="La identificación debe tener 10 o 13 digitos. \n Ahora tiene " + miTxt.length + ". \n Desea seguir ?"
  const miResp = await mostrarSwalConfirmacionEliminarAnular('error',textoSeguir)
  if (miResp.isDismissed) //alomejor no quiere avanzar
    return false
}
//al haber 13 digitos, reviso que termine en 001
if ( miTxt.length == 13 && miTxt.substring(10) != '001' ){
  mostrarSwalBotonAceptar("error","ATENCION","El Ruc debe terminar en 001")
  return false
}
//alerto cuando el ruc este repetido
if (repetido==true){
  mostrarSwalBotonAceptar("error","ATENCION","La identificación del proveedor está repetida")
  return false
}
//Nombre (razon social)
miTxt=document.getElementById("txtNombre").value.trim()     
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","El Nombre del proveedor, no puede estar vacío")
  return false
}
//FechaDesde
miTxt=document.getElementById("txtProveedorDesde").value.trim()     
if (miTxt.length==0){
  mostrarSwalBotonAceptar("error","ATENCION","El campo Proveedor desde, no puede estar vacío")
  return false
}
return true
}

const prepararJsonParaDevolver=()=>{
  jsonDevolver={}
  jsonDevolver.IDr=codigoRojoDevueltoPorSql
  jsonDevolver.IdPrincipal=document.getElementById("txtCedula").value.trim()
  jsonDevolver.IdSecundario=document.getElementById("txtSecundario").value.trim()
  jsonDevolver.RazonSocial=document.getElementById("txtNombre").value.trim()
  jsonDevolver.NombreComercial=document.getElementById("txtNombreCom").value.trim()
  jsonDevolver.TlfCel=document.getElementById("txtTlfCel").value.trim()
  jsonDevolver.Email=document.getElementById("txtEmail").value.trim()
  jsonDevolver.Clase=document.getElementById("txtClase").value.trim()
  jsonDevolver.Ciudad=document.getElementById("txtCiudad").value.trim()
  jsonDevolver.Activo=document.getElementById("comboActivo").value=="0" ? "No" : "Si"
}

const guardarRegistro=async()=>{ 
  codigoRojoDevueltoPorSql=null
  if ( await validarPantallaComun()==false) return  

  let data=new FormData(document.getElementById("miFormularioRegistro"))
  data.append('miSol','proveedor_guardar')
  //Le paso la fecha en modo string (es la unica forma que pude resolver este problema)
  data.append('fechaTMP',document.getElementById("txtProveedorDesde").value)
  data.append('usuario',valueDC.usuario.LoginUsu) //solo tiene utilidad/sentido al momento de NUEVO  
  data.append('txtID',(props.opcionRegistro_imc=="i") ? 0 : props.registroIDr) //codigo rojo del registro a editar (cuando es NUEVO se va con cero)  
  data.append('txtOpcion_imc',props.opcionRegistro_imc)    
  
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  if (dataApi==null) return
  
  //guardo el rojo devuelto por mi api (tambien me puede llegar negativo si hay error como por ejemplo ruc duplicado)
  codigoRojoDevueltoPorSql=dataApi  
}

const crearCensoRegistrosCuandoEsNuevo=(miTodosJson)=>{
//hago un censo. es valido solamente cuando deseo crear un NUEVO registro
censoRegistros=null //lo limpio
censoRegistros=miTodosJson.map( registro => { 
  let tmp = {
  "IDr": registro.IDr,
  "IdPrincipal": registro.IdPrincipal,
  }
  return tmp
  })
}

const buscarRepetidoEnCenso=()=>{
repetido=false
let cedRuc=document.getElementById("txtCedula").value.trim().toLowerCase()
//reviso cuando es NUEVO 
if (props.opcionRegistro_imc=='i'){
  censoRegistros.forEach(x=>{
    if (x.IdPrincipal.trim().toLowerCase()==cedRuc) repetido = true
  })
}
//reviso cuando es EDITAR
if (props.opcionRegistro_imc=='m'){
  censoRegistros.forEach(x=>{
    if (x.IDr != props.registroIDr && x.IdPrincipal.trim().toLowerCase()==cedRuc) repetido = true
  })
}
//resultado  
if (repetido){
  document.getElementById("txtCedula").style.background='red'
  mostrarSwalBotonAceptar("error","ATENCION","La Identificación está repetida")
}
else{
  document.getElementById("txtCedula").style.background='white'
}

return repetido
}

const gestionarBuscarRegistro=async()=>{
//esta funcion solo se usa cuando sea Modificar o Consultar
//a) cuando sea 'Modificar':  
  //La idea es, traerme desde la BDD, todas las filas (pero solo con los campos IDr, IdPrincipal para el censo)
  //Ademas me traigo todos los campos pero SOLO del registro que deseo editar
//b) cuando se 'Consultar':
  //Me traigo todos los campos pero SOLO del registro que deseo consultar

  let data=new FormData()
  data.append('miSol',(props.opcionRegistro_imc=='m') ? 'proveedor_buscarProveedorParaEditarMasCenso' : 'proveedor_buscarxid')
  data.append('IDr',props.registroIDr)  

    let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
    //al venir NULL, => entonces el formulario debe cerrarse automaticamente
    if (dataApi==null) set_lecturaBDDsuperado(false)
    if (dataApi==null) return      

    //rumbo ideal. hubo conexion. Pero debo revisar quizá el producto ya no existe (quizá fué casualmente eliminado dede otra pc)
    miRegistroEdit=dataApi[0] //extraigo el producto a ver/editar

    if (miRegistroEdit==null){
      await mostrarSwalBotonAceptar("error","ATENCION","El proveedor ya no existe...")            
      set_lecturaBDDsuperado(false)
      return
    } 
    //elimino del JSON la posicion 0 y obtengo el censo [aplica solo para el caso a) osea en modificacion]
    if (miRegistroEdit!=null && props.opcionRegistro_imc=='m'){
      dataApi.splice(0,1)
      censoRegistros=dataApi //creo el censo, simplemente copiando desde DATA al censo  
    }   
    
    //muestro el registro
    mostrarRegistro()    
  }

const mostrarRegistro=()=> {
  //=============== Parte de arriba (fija) ==================
  set_txtCedulaState(miRegistroEdit.IdPrincipal)
  set_txtNombreState(miRegistroEdit.RazonSocial)   
  // ======================== Para la pestana BASICOS ========================
  set_txtNombreComState(miRegistroEdit.NombreComercial)
  set_txtClaseState(miRegistroEdit.Clase)
  set_txtEmailState(miRegistroEdit.Email)
  set_txtDireccionState(miRegistroEdit.Direccion)
  set_txtCiudadState(miRegistroEdit.Ciudad)
  set_txtTlfFijoState(miRegistroEdit.TlfFijo) 
  set_txtTlfCelState(miRegistroEdit.TlfCel)
  set_txtTlfWhatState(miRegistroEdit.TlfWhat)  
  //======================== Pestana EXTRAS ========================
  set_txtSecundarioState(miRegistroEdit.IdSecundario)
  set_comboActivoState(miRegistroEdit.Activo)
  set_comboPRState(miRegistroEdit.ParteRelacionadaSN=="S" ? "1" : "0")
  set_txtPersonaContactoState(miRegistroEdit.PersonaContacto)
  set_txtObservacionesState(miRegistroEdit.Observaciones)
  //======================== FECHA ========================
  //la fecha viene asi desde MYSQL/JSON yyyy-mm-dd siempre 10 caracteres
  let miAno=miRegistroEdit.FechaDesde.substring(0,4)
  let miMes=miRegistroEdit.FechaDesde.substring(5,7)
  let miDia=miRegistroEdit.FechaDesde.substring(8)    
  //OJO: Al usar el metodo DATE con 3 string, el mes de enero es 0. OJO
  set_fechaEdoState(new Date(miAno,miMes-1,miDia))
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{  
  //caso 1: cuando se desea NUEVO registro
  if (props.opcionRegistro_imc=='i'){
    document.getElementById("tituloH1").innerText= 'NUEVO'  
    crearCensoRegistrosCuandoEsNuevo(props.registrosFull) //creo un censo con los datos que vienen de la tabela (posiblemente esté desactualizada si otro usuario está metiendo datos desde otro dispositivo)
     //En caso de tener Intencion de crear UN nuevo proveedor desde Compras o equivalentes
     if (props.cedulaCandidata.trim().length>0){
      set_txtCedulaState(props.cedulaCandidata)
     }
  }
  //caso 2: cuando se desea EDITAR/CONSULTAR el registro  
  if (props.opcionRegistro_imc=='m' || props.opcionRegistro_imc=='c' ){
    //cuando sea CONSULTAR, no muestro el boton guardar
    if (props.opcionRegistro_imc=='c'){
      document.getElementById("btnGuardar").style.visibility= 'hidden'
      //FALTA: no me desactiva al consultar
      document.getElementById("divParteFija").disabled=true
    }
    document.getElementById("tituloH1").innerText= (props.opcionRegistro_imc=='m') ? 'EDITAR' : 'CONSULTAR'
    gestionarBuscarRegistro() //ahi se obtienen los datos del proveedor que deseo editar y una lista (actualizada) para el censo
  }   
  //al descargar, limpio algunas variables sensibles
  return () => {
    censoRegistros=null
    miRegistroEdit=null
    codigoRojoDevueltoPorSql=null  
    repetido=null  
  }      
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al llamador
useEffect(()=>{  
  if (lecturaBDDsuperado==false){    
    props.ocultarModal("close",null)
  }
  },[lecturaBDDsuperado]
)

const ComponentePrincipalBody=()=>{
return(    
<div id="divMaster" name="divMaster" style={{backgroundColor:'WhiteSmoke',}}>
<Form id="miFormularioRegistro">
{/* DIV para el encabezado: botones de GUARDAR Y SALIR*/}
<div id="divGuardarSalir" name="divGuardarSalir" style={{backgroundColor:'silver',paddingTop:'0', paddingBottom:"2%"}}>
  <Row style={{margin:'0',}}>
    <Col xs="4" style={{paddingTop:'2%'}}>
         <h3 id="tituloH1"></h3>
    </Col>
    <Col xs="5" style={{paddingTop:'2%',}}>
      <Button id="btnGuardar" name="btnGuardar" color="primary" solid="true" style={{width:"90%", color:'white'}}
        onClick={async()=>{
          await guardarRegistro() 
          if (codigoRojoDevueltoPorSql==null) return //quiere decir que NO se pudo guardar el registro
          // al recibir negativo, es que hubo algun error (alomejor cedula repetida) 
          if (codigoRojoDevueltoPorSql<0){
           await mostrarSwalBotonAceptar("warning","NO SE PUDO GUARDAR","Parece que está repitiendo una cédula/ruc")
           return 
          }
          //todo cool
          prepararJsonParaDevolver() //genero un JSON vacan para devolver al llamador
          props.cerrarModal('save',jsonDevolver)                  
        }}>GUARDAR
      </Button>      
    </Col>  
    <Col xs="3" style={{textAlign:'right',paddingTop:'0',paddingRight:'0'}}> 
      <Button style={{ width: '40px', height: '40px', }} id="btnSalir" name="btnSalir" color="danger"    
        onClick= { () => {           
         props.cerrarModal('close',null)
        }}>      
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} />
      </Button>              
    </Col>
  </Row>             
</div> {/* divGuardarSalir */}
 { ParteFija() }
 
{/* aqui creo un tab de 2 pestanas */}   
<ModalBody> 
<Nav tabs >
  <NavItem >
    <NavLink style={{ backgroundColor:'hotpink'}}
      active= {tabActivoState == '1'}
      onClick={() => { 
        if(tabActivoState=='1') return 
        set_tabActivoState('1')
        }}>
      BASICOS
    </NavLink>
  </NavItem>
  <NavItem >
    <NavLink style={{ backgroundColor:'hotpink'}}
      active= {tabActivoState == '2'}
      onClick={() => { 
        if(tabActivoState=='2') return 
        set_tabActivoState('2')
        }} >
      EXTRA
    </NavLink>
  </NavItem>
</Nav>

<TabContent activeTab={tabActivoState} style={{ backgroundColor:'pink', borderStyle:'solid', borderColor:'silver',borderWidth:'medium'  }}> 
   <TabPane tabId="1">
     { TabDatosBasicos() }
   </TabPane>
   <TabPane tabId="2">
     { TabDatosExtra() }
   </TabPane>
</TabContent>
</ModalBody>
 
</Form> {/* miFormulario */}
</div> // divMaster
)}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='proveedor') return ComponentePrincipal()

} //del IncModProveedor

export default IncModProveedor
