//permite editar un %
//recibe un double y devuelve un double redondeado comunmente a 2 decimales (la cantidad de decimales para aproximar viene dado en un parametro)
//recibe otros parametros como: titulo, subtitulo, obviamente el valor de % viejo, color, cantidad de decimales para aproximar y una bandera para saber si se acepta CERO
import React from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,sleepPepe } from './lib_basica'
import { aceptarSoloEnteros,aceptarDecimales } from './lib_basica'

export default class PedirDescuentoP extends React.Component {   

cambiarPorcentajeYsalir=async()=>{
  //Se acepta 100 exactamente
  //no se acepta por encima de 100
  //se usa 2 decimales
  let miX=document.getElementById('txtPorc').value
  miX=parseFloat(miX)

  if (isNaN(miX)) {
    mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un número válido")
    return
  }

  miX=miX.toFixed(this.props.mi234Decimales)  //lo convierto a texto con n decimales  (puede ser 2,3,4)
  miX=parseFloat(miX)

  //verifico que no meta negativos
  if (miX<0){
    mostrarSwalBotonAceptar("error","ATENCION","No se acepta negativos")
    return
  }

  //verifico si se acepta CERO
  if (this.props.miAceptaCero==false && miX==0){
    mostrarSwalBotonAceptar("error","ATENCION","No se acepta cero")
    return
  }

  //verifico que no se pase de 100
  if (miX>100){
    mostrarSwalBotonAceptar("error","ATENCION","Porcentaje muy alto")
    return
  }

  if (miX==100){
    let miProductoEnviar="Seguro que desea indicar 100% ?"
    let miResp = await mostrarSwalConfirmacionEliminarAnular('ATENCION',miProductoEnviar)
    if (miResp.isDismissed) return //alomejor se arrepintio    
  }
    
  this.props.cerrarModal(true,miX) //le mando miX como numero, ya redondeado a 2 decimales
}

componentDidMount(){  
 //escribo el valor viejo
  document.getElementById("txtPorc").value= this.props.miValorOriginal

  setTimeout(()=>{ 
    document.getElementById("txtPorc").focus()  
  },1)  
}
  
render(){
return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Nombre del producto + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{this.props.miTitulo}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ this.props.cerrarModal(false,null)}} >
        <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 
  <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:this.props.miColorFondo,color:'pink'}}>
    <Row style={{margin:'0'}}>
      <Col xs="7">
        <Label for="txtPorc">{this.props.miSubTitulo}</Label>
        <Input type="number" inputMode="decimal" style={{textAlign:'center', fontWeight: 'bold', height:'80px',fontSize:'50px' }} name="txtPorc" id="txtPorc" maxLength="9" 
        onKeyPress = {(e) => { 
          aceptarDecimales(e) 
          let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
          if ( miKey ==13) 
              this.cambiarPorcentajeYsalir()
        }}/>
      </Col>
      <Col xs="5" style={{textAlign:'center',}}>    
      <Button style={{width:'120px', height:'120px',fontSize:'60px',}} id="btnEnter" name="btnEnter" color="success" 
        onClick= {()=> this.cambiarPorcentajeYsalir() }>
        <FontAwesomeIcon color="purple" icon={faCheck} /> 
      </Button>
      </Col>         
    </Row>  
    </div> {/* divBloque1 */}
</div> // divMaster
)}
}