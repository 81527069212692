import React from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import { Row, Col} from 'reactstrap'
import buscarPhpPath, { sleepPepe } from './lib_basica'
import {aceptarSoloEnteros,aceptarDecimales,verificarSiEsNumeroEnteroSolamente,verificarSiEsNumeroEnteroMayorQueCero,hayInternet } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalUnSegundo,mostrarConfirmacionEliminarAnular,mostrarSwalReintentar } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleDown, faWindowClose ,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
//************** React-DatePicker ******/
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from 'date-fns/locale/es' //'es' sirve para que los nombres de los meses salgan en espanol
registerLocale('es',es) //obligatorio

export default class PedirCondicionesPresupuesto extends React.Component {   
  state = { 
    fechaEmision:null, //esta fecha, realmente viene desde el llamador
  }     
    
nuevaImprimirCondiciones=null //[1/0], 
nuevaAlContado10=null  //[1/0]
nuevaPlazo=null //entero, 3 digitos maximo es decir 999
nuevaValidezDias=null //entero, 3 digitos maximo es decir 999
nuevaTiempoEntregaDias=null //entero, 3 digitos maximo es decir 999
nuevaGarantia=null //texto [puede ser vacio]
valueDC=null //Esta variable me sirve para tener una copia de los valores del context  

mostrarOcultarDiasCredito=()=>{
  if (this.nuevaAlContado10=='1'){
    document.getElementById("divPrendeApaga").style.visibility= 'hidden'  
  }
  else{
    document.getElementById("divPrendeApaga").style.visibility= 'visible '  
  }
}

validarPantalla () {
this.nuevaPlazo=0
//empiezo con el radio de credito

if (this.nuevaAlContado10=='0'){
  if (!verificarSiEsNumeroEnteroMayorQueCero(document.getElementById("txtPlazo"))){
    mostrarSwalBotonAceptar("error","ATENCION","Los dias de crédito debe ser un valor mayor que cero")
    return false
  }

  //La cantidad de dias, es maximo 999 
  this.nuevaPlazo=parseInt(document.getElementById("txtPlazo").value.trim(),10)
  if (this.nuevaPlazo >999){
    mostrarSwalBotonAceptar("error","ATENCION","Los dias de crédito solo acepta hasta 999")
    return false
  }
}  

//oferta valida por, maximo 999
if (!verificarSiEsNumeroEnteroMayorQueCero(document.getElementById("txtValidez"))){
  mostrarSwalBotonAceptar("error","ATENCION","La oferta válida, debe tener un numero mayor que cero")
  return false
}

this.nuevaValidezDias=parseInt(document.getElementById("txtValidez").value.trim(),10)
if (this.nuevaValidezDias >999){
  mostrarSwalBotonAceptar("error","ATENCION","El valor máximo para la oferta válida es 999")
  return false
}

//tiempo de entrega, maximo 999 (se acepta CERO, Cero significa entrega inmediata)
if (!verificarSiEsNumeroEnteroSolamente(document.getElementById("txtTiempoEntrega"))){
  mostrarSwalBotonAceptar("error","ATENCION","El tiempo de entrega debe tener un valor numérico. Use 0 para entrega inmediata")
  return false
}

this.nuevaTiempoEntregaDias=parseInt(document.getElementById("txtTiempoEntrega").value.trim(),10)
if (this.nuevaTiempoEntregaDias <0){
  mostrarSwalBotonAceptar("error","ATENCION","El tiempo de entrega debe tener un valor positivo. Use 0 para entrega inmediata")
  return false
}

//aqui todo esta OK. Entonces actualizo los valores que voy a devolver al llamador
this.nuevaImprimirCondiciones=(document.getElementById("checkCondi").checked) ? "1" : "0"
this.nuevaGarantia=document.getElementById("txtGarantia").value.trim()

return true
}
   
componentDidMount(){
  //coloco la fecha del presupuesto
  this.setState( {fechaEmision:this.props.fechaEmision  } ) //se requiere asi, para que funcione bien el dtPicker

  this.copiarVariablesDesdeProps()
  //prendo el radio que corresponda, [contado/credito]
  if (this.nuevaAlContado10=="1"){
    document.getElementById("contado").checked= true  
  }
  else{
    document.getElementById("credito").checked= true  
  }  

  //prendo/apago el check de imprimir condiciones
  document.getElementById("checkCondi").checked= (this.nuevaImprimirCondiciones=="1")

  //contado / credito
  if (this.nuevaAlContado10=='1'){
      document.getElementById("contado").setAttribute("checked","true")
      document.getElementById("txtPlazo").value=''
  }  
  else{
    document.getElementById("credito").setAttribute("checked","true")
    document.getElementById("txtPlazo").value=this.nuevaPlazo 
  }
  this.mostrarOcultarDiasCredito() 

  //oferta valida por
  document.getElementById("txtValidez").value=this.nuevaValidezDias
  //tiempo de entrega
  document.getElementById("txtTiempoEntrega").value=this.nuevaTiempoEntregaDias
  //garantia
  document.getElementById("txtGarantia").value=this.nuevaGarantia
}

copiarVariablesDesdeContext=(value)=>{
    this.valueDC=value
}

copiarVariablesDesdeProps=()=>{
  // paso de las props a las variables propias de este formulario  
  this.nuevaImprimirCondiciones=this.props.caPiePhp.ImprimirCondiciones
  this.nuevaAlContado10=this.props.caPiePhp.AlContado
  this.nuevaPlazo=this.props.caPiePhp.Plazo //al ser de contado, entonces this.props.plazo viene VACIO. no viene con cero
  this.nuevaValidezDias=this.props.caPiePhp.ValidezDias
  this.nuevaTiempoEntregaDias=this.props.caPiePhp.TiempoEntregaDias
  this.nuevaGarantia=this.props.caPiePhp.Garantia    
}

render(){            
return (   
<AppContextConsumer>
{value =>{
return(    
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
{this.copiarVariablesDesdeContext(value)}

{/* encabezado y boton X */}
<Row style={{margin:'0',}}>
  <Col xs="10" >
    <Label id="miTitulo" name="miTitulo" style={{marginTop:'5px',fontWeight: 'bold'}}>Ultimo paso</Label>
  </Col>
  <Col xs="2" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ this.props.ocultarModal_Condiciones()  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
  </Col>
</Row>          
<Row style={{margin:'0',}}>
  <Col xs="6" style={{textAlign:'right',padding:'0',}}>
    <Label for="txtFechaEmision">Fecha de emisión:</Label>
  </Col>
  <Col xs="6">
    <DatePicker id="txtFechaEmision" name="txtFechaEmision" className="anchoDatePickerGenerico" locale='es' 
      selected={ this.state.fechaEmision } 
      onChange= {  (datePar)  => this.setState({fechaEmision:datePar}) } dateFormat="dd/MM/yyyy" />   
  </Col>
</Row>   
<div id="divMarcoTrabajo" name="divMarcoTrabajo" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'1%',padding:'1%',background:'purple', color:'pink'}}>
  <Row style={{margin:'0'}}>
    <center>
      <Button disabled id="btnCondiciones" name="btnCondiciones" color="primary" solid="true"  style={{width:"100%",marginTop:'1%',marginBottom:'2%',color:'white'}}>CONDICIONES</Button>
    </center>
    <center>
      <Label for="checkCondi" style={{color:'white',fontSize:'18px'}}>
        <Input id="checkCondi" name="checkCondi" type="checkbox" /> Agregar condiciones a la proforma
      </Label>
    </center> 
  </Row>
  <hr style={{color:'black'}}></hr>
  <Row style={{margin:'0'}}>  
    <legend >Forma de Pago</legend>
  </Row>
  <Row style={{margin:'0'}}>  
    <Col>
      <Label>
        <Input type="radio" id="contado" name="radioFormaPago" value="1"
          onClick={()=>{ 
            this.nuevaAlContado10="1"
            this.mostrarOcultarDiasCredito() 
          }}
          /> Contado</Label>
    </Col>
  </Row>
  <Row style={{margin:'0'}}>  
    <Col xs="3">
      <Label>
        <Input type="radio" id="credito" name="radioFormaPago" value="0" 
        onClick={()=>{ 
          this.nuevaAlContado10="0"
          this.mostrarOcultarDiasCredito() 
        }}
        /> Crédito </Label>
    </Col>  
    <Col xs="9">    
      <div id="divPrendeApaga" name="divPrendeApaga" style={{ visibility:'hidden' }}> {/* este div se debe ocultar cuando el pago sea al CONTADO, arranco: APAGADO */}
        <div style={{ display:'flex' }}> 
          <Label for="txtPlazo" style={{marginRight:'3%'}}>Máximo </Label>
          <Input type ="number" min="1" max="999" maxLength="3" style={{width:'24%',}} id="txtPlazo" name="txtPlazo" 
            onKeyPress = {(e) => { aceptarSoloEnteros(e) } } 
            onPaste = {(e) => e.preventDefault() }
            >
          </Input>
          <Label style={{marginLeft:'2%'}}>Dias</Label>
        </div>
      </div>  
    </Col>
  </Row>
  <hr></hr>
  <Row style={{margin:'0'}}>  
    <Col style={{paddingLeft:'2%',display:'flex'}}>    
        <Label for="txtValidez" style={{marginRight:'2%'}}>Oferta válida por</Label>
        <Input type ="number" min="1" max="999" maxLength="3" style={{width:'17%',}} id="txtValidez" name="txtValidez" 
          onKeyPress = {(e) => { aceptarSoloEnteros(e) }} 
          onPaste = {(e) => e.preventDefault() }>
        </Input>  
        <Label style={{marginLeft:'2%'}}>Dias</Label>    
    </Col>
  </Row>
  <Row style={{marginLeft:'0',marginRight:'0',marginTop:'2%',marginBottom:'0'}}>
    <Col style={{paddingLeft:'2%',display:'flex',}}>    
        <Label for="txtTiempoEntrega" style={{marginRight:'2%'}}>Tiempo de entrega</Label>
        <Input type ="number" min="1" max="999" maxLength="3" style={{width:'17%',}} id="txtTiempoEntrega" name="txtTiempoEntrega" 
          onKeyPress = {(e) => { aceptarSoloEnteros(e) }} 
          onPaste = {(e) => e.preventDefault() }
          >
        </Input>  
        <Label style={{marginLeft:'2%'}}>Dias</Label>
    </Col>
  </Row>
  <hr></hr>
  <Row style={{margin:'0',paddingBottom:'1%'}}>
    <Col style={{paddingLeft:'2%',}}>
      <Label for="txtGarantia">Garantía</Label>
      <Input maxLength="100" name="txtGarantia" id="txtGarantia"></Input>
    </Col>
  </Row>
</div> {/* divMarcoTrabajo */}

<Button style={{float:'right',marginRight:"3%",marginBottom:'1%'}} size="md" id="btnListo" name="btnListo" color="success" solid="true" 
  onClick={async()=>{               
    if (this.validarPantalla()){
      if (await hayInternet()==false) return 
      //es obligatorio que la fecha se regrese al llamador en formato STRING: en formato dd/MM/yyyy
      this.props.actualizar_caPiePhp(this.nuevaAlContado10,this.nuevaPlazo,this.nuevaValidezDias,this.nuevaTiempoEntregaDias,this.nuevaGarantia,this.nuevaImprimirCondiciones,document.getElementById('txtFechaEmision').value)      
      this.props.ocultarModal_Condiciones()
      this.props.procesarProforma()
    }
  }}>
  GUARDAR
</Button>

</div> //Div master
)
}
}
</AppContextConsumer>    
) //del return del if
}//del render
} //de la clase
