//este formulario, me permite seleccionar UN codigo de Iva
//y devuelve al llamador la fila (registro) completa. Es decir una fila con varias columnas

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import DataTable  from 'react-data-table-component'
import {hayInternet,buscarCodigosIvaRetencionConSwal } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)
let textoBusquedaClon='' //es una copia de textoBusquedaState

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE CLIENTES'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
		},
	},   
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
        minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },    
    cells: {
      style: {
        fontSize:'16px', //16px
        //el borde solo lo quiero ABAJO
        // top | right | bottom | left 
        //border-style: none solid dotted dashed;      
        borderStyle:"none none solid none", 
        borderColor:'silver',
        borderWidth:'thin',           
        //marginLeft:'20px', //funciona
        //paddingLeft: '80px', // no me funciona override the cell padding for data cells
        //paddingRight: '8px',
        //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
        //backgroundColor:'yellow', //
        //fontWeight:'bold',
        //height:'10px', /* bacan */
        //paddingTop:'0', /* no funciona */
        //paddingBottom:'0', /* no funciona */
        //margin:0, /* no me funciona */
      },
    },
}

export default function TabelaRetencionCodigosIvaBuscador(props){
const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros
const [textoBusquedaState,set_textoBusquedaState]=useState('') //para poder escribir un texto de busqueda en el textBox

const filaCondicional=[
  //fila NO seleccionada
  {
    when: row => (rowRegistroState==null || row.Codigo != rowRegistroState.Codigo ),
    style: row => ({ 
      backgroundColor:row.Activo=="Si"  ? 'white' : valueDC.sistema.coloresItemInactivo,
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (rowRegistroState !=null && row.Codigo == rowRegistroState.Codigo ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaSecundaria,
      color: 'black',
    }),
  },
]

const miEstructura=[  
  {  
    name:'IDr',
    selector:row=>row.IDr,
    sortable:true,
    center:true,
    grow:0.2,
    compact:true, //padding 0
    omit:true, //oculta (siempre) la celda
  },
  {
    name:'CODIGO',
    selector:row=>row.Codigo,
    sortable:true,
    compact:true,
    center:true,
    grow:0.1, //es una medida relativa (1 es mi referencia)
  },      
  {
    name:'ACCIONES',
    sortable:false,
    center:true,
    compact:true,
    grow:0.1, //originalmente 1.6
        
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    <Button id="btnSeleccionar" name="btnSeleccionar" style={{ marginRight:'1px',color:'white',fontWeight: 'bold' }} raised = "true" primary='false' color="info" 
      onClick={ () => { 
        set_rowRegistroState(row)
        rowRegistroClon=row
        props.cerrarModal('select',rowRegistroClon)
        desmontar() //desmontar, siempre de ultimo 
      }}>Listo
    </Button>
    </div>,
  },    
  {
    name:'DESCRIPCION',
    selector:row=>row.Descripcion,
    sortable:true,
    compact:true,
    left:true,
    grow:2,
  },    
  {
    name:'%',
    selector:row=>row.Porcentaje,
    sortable:true,
    compact:true,
    grow:0.2,
  },    
]

const refrescarData=async()=>{
//busco de la BDD todos los registros de la tabla: retenciones_codigos_iva  
//OJO: puede venir null cuando hay un error de sintaxis / o viene un json con muchas filas
let respuestaJson = await buscarCodigosIvaRetencionConSwal('Codigo','asc',valueDC)
  //evaluo la respuesta (en caso venir null me salgo)  
let salirme=false
if (respuestaJson==null){
  salirme=true
}
 if (salirme==true){
  props.cerrarModal('close',null)    
  desmontar() //de ultimo
}

if (salirme) return
//rumbo normal, hago un filtro, solo me interesan los activos
let activos=respuestaJson.filter(item=>item.Activo=="Si")
set_registrosFullState(activos)
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  refrescarData()
  //blanqueo variables sensibles, que no sean del estado
  rowRegistroClon=null  
  textoBusquedaClon=''
  },[]
)

const filtrarElementos=()=>{
  set_rowRegistroState(null)                 
  rowRegistroClon=null

  textoBusquedaClon=textoBusquedaClon.trim().toLocaleLowerCase()  
  let resultado=registrosFullState.filter(item=>{
    if (
      item.Codigo.toString().toLocaleLowerCase().includes(textoBusquedaClon) ||
      item.Descripcion.toString().toLocaleLowerCase().includes(textoBusquedaClon) ||      
      item.Porcentaje.toString().toLocaleLowerCase().includes(textoBusquedaClon) 
      )  
      item.VisibleSN='S'
    else
      item.VisibleSN='N'
    
    return item        
    })
    set_registrosFullState(resultado)
} 

const desmontar=()=>{
  set_registrosFullState([])
  rowRegistroClon=null
  textoBusquedaClon=''
}

const copiarVariablesDesdeContext=(value)=>{
 valueDC=value
}

const Componente_ListaRegistros=()=>{    
return(
<AppContextConsumer>
{value =>{
return(      
<div id="divMaster" name="divMaster" style={{width:'100%',minHeight:'80vh',background:'gray'}}>
   { copiarVariablesDesdeContext(value) } 
    {/**** barra de titulo  */}
    <div id="divBarraTitulo" name="divBarraTitulo"> 
      <Row style={{margin:'0'}}> 
        <Col xs="10">       
          <Label style={{marginTop:'5px',fontWeight: 'bold',color:"pink"}}>{"Codigos de Iva"}</Label>
        </Col>
        <Col xs="2" style={{textAlign:'right',padding:'0'}}>
          <Button style={{width:'40px', height:'40px', }} id="btnCerrar" name="btnCerrar" color="danger" 
            onClick= { () =>{ 
              props.cerrarModal('close',null) 
              desmontar() //desmontar, siempre de ultimo
            }} >
            <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
          </Button>
        </Col>
      </Row>
    </div> { /* divBarraTitulo */ }  

    {/******* para input de busqueda y refrescar  *************/}
    <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', marginLeft:'10px', }} > 
      <Input className = "textoBusquedaClientes" value={textoBusquedaState} type="text" placeholder="Texto de búsqueda" id="inputBusqueda" name="inputBusqueda"  
        onChange={(e)=>{
          set_textoBusquedaState(e.target.value)
          textoBusquedaClon=e.target.value
          filtrarElementos()
      }}/>
      <Button  
        id="btnLimpiar" name="btnLimpiar" color="secondary"  style = { {marginLeft:'5px', marginRight:'5px' }}
        onClick={()=>{
          set_textoBusquedaState('')
          textoBusquedaClon='' 
          set_rowRegistroState(null)
          rowRegistroClon=null
          filtrarElementos()    
        }}> 
        <FontAwesomeIcon color="paleGreen" icon={faBroom} style = { {marginRight:'5px' }} />
        Limpiar
      </Button>        
    </div> {/* divBuscador1 */}

    { /* ***** TABELA ******/ }
    <div id="divTabela" name="divTabela" style={{marginTop:'2%',marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
      <DataTable
        //************ DATA TABLA PROPERTIES (basic) ***********/
        //title='Codigos de Iva'
        columns={miEstructura}
        data={registrosFullState.filter(item=>item.VisibleSN=='S')}
        conditionalRowStyles={filaCondicional}  
        keyField ='Codigo' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
        //este onRowClicked, me sirve solamente para que al tocar/clickear un fila, se ponga de fondo morado u otro color
        onRowClicked={(row) => { 
          set_rowRegistroState(row)
          rowRegistroClon=row
        }}
        //striped  //Una fila de un color y la siguiente de otro color (PARA MI CASO DEBE ESTAR APAGADO)
        highlightOnHover  
        //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
        noDataComponent = ""
        //************ DATA TABLA PROPERTIES (pagination) ***********/
        pagination
        //paginationServer //change de default pagination to work with server pagination
        paginationPerPage={ valueDC.sistema.paginadoParaOtros } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
        paginationComponentOptions={paginacionOpciones}

        //************ DATA TABLA PROPERTIES (header) ***********/
        //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body

        //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
        customStyles={miEstilacho} /* redefino algun estilo */
      />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

</div> //divMaster
)
}
}
</AppContextConsumer>    
)
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return Componente_ListaRegistros()

} // del export
