import React from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,sleepPepe } from './lib_basica'
import { aceptarSoloEnteros,aceptarDecimales } from './lib_basica'
 
export default class PedirDiasCredito extends React.Component {

validarYsalir=()=>{
//validar que no sea cero   
let miCant=document.getElementById('txtCant').value
miCant=parseInt(miCant,10)

if (isNaN(miCant)) {
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un número válido")
  return
}

if (miCant<=0) {
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un número mayor que CERO")
  return
}

//miCant=Math.round(miCant*100)/100
//miCant=miCant.toFixed(2) 
//miCant=parseFloat(miCant) 
  
if (miCant > this.props.topeMaximo ) {
  mostrarSwalBotonAceptar("error","ATENCION","Los dias máximo son " + this.props.topeMaximo)
  return
}

this.props.cerrarModal(true,miCant)
}

componentDidMount(){    
  document.getElementById("txtCant").value= this.props.valorPorDefecto
  setTimeout(()=>{ 
    document.getElementById("txtCant").focus()  
  },1)
}
  
render(){ 
let descripcion40Letras=this.props.miTitulo
return (
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Titulo + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{descripcion40Letras}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ this.props.cerrarModal(false,0)  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 
  <div style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',padding:'2%',background:'dimGray', color:'pink'}}>
    <Row >
      <Col xs="7" >        
        <br/>
        <Input type="number" inputMode="decimal"  style={{textAlign:'center', fontWeight: 'bold', height:'80px',fontSize:'50px' }} name="txtCant" id="txtCant"      
        onKeyPress = {(e) => { 
          aceptarSoloEnteros(e) 
          let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
          if ( miKey ==13) 
              this.validarYsalir()
        }}/>
      </Col>

      <Col xs="5" style={{textAlign:'center',}}>    

      <Button style={{width:'120px', height:'120px',fontSize:'60px',}} id="btnEnter" name="btnEnter" color="success" 
        onClick= {()=> {
          this.validarYsalir()
        }}>
        <FontAwesomeIcon color="purple" icon={faCheck} /> 
      </Button>

      </Col>         
    </Row>  
    </div> {/* //div interno */}
</div> //div master
)}
}