//me muestra una ventanita con los 4 precios de un poducto
//se requiere como entrada en las props un JSON con todos los campos del producto. Siempre es UNO solo
//tambien se requiere el codigo de la bodega/almacen. Ya que cada bodega puede tener precios diferentes
import React from 'react'
import {Input, Label, Button, ButtonGroup, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose ,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'
 
export default class Ver4PreciosDeUnProducto extends React.Component {   
valueDC=null //Aqui hago una copia de los valores del context
//Aqui guardo los precios definitivos, segun el almacen
Pvp1Sin 
Pvp1Con 
Pvp2Sin 
Pvp2Con 
Pvp3Sin 
Pvp3Con 
Pvp4Sin 
Pvp4Con 
MiPorcIvaDef=null //aqui voy a guardar el % de iva para este producto
MiBodegaStock=null //aqui guardo el stock de la bodega

cambiarPuntoPorComa=(miTexto)=>{
  miTexto=miTexto.replace(".",",")
  return miTexto
}

componentDidMount(){  
  //pongo la descripcion
  document.getElementById("txtDes").textContent=this.props.miProd.Descripcion

  //determino el PVP segun el almacen
  switch (this.valueDC.estable.Almacen * 1) {
    case 0:
      this.Pvp1Sin=this.props.miProd.A0_P1
      this.Pvp2Sin=this.props.miProd.A0_P2
      this.Pvp3Sin=this.props.miProd.A0_P3
      this.Pvp4Sin=this.props.miProd.A0_P4
      this.MiBodegaStock=this.props.miProd.A0_Stock
      break
    case 1:
      this.Pvp1Sin=this.props.miProd.A1_P1
      this.Pvp2Sin=this.props.miProd.A1_P2
      this.Pvp3Sin=this.props.miProd.A1_P3
      this.Pvp4Sin=this.props.miProd.A1_P4
      this.MiBodegaStock=this.props.miProd.A1_Stock
      break
    case 2:
      this.Pvp1Sin=this.props.miProd.A2_P1
      this.Pvp2Sin=this.props.miProd.A2_P2
      this.Pvp3Sin=this.props.miProd.A2_P3
      this.Pvp4Sin=this.props.miProd.A2_P4
      this.MiBodegaStock=this.props.miProd.A2_Stock
      break
    case 3:
      this.Pvp1Sin=this.props.miProd.A3_P1
      this.Pvp2Sin=this.props.miProd.A3_P2
      this.Pvp3Sin=this.props.miProd.A3_P3
      this.Pvp4Sin=this.props.miProd.A3_P4      
      this.MiBodegaStock=this.props.miProd.A3_Stock
      break
    case 4:
      this.Pvp1Sin=this.props.miProd.A4_P1
      this.Pvp2Sin=this.props.miProd.A4_P2
      this.Pvp3Sin=this.props.miProd.A4_P3
      this.Pvp4Sin=this.props.miProd.A4_P4      
      this.MiBodegaStock=this.props.miProd.A4_Stock
      break      
    case 5:
      this.Pvp1Sin=this.props.miProd.A5_P1
      this.Pvp2Sin=this.props.miProd.A5_P2
      this.Pvp3Sin=this.props.miProd.A5_P3
      this.Pvp4Sin=this.props.miProd.A5_P4      
      this.MiBodegaStock=this.props.miProd.A5_Stock
      break      
    case 6:
      this.Pvp1Sin=this.props.miProd.A6_P1
      this.Pvp2Sin=this.props.miProd.A6_P2
      this.Pvp3Sin=this.props.miProd.A6_P3
      this.Pvp4Sin=this.props.miProd.A6_P4      
      this.MiBodegaStock=this.props.miProd.A6_Stock
      break      
    case 7:
      this.Pvp1Sin=this.props.miProd.A7_P1
      this.Pvp2Sin=this.props.miProd.A7_P2
      this.Pvp3Sin=this.props.miProd.A7_P3
      this.Pvp4Sin=this.props.miProd.A7_P4      
      this.MiBodegaStock=this.props.miProd.A7_Stock
      break      
    case 8:
      this.Pvp1Sin=this.props.miProd.A8_P1
      this.Pvp2Sin=this.props.miProd.A8_P2
      this.Pvp3Sin=this.props.miProd.A8_P3
      this.Pvp4Sin=this.props.miProd.A8_P4      
      this.MiBodegaStock=this.props.miProd.A8_Stock
      break      
    case 9:
      this.Pvp1Sin=this.props.miProd.A9_P1
      this.Pvp2Sin=this.props.miProd.A9_P2
      this.Pvp3Sin=this.props.miProd.A9_P3
      this.Pvp4Sin=this.props.miProd.A9_P4      
      this.MiBodegaStock=this.props.miProd.A9_Stock
      break      
  }
  //convierto los precios en Numero
  this.Pvp1Sin=parseFloat(this.Pvp1Sin)
  this.Pvp2Sin=parseFloat(this.Pvp2Sin)
  this.Pvp3Sin=parseFloat(this.Pvp3Sin)
  this.Pvp4Sin=parseFloat(this.Pvp4Sin)

  //detecto el % de iva TipoIvaVenta
  this.MiPorcIvaDef= (this.props.miProd.TipoIvaVenta==0) ? 0 : (this.valueDC.iva.Porcentaje * 1)

  //calculo el precio con iva
  this.Pvp1Con=this.Pvp1Sin + this.Pvp1Sin * this.MiPorcIvaDef /100
  this.Pvp2Con=this.Pvp2Sin + this.Pvp2Sin * this.MiPorcIvaDef /100
  this.Pvp3Con=this.Pvp3Sin + this.Pvp3Sin * this.MiPorcIvaDef /100
  this.Pvp4Con=this.Pvp4Sin + this.Pvp4Sin * this.MiPorcIvaDef /100
  
  //**********muestro en pantalla**********************
  //PVP1
  document.getElementById("txtP1Sin").value=this.cambiarPuntoPorComa(this.Pvp1Sin.toFixed(4))
  document.getElementById("txtP1Con").value=this.cambiarPuntoPorComa(this.Pvp1Con.toFixed(4))
  //PVP2
  document.getElementById("txtP2Sin").value=this.cambiarPuntoPorComa(this.Pvp2Sin.toFixed(4))
  document.getElementById("txtP2Con").value=this.cambiarPuntoPorComa(this.Pvp2Con.toFixed(4))
  //PVP3
  document.getElementById("txtP3Sin").value=this.cambiarPuntoPorComa(this.Pvp3Sin.toFixed(4))
  document.getElementById("txtP3Con").value=this.cambiarPuntoPorComa(this.Pvp3Con.toFixed(4))
  //PVP4
  document.getElementById("txtP4Sin").value=this.cambiarPuntoPorComa(this.Pvp4Sin.toFixed(4))
  document.getElementById("txtP4Con").value=this.cambiarPuntoPorComa(this.Pvp4Con.toFixed(4))      

  /****************STOCK DE LA BODEGA***************/
  let MiBodegaName= 'Stock en Bodega ' + ' [' +  this.valueDC.almacen.AliasAlm + ']: '
  document.getElementById("btnBodegaStock").innerText= MiBodegaName  + this.cambiarPuntoPorComa(this.MiBodegaStock)
}

copiarVariablesDesdeContext=(value)=>{
  this.valueDC=value
}

render(){
return (
<AppContextConsumer>
{value =>{           
return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
{this.copiarVariablesDesdeContext(value)}

{/* Codigo del producto + Boton Cerrar*/}
<Row style={{margin:'0',}}>
  <Col xs="10" >
    <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{this.props.miProd.CodPrincipal}</Label>
  </Col>
  <Col xs="2" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ this.props.cerrarModal() }}>
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
  </Col>
</Row>      

{/* Empiezo a mostrar la informacion, color purple */}
<div id="divInfo" name="divInfo"style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink'}}>    
  <Row style={{margin:'0',}}>
    <Col >     
      <Label  name="txtDes" id="txtDes" style={{width:'100%',padding:'1%', background:'purple',border: 'pink 1px solid', textAlign:'justify'}}></Label>      
    </Col>
  </Row>  
  {/* Precio 1 */}
  <Row style={{marginTop:'2%',marginBottom:'2%',marginLeft:'0',marginRight:'0'}}>
    <Col xs="6" style={{display:'flex'}}>
      <Label >PVP 1</Label>
      <Input disabled style={{textAlign:'right', width:"60%",marginLeft:"4%"}} name="txtP1Sin" id="txtP1Sin"/>
    </Col>
    <Col xs="6" style={{display:'flex'}}>
      <Label >+ IVA</Label>
      <Input disabled style={{textAlign:'right', width:"70%",marginLeft:'4%'}} id="txtP1Con" name="txtP1Con"/> 
    </Col>  
  </Row>  
  {/* Precio 2 */}
  <Row style={{marginTop:'2%',marginBottom:'2%',marginLeft:'0',marginRight:'0'}}>
    <Col xs="6" style={{display:'flex'}}>
      <Label >PVP 2</Label>
      <Input disabled style={{textAlign:'right', width:"60%",marginLeft:"4%"}} name="txtP2Sin" id="txtP2Sin"/>
    </Col>
    <Col xs="6" style={{display:'flex'}}>
      <Label >+ IVA</Label>
      <Input disabled style={{textAlign:'right', width:"70%",marginLeft:'4%'}} id="txtP2Con" name="txtP2Con"/> 
    </Col>  
  </Row>  
  {/* Precio 3 */}
  <Row style={{marginTop:'2%',marginBottom:'2%',marginLeft:'0',marginRight:'0'}}>
    <Col xs="6" style={{display:'flex'}}>
      <Label >PVP 3</Label>
      <Input disabled style={{textAlign:'right', width:"60%",marginLeft:"4%"}} name="txtP3Sin" id="txtP3Sin" />   
    </Col>
    <Col xs="6" style={{display:'flex'}}>
      <Label >+ IVA</Label>
      <Input disabled style={{textAlign:'right', width:"70%",marginLeft:'4%'}} id="txtP3Con" name="txtP3Con"/> 
    </Col>  
  </Row>  
  {/* Precio 4 */}
  <Row style={{marginTop:'2%',marginBottom:'2%',marginLeft:'0',marginRight:'0'}}>
    <Col xs="6" style={{display:'flex'}}>
      <Label >PVP 4</Label>
      <Input disabled style={{textAlign:'right', width:"60%",marginLeft:"4%"}} name="txtP4Sin" id="txtP4Sin"/>
  </Col>
  <Col xs="6" style={{display:'flex'}}>
    <Label >+ IVA</Label>
    <Input disabled style={{textAlign:'right', width:"70%",marginLeft:'4%'}} id="txtP4Con" name="txtP4Con"/> 
  </Col>  
</Row>  

{/***************************STOCK DE LA BODEGA EN CUESTION  *****************************/}
<Row style={{margin:'0',}}>
  <ButtonGroup>
    <Button style={{height:'40px',}} id="btnBodegaStock" color='success' ></Button>     
</ButtonGroup>
</Row>  

</div>  {/* divInfo */}
</div> // divMaster
) //del return interno
}}
</AppContextConsumer>
)}
} //de la clase
