//alert(JSON.stringify(rowRegistroState,null,2))
//nota importante. despues de un swal de espera (spinning) y antes de llamar al modal. se debe hacer un sleep para que no se vea el fondo verde en el lado derecho
//await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,ModalHeader,ModalBody,ModalFooter,Form,FormGroup,Button,ButtonGroup,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import buscarPhpPath, { sleepPepe,devolverFechaHoyTexto,devolverMesTextoDesdeUnNumero,enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal,tildarCampoEnviadoPorCorreoNoSriSinSwal,determinarFormatoImpresoraGrande } from './lib_basica'
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,hayInternet,ejecutarFetchGenericoConSwal } from './lib_basica'
import DataTable, { createTheme } from 'react-data-table-component'
//awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSun,faPencilAlt,faPen,faReceipt,faVoteYea,faFlag,faThumbtack,faAlignCenter,faClipboard,faRunning,faDoorOpen,faGlasses,faBroom,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import FormularioCorreo from './FormularioCorreo'
import PedirMesYano_OK from './PedirMesYano_OK'
import VerPdfOk from './VerPdfOk'
import MostrarPanelDocNoSri from './MostrarPanelDocNoSri'
import Facturacion from './Facturacion'
//Context
import {AppContextConsumer} from './ContextBase'
//Variables normales globales que No son parte del estado
let correoEnviadoCorrectamenteSN=null //para saber si se pudo enviar el correo
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //obtengo una Fila (row) clonada, completa para mandarla al PANEL y que el usuario pueda ver mas detalles en un modal. Y para multiples usos
let mesVer= null //(es el mes y ano que deso ver las proformas)
let anoVer= null 


const paginacionOpciones={
  rowsPerPageText:'Filas por Página',    
  rangeSeparatorText: 'de',
  selectAllRowsItem:true,
  selectAllRowsItemText:'Todos',
}

const miEstilacho = {
	table: {
		style: {
			minHeight: '45vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE PROFORMAS'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
		},
	},     
  headCells: {
    style: {
      //marginLeft:'50px', //ok funcion
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok
      fontSize:'18px',   //ok
      fontWeight: 'bold'
    },
  },  
  rows: {      
    style: {
      minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }, 
  },
  cells: {
    style: {
      fontSize:'16px', //16px
      //el borde solo lo quiero ABAJO
      // top | right | bottom | left 
      //border-style: none solid dotted dashed;      
      borderStyle:"none none solid none", 
      borderColor:'silver',
      borderWidth:'thin',           
      //marginLeft:'20px', //funciona
      //paddingLeft: '80px', // no me funciona override the cell padding for data cells
      //paddingRight: '8px',
      //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
      //backgroundColor:'yellow', //
      //fontWeight:'bold',
      //height:'10px', /* bacan */
      //paddingTop:'0', /* no funciona */
      //paddingBottom:'0', /* no funciona */
      //margin:0, /* no me funciona */
    },
  },
}

const filaCondicionalExterna=(filaClonada)=>[
  //fila NO seleccionada
  {
    when: row => (row.Proforma != filaClonada?.Proforma),
    style: row => ({ 
      backgroundColor:row.Favorito=="1"  ? valueDC.sistema.coloresProformaFavorita : 'white',
      color: 'dimgray', 
     }),    
  },    
  //fila seleccionada    
  {
    when: row => (row.Proforma == filaClonada?.Proforma),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaPrincipal,
      color: 'white', 
    }),
  },
]

const TabelaPresupuestosLista=(props)=>{
  //variables de estado
  const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
  const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros Osea al inico es lo que está liago a la tabela
  const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela     
  const [textoBuscarState,set_textoBuscarState]=useState('') //para el texto que va en el cuadrito de buscar
  const [dropUtilidadesState,set_dropUtilidadesState]=useState(false) //sirve para abir el drop superior izquierda de la pantalla(UTILIDADES)
  const [idMasState,set_idMasState]=useState(0) //Para poder activar el menu desplegable en el registro que me interesa. Puede tener 0 o 17 caracteres de row.Factura
  //Modales
  const [modalPedirMesYanoState,set_modalPedirMesYanoState]=useState(false) //para pedir periodo
  const [modalPanelProformaState,set_modalPanelProformaState] =useState(false) //para ver los datos ocultos de la proforma: fecha y hora,correo del cliente.....
  const [modalCorreoState,set_modalCorreoState]=useState(false) //para abrir el modal de enviar correo
  const [modalVerProformaPDF,set_modalVerProformaPDF] =useState(false) //para ver el pdf de la proforma
  
const hayFilaSeleccionada=async()=>{ 
  //solo me sirve para determinar si hay UNA fila seleccionada
  let miDevolver=true
  if (rowRegistroClon==null){
    await mostrarSwalBotonAceptar("error","ATENCION","Debe seleccionar una Proforma")          
    miDevolver=false
  }
  return miDevolver
}

const miEstructura=[  
  {  
    name:'PROFORMA', //lo que el usario ve. 
    selector:row => row.Proforma, //Tal como viene en el Json desde las Base de datos (17 caracteres)
    sortable:true,
    left:true,
    grow:1.5, //es una medida relativa (1 es mi referencia)
    compact:true, //padding 0
    omit:false, //en algunos casos, se debe ocultar algunas columnas
  },
  {
    name:'RAZON SOCIAL',
    selector:row => row.RazonSocial, 
    sortable:true,
    left:true,
    compact:true,
    grow:1.5,    
  },    
//******aqui van las ACCIONES con iconos: EDITAR, enviar x correo
{
  name:'',
  sortable:false,
  center:true,
  compact:true,
  grow:1.3, 
  //style:{ background: 'gray' },
  //width:'20%',
        
  cell: (row) => 
  <div style={{ display:'flex' }}>            
    {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
    {/* tambien da error al poner raised. se debe poner raised="true"  */}
    {/* o se puede poner raised= {miVariable.toString() }  */}

  {/* boton Editar y Enviar por correo   */}
  <Button id="btnEditar" name="btnEditar" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
    onClick={ async(e) => { 
      //me interesa primeramente que se ponga fondo azul
      set_idMasState(0)
      set_rowRegistroState(row)
      rowRegistroClon=row

      //llamo al componen de Facturacion.js     
      set_nombreComponenteParaVerState('facturar' )
    }}>
    <FontAwesomeIcon size='lg' color="white" icon={faPencilAlt}/>
  </Button>
  <Button id="btnEnviarEmail" name="btnEnviarEmail" style={{ marginRight:'1px' }} raised = "true" primary='false' color="info" 
    onClick={async(e) => {
      //me interesa primeramente que se ponga fondo azul
      set_idMasState(0)
      set_rowRegistroState(row)
      rowRegistroClon=row

      if (rowRegistroClon?.EmailCliente.trim()==''){
        mostrarSwalBotonAceptar("warning","ATENCION","El cliente no tiene correo")                          
        return
      }
      let dataApi=await gestionarCrearProformaPDF() //puede venir null o el mismo numero de Proforma enviada. Nunca viene negativo            
      if (dataApi==null) return
      if (dataApi<0) return      

      //rumbo ideal, ya se hizo el pdf
      await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
      set_modalCorreoState(true)      
    }}>
    <FontAwesomeIcon size='lg' color="white" icon={faEnvelope} />
  </Button>
        
  {/* dropdown para el boton MAS... */}
  {/* al hacer clic por segunda vez consecutiva se debe apagar el desplegable */}
  {/* NOTA: la funcion obligarRender solo la usa este DROPDOWN direction:up', 'down', 'left', 'right' */}

  <Dropdown id="dropMas" name="dropMas" isOpen={ idMasState==row.Proforma } direction="right"  
    toggle= { ()=> {
      set_rowRegistroState(row) //obligatorio
      rowRegistroClon=row
      set_idMasState((idMasState == 0) ? row.Proforma : 0)
    }}>    

    <DropdownToggle color="info">
      <FontAwesomeIcon size='lg' color="white" icon={faEllipsisH} />
    </DropdownToggle>

    <DropdownMenu style={{backgroundColor:'pink',}} >              
      <DropdownItem onClick={ () => alert('editar condiciones.. ' + row.Proforma )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faPencilAlt} /> Editar condiciones y Fecha</DropdownItem>        
      <DropdownItem onClick={ () => alert('editar observaciones.. ' + row.Proforma )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faPencilAlt} />  Editar observaciones</DropdownItem>             
      <DropdownItem onClick={ () => alert('editar texto complementario.. ' + row.Proforma )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faPencilAlt} />  Editar texto complementario</DropdownItem>             

      <DropdownItem divider />
      <DropdownItem onClick={ () => alert('ticket.. ' + row.Proforma )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faReceipt} />  Ticket</DropdownItem> 
      <DropdownItem onClick={ async() => { set_modalPanelProformaState(true) }}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faVoteYea} /> Ver panel</DropdownItem>        
      <DropdownItem onClick={ () => alert('poner favorito.. ' + row.Proforma )}> <FontAwesomeIcon style={{color:'dimGray'}} icon={faThumbtack} />  Marcar como favorito</DropdownItem> 
      <DropdownItem divider />

      <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
    </DropdownMenu>

    </Dropdown>
  </div>,

}, 
//*** fin de las ACCIONES
  {
    name:'FECHA',
    selector:row => row.FechaEmisionFormat,  
    sortable:true,
    left:true,
    compact:true,
    grow:0.7,   
  },   
  {
    name:'IDENTIFIC.',
    selector:row => row.IdPrincipal,  
    sortable:true,
    left:true,
    compact:true,
    grow:0.9,  
  },  
  {
    name:'TOTAL',
    selector:row => row.TotalPresupuesto,  
    sortable:true,
    right:true,
    compact:true,
    grow:0.7,
  }, 
  {
    name:'ExC',
    selector:row => row.EnviadoAlReceptor,  
    sortable:true,
    center:true,
    compact:true,
    grow:0.25,
    hide:'md', //oculta en pantallas medianas hacia atras
  },              
  {
    name:'CORREO',
    selector:row => row.EmailCliente,
    sortable:true,
    left:true,
    compact:true,
    grow:0.75,
    hide:'sm',
  },     
  {
    name:'CREA',
    selector:row => row.OpCrea,  
    sortable:true,
    center:true,
    compact:true,
    grow:0.25,
    hide:'sm', //se oculta en pantallas pequenas por debajo de 580px de ancho
    //hide:'md', //aparentemente oculta de md hacia atras
  },  
]

const refrescarData=async()=>{ 
  let data=new FormData()
  data.append('miSol','presupuestos_buscarUnMesPocasColumnas')
  data.append('miMes',mesVer)
  data.append('miAno',anoVer)
  data.append('orderBy','Proforma')
  data.append('forma','Desc') 

  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  set_idMasState(0)
  set_registrosFullState(dataApi)   
}

const ponerMesYanoTitulo=()=>{
  let miPeriodo=devolverMesTextoDesdeUnNumero(mesVer) 
  miPeriodo = miPeriodo + "-" + anoVer
  document.getElementById("miPeriodo").innerText=miPeriodo
}

const determinarMesAnoActual=()=>{
  mesVer= (new Date().getMonth()) + 1 //OJO: enero es 0 (es el mes y ano que deso ver las facturas)
  anoVer= (new Date().getFullYear()) 
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  determinarMesAnoActual()
  refrescarData() 

  //Al desmontar el componente, entonces limpio las variables ensibles
  return () => {
    //variables normales
    rowRegistroClon=null 
    //variables de estado
    set_registrosFullState([]) //OBLIGATORIO VACIARLO con [] para no haga colisión con el segundo useEffect
    set_rowRegistroState(null) 
  }  
  },[]
)
// *** cuando recibo NULL de mi API, entonces me devuelvo al menu principal
useEffect(()=>{  
  if (registrosFullState==null){    
    props.activarMenu() 
  }
  },[registrosFullState]
)

const eliminarPresupuestoDelJson=()=>{ 
  //luego de eliminar de la BDD, lo elimino del JSON. Asi me ahorro tener que volver a leer de la BDD
  let datosFullCopia=registrosFullState.filter(item=>{
    return (item.Proforma != rowRegistroClon.Proforma)
  })

  set_registrosFullState(datosFullCopia)  
  set_rowRegistroState(null) 
  set_idMasState(0)
  rowRegistroClon=null
} 

const actualizarUnaFilaDelJson=(
  FechaEmisionFormat_Mod,
  IdPrincipal_Mod,
  IdSecundario_Mod,
  RazonSocial_Mod,
  NombreComercial_Mod,
  TotalPresupuesto_Mod,
  NombreVen_Mod,
  NombreFac_Mod, //aun no implementado
  Condicion_Mod,
  EmailCliente_Mod,
  CodClienteRojo_Mod,
) =>{   
  //cuando voy a una pantalla de modificar un presupuesto, y regreso. Entonces debo actualizar el JSON con los nuevos valores
  //posiblemente ha cambiado el monto, fecha, cliente, vendedor, etc
  //por ahora no se esta cambiando el nombre del facturador
  //es importante que cada parametro venga en modo texto y ya formatedo con los decimales correspondientes
  
  let copiaFull=registrosFullState.map(item=>{
    if (item.Proforma==rowRegistroClon.Proforma){
      item.FechaEmisionFormat=FechaEmisionFormat_Mod
      item.IdPrincipal=IdPrincipal_Mod
      item.IdSecundario=IdSecundario_Mod
      item.RazonSocial=RazonSocial_Mod
      item.NombreComercial=NombreComercial_Mod
      item.TotalPresupuesto=TotalPresupuesto_Mod
      item.NombreVen=NombreVen_Mod
      //item.NombreFac=NombreFac_Mod //aun no implementado
      item.Condicion=Condicion_Mod
      item.EmailCliente=EmailCliente_Mod
      item.CodClienteRojo=CodClienteRojo_Mod     
    }
    return item 
  })

  set_registrosFullState(copiaFull)
  //falta simular un clic sobre la fila para que se copie en el clon. 
  //Pero parece que se hace automaticamente. Investigar este caso
} 

const filtrarElementos=(cadenaLoca)=>{
  cadenaLoca=cadenaLoca.toLocaleLowerCase()  
  
  let resultado=registrosFullState.map(item=>{  
  if (
      item.Proforma.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.FechaEmisionFormat.toString().toLocaleLowerCase().includes(cadenaLoca) ||      
      item.IdPrincipal.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.IdSecundario.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.RazonSocial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreComercial.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.TotalPresupuesto.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreVen.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.NombreFac.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.Condicion.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.EmailCliente.toString().toLocaleLowerCase().includes(cadenaLoca) ||
      item.OpCrea.toString().toLocaleLowerCase().includes(cadenaLoca)
      )  
        item.VisibleSN='S'    
      else 
        item.VisibleSN='N'    
  
    return item  
  })
  set_registrosFullState(resultado)
  set_idMasState(0)
  rowRegistroClon=null  
} 

const eliminarPresupuestoEnBDD = async () => {
  let data=new FormData()
  data.append('miSol','presupuestos_Eliminar')
  data.append('miEstab',rowRegistroClon.Estab)
  data.append('miPunto',rowRegistroClon.Punto)
  data.append('miNumPre',rowRegistroClon.NumPre)
  
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)  
  return dataApi //dominio: null,-100,numero positivo
}
  
const gestionarTildarEnvioDeCorreoEnGridMasBDD=()=>{
  //*******debo tildar en la Tabela que ya se hizo el envio. Tambien se llama a un compenente en segundo plano para que se tilde en la BDD
  //hay dos casos donde no hace falta tildar ya que coincide con lo que estaba anteriormente
  if (rowRegistroClon.EnviadoAlReceptor=="No" && correoEnviadoCorrectamenteSN=="N")
    return
  if (rowRegistroClon.EnviadoAlReceptor=="Si" && correoEnviadoCorrectamenteSN=="S")
    return
  
  let correoEnviadoRealmenteSiNo=(correoEnviadoCorrectamenteSN=="S") ? "Si" : "No"
  //OJO: Puede darse el caso que el envio del correo me quede invertido a la forma actual de la table (Es lo mas comun)
  if (correoEnviadoRealmenteSiNo != rowRegistroClon.EnviadoAlReceptor){
      let copiaFull=registrosFullState.map(item=>{
        if (item.Estab==rowRegistroClon.Estab && item.Punto==rowRegistroClon.Punto && item.NumPre==rowRegistroClon.NumPre) 
          item.EnviadoAlReceptor=correoEnviadoRealmenteSiNo

        return item
      })

      set_registrosFullState(copiaFull)
      
      //tildo en la base de datos, pero no me interesa esperar la respuesta (es en segundo plano)
      //El segundo parametro puede ser 0 o 1. Es decir puedo guardar false o true en el campo EnviadoAlReceptor
      let tildadoCorrectamenteSN=tildarCampoEnviadoPorCorreoNoSriSinSwal("PR",(correoEnviadoCorrectamenteSN=="S") ? 1 : 0,rowRegistroClon.Estab,rowRegistroClon.Punto,rowRegistroClon.NumPre,valueDC)      
  }
}

const gestionarCrearProformaPDF=async()=>{
  let miFormato=determinarFormatoImpresoraGrande(valueDC,rowRegistroClon.Estab,'FormatoProforma')
  if (miFormato==''){
    await mostrarSwalBotonAceptar("error","ATENCION","No se consigue el formato")          
    return null
  }

  let data=new FormData()
  data.append('miSol','presupuesto_gestionarGenerarPDF')
  data.append('miFormato',miFormato)
  data.append('miEstab',rowRegistroClon.Estab)
  data.append('miPunto',rowRegistroClon.Punto)
  data.append('miNumPre',rowRegistroClon.NumPre)
    
  let dataApi=await ejecutarFetchGenericoConSwal(valueDC.sistema.numeroDeReintentosPhp,valueDC.sistema.milisegundosParaNuevoReintentoPhp,data)
  //en dataApi, puede venir null o el mismo numero de Proforma enviada. Nunca viene negativo
  return dataApi
}

const ComponentePrincipalBody=()=>{
return (  
<div id="divMaster" name="divMaster" style={{ width:'100%', minHeight:'100vh', background: 'purple', paddingTop:'10px', paddingBottom:'10px', }} > 

  <div id="divSubMaster" name="divSubMaster">
  {/******* Boton superior de OPCIONES (utilidades) ************/}
    <Row style={{margin:'0',paddingLeft:'0px'}} >     
    <Dropdown style = {{marginTop:'10px',marginBottom:'10px', }}  id="dropUtilidades" name="dropUtilidades" isOpen={ dropUtilidadesState }  size="md"
      toggle= { ()=> {
        set_dropUtilidadesState(!dropUtilidadesState)
      }}>
      <DropdownToggle caret color="primary">
        Opciones
      </DropdownToggle>  
      <DropdownMenu style={{backgroundColor:'cyan',}} >        
        <DropdownItem onClick={ () => alert(JSON.stringify(rowRegistroClon,null,2) )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> JSON de la proforma seleccionada</DropdownItem>        
        <DropdownItem onClick={ () => alert('opcion 2 ' )}>      <FontAwesomeIcon style={{color:'dimGray'}} icon={faAddressCard} /> Opcion 2</DropdownItem>        
        <DropdownItem divider />
        <DropdownItem > <FontAwesomeIcon style={{color:'dimGray'}} icon={faRunning} /> Cerrar</DropdownItem>        
      </DropdownMenu>
    </Dropdown>
    </Row>
  
    {/******* Menu superior, para telefono vertical: solo 4 botones ************  */}
    <div id="divMenuSuperior" name="divMenuSuperior" > 
    <Row style={{margin:'0px',}} >
     <Col>
     <ButtonGroup> 
      <Button id="btnVerProformaPDF" name="btnVerProformaPDF" className="btnPpal btnBordebtnPpal"  
        onClick = {async()=>{ 
          if ( await hayFilaSeleccionada()==false ) return 
          let miResp=await gestionarCrearProformaPDF() //puedo recibir null, o el mismo numero de la proforma (es lo deseado)
          if (miResp==null) return
          
          await sleepPepe(200) //es para que no se vea el fondo verde en el lado derecho con 200 ok
          set_modalVerProformaPDF(true)  //lo ideal
        }}> 
        <span style ={{ fontSize:"35px"}}>
          <FontAwesomeIcon color="gainsboro" icon={faGlasses} />
        </span>
        <span><br/>Ver</span>
      </Button>  
      <Button id="btnTicket" name="btnTicket" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
        onClick = { () => { alert('ticket') } }>  
         <span style ={{ fontSize:"35px"}}>
         <FontAwesomeIcon color="gainsboro" icon={faReceipt}/> 
         </span>
         <span><br/>Ticket</span>
      </Button>  
      <Button id="btnPanel" name="btnPanel" className="btnPpal btnBordebtnPpal btnOcultoPeq" color="success"
        onClick = {   async() => { if (await hayFilaSeleccionada() ) set_modalPanelProformaState(true) }}>  
         <span style ={{ fontSize:"35px"}}>
         <FontAwesomeIcon color="gainsboro" icon={faVoteYea}/> 
         </span>
         <span><br/>Panel</span>
      </Button>      
      <Button id="btnEliminar" name="btnEliminar" className="btnPpal btnBordebtnPpal"  
        onClick = { async () => { 
          if (await hayFilaSeleccionada()==false ) return
          
          set_idMasState(0)
          const miRegistroEnviar="Seguro desea eliminar la proforma: \n" + rowRegistroClon.Proforma
          const miResp = await mostrarSwalConfirmacionEliminarAnular('ELIMINAR',miRegistroEnviar)  
          if (miResp.isDismissed) return //alomejor se arrepintio

          let definitivo=await eliminarPresupuestoEnBDD() //En definitivo puedo recibir: null,-100,numero positivo (osea el mismo enviado)
          if (definitivo==null) return
          if (definitivo<0) return

          //rumbo ideal
          //pongo una notificacion abajo a la derecha
          mostrarSwalPos("Eliminado",valueDC.sistema.milisegundosDeDuracionEnNotificacionesSwal)
          eliminarPresupuestoDelJson() //elimino del json y refresco la tabela                  
        }} >  
        <span style ={{ fontSize:"35px"}}>
          <FontAwesomeIcon color="gainsboro" icon={faTrashAlt} /> 
        </span>
        <span><br/>Eliminar</span>          
      </Button>  
      <Button id="btnFavorito" name="btnFavorito" className="btnPpal btnBordebtnPpal btnOcultoPeq"  
        onClick = { () => { alert ('favorito') }}>  
          <span style ={{ fontSize:"35px"}}>
          <FontAwesomeIcon color="gainsboro" icon={faThumbtack} /> 
          </span>
          <span><br/>Favorito</span>          
      </Button>  
      <Button id="btnRefresh" name="btnRefresh" className="btnPpal btnBordebtnPpal"  
        onClick = { async() => { 
          set_textoBuscarState('')
          //pongo a 0 los codigos del estado para que se quite la franja AZUL
          set_rowRegistroState(null)
          rowRegistroClon=null
          set_idMasState(0)

          determinarMesAnoActual() 
          ponerMesYanoTitulo() //solo escribe como titulo
          refrescarData()          
        }}>  
        <span style ={{ fontSize:"35px"}}>
        <FontAwesomeIcon color="gainsboro" icon={faSyncAlt} /> 
        </span>
        <span><br/>Refresh</span>          
      </Button>  
      <Button id="btnSalir" name="btnSalir" className="btnPpal btnBordebtnPpal"  
        onClick = { () => {           
          props.activarMenu()
        }}>  
        <span style ={{ fontSize:"35px"}}>
        <FontAwesomeIcon color="gainsboro" icon={faDoorOpen} /> 
        </span>
        <span><br/>Salir</span>          
      </Button>  
     </ButtonGroup>
     </Col>    
    </Row>
    </div> {/* divMenuSuperior */}
  
      {/******* para input de busqueda y cambiar mes  //style = { {width:'35%' }} // pero en dispositivos pequenos debe ser 50%  *************/}
      <div id="divBuscador1" name="divBuscador1" style = { {display:'flex', paddingLeft:'1%', marginBottom:'10px'}} > 
          <Input
            className = "textoBusquedaClientes"
            type="text" placeholder="Texto de búsqueda" name="inputBusqueda" id="inputBusqueda" 
            value={textoBuscarState}
            onChange={()=>{
              set_textoBuscarState( document.getElementById("inputBusqueda").value )
              filtrarElementos( document.getElementById("inputBusqueda").value.trim() )
          }}/>
          <Button  
            id="btnLimpiar" name="btnLimpiar" color="secondary" style = { {marginLeft:'5px' }}
            onClick={() => {           
              set_textoBuscarState('')
              set_rowRegistroState(null)
              rowRegistroClon=null
              set_idMasState(0)          
              filtrarElementos('')                      
            }}
          > 
            <FontAwesomeIcon color="paleGreen" icon={faBroom} />
          </Button>   
  
          <Label id="miPeriodo" name="miPeriodo" style={{marginLeft:"10px",marginRight:"10px",marginTop:"5px",fontSize:'18px', fontWeight:'bold',color:"white" }}>
            {
              devolverMesTextoDesdeUnNumero(mesVer) + "-" + anoVer
            }
          </Label> 
          <Button size="md" id="btnPeriodo" name="btnPeriodo" color="info" solid="true" 
           onClick={()=>{
              set_modalPedirMesYanoState(true)        
            }}>
          Cambiar
          </Button>
      </div> { /* divBuscador1 */ }
  
  </div> { /* div SubMaster */ }
  
    { /* inicio del DIV para la tabela height:'70vh', */ }
    <div id="divTabela" name="divTabela" style={{marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'20px',}} > 
      <DataTable
      //************ DATA TABLA PROPERTIES (basic) ***********/
      title='Lista de Proformas'
      columns={miEstructura}
      data={registrosFullState?.filter(item=>item.VisibleSN=='S')}
      conditionalRowStyles={filaCondicionalExterna(rowRegistroClon)} //externa por fin. Pero no sé porque no es necesario enviar la row como parametro
      keyField ='Presupuesto' /* Se puede poner 'id' (valor por omision), 'Factura' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */    
      onRowClicked={(row) => {
        var today = new Date()
        // obtener la fecha y la hora
        var now = today.toLocaleString()
        console.log(now)

        set_rowRegistroState(row)
        rowRegistroClon=row
        set_idMasState(0)         
      }}
      highlightOnHover  
      //pointerOnHover='true'
      //noDataComponent = "<h1><span>Nada por aquí</span></h1>"
      noDataComponent = ""     
      //className='string' //override the className on the Table wrapper
      //style= object overrade the className on the Table wrapper
      //style= {{minHeight:'60vh'}} //ahora se ponde dentro de estilacho
      //responsive='true' //true es el valor por defecto
      //disabled='false' //por defecto false
      //onRowClicked={miOnRowClicked()} //callback to access the row, even on row click (no pude hacerle funcionar)
      //dense //El alto de la fila se compacta (no hace falta poner = 'true'. solo se pone dense)
      //noTableHead //oculta las cabeceras
  
      //************ DATA TABLA PROPERTIES (row selection) ***********/
      //selectableRows //aparece el checkbox
      //selectableRowsVisibleOnly
      //selectableRowsHighlight
      //selectableRowsNoSelectAll
  
      //************ DATA TABLA PROPERTIES (row expander) ***********/
      //expandableRows //interesante
  
      //************ DATA TABLA PROPERTIES (sorting) ***********/
      //defaultSortField
  
      //************ DATA TABLA PROPERTIES (pagination) ***********/
      pagination
      //paginationServer //change de default pagination to work with server pagination
      paginationPerPage={ valueDC.sistema.paginadoParaProformas } // a veces da error, que requiere numero.  lo puse entre {} y se le quito
      paginationComponentOptions={paginacionOpciones}
  
      //************ DATA TABLA PROPERTIES (header) ***********/
      //actions //component or array of components
      //pepe. no poner fixedHeader
      //fixedHeader //Makes the tabale header fixed allowing you to scroll the table body
      //fixedHeaderScrollHeight = "400" //valor por defecto 100vh 
      //subHeader //me gustaria esta opcion pero no funciona
  
      //************ DATA TABLA PROPERTIES (theme theming and customization) ***********/
      //theme='dark' //interesante, ya dark existe
      //theme="solarized" //muy interesante (no implementado)
      customStyles={miEstilacho} /* redefino algun estilo */
      />    { /* del componente DataTable */ }
      </div> { /* divTabela */ }
  
      {/* ************************* MODAL PARA ENVIAR CORREO *************** */}
      <Modal style={{ backgroundColor:'blue',}} size={'md'}  isOpen={ modalCorreoState } >
        <FormularioCorreo 
          cerrarModalCorreo={async(accion,miAsunto,miMensaje)=>{
            set_idMasState(0)
            set_modalCorreoState(false)
            //accion puede ser: close/send
            if (accion=='close') return      
            if (accion=='send') {
              correoEnviadoCorrectamenteSN = await enviarCorreoNoSriConAsuntoMasMensajeMasUnArchivoConSwal(rowRegistroClon?.RazonSocial,rowRegistroClon?.EmailCliente,'S','PR_' + rowRegistroClon?.Estab + "-" + rowRegistroClon?.Punto + "-" + rowRegistroClon?.NumPre + ".pdf",valueDC,miAsunto,miMensaje)
              gestionarTildarEnvioDeCorreoEnGridMasBDD() //debo tildar en la tabela y tambien en la BDD
            }            
          }}
          destinoNombre={rowRegistroClon?.RazonSocial}
          destinoEmail={rowRegistroClon?.EmailCliente}
          asuntoDefault={"PROFORMA SOLICITADA"}
          mensajeDefault={"Atención: \n" + rowRegistroClon?.RazonSocial}
        />
      </Modal>
  
      {/* *********** MODAL PARA PEDIR MES Y ANO ************ */}
      <Modal style={{ backgroundColor:'blue',}} size={'sm'}  isOpen={ modalPedirMesYanoState } >
        <PedirMesYano_OK 
          cerrarModal={async(accion,mesNuevo,anoNuevo)=>{
            set_modalPedirMesYanoState(false)
            //accion puede ser: close/select 
            if (accion=='close') return
            //rumbo ideal....          
            set_textoBuscarState('')
            set_rowRegistroState(null)
            rowRegistroClon=null
            set_idMasState(0)
            mesVer=mesNuevo
            anoVer=anoNuevo
            ponerMesYanoTitulo()
            refrescarData()            
          }}
          mesActual={mesVer}
          anoActual={anoVer}
        />
      </Modal>  
  
      {/* *********** MODAL PARA VER EL PANEL DE LA PROFORMA ************ */}
      <Modal size={'md'}  isOpen={ modalPanelProformaState } >
        <MostrarPanelDocNoSri
          cerrarModal={()=> {
            set_modalPanelProformaState(false)
          }}
          row={rowRegistroClon}       
          tipoDoc={"PROFORMA"}
        />
      </Modal>            

      {/* *********** MODAL PARA VER LA PROFORMA EN PDF **************** */}
      <Modal size={'lg'} isOpen={ modalVerProformaPDF } >
        <VerPdfOk
          documentoTitulo={"PROFORMA"}
          documentoArchivo={"PR_" + rowRegistroClon?.Estab + "-" + rowRegistroClon?.Punto + "-" + rowRegistroClon?.NumPre }
          corchetesSN={"S"} //SI quiero ver entre corchetes el nombre del archivo
          tipoSriSN={"N"} //es un documento del sri?
          estiloPantallaPG={"P"} //Para modal le mando P
          activarMenu={ () => set_modalVerProformaPDF(false) } 
        />
      </Modal>      

</div> //  del divMaster
) //del return interno
}

const ComponentePrincipal=()=>{
  return (
    <AppContextConsumer>
      { (value) => {
        valueDC=value //copio el context a mi variable global
        return ComponentePrincipalBody()
      } }
    </AppContextConsumer>
  )
}

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return ComponentePrincipal()
if (nombreComponenteParaVerState=='facturar') {
  return (<Facturacion 
    docElab="PR_MOD"
    miEstab={ rowRegistroClon.Estab } 
    miPunto={ rowRegistroClon.Punto } 
    miNumPre={ rowRegistroClon.NumPre } 
    actualizarUnaFilaDelJson={actualizarUnaFilaDelJson}
    verPvpSinIva={(valueDC.sistema.mallaDeVentasVerPreciosSinIva=="Si") ? true : false}
    verPvpConIva={(valueDC.sistema.mallaDeVentasVerPreciosConIva=="Si") ? true : false}      
    activarMenu={ () =>  set_nombreComponenteParaVerState('lista')  } 
    />)
  }

} //de TabelaPresupuestosLista

export default TabelaPresupuestosLista
