//modal para leer un texto especifico. Por ejemplo al facturar para cambiar la descripcion
import React from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
 
export default class PedirTextoGenerico extends React.Component {

regresarAlLlamador=()=>{
//puede haber vacio, pero el llamador evalua ese caso
let textoNuevo=document.getElementById('txtTexto').value.trim()

this.props.cerrarModal()
this.props.regresarAlLlamador(textoNuevo)  
}

componentDidMount(){    
  document.getElementById('txtTexto').value=this.props.textoViejo
  setTimeout(()=>{ 
    document.getElementById("txtTexto").focus()  
  },1)
}

render(){          
let miTitulo=this.props.miTitulo
let miLongitudMaxima=this.props.miLongitudMaxima
return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Titulo + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{miTitulo}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ this.props.cerrarModal()  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 
  {/* Fila para poner el texto */}
  <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink'}}>
    <Row style={{margin:'0'}}>
      <Col xs="12">
        <Input type="text" maxLength = {miLongitudMaxima} name="txtTexto" id="txtTexto" style={{textAlign:'left',height:'45px',fontSize:'20px' }}
          onKeyPress = {(e) => { 
            let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
            if ( miKey ==13) 
                this.regresarAlLlamador()
          }}/>      
      </Col>
    </Row>  
    {/* Boton Aceptar */}
    <Row style={{margin:'0'}}>
      <Col style={{textAlign:'right',marginTop:'20px'}}>    
        <Button size="lg" id="btnListo" name="btnListo" color="info" solid="true" 
          onClick={()=>{this.regresarAlLlamador()}}>LISTO
        </Button>
      </Col>         
    </Row>    
    
  </div> {/* divBloque1  */}
</div> // divMaster
)}
}
