// me sirve para editar un valor. generalmente usado para cambiar la cantidad de productos vendidos o comprados
// en las props debe venir la variable miValorTexto2D (de tipo texto y con 2 decimales)
// al final devuelvo una variable tipo numerico ya redondeado con 2 decimales

import React from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,sleepPepe } from './lib_basica'
import { aceptarSoloEnteros,aceptarDecimales } from './lib_basica'
 
export default class PedirCantidad extends React.Component {

cambiarCantidadYsalir=()=>{ 
let miCant=document.getElementById('txtCant').value
miCant=parseFloat(miCant)

if (isNaN(miCant)) {
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un número válido")
  return
}

if (miCant<=0 && this.props.miAceptaCero==false) {
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un número mayor que CERO")
  return
}

miCant=Math.round(miCant*100)/100
miCant=miCant.toFixed(2) 
miCant=parseFloat(miCant) 
  
this.props.cerrarModal(true,miCant)
}

componentDidMount(){   
  document.getElementById("txtCant").value= (this.props.miValorTexto2D*1)
  setTimeout(()=>{ 
    document.getElementById("txtCant").focus()  
  },50)
}
  
render(){ 
return (
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Nombre del producto + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginTop:'5px',fontWeight: 'bold'}}>{this.props.miTitulo}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
      <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
        onClick= { () =>{ this.props.cerrarModal(false,null)  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
      </Button>
    </Col>
  </Row> 
  <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink'}}>
    <Row style={{margin:'0'}}>
      <Col xs="7" >
        <Label for="txtCant">{this.props.miSubTitulo}</Label>
        <Input type="number" inputMode="decimal"  style={{textAlign:'center', fontWeight: 'bold', height:'80px',fontSize:'50px' }} name="txtCant" id="txtCant"      
          onKeyPress = {(e) => { 
            aceptarDecimales(e) 
            let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
            if ( miKey ==13) 
              this.cambiarCantidadYsalir()
          }}/>
        <Label for="txtCant">(Máx 2 decimales)</Label>
      </Col>
      <Col xs="5" style={{textAlign:'center',}}>    
        <Button style={{width:'120px', height:'120px',fontSize:'60px',}} id="btnEnter" name="btnEnter" color="success" 
          onClick= {()=> {
            this.cambiarCantidadYsalir() 
          }}>
          <FontAwesomeIcon color="purple" icon={faCheck} /> 
        </Button>
      </Col>         
    </Row>  
  </div> {/* divBloque1 */}
</div> //divMaster
)}
}