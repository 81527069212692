//esta peequena tabela, me muestra las retenciones asociadas a una Compra 
//y me permite re-imprimir

import React, {useState,useEffect} from 'react'
import {Row,Col,Modal,Button,Input,Label} from 'reactstrap'
import {Dropdown,DropdownItem, DropdownMenu,DropdownToggle} from 'reactstrap'
import DataTable  from 'react-data-table-component'
import {hayInternet,listarRetencionesAsociadasAunaCompraConSwal } from './lib_basica'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBroom,faWindowClose,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//del context
import {AppContextConsumer} from './ContextBase'

//variables, que no necesitan estar en el estado
let valueDC=null //Aqui hago una copia de los valores del context
let rowRegistroClon=null //es una copia del estado de la variable rowRegistroState. (ya que no siempre el estado se actualiza tan rapido, la variable si es rapida)

const paginacionOpciones={
    rowsPerPageText:'Filas por Página',    
    rangeSeparatorText: 'de',
    selectAllRowsItem:true,
    selectAllRowsItemText:'Todos',
}

const miEstilacho={
	table: {
		style: {
			minHeight: '33vh',      
		},
	},  
  //el header, se refiere al titulo que dice 'LISTA DE CLIENTES'
	header: {
		style: {
			color: 'black',
			backgroundColor: 'hotpink',
      //fontSize:'22px',   
      //fontWeight: 'bold',
		},
	},   
  headCells: {
    style: {
      //paddingLeft: '50px', // no me funciona, override the cell padding for head cells
      //paddingRight: '8px',
      background: 'hotpink', //ok
      color: 'lavender',  //ok lavender.
      fontSize:'18px',   //ok 18
      fontWeight: 'bold'
    },
  },
  rows: {
    style: {
        minHeight: '44px', // bacan '30px' o 20% (le puse 44px para que el boton de EDITAR quede centrado a lo alto)
    }
  },    
    cells: {
      style: {
        fontSize:'16px', //16px
        //el borde solo lo quiero ABAJO
        // top | right | bottom | left 
        //border-style: none solid dotted dashed;      
        borderStyle:"none none solid none", 
        borderColor:'silver',
        borderWidth:'thin',           
        //marginLeft:'20px', //funciona
        //paddingLeft: '80px', // no me funciona override the cell padding for data cells
        //paddingRight: '8px',
        //color: 'dimgray', // NO USAR EL COLOR AQUI. el color de la celda se maneja en  FILACONDICIONAL            
        //backgroundColor:'yellow', //
        //fontWeight:'bold',
        //height:'10px', /* bacan */
        //paddingTop:'0', /* no funciona */
        //paddingBottom:'0', /* no funciona */
        //margin:0, /* no me funciona */
      },
    },
}

export default function RetencionesAsociadasAunaCompra(props){
const [rowRegistroState,set_rowRegistroState]=useState(null) //mas que todo para color de la fila seleccionada y la tabela
const [nombreComponenteParaVerState,set_nombreComponenteParaVerState]=useState('lista')
const [registrosFullState,set_registrosFullState]=useState([]) //para guardar todos los registros

const filaCondicional=[
  //fila NO seleccionada
  {
    when: row => (rowRegistroState==null || row.Retencion != rowRegistroState.Retencion ),
    style: row => ({ 
      backgroundColor: 'white',
      color: 'dimgray',
     }),    
  },
  //fila seleccionada
  {
    when: (row) => (rowRegistroState !=null && row.Retencion == rowRegistroState.Retencion ),
    style: row=> ({    
      backgroundColor: valueDC.sistema.coloresFilaSeleccionadaSecundaria,
      color: 'black',
    }),
  },
]

const miEstructuraDataTable=[  
  {  
    name:'RETENCION', //lo que el usario ve. 17 caracteres
    selector:row => row.Retencion,
    sortable:true,
    left:true,
    grow:1.5,  
    compact:true, 
    omit:false, //en algunos casos, se debe ocultar algunas columnas
  },
  {
    name:'FECHA RET.',
    selector:row => row.FechaEmisionFormat,
    sortable:true,
    left:true,
    compact:true,
    grow:1, //es una medida relativa (1 es mi referencia)
  },    
  {
    name:'',
    sortable:false,
    center:true,
    compact:true,
    grow:1.6, //originalmente 1.6
    //width:'20%',
        
    cell: (row) => 
    <div style={{ display:'flex' }}>            
      {/* hay un error al poner primary. se debe poner primary ="true" (revisar en la documentacion de ReactsTrap */}
      {/* tambien da error al poner raised. se debe poner raised="true"  */}
      {/* o se puede poner raised= {miVariable.toString() }  */}

    {/* boton Listo  */}
    <Button id="btnVer" name="btnVer" style={{ marginRight:'1px',color:'white',fontWeight: 'bold' }} raised = "true" primary='false' color="info" 
      onClick={ () => { 
        set_rowRegistroState(row)
        rowRegistroClon=row
        //props.cerrarModal('select',rowRegistroClon.IdPrincipal.trim())
        //desmontar() //de ultimo
      }}>Ver
    </Button>
          
    </div>,
  },    
  {
    name:'DocQueSeModifica',
    selector:row => row.DocQueSeModifica,
    sortable:true,
    compact:true,
    left:true,
    grow:1,
    omit:true,
  },    
  {
    name:'CodProvRojo',
    selector:row => row.CodProvRojo,
    sortable:true,
    compact:true,
    grow:0.9,
    omit:true,
  },    
  {
    name:'ESTAB',
    selector:row => row.Estab,
    sortable:true,
    compact:true,
    grow:1.5, 
    omit:true, 
  }, 
  {
    name:'PUNTO',
    selector:row => row.Punto,
    sortable:true,
    compact:true,
    grow:1,
    omit:true, 
  },      
  {
    name:'NumRet',
    selector:row => row.NumRet,
    sortable:true,
    compact:true,
    grow:1,
    omit:true, 
  },  
]

const ponerCabecera=()=>{
  //Tipo de documento [FACTURA, NOTA DE CREDITO, LIQUIDACION]
  let miTipoDoc=null
  switch (props.TipoDocFNL) {
    case 'F':
      miTipoDoc='FACTURA' 
      break;
    case 'N':
      miTipoDoc='NOTA DE DEBITO'
      break
    case 'L':
      miTipoDoc='LIQUIDACION'
      break
  }
  document.getElementById("txtTipoDoc").value= miTipoDoc
  //Numero
  document.getElementById("txtNumDoc").value= props.doc17DG
  //Proveedor
  document.getElementById("txtProveedor").value= props.RazonSocial
  //Fecha
  document.getElementById("txtFecha").value= props.fechaDGformat

}

const refrescarData=async()=>{
  //busco la informacion desde la BDD, al haber algun error entonces me salgo automaticamente
  //OJO: se espera un array de filas, lo mas comun es que venga con una sola Fila. Pero podrían ser varias
  let respuestaJson = await listarRetencionesAsociadasAunaCompraConSwal(
    props.IDr,props.TipoDocFNL,props.estabDG,props.puntoDG,props.numDG,valueDC
    )
    
  //evaluo la respuesta (en caso venir null, me salgo)  
  let salirme=false
  if (respuestaJson==null){
    salirme=true
  }
  if (salirme==true){
    props.cerrarModal(false) //el false es para indicar que NO se pudo cargar la data
    desmontar() //de ultimo
  }
  if (salirme==false){
    set_registrosFullState(respuestaJson)
  }
}

//************ simulo el componentDidMount */
//el useEffect me simula el didMount (siempre y cuando los corchetes [] esten vacios) 
useEffect(()=>{
  //blanqueo variables sensibles, que no sean del estado
  rowRegistroClon=null 
  //pongo el encabezado (esos datos vienen desde las props) 
  ponerCabecera()
  //leo de la BDD
  refrescarData()
  },[]
)

const desmontar=()=>{
  set_registrosFullState([])
  rowRegistroClon=null
}

const copiarVariablesDesdeContext=(value)=>{
 valueDC=value
}

const Componente_ListaRegistros=()=>{  
return(
<AppContextConsumer>
{value =>{
return(      
<div id="divMaster" name="divMaster" style={{width:'100%',minHeight:'70vh',background:'gray'}}>
   { copiarVariablesDesdeContext(value) } 
    {/**** barra de titulo  */}
    <div id="divBarraTitulo" name="divBarraTitulo"> 
      <Row style={{margin:'0'}}> 
        <Col xs="10">       
          <Label style={{marginTop:'5px',fontWeight: 'bold',color:"pink"}}>{"Retenciones Asociadas"}</Label>
        </Col>
        <Col xs="2" style={{textAlign:'right',padding:'0'}}>
          <Button style={{width:'40px', height:'40px', }} id="btnCerrar" name="btnCerrar" color="danger" 
            onClick= { () =>{ 
              props.cerrarModal(true) //realmente se pudo leer de la BDD
              desmontar() //de ultimo    
            }}>
            <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
          </Button>
        </Col>
      </Row>
    </div> { /* divBarraTitulo */ }  

    {/* Datos del documento generador: numero, fecha, etc */}
    <div id="divDG" name="divDG"  style={{width:'98%',marginLeft:'1%',marginRight:'1%',marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink'}}>    
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0',}}>
        <Col xs="6">
          <Label style={{width:"30%" }} >Generador</Label>
          <Input name="txtTipoDoc" id="txtTipoDoc" disabled style={{marginLeft:"0%",width:"100%",textAlign:'center'}}/>           
        </Col>        
        <Col xs="6">
          <Label style={{width:"30%" }}>Número</Label>
          <Input id="txtNumDoc" name="txtNumDoc" disabled style={{marginLeft:"0%",width:"100%",textAlign:'center'}}/>           
        </Col>  
      </Row>
      <Row style={{marginBottom:"1%",marginLeft:'0',marginRight:'0',}}>
        <Col xs="6">
          <Label style={{width:"30%" }} >Proveedor</Label>
          <Input name="txtProveedor" id="txtProveedor" disabled style={{marginLeft:"0%",width:"100%",textAlign:'center'}}/>           
        </Col>
        <Col xs="6">
          <Label style={{width:"30%" }} >Fecha</Label>
          <Input id="txtFecha" name="txtFecha" disabled style={{marginLeft:"0%",width:"100%",textAlign:'center'}}/>           
        </Col>  
      </Row>
    </div> {/* divDG */}

    { /* ***** TABELA ******/ }
    <div id="divTabela" name="divTabela" style={{marginTop:'2%',marginLeft:'1%',marginRight:'1%',borderStyle:"solid", borderColor:'black', borderWidth:'2px', background:'purple', width:'98%', marginBottom:'1%',}} > 
      <DataTable
        //************ DATA TABLA PROPERTIES (basic) ***********/
        title='Retenciones Asociadas'
        columns={miEstructuraDataTable}
        data={registrosFullState}
        conditionalRowStyles={filaCondicional}  
        keyField ='Retencion' /* Se puede poner 'id' (valor por omision), 'IDr' o cualquier campo que sea mi clave...obligatoriamente se refiera a la propiedad SELECTOR */
        //este onRowClicked, me sirve solamente para que al tocar/clickear un fila, se ponga de fondo morado u otro color
        onRowClicked={(row) => { 
          set_rowRegistroState(row)
          rowRegistroClon=row
        }}
        highlightOnHover  
        noDataComponent = ""
        customStyles={miEstilacho} /* redefino algun estilo */
      />    { /* del componente DataTable */ }
    </div> { /* divTabela */ }

</div> //Div principal
) // del return interno
}
}
</AppContextConsumer>    
)//Del return externo 
} //del componente: Componente_ListaRegistros

//*******************************************************************
// ***************** Programa principal *****************************
//*******************************************************************
if (nombreComponenteParaVerState=='lista') return Componente_ListaRegistros()

} //del export 
