//alert(JSON.stringify(registrosFullState,null,2) 

import React from 'react'
import { Input, Label, Button, Row, Col} from 'reactstrap'
//awensome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckSquare, faWindowClose,faFileInvoiceDollar,faListOl,faPercent,faLayerGroup, faCubes,faSearch,faMinus,faPlusCircle,faPlus,faCheckCircle,faCheck,faTimes,faTimesCircle,faBookReader,faUserAlt,faUser,faUserPlus,faUserAltSlash,faEnvelope,faCommentDollar, faDollarSign, faMoneyBill, faEye,  faAddressCard, faPrint, faFileExcel,faEdit,faReplyAll,faTrashAlt,faEllipsisH,faSyncAlt } from '@fortawesome/free-solid-svg-icons' 
//otros
import {gestionarCatch,mostrarSwalEspera,apagarSwal,mostrarSwalBotonAceptar,mostrarSwalPos,mostrarSwalConfirmacionEliminarAnular,mostrarSwalReintentar,sleepPepe } from './lib_basica'
import { aceptarSoloEnteros,aceptarDecimales } from './lib_basica'
 
export default class PedirPrecioEspecifico extends React.Component {
  miPvpSin=0
  miPvpCon=0
  miIvaDef=null

cambiarPrecioYsalir=async()=>{
//no puede haber vacio
let campo1=document.getElementById("txtPvpSinIva").value.trim()
let campo2=document.getElementById("txtPvpConIva").value.trim()

if (campo1.length==0 || campo2.length==0) {
  mostrarSwalBotonAceptar("error","ATENCION","El precio no puede estar vacío")
  return
}

if (isNaN(this.miPvpSin)) {
  mostrarSwalBotonAceptar("error","ATENCION","Debe indicar un precio válido")
  return
}

//No Se acepta negativo
if (this.miPvpSin<0) {
  mostrarSwalBotonAceptar("error","ATENCION","No se permite negativos")
  return  
}

//Se acepta cero, pero da la alerta
if (this.miPvpSin==0) {
  let miEnviar="Seguro que desea colocar el Precio en 0 ?"
  let miResp = await mostrarSwalConfirmacionEliminarAnular('DESEA CONTINUAR ?',miEnviar)
  if (miResp.isDismissed) 
    return  //alomejor se arrepintio        
}

//da la alerta si esta vendiendo por debajo del costo promedio
/*
let miCostoPromedio=parseFloat(this.props.miFilaAzulJson.CostoP)

if (this.miPvpSin>0 && this.miPvpSin<miCostoPromedio){
  let miEnviar="El precio de venta está por debajo del costo ponderado"
  let miResp = await mostrarSwalConfirmacionEliminarAnular('DESEA CONTINUAR ?',miEnviar)
  if (miResp.isDismissed) 
    return  //alomejor se arrepintio        
}
*/

this.props.cerrarModal()
this.props.cambiarPrecioEspecifico(this.miPvpSin)  //el parametro va como NUMBER
}

calcularPvpConIva=()=>{
  let tmp=this.miPvpSin+this.miPvpSin * this.miIvaDef/100
  tmp=Math.round(tmp*10000)/10000
  document.getElementById("txtPvpConIva").value=tmp.toFixed(4)
}

calcularPvpSinIva=()=>{
  let miFactor= 1 + this.miIvaDef/100
  let tmp=this.miPvpCon / miFactor
  tmp=Math.round(tmp*10000)/10000
  document.getElementById("txtPvpSinIva").value=tmp.toFixed(4)

  this.miPvpSin=parseFloat(document.getElementById("txtPvpSinIva").value)
}

componentDidMount(){  
  this.miIvaDef=parseFloat(this.props.miIvaDef)

  //bloquear el txt correspondiente
  if (this.props.deseoPvpSinCon=="Sin"){
    document.getElementById('txtPvpConIva').disabled = true
    setTimeout(()=>{ 
      document.getElementById("txtPvpSinIva").focus()  
    },1)    
  }
  else  {
    document.getElementById('txtPvpSinIva').disabled = true
    setTimeout(()=>{ 
      document.getElementById("txtPvpConIva").focus()  
    },1)    
  }
  
  //de tener el P0, entonces lo pongo de una vez 
  if (this.props.miFilaAzulJson.Precio01234=="0"){
    document.getElementById('txtPvpSinIva').value=this.props.miFilaAzulJson.PvpSinIva * 1
    this.miPvpSin=parseFloat(this.props.miFilaAzulJson.PvpSinIva)    
    this.calcularPvpConIva()
  }
}

render(){          
let descripcion40Letras=this.props.miFilaAzulJson.DescripcionModificada.substring(0,38)
return (               
<div id="divMaster" name="divMaster" style={{background:'hotPink'}}>    
  {/* Nombre del producto + Boton Cerrar*/}
  <Row style={{margin:'0',}}>
    <Col xs="10" >
      <Label style={{marginLeft:'2%',fontWeight: 'bold'}}>{descripcion40Letras}</Label>
    </Col>
    <Col xs="2" style={{textAlign:'right',padding:'0'}}>
    <Button style={{width:'40px', height:'40px',}} id="btnCerrar" name="btnCerrar" color="danger" 
      onClick= { () =>{ this.props.cerrarModal()  }} >
      <FontAwesomeIcon color="aquamarine" icon={faWindowClose} /> 
    </Button>
    </Col>
  </Row> 
  {/* Fila para poner los dos precios*/}
  <div id="divBloque1" name="divBloque1" style={{marginLeft:"3%",marginRight:"3%",marginTop:"3%",marginBottom:'3%',paddingTop:'2%',paddingBottom:'2%',background:'purple', color:'pink'}}>
    <Row style={{margin:'0'}}>
      <Col xs="6">
        <Label>{this.props.etiquetaPrecioCosto} Sin Iva</Label>
        <Input name="txtPvpSinIva" id="txtPvpSinIva" type="number" inputMode="decimal" style={{textAlign:'center', fontWeight: 'bold', height:'55px',fontSize:'30px' }}
          onKeyPress = {(e) => { 
            aceptarDecimales(e)        
            let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
            if ( miKey ==13) 
                this.cambiarPrecioYsalir()
          }}   
          onChange = {(e)=>{
            this.miPvpSin=parseFloat(document.getElementById("txtPvpSinIva").value)
            this.calcularPvpConIva()
          }}/>      
      </Col>
      <Col xs="6">
        <Label>{this.props.etiquetaPrecioCosto} Con Iva</Label>
        <Input name="txtPvpConIva" id="txtPvpConIva" type="number" style={{textAlign:'center', fontWeight: 'bold', height:'55px',fontSize:'30px' }} 
          onKeyPress = {(e) => { 
            aceptarDecimales(e)        
            let miKey= e.keyCode || e.which //Codigo de la tecla que esta entrando
            if ( miKey ==13) 
                this.cambiarPrecioYsalir()
          }}   
          onChange = {(e)=>{
            this.miPvpCon=parseFloat(document.getElementById("txtPvpConIva").value)
            this.calcularPvpSinIva()
          }}/>      
      </Col>
    </Row>  
    {/* Boton Aceptar */}
    <Row style={{margin:'0'}}>
      <Col xs="6" style={{textAlign:'left',marginTop:'40px', color:'silver',}}>    
        <Label>(Máx 4 decimales)</Label>
      </Col>   
      <Col xs="6" style={{textAlign:'right',marginTop:'20px'}}>    
        <Button size="lg" id="btnGuardar" name="btnGuardar" color="info" solid="true" 
          onClick={()=>{this.cambiarPrecioYsalir()}}>LISTO
        </Button>
      </Col>         
    </Row>        
  </div> {/* divBloque1  */}
</div> // div externo (master)
)}
}
